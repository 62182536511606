import { View } from "@/components/ui";
import { Warning } from "@/components/shared/icons";
import { cn } from "@/lib/utils";
import { Typography } from "../../typography";

export const ErrorMessage = ({
  errorMessage,
  className,
}: {
  errorMessage: string | React.ReactNode;
  className?: string;
}) => {
  if (!errorMessage) return <></>;
  return (
    <View
      className={cn(
        "flex flex-row items-center gap-2 rounded-lg border border-secondary-border bg-primary-surface p-3 text-secondary-text",
        className,
      )}
    >
      <Warning
        weight="fill"
        size={20}
        className="shrink-0 color-secondary-text"
      />

      <View className="flex-1 text-wrap">
        {typeof errorMessage === "string" ? (
          <Typography
            variant="body-md"
            color="secondary-text"
            className="w-full"
          >
            {errorMessage}
          </Typography>
        ) : (
          errorMessage
        )}
      </View>
    </View>
  );
};
