export const getCookie = (key: string): string | null => {
  const target = `${key}=`
  const cookies = decodeURIComponent(document.cookie).split(';')

  for (let cookie of cookies) {
    cookie = cookie.trim()
    if (cookie.startsWith(target)) {
      return cookie.slice(target.length)
    }
  }

  return null
}

export const setCookie = (
  key: string,
  value: string,
  expInDays = 7,
  path = '/'
): string => {
  const expires = new Date(Date.now() + expInDays * 864e5).toUTCString()
  document.cookie = `${key}=${value};expires=${expires};path=${path}`
  return value
}
