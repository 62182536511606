import { createRequestFactory } from "@/lib/request-factory";
import type {
  CreateContactMutation,
  CreateContactMutationVariables,
} from "@/__generated__/graphql";
import { CreateContactDocument } from "@/__generated__/graphql";
import type { Contact } from "@/types/users";

type Response = Contact;

export const useCreateContactStore = createRequestFactory<
  CreateContactMutation,
  Response,
  CreateContactMutationVariables
>({
  method: "mutation",
  graphqlDocument: CreateContactDocument,
  transformFunction: (res) => {
    if (res.createContact?.contact) {
      return res.createContact.contact;
    }
    throw new Error("request failed");
  },
});
