import { CaretRight } from "@/components/shared/icons";
import { Badge, View } from "@/components/ui";
import React from "react";
import { cn } from "@/lib/utils";

export interface NavbarListItemProps {
  icon?: React.ReactNode;
  title: string;
  showTrailingIcon?: boolean;
  badge?: string;
  size?: "large" | "small";
  onClick?: () => void;
}

export function NavbarListItem({
  icon,
  title,
  showTrailingIcon = true,
  badge,
  size = "large",
  onClick,
}: NavbarListItemProps) {
  return (
    <View
      onClick={onClick}
      className={cn(
        "ease flex cursor-pointer flex-row items-center justify-between rounded-xl border border-transparent transition color-white hover:border-brand-static-border hover:bg-brand-over-static",
        {
          "px-3 py-4": size === "large",
          "p-3": size === "small",
        },
      )}
    >
      <View className="flex flex-row items-center">
        {icon && <View className="mr-2.5">{icon}</View>}
        <View>{title}</View>
      </View>
      <View className="flex flex-row items-center">
        {badge && (
          <Badge variant="danger-fill" outline={false} className="py-0">
            {badge}
          </Badge>
        )}
        {showTrailingIcon && <CaretRight />}
      </View>
    </View>
  );
}
