import { View } from "@/components/ui";
import type { CreditAccountTransaction } from "@/__generated__/graphql";
import { CreditAccountTransactionTypeEnum } from "@/__generated__/graphql";
import { formatDateTime } from "@/lib/helpers/date";
import { formatPrice } from "@/lib/helpers/number";
import { cn } from "@/lib/utils";
import { UsersThree, TrayArrowUp, Receipt } from "@/components/shared/icons";
import { Typography } from "../typography";

function transactionHistoryIcon({
  transactionType,
}: {
  transactionType: CreditAccountTransactionTypeEnum;
}): React.ReactNode {
  switch (transactionType) {
    case CreditAccountTransactionTypeEnum.Referral:
      return <UsersThree size={28} weight="fill" className="text-primary" />;
    case CreditAccountTransactionTypeEnum.Payment:
      return <Receipt size={28} weight="fill" className="text-danger" />;
    case CreditAccountTransactionTypeEnum.TopUp:
      return <TrayArrowUp size={28} weight="fill" className="text-success" />;
    default:
      return <UsersThree size={28} weight="fill" className="text-primary" />;
  }
}

type Props = {
  transaction: CreditAccountTransaction;
  icon?: React.ReactNode;
};

export default function TransactionHistoryCard({ transaction, icon }: Props) {
  return (
    <View
      key={transaction.id}
      className="flex w-full flex-1 flex-row gap-2 border-b border-secondary-border py-4"
    >
      {icon &&
        transactionHistoryIcon({
          transactionType: transaction.transactionType,
        })}
      <View className="flex flex-1 flex-row justify-between">
        <View>
          <Typography variant="body-xl">{transaction.description}</Typography>
          <Typography variant="body-md" color="foreground-intermediate">
            {formatDateTime(
              new Date(transaction.createdAt),
              "d LLL y',' hh':'mm",
            )}
          </Typography>
        </View>
        <Typography
          variant="h7"
          className={cn(
            "",
            transaction.transactionType ===
              CreditAccountTransactionTypeEnum.TopUp
              ? "text-success"
              : "text-danger",
          )}
        >
          {formatPrice(transaction.amount)}
        </Typography>
      </View>
    </View>
  );
}
