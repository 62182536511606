import * as React from "react";
import type { SVGProps } from "react";
const SvgGoogleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 41 40" fill="none" {...props}>
    <rect x={1} y={0.5} width={39} height={39} rx={7.5} fill="#EEF7FF" />
    <rect x={1} y={0.5} width={39} height={39} rx={7.5} stroke="#003499" />
    <path
      d="M30.2392 19.07C30.1892 18.56 29.7592 18.18 29.2492 18.18H21.6992C21.1492 18.18 20.6992 18.63 20.6992 19.18V20.89C20.6992 21.44 21.1492 21.89 21.6992 21.89H26.2092C26.0992 22.81 25.4992 24.2 24.1692 25.13C23.3192 25.72 22.1892 26.13 20.6992 26.13C20.6292 26.13 20.5692 26.13 20.4992 26.12C17.9492 26.04 15.7892 24.33 15.0092 21.98C14.7992 21.35 14.6792 20.69 14.6792 20C14.6792 19.31 14.7992 18.64 14.9992 18.02C15.0592 17.84 15.1292 17.66 15.2092 17.48C16.1292 15.41 18.1392 13.95 20.4992 13.88C20.5592 13.87 20.6292 13.87 20.6992 13.87C22.1292 13.87 23.1992 14.34 23.9492 14.86C24.3392 15.13 24.8592 15.07 25.1992 14.74L26.5892 13.38C27.0292 12.95 26.9892 12.22 26.4892 11.86C24.8992 10.69 22.9592 10 20.6992 10C20.6292 10 20.5692 10 20.4992 10.01C16.6692 10.08 13.3792 12.3 11.7692 15.51C11.0892 16.87 10.6992 18.39 10.6992 20C10.6992 21.61 11.0892 23.13 11.7692 24.49H11.7792C13.3892 27.7 16.6792 29.92 20.4992 29.99C20.5692 30 20.6292 30 20.6992 30C23.3992 30 25.6692 29.11 27.3192 27.58C29.2092 25.83 30.2992 23.27 30.2992 20.22C30.2992 19.79 30.2792 19.42 30.2392 19.07Z"
      fill="#1155D9"
    />
  </svg>
);
export default SvgGoogleIcon;
