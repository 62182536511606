import { Dialog, DialogContent, View, Button } from "@/components/ui";
import { Typography } from "@/components/shared/typography";
import { SmileySad } from "@/components/shared/icons";
import { useRoute } from "@/components/shared/router";
import { useAuthState } from "@/store/auth";
import { storage } from "@/lib/storage";
import { DEFAULT_JWT_DATA, DEFAULT_USER_DATA } from "@/constants";

export const DeleteSucceedModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen(o: boolean): void;
}) => {
  const { replace } = useRoute();
  const onReturnToHomePage = () => {
    const { setUserInfo, setJwtInfo } = useAuthState.getState();
    setUserInfo(DEFAULT_USER_DATA);
    setJwtInfo(DEFAULT_JWT_DATA);
    storage.deleteAll();
    replace({ pageKey: "homepage" });
  };

  const onClose = () => {
    setOpen(false);
    onReturnToHomePage();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="md:w-[375px]">
        <View className="flex flex-col items-center gap-4">
          <SmileySad size={32} className="text-brand-primary" />
          <Typography variant="label-xl">
            Your account has been deleted
          </Typography>
          <Typography variant="body-md">
            We are sad to see you go. Your account has been successfully
            deleted. Thank you for using our service. We hope to serve you again
            in the future.
          </Typography>
          <Button
            variant="primary"
            color="CTA"
            className="mt-2 w-full"
            onClick={onReturnToHomePage}
            children="Return to Homepage"
          />
        </View>
      </DialogContent>
    </Dialog>
  );
};
