import { useEffect } from "react";
import { useRoute } from "@/components/shared/router";
import type { ServiceName } from "@/types/service";
import { useBookingState } from "@/store/booking/useBookingState";
import { useParams } from "@/components/hooks/use-params";
import { usePathname } from "./use-pathname";

export const bookingRoutes: Record<ServiceName, string[]> = {
  "home-cleaning": [
    "/service-detail/home-cleaning",
    "/booking/home-cleaning",
    "/booking/home-cleaning/select-slot",
    "/booking/home-cleaning/booking-info",
    "/booking/home-cleaning/confirmation",
    "/booking/home-cleaning/complete-booking",
  ],
  "mattress-cleaning": [
    "/service-detail/mattress-cleaning",
    "/booking/mattress-cleaning",
    "/booking/mattress-cleaning/select-slot",
    "/booking/mattress-cleaning/booking-info",
    "/booking/mattress-cleaning/confirmation",
    "/booking/mattress-cleaning/complete-booking",
  ],

  "sofa-cleaning": [
    "/service-detail/sofa-cleaning",
    "/booking/sofa-cleaning",
    "/booking/sofa-cleaning/select-slot",
    "/booking/sofa-cleaning/booking-info",
    "/booking/sofa-cleaning/confirmation",
    "/booking/sofa-cleaning/complete-booking",
  ],
  "rug-cleaning": [
    "/service-detail/rug-cleaning",
    "/booking/rug-cleaning",
    "/booking/rug-cleaning/select-slot",
    "/booking/rug-cleaning/booking-info",
    "/booking/rug-cleaning/confirmation",
    "/booking/rug-cleaning/complete-booking",
  ],
  "pram-seat": [
    "/service-detail/pram-seat",
    "/booking/pram-seat",
    "/booking/pram-seat/select-slot",
    "/booking/pram-seat/booking-info",
    "/booking/pram-seat/confirmation",
    "/booking/pram-seat/complete-booking",
  ],
  "aircon-service": [
    "/select-service/aircon-service",
    "/service-detail/aircon-service",
    "/booking/aircon-service",
    "/booking/aircon-service/select-slot",
    "/booking/aircon-service/booking-info",
    "/booking/aircon-service/confirmation",
    "/booking/aircon-service/complete-booking",
  ],
  "home-beauty": [
    "/service-detail/home-beauty",
    "/booking/home-beauty",
    "/booking/home-beauty/select-slot",
    "/booking/home-beauty/booking-info",
    "/booking/home-beauty/confirmation",
    "/booking/home-beauty/complete-booking",
  ],
};
const firstPages = Object.values(bookingRoutes).map((routes) => routes[0]);

export const useBookingRouterInterceptor = () => {
  const { name } = useParams<{ name: ServiceName }>();
  const path = usePathname();
  const { replace } = useRoute();
  const {
    data: { currentPath },
    setBookingState,
  } = useBookingState();

  const returnToFirstPage = (serviceName: ServiceName) => {
    replace(bookingRoutes[serviceName][0]);
  };
  const returnToHomePage = () => {
    replace({
      pageKey: "homepage",
    });
  };

  useEffect(() => {
    if (!name || !Object.values(bookingRoutes).flat().includes(path)) {
      returnToHomePage();
      return;
    }
    if (firstPages.includes(path)) {
      setBookingState({ currentPath: path });
      return;
    }

    const stateStep = bookingRoutes[name].findIndex(
      (val) => val === currentPath,
    );
    const currentPageStep = bookingRoutes[name].findIndex(
      (val) => val === path,
    );

    if (
      stateStep < currentPageStep ||
      (stateStep > currentPageStep &&
        stateStep === bookingRoutes[name].length - 1)
    ) {
      returnToFirstPage(name);
    } else if (stateStep > currentPageStep) {
      setBookingState({
        currentPath: bookingRoutes[name][currentPageStep],
      });
    }
  }, []);
};

export const useBookingRoute = (): { forward(): void } => {
  const { replace, push } = useRoute();
  const path = usePathname();
  const { name = "home-cleaning" } = useParams<{ name: ServiceName }>();
  const { setBookingState } = useBookingState();
  const index = bookingRoutes[name].findIndex((val) => val === path);

  const forward = () => {
    const pathNotExist = index === -1 || index === undefined;
    const isLastPage = index === bookingRoutes[name].length - 1;

    if (pathNotExist) {
      console.error("Failed to find current route");
    } else if (isLastPage) {
      replace({ pageKey: "visits" });
      setBookingState({ currentPath: "" });
    } else {
      setBookingState({
        currentPath: bookingRoutes[name][index + 1],
      });
      push(bookingRoutes[name][index + 1]);
    }
  };

  return {
    forward,
  };
};
