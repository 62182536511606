import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const rateVisitSchema = z.object({
  rating: z.number().min(1, "Review Rating is Required"),
  comment: z.string().min(10, "Must be at least 10 characters long"),
});

export type RateVisitFormData = z.infer<typeof rateVisitSchema>;

export interface RateVisitFormReturn extends UseFormReturn<RateVisitFormData> {
  defaultValues: RateVisitFormData;
}

export const initialFormValues: RateVisitFormData = {
  rating: 0,
  comment: "",
};

export const useRateVisitForm = (
  initialValues?: RateVisitFormData,
): RateVisitFormReturn => ({
  defaultValues: initialValues || initialFormValues,
  ...useForm<RateVisitFormData>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: zodResolver(rateVisitSchema),
  }),
});
