import { useMemo } from "react";
import { singaporeTaxCalculation } from "@/lib/helpers/number";
import { calculateServiceDiscount } from "@/lib/booking-lib";
import { getLineItemClientDetail } from "@/lib/line-items/helpers";
import type { PackageDetailFragmentFragment } from "@/__generated__/graphql";
import { PackageServiceTypeEnum } from "@/__generated__/graphql";
import { useBookingState } from "@/store/booking/useBookingState";

export const useBookingOrder = () => {
  const {
    data: {
      department,
      promo,
      packageDetailsFilter,
      selectedSlot,
      selectedPackageIds,
      packageDetails,
    },
  } = useBookingState();

  return useMemo(() => {
    const duration = packageDetailsFilter.duration;
    const unitPrice = selectedSlot?.rateValue || 0;
    const packages =
      selectedPackageIds
        .map(
          (id) =>
            packageDetails.find(
              (pkg) => pkg.id === id,
            ) as PackageDetailFragmentFragment,
        )
        .filter((val) => !!val) || [];
    const lineItems = packages.map((pkg) => getLineItemClientDetail(pkg));
    const discounts = lineItems.filter(
      (item) => item.serviceType === PackageServiceTypeEnum.Discount,
    );

    let firstSessionValue = 0;
    let totalUnits = 0;
    let totalDuration = 0;
    packages
      .filter((val) => !!val)
      .forEach((pkg) => {
        firstSessionValue +=
          pkg.serviceBillingValue *
          (pkg.repeatEveryTimes > 0 ? pkg.repeatEveryTimes : 1);
        totalUnits += pkg.units;
        totalDuration += pkg.duration;
      });
    const { percent: percentDiscount, total: totalDiscount } =
      calculateServiceDiscount(totalUnits, firstSessionValue, department);
    const totalBeforeDiscount = firstSessionValue;
    const totalAfterDiscount = totalBeforeDiscount - totalDiscount;
    const tax = singaporeTaxCalculation(totalAfterDiscount).tax;

    const serviceDuration = `${totalDuration.toFixed(
      2,
    )}h (${totalUnits} Items)`;

    return {
      lineItems,
      duration,
      unitPrice,
      totalBeforeDiscount,
      totalAfterDiscount,
      tax,
      discounts,
      percentDiscount,
      totalDiscount,
      total: totalAfterDiscount + tax - (promo?.computedDiscount || 0),
      promo,
      serviceDuration,
    };
  }, [
    selectedSlot,
    packageDetails,
    promo,
    selectedPackageIds,
    selectedPackageIds.length,
  ]);
};
