import { View, Image } from "@/components/ui";
import type { ServiceName } from "@/types/service";
import { Column, Container } from "@/components/ui/layout";
import { ServiceFaqSection } from "./service-faq-section";
import { ServiceHeroSection } from "./service-hero-section";
import { ServiceDetailSection } from "./service-detail-section";
import { CustomerReviews } from "./customer-reviews";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { SectionWrapper } from "@/components/shared/sticky-scroll-navbar";
import { useBookingRouterInterceptor } from "@/components/hooks/use-booking-route";
import { getPlatform, safeGet } from "@/lib/utils";
import { useEffect, useMemo } from "react";
import {
  defaultServiceDetails,
  useBookingState,
} from "@/store/booking/useBookingState";
import type { ServiceDetail as IServiceDetail } from "@/types/booking";
import { ServiceDetailNavigation } from "./service-detail-navigation";
import { getDepartment } from "@/lib/booking-lib";
import { useClientReviewsStore } from "@/store/booking/clientReviews";
import { formatDate } from "@/lib/helpers/date";
import { addYears } from "date-fns";
import { defaultServiceReviews } from "@/constants/booking";
import { ChoosePackageModal } from "@/components/shared/booking/choose-package-modal";
import { ChatWithSales } from "@/components/shared/chat-with-sales";
import { IfElse } from "@/components/shared/if-else";
import {
  PackageDepartmentEnum,
  PackageDetailSortByEnum,
  PackageRecurrenceEnum,
  ServiceTypeEnum,
  SortEnum,
} from "@/__generated__/graphql";
import { useAllClientPackageDetailsStore } from "@/store/booking/allClientPackageDetails";
import { GoogleReviews } from "./google-reviews";
import { Spin } from "@/components/shared/spin";
import { Typography } from "@/components/shared/typography";

export function ServiceDetail({ name }: { name: ServiceName }) {
  const { mediaQuery, width } = useWindowDimensions();
  const { fetch: getReviews, loading } = useClientReviewsStore();
  const platform = getPlatform();
  useBookingRouterInterceptor();
  const {
    data: { serviceDetails, packageDetailsFilter, serviceReviews },
    setBookingState,
  } = useBookingState();
  const { fetch: getPackages } = useAllClientPackageDetailsStore();

  const pageServiceDetails = useMemo(() => {
    const detailKey =
      name === "aircon-service"
        ? `${name}-${packageDetailsFilter?.serviceTypes?.[0]}`
        : name;
    return safeGet(
      serviceDetails,
      detailKey,
      defaultServiceDetails,
    ) as IServiceDetail;
  }, [name]);

  const initialHomeBeauty = () => {
    setBookingState({
      department: PackageDepartmentEnum.HomeBeauty,
      packageDetailsFilter: {
        serviceTypes: [ServiceTypeEnum.Combo],
        repeatEvery: [PackageRecurrenceEnum.AdHoc],
      },
    });

    getPackages({
      requestPayload: {
        filters: {
          sort: [SortEnum.Asc],
          sortBy: [PackageDetailSortByEnum.Duration],
          serviceTypes: [
            ServiceTypeEnum.Combo,
            ServiceTypeEnum.Manicure,
            ServiceTypeEnum.Pedicure,
            ServiceTypeEnum.AddOn,
            ServiceTypeEnum.Upgrade,
            ServiceTypeEnum.Discount,
          ],
          department: PackageDepartmentEnum.HomeBeauty,
        },
      },
    }).then((res) => {
      if (!res.data || res.error) {
        return;
      }
      setBookingState({
        packageDetails: res.data,
      });
    });
  };

  const {
    title,
    ratingTotal,
    bookingTotal,
    descriptions,
    image,
    detailImages,
    faqs,
    rateFrom,
    rateTo,
    averageRating,
    guarantee,
    startPrice,
  } = pageServiceDetails;

  useEffect(() => {
    setBookingState({
      department: getDepartment(name),
      selectedPackageIds: [],
      selectedAddress: null,
      selectedWorkers: [],
      serviceReviews: defaultServiceReviews,
    });

    getReviews({
      requestPayload: {
        filters: {
          startDate: formatDate(addYears(new Date(), -1)),
          endDate: formatDate(new Date()),
          department: getDepartment(name),
          limit: 10,
        },
      },
    });

    if (name === "home-beauty") {
      initialHomeBeauty();
    }
  }, []);

  return (
    <View className="py-0 md:py-6">
      <Container>
        <Image
          src={image}
          className="w-full rounded-none md:rounded-xl"
          height={211}
          width={width}
        />
      </Container>
      <Container>
        <Column desktop="8">
          <View className="flex flex-col gap-4">
            <SectionWrapper keyName="service">
              <ServiceHeroSection
                title={title}
                ratingTotal={ratingTotal}
                bookingTotal={bookingTotal}
                descriptions={descriptions}
                image={image}
                rateFrom={rateFrom}
                rateTo={rateTo}
                averageRating={averageRating}
                guarantee={guarantee}
              />
            </SectionWrapper>

            {mediaQuery === "mobile" && (
              <SectionWrapper keyName="reviews">
                <IfElse if={!loading} else={<Spin className="my-8 size-8" />}>
                  <IfElse
                    if={!!serviceReviews?.reviews?.length}
                    else={<GoogleReviews />}
                  >
                    <CustomerReviews
                      serviceReviews={serviceReviews}
                      key="customer-review"
                    />
                  </IfElse>
                </IfElse>
              </SectionWrapper>
            )}

            <SectionWrapper keyName="details">
              <ServiceDetailSection detailImages={detailImages} />
            </SectionWrapper>

            <SectionWrapper keyName="faqs">
              <ServiceFaqSection faqs={faqs} />
            </SectionWrapper>
          </View>
          {!!packageDetailsFilter.serviceTypes?.[0] && (
            <ChoosePackageModal name={name} />
          )}
        </Column>

        <Column desktop="4">
          <IfElse if={platform === "web"}>
            <View className="relative flex flex-col gap-4 py-4 md:sticky md:top-28">
              <View className="flex hidden flex-row items-center justify-between md:flex">
                <Typography color="foreground-intermediate" variant="body-lg">
                  Starting from
                </Typography>
                <Typography variant="h6">{`$${startPrice}`}</Typography>
              </View>
              <ServiceDetailNavigation name={name} />
              <View className="hidden flex-col gap-4 md:flex">
                <IfElse if={!loading} else={<Spin className="my-8 size-8" />}>
                  <IfElse
                    if={!!serviceReviews?.reviews?.length}
                    else={<GoogleReviews />}
                  >
                    <CustomerReviews
                      serviceReviews={serviceReviews}
                      key="customer-review"
                    />
                  </IfElse>
                </IfElse>

                <ChatWithSales />
              </View>
            </View>
          </IfElse>
        </Column>
      </Container>
    </View>
  );
}
