import { View } from "@/components/ui";
import { ActionNavigation } from "@/components/shared/booking/action-navigation";
import { useBookingRoute } from "@/components/hooks/use-booking-route";
import { NativeActionNavigation } from "@/components/shared/booking/native-action-navigation";
import type { ServiceName } from "@/types/service";
import { useIntl } from "react-intl";

export const SelectServiceNavigation = (props: { name: ServiceName }) => {
  const { forward } = useBookingRoute();
  const onClickButton = () => {
    forward();
  };
  const intl = useIntl();
  return (
    <View>
      <ActionNavigation
        buttonText={intl.formatMessage({
          defaultMessage: "Select Services",
          id: "select-services",
        })}
        onClickButton={onClickButton}
      />
      <NativeActionNavigation
        buttonText={intl.formatMessage({
          defaultMessage: "Select Services",
          id: "select-services",
        })}
        onClickButton={onClickButton}
      />
    </View>
  );
};
