import { View } from "@/components/ui";
import { PackageListOverview } from "../package-list-overview";
import { CreditOverview } from "../credit-overview";
import { AccountOverview } from "../account-overview";
import { AccountProfileOverview } from "../account-profile-overview";

export const Overview = () => {
  return (
    <View className="flex flex-col gap-4 p-4 md:p-0">
      <AccountProfileOverview />

      <CreditOverview />

      <PackageListOverview />

      <AccountOverview />
    </View>
  );
};
