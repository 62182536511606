import { app } from "@/config/env";

type RefreshTokenResponse = {
  jwt: string;
  jwt_expiry: number;
  jwt_refresh_token: string;
};

export const updateToken = async (refreshToken: string) => {
  const refreshTokenUrl = app.REFRESH_TOKEN_ENDPOINT;

  try {
    const response: Response = await fetch(refreshTokenUrl, {
      headers: {
        "x-jwt-refresh-token": refreshToken,
      },
      method: "POST",
      mode: "cors",
      credentials: "include",
    });

    if (response.ok) {
      const body = (await response.json()) as RefreshTokenResponse;
      const jwtInfo = {
        jwt: body.jwt,
        refreshToken: body.jwt_refresh_token,
        jwtExpiry: body.jwt_expiry,
      };
      return jwtInfo;
    } else {
      return null;
    }
  } catch (e) {
    console.log("error get token", e);
    return null;
  }
};
