import { DialogHeader, DialogTitle, View } from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";
import { Divider } from "@/components/shared/divider";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";

export function ModalHeader({ onClose }: { onClose(): void }) {
  const { isDesktop } = useWindowDimensions();

  return (
    <View className="relative">
      <DialogHeader className="flex flex-row items-center justify-between gap-2">
        <DialogTitle>Manage Appointment</DialogTitle>
        {isDesktop && (
          <IconButton variant="ghost" iconName="x" onClick={onClose} />
        )}
      </DialogHeader>
      <Divider className="py-1" />
    </View>
  );
}
