import { View } from "@/components/ui";
import { cn, isBetween } from "@/lib/utils";
import type { ElementType, ReactNode } from "react";
import {
  createContext,
  forwardRef,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { Typography } from "../typography";

type NavbarItem = {
  label: string;
  key: string;
};

type NavbarContext = {
  items: NavbarItem[];
  navigateTo(key: string): void;
  activeSection: string | null;
  registerSection(key: string, element: HTMLElement): void;
};

const HEADER_OFFSET = 64;
const NAVBAR_HEIGHT = 48;

function scrollToTargetWithOffset(
  element: HTMLElement,
  offset = HEADER_OFFSET + NAVBAR_HEIGHT,
) {
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

const StickyScrollNavbarContext = createContext<NavbarContext | null>(null);

export const StickyScrollNavbar = ({
  children,
  activeMenuKey,
  items,
  onScroll = () => {},
  navbar = <NavbarMenu />,
}: {
  items: NavbarItem[];
  children: ReactNode;
  activeMenuKey?: string;
  onScroll?(scrollEvent: { y: number; x: number }): void;
  navbar?: ReactNode;
}) => {
  const [showNavigation, setShowNavigation] = useState(false);
  const [activeSection, setActiveSection] = useState(activeMenuKey ?? null);

  const sectionRefs = useRef<{ [key: string]: HTMLElement }>({});

  const registerSection = (key: string, element: HTMLElement) => {
    sectionRefs.current[key] = element;
  };

  useLayoutEffect(() => {
    const listener = () => {
      const y = window.scrollY;
      const x = window.scrollX;

      setShowNavigation(y >= 200);

      const position = items
        .map(({ key }) => {
          const element = sectionRefs.current[key];

          if (!element) return { key, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = Math.max(0, rect.top + y - HEADER_OFFSET);
          const bottom = Math.max(0, rect.bottom + y - HEADER_OFFSET);

          return { key, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(y, top, bottom));

      if (position?.key) {
        setActiveSection(position.key);
      }

      onScroll({ y, x });
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [items]);

  const navigateTo = (key: string) => {
    const element = sectionRefs.current[key];
    scrollToTargetWithOffset(element);
    setActiveSection(key);
  };

  return (
    <StickyScrollNavbarContext.Provider
      value={{
        items,
        activeSection,
        navigateTo,
        registerSection,
      }}
    >
      {showNavigation && createPortal(navbar, document.body)}
      {children}
    </StickyScrollNavbarContext.Provider>
  );
};

export function useNavbarContext() {
  const context = useContext(StickyScrollNavbarContext);
  if (!context) {
    throw new Error(
      "Navbar compound components cannot be rendered outside the StickyScrollNavbar",
    );
  }
  return context;
}

export const SectionWrapper = ({
  children = <View />,
  keyName,
}: {
  children?: ReactNode;
  keyName: string;
}) => {
  const { registerSection } = useNavbarContext();

  return (
    <div id={keyName} ref={(el) => el && registerSection(keyName, el)}>
      {children}
    </div>
  );
};

type NavbarMenuProps = Omit<ElementType<HTMLDivElement>, "children"> & {
  className?: string;
};

export const NavbarMenu = forwardRef<HTMLDivElement, NavbarMenuProps>(
  ({ className, ...props }: NavbarMenuProps, ref) => {
    const { navigateTo, activeSection, items } = useNavbarContext();

    return (
      <div
        ref={ref}
        className={cn(
          "relatives flex h-12 flex-row bg-white shadow-sm md:hidden",
          "animate-in slide-in-from-top",
          "fixed top-[64px] z-30 w-full",
          className,
        )}
        {...props}
      >
        {items.map((menu) => (
          <View
            className={
              "flex h-full flex-1 cursor-pointer items-center justify-center"
            }
            key={menu.key}
            onClick={() => navigateTo(menu.key)}
          >
            <Typography
              className={cn(
                "text-md h-full border-b-[3px] leading-[48px]",
                "transition-colors delay-150 ease-in-out",
                activeSection === menu.key
                  ? "border-primary text-primary"
                  : "border-transparent text-secondary-text",
              )}
            >
              {menu.label}
            </Typography>
          </View>
        ))}
      </div>
    );
  },
);
