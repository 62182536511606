import * as React from "react";
import type { SVGProps } from "react";
const SvgBadgedUserProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none" {...props}>
    <rect y={2} width={32} height={32} rx={16} fill="#FAFAFA" />
    <rect x={21} y={1.30029} width={12} height={12} rx={6} fill="#FAFAFA" />
    <rect
      x={21}
      y={1.30029}
      width={12}
      height={12}
      rx={6}
      stroke="#FAFAFA"
      strokeWidth={2}
    />
    <path
      d="M32 7.30029C32 10.0617 29.7614 12.3003 27 12.3003C24.2386 12.3003 22 10.0617 22 7.30029C22 4.53887 24.2386 2.30029 27 2.30029C29.7614 2.30029 32 4.53887 32 7.30029Z"
      fill="#FF7878"
    />
    <path
      d="M16 8C13.38 8 11.25 10.13 11.25 12.75C11.25 15.32 13.26 17.4 15.88 17.49C15.96 17.48 16.04 17.48 16.1 17.49C16.12 17.49 16.13 17.49 16.15 17.49C16.16 17.49 16.16 17.49 16.17 17.49C18.73 17.4 20.74 15.32 20.75 12.75C20.75 10.13 18.62 8 16 8Z"
      fill="currentColor"
    />
    <path
      d="M21.0809 20.1499C18.2909 18.2899 13.7409 18.2899 10.9309 20.1499C9.66094 20.9999 8.96094 22.1499 8.96094 23.3799C8.96094 24.6099 9.66094 25.7499 10.9209 26.5899C12.3209 27.5299 14.1609 27.9999 16.0009 27.9999C17.8409 27.9999 19.6809 27.5299 21.0809 26.5899C22.3409 25.7399 23.0409 24.5999 23.0409 23.3599C23.0309 22.1299 22.3409 20.9899 21.0809 20.1499Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBadgedUserProfileIcon;
