import { View } from "@/components/ui";
import { cn } from "@/lib/utils";
import { IconButton } from "@/components/ui/icon-button";
import { Typography } from "../typography";

export const NumChangeWidget = ({
  value,
  onAdd,
  onMinus,
  className,
  addDisabled,
}: {
  value: number;
  onAdd(): void;
  onMinus(): void;
  className?: string;
  addDisabled?: boolean;
}) => {
  return (
    <View className={cn("flex flex-row items-center gap-2", className)}>
      <IconButton
        variant="ghost"
        size="sm"
        iconName="minus"
        color={value <= 0 ? "foreground" : "CTA2-tertiary"}
        rounded="md"
        className={cn(
          "flex h-6 w-6 shrink-0 items-center justify-center rounded-md bg-primary-surface p-0 hover:bg-primary-surface",
          {
            "cursor-not-allowed bg-background-intermediate hover:bg-background-intermediate":
              value <= 0,
          },
        )}
        onClick={() => {
          if (value <= 0) return;
          onMinus();
        }}
      />

      <Typography variant="label-md" align="center" className="min-w-4 flex-1">
        {value}
      </Typography>

      <IconButton
        variant="ghost"
        size="sm"
        color={addDisabled ? "foreground" : "CTA2-tertiary"}
        rounded="md"
        className={cn(
          "flex h-6 w-6 shrink-0 items-center justify-center rounded-md bg-primary-surface p-0 hover:bg-primary-surface",
          {
            "cursor-not-allowed bg-background-intermediate hover:bg-background-intermediate":
              addDisabled,
          },
        )}
        iconName="plus"
        onClick={() => {
          if (addDisabled) return;
          onAdd();
        }}
      />
    </View>
  );
};
