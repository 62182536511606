import type {
  ClientConfirmReservationMutation,
  PackageDepartmentEnum,
  PackageDetailFragmentFragment,
  ServiceTypeEnum,
  SignInProviderEnum,
} from "@/__generated__/graphql";
import { logAnalyticEvent } from "../../firebase";
import { isLocal } from "@/lib/utils";
import { PackageFrequencyGroupEnum } from "@/types/booking";

export type AnalyticsConversion =
  | EventPurchase
  | EventAddToCart
  | EventFindAvailability
  | EventUserSignUp
  | EventUserSignIn
  | EventViewService
  | EventSelectServiceType
  | EventWAClick;

type Item = {
  item_id: string;
  item_category: string;
  price: number;
  quantity: number;
};

type EventWAClick = {
  name: 'wa_click',
  values: Record<string, string>
}

type EventPurchase = {
  name: "purchase";
  values: {
    currency: "SGD";
    value: number;
    transaction_id: string;
    coupon?: string;
    tax?: number; // we can put GST for this
    items: Item[];
  };
};

type EventAddToCart = {
  name: "add_to_cart";
  values: {
    currency: "SGD";
    value: number;
    items: Item[];
  };
};

type EventFindAvailability = {
  name: "find_availability";
  values: {
    currency: "SGD";
    items: Item[];
  };
};

type EventUserSignUp = {
  name: "sign_up";
  values: {
    method: SignInProviderEnum;
  };
};

type EventUserSignIn = {
  name: "login";
  values: {
    method: SignInProviderEnum;
  };
};

type EventViewService = {
  name: "view_service";
  values: {
    service_type: PackageDepartmentEnum;
  };
};
type EventSelectServiceType = {
  name: "select_service_type";
  values: {
    service_type: ServiceTypeEnum | PackageDepartmentEnum;
  };
};

export function sendAnalyticData(eventData: AnalyticsConversion): void {
  if (isLocal) {
    return;
  }

  logAnalyticEvent(eventData.name, {
    ...eventData.values,
    timestamp: Date.now(),
  });
}

export function transformBookingDataToAddToCart(
  packageDetails: PackageDetailFragmentFragment[],
): EventAddToCart["values"] {
  const totalServiceBillingValue = packageDetails.reduce<number>(
    (total, { serviceBillingValue }) => total + serviceBillingValue,
    0,
  );

  return {
    currency: "SGD",
    value: totalServiceBillingValue,
    items: packageDetails.map((packageDetail) => ({
      item_id: packageDetail.id,
      item_category: packageDetail.department,
      price: packageDetail.unitValue,
      quantity: packageDetail.duration,
    })),
  };
}

export function transformReservationDataToPurchase(
  frequency: PackageFrequencyGroupEnum,
  reservation: NonNullable<
    ClientConfirmReservationMutation["clientConfirmReservation"]
  >,
): EventPurchase["values"] {
  let multiplier: number;
  switch (frequency) {
    case PackageFrequencyGroupEnum.AD_HOC:
    case PackageFrequencyGroupEnum.QUARTERLY:
    case PackageFrequencyGroupEnum.TRI_YEARLY:
      multiplier = 1;
      break;
    case PackageFrequencyGroupEnum.FORTNIGHT:
      multiplier = 2;
      break;
    case PackageFrequencyGroupEnum.WEEKLY:
      multiplier = 4;
      break;
    default:
      multiplier = 0;
      break;
  }

  return {
    transaction_id: reservation.package.id,
    value: reservation.package.serviceBillingValue * multiplier,
    currency: "SGD",
    items: Array(multiplier).fill({
      item_id: reservation.package.packageDetail.id,
      item_category: reservation.package.packageDetail.serviceType,
      price: reservation.package.unitValue,
      quantity: reservation.package.packageDetail.duration,
    }) as Item[],
  };
}
