export type ServiceName =
  | "home-cleaning"
  | "aircon-service"
  | "home-beauty"
  | UpholsteryServiceName;
// | (string & {}); // let any string also valid

export type UpholsteryServiceName =
  | "mattress-cleaning"
  | "sofa-cleaning"
  | "rug-cleaning"
  | "pram-seat";

export const UpholsteryServiceNames: UpholsteryServiceName[] = [
  "mattress-cleaning",
  "sofa-cleaning",
  "rug-cleaning",
  "pram-seat",
];
