import { Button, View } from "@/components/ui";
import { ReportIssueForm } from "../report-issue-form";
import type { VisitIssueScreenProps } from "./type";
import { MobileFullscreenModal } from "@/components/shared/mobile-fullscreen-modal";
import { Typography } from "@/components/shared/typography";

export function VisitIssueScreen({
  open,
  title,
  description,
  loading,
  onClose,
  onSubmit,
  form,
}: VisitIssueScreenProps) {
  return (
    <MobileFullscreenModal
      open={open}
      onClose={onClose}
      title={title}
      FooterComponent={
        <Button
          variant="primary"
          color="CTA"
          fullWidth="full"
          onClick={onSubmit}
          loading={loading}
          disabled={loading}
          children=" Submit"
        />
      }
    >
      <View className="flex flex-col gap-3">
        <Typography variant="body-lg" color="foreground-default">
          {description}
        </Typography>
        <ReportIssueForm form={form} />
      </View>
    </MobileFullscreenModal>
  );
}
