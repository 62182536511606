import {
  ServiceAcIcon,
  ServiceHomeCleaningIcon,
  ServiceManicureIcon,
  ServiceMattressIcon,
  ServicePramCarSeatIcon,
  ServiceRugCleaningIcon,
  ServiceSofaIcon,
} from "@/components/shared/icons";
import type { ServiceName } from "@/types/service";
import type { IntlShape } from "react-intl";

type ServiceItem = {
  title: string;
  name: ServiceName;
  icon: JSX.Element;
};

export function otherServiceItems(t: IntlShape): ServiceItem[] {
  return [
    // {
    //   title: t.formatMessage({
    //     defaultMessage: "Deep Cleaning",
    //     id: "deep-cleaning",
    //   }),
    //   name: "deep-cleaning",
    //   icon: <ServiceHomeCleaningIcon className="size-10 color-primary" />,
    // },
    {
      title: t.formatMessage({
        defaultMessage: "Sofa Cleaning",
        id: "sofa-cleaning",
      }),
      name: "sofa-cleaning",
      icon: <ServiceSofaIcon className="size-10 color-primary" />,
    },
    {
      title: t.formatMessage({
        defaultMessage: "Carpet Cleaning",
        id: "rug-cleaning",
      }),
      name: "rug-cleaning",
      icon: <ServiceRugCleaningIcon className="size-10 color-primary" />,
    },
    // {
    //   title: t.formatMessage({
    //     defaultMessage: "UV-C Disinfection",
    //     id: "uv-c-disinfection",
    //   }),
    //   name: "uv-c-disinfection",
    //   icon: <ServiceUVCIcon className="size-10 color-primary" />,
    // },
    {
      title: t.formatMessage({
        defaultMessage: "Pram & Car Seat Cleaning",
        id: "pram-seat",
      }),
      name: "pram-seat",
      icon: <ServicePramCarSeatIcon className="size-10 color-primary" />,
    },
  ];
}

export function popularServiceItems(t: IntlShape): ServiceItem[] {
  return [
    {
      title: t.formatMessage({
        defaultMessage: "Home Cleaning",
        id: "home-cleaning",
      }),
      name: "home-cleaning",
      icon: <ServiceHomeCleaningIcon className="size-10 color-primary" />,
    },
    {
      title: t.formatMessage({
        defaultMessage: "Aircon Service",
        id: "aircon-service",
      }),
      name: "aircon-service",
      icon: <ServiceAcIcon className="size-10 color-primary" />,
    },
    {
      title: t.formatMessage({
        defaultMessage: "Home Beauty",
        id: "home-beauty",
      }),
      name: "home-beauty",
      icon: <ServiceManicureIcon className="size-10 color-primary" />,
    },
    {
      title: t.formatMessage({
        defaultMessage: "Mattress Cleaning",
        id: "mattress-cleaning",
      }),
      name: "mattress-cleaning",
      icon: <ServiceMattressIcon className="size-10 color-primary" />,
    },
  ];
}
