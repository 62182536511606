import { create } from "zustand";
import { IssueTypeEnum } from "@/__generated__/graphql";
import { usePackageIssueDetailsStore } from "./issueDetails";

export enum PackageIssueType {
  editAddress = "editAddress",
  modifyPlan = "modifyPlan",
  cancelPackage = "cancelPackage",
}

type PackageIssueState = {
  showModal: boolean;
  issueType: PackageIssueType | null;
  packageId: string | null;
};

type PackageIssueStore = {
  data: PackageIssueState;
  openPackageIssueModal(issueType: PackageIssueType, packageId: string): void;
  closePackageIssueModal(): void;
};

const initialData: PackageIssueState = {
  showModal: false,
  issueType: null,
  packageId: null,
};

export const usePackageIssueStore = create<PackageIssueStore>((set, get) => ({
  data: initialData,
  openPackageIssueModal: (issueType: PackageIssueType, packageId: string) => {
    set({
      data: {
        ...get().data,
        showModal: true,
        issueType,
        packageId,
      },
    });

    const type =
      issueType === PackageIssueType.modifyPlan ||
      issueType === PackageIssueType.editAddress
        ? IssueTypeEnum.PackageUpdate
        : IssueTypeEnum.PackageTermination;

    usePackageIssueDetailsStore.getState().fetch({
      requestPayload: {
        type,
      },
    });
  },
  closePackageIssueModal: () => {
    set({
      data: initialData,
    });
  },
}));
