import type {
  PackageQuery,
  PackageQueryVariables,
  Package,
} from "@/__generated__/graphql";
import { PackageDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";
import { usePackageState } from "./usePackageState";

type Response = Package;

export const usePackageStore = createRequestFactory<
  PackageQuery,
  Response,
  PackageQueryVariables
>({
  method: "query",
  graphqlDocument: PackageDocument,
  transformFunction: (data) => {
    if (data.package) return data.package as Response;
    else throw new Error("");
  },
  onFetchSuccess: (res) => {
    const { setPackage } = usePackageState.getState();
    setPackage(res);
  },
});
