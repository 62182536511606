import { View, Image } from "@/components/ui";
import { cn } from "@/lib/utils";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";
import AppStoreSocialBtn from "@/assets/icons/svgs/app-store-social-btn.svg";
import GooglePlaySocialBtn from "@/assets/icons/svgs/google-play-social-btn.svg";
import { Link } from "@/components/shared/link";
import { links } from "@/constants";

export function GetTheAppNow({
  size = "large",
  className = "",
}: {
  size?: "small" | "large";
  className?: string;
}) {
  const intl = useIntl();
  return (
    <View
      className={cn("item grid", className, {
        "gap-2": size === "small",
        "gap-3": size === "large",
      })}
    >
      <Typography color="white" align="center">
        {intl.formatMessage({
          defaultMessage: "Get the app now!",
          id: "get-the-app-now",
        })}
      </Typography>

      <View className="flex items-center justify-center gap-2">
        <Link
          href={links.appStoreClientApp}
          className={cn(
            "flex items-center justify-center rounded-full border border-primary-border bg-primary-surface",
            {
              "h-[32px] w-[103px]": size === "small",
              "h-[38px] w-[122px]": size === "large",
            },
          )}
        >
          <Image src={AppStoreSocialBtn} />
        </Link>
        <Link
          href={links.googlePlayClientApp}
          className={cn(
            "flex items-center justify-center rounded-full border border-primary-border bg-primary-surface",
            {
              "h-[32px] w-[103px]": size === "small",
              "h-[38px] w-[122px]": size === "large",
            },
          )}
        >
          <Image src={GooglePlaySocialBtn} />
        </Link>
      </View>
    </View>
  );
}
