import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientVerifyPhoneNumberDocument,
  type ClientVerifyPhoneNumberMutationVariables,
  type ClientVerifyPhoneNumberMutation,
} from "@/__generated__/graphql";

type Response = {
  expiresAt?: string;
};

export const useClientVerifyPhoneNumberStore = createRequestFactory<
  ClientVerifyPhoneNumberMutation,
  Response,
  ClientVerifyPhoneNumberMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientVerifyPhoneNumberDocument,
  transformFunction: (res) => {
    if (res.clientVerifyPhoneNumber?.result) {
      if (
        res.clientVerifyPhoneNumber.result.__typename === "SendPinCodeToPhoneOk"
      ) {
        return { expiresAt: res.clientVerifyPhoneNumber.result.expiresAt };
      }

      throw new Error(res.clientVerifyPhoneNumber.result.message);
    }
    return {};
  },
});
