import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientDocument,
  type ClientQuery,
  type ClientQueryVariables,
} from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";
import type { UserInfo, UserPaymentMethod } from "@/types/users";
import { storage } from "@/lib/storage";
import { DEFAULT_JWT_DATA, DEFAULT_USER_DATA } from "@/constants";

type Response = {
  userInfo: UserInfo;
};

export const useClientStore = createRequestFactory<
  ClientQuery,
  Response,
  ClientQueryVariables
>({
  method: "query",
  graphqlDocument: ClientDocument,
  fetchPolicy: "network-only",
  transformFunction: (res) => {
    const { setJwtInfo, setUserInfo } = useAuthState.getState();

    if (res.client) {
      const {
        id,
        user,
        contacts,
        addresses,
        accountType,
        creditAccounts,
        paymentMethods,
        billingAccount,
      } = res.client;

      const preferences = res.client.preferences as { auto_pay?: boolean };

      let userPaymentMethod: UserPaymentMethod | null = null;

      if (paymentMethods.length > 0) {
        userPaymentMethod = {
          id: paymentMethods[0].id,
          brand: paymentMethods[0].brand,
          expMonth: paymentMethods[0].expMonth,
          expYear: paymentMethods[0].expYear,
          last4: paymentMethods[0].last4,
          name: paymentMethods[0].name,
        };
      }

      const userInfo: UserInfo = {
        id,
        contacts,
        addresses: addresses.map((addr) => ({
          ...addr,
          unitNumber: addr.unitNumber ?? undefined,
        })),
        user: {
          id: user.id,
          email: user.email,
          phoneNumber: user.phoneNumber ?? undefined,
          phoneNumberVerifiedAt: user.phoneNumberVerifiedAt ?? undefined,
          emailVerifiedAt: user.emailVerifiedAt ?? undefined,
        },
        firstName: contacts[0].firstName,
        lastName: contacts[0].lastName,
        accountType,
        creditAccounts,
        paymentMethod: userPaymentMethod,
        autoPay: preferences?.auto_pay ?? false,
        billingAccount: {
          id: billingAccount.id,
          balance: billingAccount.balance,
          overdueAmount: billingAccount.overdueAmount ?? 0,
          totalOverdueInvoice: billingAccount.totalOverdueInvoice ?? 0,
        },
      };

      setUserInfo(userInfo);
      return { userInfo } as Response;
    }

    storage.deleteAll();
    setUserInfo(DEFAULT_USER_DATA);
    setJwtInfo(DEFAULT_JWT_DATA);
    throw new Error("Request Failed");
  },
  onFetchSuccess: (data) => {
    const { setUserInfo } = useAuthState.getState();
    setUserInfo(data.userInfo);
  },
});
