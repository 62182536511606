import { View } from "@/components/ui";
import { Medal, Invoice, House, MapPinArea } from "@/components/shared/icons";
import type { NavbarListItemProps } from "../navbar-list-item";
import { NavbarListItem } from "../navbar-list-item";
import { Link } from "@/components/shared/link";
import { useAuthState } from "@/store/auth";

export function GenerateMenu() {
  const totalOverdueInvoice = useAuthState(
    ({ data }) => data.userInfo.billingAccount.totalOverdueInvoice,
  );

  const generateMenuList: (NavbarListItemProps & { href: string })[] = [
    {
      icon: <House size={24} />,
      title: "Home",
      href: "/",
    },
    {
      icon: <MapPinArea size={24} />,
      title: "Visits",
      href: "/visits",
    },
    {
      icon: <Invoice size={24} />,
      title: "Invoices",
      badge: totalOverdueInvoice
        ? `${totalOverdueInvoice} Outstanding`
        : undefined,
      href: "/profile/invoices",
    },
    {
      icon: <Medal size={24} />,
      title: "Rewards",
      href: "/rewards",
    },
  ];

  return (
    <View>
      {generateMenuList.map((item) => {
        return (
          <Link href={item.href} key={item.title}>
            <NavbarListItem
              icon={item.icon}
              title={item.title}
              key={item.title}
              badge={item.badge}
            />
          </Link>
        );
      })}
    </View>
  );
}
