export const ROUTES = {
  Root: "",
  Login: "/login",
  Signup: "/signup",
  Onboarding: "/onboarding",
  Profile: {
    Root: "profile",
    Children: {
      Overview: "overview",
      AccountInfo: "account-info",
      ChangePhoneNumber: "change-phone-number",
      Invoices: {
        Root: "invoices",
        Children: {
          Paid: "paid",
          Unpaid: "unpaid",
          SelectPayment: "select-payment",
        },
        Detail: "invoices/:id",
      },
      Payment: "payment",
      TermsOfServices: "terms-of-services",
      PrivacyPolicy: "privacy-policy",
      CreditAccount: "credit-account",
      MyVouchers: "my-vouchers",
      //Address page related
      Addresses: "addresses",
      Address: "addresses/:id",
      NewAddress: "addresses/new",
      //contact page related
      Contacts: "contacts",
      Contact: "contacts/:id",
      NewContact: "contacts/new",
      //packages page related
      Packages: "packages",
      Package: "packages/:id",
    },
  },
  ServiceDetail: "/service-detail/:name",
  SelectService: "/select-service/:name",
  Booking: {
    Root: "/booking/:name",
    SelectSlot: "booking/:name/select-slot",
    BookingInfo: "booking/:name/booking-info",
    Confirmation: "booking/:name/confirmation",
    CompleteBooking: "booking/:name/complete-booking",
  },
  Visits: {
    Root: "visits",
    Children: {
      Upcoming: "upcoming",
      History: "history",
    },
    Rate: "rate",
    ManageVisit: "manage-visit",
    AirconReschedule: "aircon-reschedule",
    RescheduleVisit: "/visits/reschedule",
  },
  Rewards: {
    Root: "rewards",
    Children: {
      Promos: "promos",
      YourRewards: "your-rewards",
    },
  },
  Notifications: {
    Root: "notifications",
    Children: {
      Detail: ":id",
    },
  },
} as const;
