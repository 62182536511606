import { View, Input, Button, Checkbox, Label } from "@/components/ui";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { InputFormControl } from "../input-form-control";
import { useEffect, useId } from "react";
import { PhoneNumberInput } from "../phone-number-input";
import { defaultNationCode } from "@/constants";
import { z } from "zod";
import { emailRE, nameRE } from "@/constants/regex";
import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "../typography";
import { useIntl } from "react-intl";
import { IconButton } from "@/components/ui/icon-button";
import { superRefinePhoneNumber } from "@/lib/helpers/phone-number-validation";

const contactSchema = z.object({
  firstName: z
    .string()
    .min(1, "First name is required")
    .refine((value) => nameRE.test(value), {
      message: "Please avoid using special characters",
    }),
  lastName: z
    .string()
    .min(1, "Last name is required")
    .refine((value) => nameRE.test(value), {
      message: "Please avoid using special characters",
    }),
  primary: z.boolean(),
  email: z.array(
    z.object({
      value: z.string().regex(emailRE, "Invalid email"),
    }),
  ),
  phoneNumber: z.array(
    z
      .object({
        nationCode: z.string().default(defaultNationCode),
        phoneNumber: z.string().min(6, "Phone number is required"),
      })
      .superRefine((arg, ctx) => {
        superRefinePhoneNumber({
          code: arg.nationCode,
          phoneNumber: arg.phoneNumber,
          ctx,
        });
      }),
  ),
});

export type ContactFormData = z.infer<typeof contactSchema>;
const defaultValues = {
  firstName: "",
  lastName: "",
  email: [{ value: "" }],
  phoneNumber: [{ nationCode: defaultNationCode, phoneNumber: "" }],
  primary: true,
};

export const AddAnotherContactModal = ({
  open,
  loading,
  setOpen,
  onSubmitContactForm,
}: {
  open: boolean;
  loading: boolean;
  onSubmitContactForm(contact: ContactFormData): void;
  setOpen(open: boolean): void;
}) => {
  const intl = useIntl();
  const id = useId();

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<ContactFormData>({
    mode: "onChange",
    defaultValues,
    resolver: zodResolver(contactSchema),
  });

  const {
    fields: emailFields,
    append: appendEmailField,
    remove: removeEmailField,
  } = useFieldArray({
    control,
    name: "email",
  });
  const {
    fields: numberFields,
    append: appendNumberField,
    remove: removeNumberField,
  } = useFieldArray({
    control,
    name: "phoneNumber",
  });

  const watchEmails = watch("email");
  const watchPhoneNumbers = watch("phoneNumber");
  const onSubmitForm = handleSubmit(onSubmitContactForm);

  useEffect(() => {
    if (open) reset(defaultValues);
  }, [open]);

  return (
    <BottomDrawerModal open={open} onOpenChange={setOpen}>
      <View>
        <Typography variant="label-xl" className="pb-6">
          {intl.formatMessage({
            defaultMessage: "Add Another Contact",
            id: "add-contact",
          })}
        </Typography>
        <InputFormControl
          label={intl.formatMessage({
            defaultMessage: "First Name",
            id: "first-name",
          })}
          component={Input}
          name="firstName"
          control={control}
          componentProps={{
            placeholder: "John",
          }}
        />
        <InputFormControl
          label={intl.formatMessage({
            defaultMessage: "Last Name",
            id: "last-name",
          })}
          component={Input}
          name="lastName"
          control={control}
          componentProps={{
            placeholder: "Doe",
          }}
        />
        <View>
          {emailFields.map((field, index) => {
            return (
              <InputFormControl
                key={field.id}
                control={control}
                name={`email.${index}.value`}
                label={"Email"}
                component={Input}
                componentProps={{
                  placeholder: "example@gmail.com",
                }}
                rightRender={
                  watchEmails.length >= 2 ? (
                    <IconButton
                      iconName="minus"
                      onClick={() => removeEmailField(index)}
                    />
                  ) : (
                    <IconButton
                      iconName="plus"
                      onClick={() => appendEmailField({ value: "" })}
                    />
                  )
                }
              />
            );
          })}
        </View>

        <View>
          {numberFields.map((field, index) => {
            return (
              <PhoneNumberInput
                className="pb-3"
                key={field.id}
                control={control}
                trigger={trigger}
                errors={errors}
                phoneNumberName={`phoneNumber.${index}.phoneNumber`}
                nationCodeName={`phoneNumber.${index}.nationCode`}
                label={"Phone Number"}
                rightRender={
                  watchPhoneNumbers.length >= 2 ? (
                    <IconButton
                      iconName="minus"
                      onClick={() => removeNumberField(index)}
                    />
                  ) : (
                    <IconButton
                      iconName="plus"
                      onClick={() =>
                        appendNumberField({
                          phoneNumber: "",
                          nationCode: defaultNationCode,
                        })
                      }
                    />
                  )
                }
              />
            );
          })}
        </View>

        <View className="flex flex-row gap-2 py-6">
          <Controller
            name="primary"
            control={control}
            render={({ field }) => (
              <Checkbox
                id={id}
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            )}
          />
          <Label className="text-sm text-muted-foreground" htmlFor={id}>
            Make default Contact
          </Label>
        </View>

        <Button
          variant="primary"
          color="CTA"
          fullWidth="full"
          rounded="full"
          onClick={onSubmitForm}
          loading={loading}
          children="Save"
        />
      </View>
    </BottomDrawerModal>
  );
};
