import { Loading } from "@/components/shared/icons";
import { cn } from "@/lib/utils";
import { View } from "@/components/ui";

export const Spin = ({
  className,
}: {
  size?: number; // unused?
  className?: string;
}) => (
  <View className="flex items-center justify-center">
    <Loading className={cn("animate-spin", className)} />
  </View>
);
