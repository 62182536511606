import type { ReservationDetailFragmentFragment } from "@/__generated__/graphql";
import {
  PackageServiceTypeEnum,
  PackageUnitTypeEnum,
  UnitSizeEnum,
} from "@/__generated__/graphql";
import type { ShortDay } from "@/lib/helpers/date";

export interface Review {
  id: string;
  clientName: string;
  rating: number;
  createdAt: number;
  ltv: number;
  service: string;
  description: string;
  images: string[];
}

export interface ServiceReviews {
  rating: number;
  ratingTotal: number;
  reviews: Review[];
}
export interface ServiceDetail {
  title: string;
  ratingTotal: number;
  bookingTotal: number;
  descriptions: string[];
  image: string;
  averageRating: number;
  rateFrom: number;
  rateTo: number;
  guarantee: boolean;
  detailImages: string[];
  startPrice: number;
  faqs: { title: string; description: string }[];
}

export enum BookingModals {
  CLEANING_DURATION = "CleaningDuration",
}

export enum PackageFrequencyGroupEnum {
  NEXT_DAY_AD_HOC = "NEXT_DAY_AD_HOC",
  AD_HOC = "AD_HOC",
  FORTNIGHT = "FORTNIGHT",
  WEEKLY = "WEEKLY",
  TRI_YEARLY = "TRI_YEARLY",
  QUARTERLY = "QUARTERLY",
  WEEKLY_PLUS = "WEEKLY_PLUS",
}

export enum AirconServiceTypeEnum {
  GENERAL_SERVICING = PackageServiceTypeEnum.GeneralServicing,
  CHEMICAL_WASH = PackageServiceTypeEnum.ChemicalWash,
  CHEMICAL_OVERHAUL = PackageServiceTypeEnum.ChemicalOverhaul,
  CONDENSER_WASH = PackageServiceTypeEnum.CondenserWash,
  REPAIR_DIAGNOSTIC = PackageServiceTypeEnum.RepairDiagnostic,
}

export enum AirconPackageUnitTypeEnum {
  WALL = PackageUnitTypeEnum.Wall,
  CEILING = PackageUnitTypeEnum.Ceiling,
  DUCTED = PackageUnitTypeEnum.Ducted,
  PORTABLE = PackageUnitTypeEnum.Portable,
  WINDOW = PackageUnitTypeEnum.Window,
}

export enum HomeBeautyServiceTypeEnum {
  MANI_PEDI = PackageServiceTypeEnum.Combo,
  MANICURE = PackageServiceTypeEnum.Manicure,
  PEDICURE = PackageServiceTypeEnum.Pedicure,
  UPGRADE = PackageServiceTypeEnum.Upgrade,
  ADD_ON = PackageServiceTypeEnum.AddOn,
  DISCOUNT = PackageServiceTypeEnum.Discount,
}

export enum MattressUnitSizeEnum {
  SINGLE = UnitSizeEnum.Single,
  QUEEN = UnitSizeEnum.Queen,
  KING = UnitSizeEnum.King,
}

export enum AccessMode {
  KeypadPin = "Keypad PIN",
  RingDoorbell = "Ring Doorbell",
}

export type WorkerPortfolio = {
  id: string;
  fileName: string;
  imgUrl: string;
};

export type TimeSlot = {
  startAt: string;
  id: string;
  slotId: string;
  date: string;
  day: ShortDay;
  partOfDay: "Morning" | "Evening" | "Afternoon";
  startTime: string;
  endTime: string;
  averageTravelTime: number;
  workerId: string;
  discount?: number;
  sessionValue?: number;
  rateValue?: number;
  workerName?: string;
  avatarUrl?: string;
  workerRating?: number;
  portfolios?: WorkerPortfolio[];
};

export type PastWorker = {
  id: string;
  firstName: string;
  lastName: string;
  workerRating: number;
  avatarUrl: string;
  contactNumber: string;
};

export type Reservation = { __typename?: "Reservation" } & {
  " $fragmentRefs"?: {
    ReservationDetailFragmentFragment: ReservationDetailFragmentFragment;
  };
};

export type AppliedPromo = {
  appliedPromoCode: string;
  computedDiscount: number;
};
