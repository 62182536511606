import { createRequestFactory } from "@/lib/request-factory";
import {
  SignInDocument,
  type SignInMutation,
  type SignInMutationVariables,
} from "@/__generated__/graphql";
import type { UserInfo, JwtInfo } from "@/types/users";
import { useAuthState } from ".";
import { useClientStore } from "./client";
import { storage } from "@/lib/storage";
import { setAnalyticUserId } from "@/lib/firebase";

type Response = {
  userInfo: UserInfo;
  jwtInfo: JwtInfo;
};

// email login
export const useSignInStore = createRequestFactory<
  SignInMutation,
  Response,
  SignInMutationVariables
>({
  method: "mutation",
  graphqlDocument: SignInDocument,
  transformFunction: (res) => {
    if (res.signInClient?.result) {
      if (res.signInClient.result.__typename === "SignInClientOk") {
        const {
          jwt,
          jwtExpiry,
          refreshToken,
          client: { id, user, contacts, addresses },
        } = res.signInClient.result;
        const userInfo = {
          id,
          firstName: contacts[0].firstName,
          lastName: contacts[0].lastName,
          user,
          contacts,
          addresses,
        };
        const jwtInfo = {
          jwt,
          jwtExpiry,
          refreshToken,
        };
        return { userInfo, jwtInfo } as Response;
      }
    }
    //@ts-expect-error
    throw new Error(res.signInClient?.result.message || "Login Failed");
  },
  onFetchSuccess: (res) => {
    const { setJwtInfo } = useAuthState.getState();
    const { fetch: getUserInfo } = useClientStore.getState();
    setJwtInfo(res.jwtInfo);
    getUserInfo({
      requestPayload: {
        id: res.userInfo.id,
      },
    });
    setAnalyticUserId(res.userInfo.id)
    storage.setItem("ONBOARDING", "true");
  },
});
