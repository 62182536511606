import { z } from "zod";
import parsePhoneNumber from "libphonenumber-js";

export const getFullPhoneNumber = ({
  code,
  phoneNumber,
}: {
  code: string;
  phoneNumber: string;
}) => {
  return `+${code?.split("/")[1]}${phoneNumber}`;
};

export const superRefinePhoneNumber = ({
  code,
  phoneNumber,
  ctx,
  phoneNumberFieldName = "phoneNumber",
}: {
  code: string;
  phoneNumber: string;
  ctx: z.RefinementCtx;
  phoneNumberFieldName?: string;
}) => {
  const fullNumber = getFullPhoneNumber({ code, phoneNumber });
  if (!parsePhoneNumber(fullNumber)?.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: [phoneNumberFieldName],
      message: "Must be a valid phone number",
    });
  }
};
