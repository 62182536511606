import { Button, View } from "@/components/ui";
import { PhoneNumberInputForm } from "../login-modal/phone-number-input-form";
import { useLoginForm } from "@/store/auth/forms/useLoginForm";
import { useAuthState } from "@/store/auth";
import { AuthModals, LoginType } from "@/types/users";
import { useSendPinCodeToPhoneStore } from "@/store/auth/sendPinCodeToPhone";
import { Divider } from "@/components/shared/divider";
import { useRoute } from "@/components/shared/router";
import { cn } from "@/lib/utils";
import { useEffect } from "react";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";

export const LoginContent = ({ isModal = true }: { isModal?: boolean }) => {
  const intl = useIntl();
  const { replace } = useRoute();
  const { setPhone, setType, showModal, setLoginByModal } = useAuthState();
  const { loading, fetch: sendPinCode, error } = useSendPinCodeToPhoneStore();

  const formReturn = useLoginForm();
  const { defaultValues, reset } = formReturn;

  useEffect(() => {
    setLoginByModal(isModal);
  }, []);

  const onSubmitForm = (data: { nationCode: string; phoneNumber: string }) => {
    setType(LoginType.Login);
    setPhone(`+${data.nationCode}${data.phoneNumber}`);
    sendPinCode(
      {
        requestPayload: {
          input: {
            phone: `+${data.nationCode}${data.phoneNumber}`,
          },
        },
      },
      { selfHandleError: true },
    ).then((res) => {
      if (res.data?.success) {
        showModal(AuthModals.OTP);
      }
    });
  };
  const onGoToSignUp = () => {
    reset(defaultValues);
    if (isModal) showModal(AuthModals.SIGN_UP);
    else replace({ pageKey: "signup" });
  };
  const onGoToEmailLogin = () => {
    reset(defaultValues);
    if (isModal) showModal(AuthModals.EMAIL_LOGIN);
    else setType(LoginType.EmailLogin);
  };

  return (
    <View className={cn("flex flex-col", isModal ? "gap-2" : "gap-4")}>
      <View className="pb-3">
        <Typography variant="label-2xl">
          {" "}
          {intl.formatMessage({
            defaultMessage: "Sign In",
            id: "sign-in",
          })}
        </Typography>
        <Typography variant="body-lg" color="foreground-intermediate">
          {intl.formatMessage({
            defaultMessage:
              "To continue the booking process, you need to Login or create a new account",
            id: "loginRegister.description",
          })}
        </Typography>
      </View>

      <PhoneNumberInputForm
        formReturn={formReturn}
        onSubmitForm={onSubmitForm}
        errorMessage={error || ""}
        loading={loading}
      />
      <Divider>
        {intl.formatMessage({
          defaultMessage: "Don't have an account?",
          id: "no-account-question",
        })}
      </Divider>
      <Button
        variant="secondary"
        color="CTA2"
        fullWidth="full"
        rounded="full"
        className="mt-4 mb-6"
        onClick={onGoToSignUp}
        children={intl.formatMessage({
          defaultMessage: "Sign Up",
          id: "sign-up",
        })}
      />
      <Divider>
        {intl.formatMessage({
          defaultMessage: "or",
          id: "or",
        })}
      </Divider>
      <View className="mt-6 flex flex-row items-center justify-center">
        <Typography variant="body-lg" color="secondary-text">
          {intl.formatMessage({
            defaultMessage: "Sign in using",
            id: "sign-in-using",
          })}
        </Typography>
        <Typography
          className="ml-1.5 cursor-pointer text-sm color-[#3273f3] color-primary"
          onClick={onGoToEmailLogin}
        >
          {intl.formatMessage({
            defaultMessage: "other methods",
            id: "other-methods",
          })}
        </Typography>
      </View>
    </View>
  );
};
