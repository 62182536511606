import { Card, CardContent, View } from "@/components/ui";
import { Package } from "@/components/shared/icons";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useState } from "react";
import type { CreditAccount } from "@/__generated__/graphql";
import TransactionHistoryCard from "@/components/shared/transaction-history-card";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const PackageCredit = ({
  creditAccount,
}: {
  creditAccount: CreditAccount;
}) => {
  const intl = useIntl();
  const { isMobile } = useWindowDimensions();
  const [openTransactionHistory, setOpenTransactionHistory] =
    useState<boolean>(false);

  const handleOpenTransactionHistory = () => {
    setOpenTransactionHistory(!openTransactionHistory);
  };

  return (
    <Card border={isMobile ? "default" : "none"} className="my-4">
      <CardContent className="py-3 md:py-0">
        <View className="flex flex-row items-center gap-3 border-b border-secondary-border pb-3">
          <Package weight="fill" className="text-primary" size={32} />
          <View>
            <Typography variant="body-md" color="foreground-intermediate">
              Package Balance
            </Typography>
            <Typography>S${creditAccount.balance}</Typography>
          </View>
        </View>
        <Accordion
          className="w-full"
          type="single"
          defaultValue="item-1"
          collapsible
        >
          <AccordionItem className="border-b-0" value="item">
            <View onClick={handleOpenTransactionHistory}>
              <AccordionTrigger className="flex w-full flex-row items-center justify-between px-0 pb-0 text-lg leading-[1] hover:no-underline">
                <Typography color="brand-primary">
                  {intl.formatMessage({
                    defaultMessage: "Transaction History",
                    id: "transaction-history",
                  })}
                </Typography>
              </AccordionTrigger>
            </View>
            {creditAccount.transactions.map((item) => (
              <AccordionContent
                className="overflow-hidden pb-0 pt-3"
                key={item.id}
              >
                <TransactionHistoryCard transaction={item} />
              </AccordionContent>
            ))}
          </AccordionItem>
        </Accordion>
      </CardContent>
    </Card>
  );
};
