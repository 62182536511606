import { View, Button, Image } from "@/components/ui";
import { Typography } from "@/components/shared/typography";
import ManThinking from "@/assets/images/man-thinking.png";
import { WebLayout } from "@/components/shared/layout/homepage.web";

export const ErrorBoundaryPage = () => {
  const onBack = () => {
    document.location.href = "/";
  };
  return (
    <WebLayout>
      <View className="mx-6 my-10 flex items-center justify-center">
        <View className="flex w-96 flex-col gap-4">
          <View className="flex max-h-80 justify-center">
            <Image src={ManThinking} className="object-cover" />
          </View>
          <Typography variant="label-lg" align="center">
            Oops! Something went wrong
          </Typography>
          <Typography variant="body-md" color="secondary-text" align="center">
            We’re sorry for the inconvenience. It seems like there was an
            unexpected error. Don’t worry we’re on it!
          </Typography>
          <Button
            variant="primary"
            color="CTA"
            onClick={onBack}
            children="Back to Homepage"
          />
        </View>
      </View>
    </WebLayout>
  );
};
