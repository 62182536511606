import { InfoDialog } from "@/components/shared/info-dialog";

export const ChangePhoneNumberSuccessModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <InfoDialog
    open={open}
    onClose={onClose}
    title="Your phone number has been successfully updated."
    subtitle="You will use this new number to log in to your account"
  />
);
