import { LoginModal } from "@/components/shared/login-register/login-modal";
import { EmailLoginModal } from "@/components/shared/login-register/email-login-modal";
import { OtpVerificationModal } from "@/components/shared/login-register/otp-verification-modal";
import { SignUpModal } from "@/components/shared/login-register/sign-up-modal";
import { ForgetPasswordModal } from "@/components/shared/login-register/forget-password-model";
// import { WelcomeModal } from "@/components/shared/login-register/welcome-modal";
import { AddAddressModal } from "@/components/shared/login-register/add-address-modal";
import { useSignUp } from "@/components/hooks/auth/useSignUp";
import { useAuthState } from "@/store/auth";

export function AuthContainer() {
  const { signUpLoading, onSignUp, signUpError } = useSignUp(true);
  const {
    data: { addressModalOpen },
    showModal,
  } = useAuthState();
  return (
    <>
      <LoginModal />
      <EmailLoginModal />
      <OtpVerificationModal />
      <SignUpModal />
      <AddAddressModal
        onClose={() => showModal()}
        open={addressModalOpen}
        isDefaultAddress
        onSubmitForm={onSignUp}
        loading={signUpLoading}
        error={signUpError || ""}
      />
      <ForgetPasswordModal />
      {/* <WelcomeModal /> */}
    </>
  );
}
