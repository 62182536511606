import { View, Card, CardContent } from "@/components/ui";
import { WorkerAvatar } from "@/components/shared/worker-avatar";
import { Star } from "@/components/shared/icons";
import { Typography } from "../typography";

export const WorkerProfileCard = ({
  name,
  avatar,
  star,
}: {
  name: string;
  avatar: string;
  star: string;
}) => {
  return (
    <Card className="h-[127px] w-full">
      <CardContent>
        <View className="relative flex flex-row items-center justify-center pb-4">
          <WorkerAvatar src={avatar} className="size-[72px]" />

          <View className="absolute bottom-2 flex flex-row items-center gap-1 rounded-xl border border-secondary-border bg-white p-1">
            <Star weight="fill" className="text-warning" size={12} />
            <Typography variant="body-sm">{Number(star).toFixed(1)}</Typography>
          </View>
        </View>
        <Typography variant={"label-md"} align="center">
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
};
