import { ListHeading } from "@/components/shared/list-header";
import { View, Button, Input } from "@/components/ui";
import { MapPinPlus } from "@/components/shared/icons";
import type { Control } from "react-hook-form";
import { InputFormControl } from "@/components/shared/input-form-control";
import { Spin } from "@/components/shared/spin";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const ServiceAddressSection = ({
  address,
  isLoggedIn,
  control,
  loading,
  onSearchPostalCode,
  onChangeAddress,
  getAddressByPostalCode,
}: {
  isLoggedIn: boolean;
  loading: boolean;
  address?: string;
  control: Control<{ postalCode: string }>;
  getAddressByPostalCode(value: string): void;
  onSearchPostalCode(): void;
  onChangeAddress(): void;
}) => {
  const intl = useIntl();
  return (
    <View className="native:pt-0 flex w-full flex-col">
      <ListHeading
        title={intl.formatMessage({
          defaultMessage: "Service Address",
          id: "select-address",
        })}
        icon={MapPinPlus}
      />
      {isLoggedIn ? (
        <View className="flex flex-row items-center gap-2">
          <Typography variant="body-lg" className="flex-1">
            {address}
          </Typography>
          <Button
            variant="secondary"
            color="CTA2"
            size="sm"
            rounded="full"
            onClick={onChangeAddress}
            children={intl.formatMessage({
              defaultMessage: "Change",
              id: "change",
            })}
          />
        </View>
      ) : (
        <View className="flex flex-row items-end gap-2">
          <InputFormControl
            label="Postal Code"
            name="postalCode"
            className="flex-1 pb-0"
            control={control}
            component={Input}
            componentProps={{
              placeholder: "Input your postal code",
              suffix: loading && <Spin />,
            }}
            changeFallBack={(value) => {
              if (!value || value.length < 6 || value.length > 10) return;
              getAddressByPostalCode(value);
            }}
            rightRender={
              <Button
                // button border color between CTA2-priamry and CTA2-secondary is different. the former one is button-cta2, and the later one should be button-cta2-border
                variant="primary"
                color="CTA2-tertiary"
                onClick={onSearchPostalCode}
                children={intl.formatMessage({
                  defaultMessage: "Find Availability",
                  id: "find-avaliability",
                })}
              />
            }
          />
        </View>
      )}
    </View>
  );
};
