import { Input, View } from "@/components/ui";
import { PasswordIcon, EyeClosed, Eye } from "../icons";
import type { ComponentProps } from "react";
import { useState } from "react";

export const PasswordInput = (props: ComponentProps<typeof Input>) => {
  const [isPwd, setIsPwdType] = useState(true);

  const togglePwdType = () => {
    setIsPwdType(!isPwd);
  };

  return (
    <Input
      placeholder="Enter Password"
      prefix={<PasswordIcon className="size-5" />}
      suffix={
        <View className="text-secondary-text" onClick={togglePwdType}>
          {isPwd ? (
            <EyeClosed className="size-5" color="#68778d" />
          ) : (
            <Eye className="size-5" color="#68778d" />
          )}
        </View>
      }
      type={isPwd ? "password" : "text"}
      {...props}
    />
  );
};
