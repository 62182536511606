import {
  AccordionContent,
  AccordionTrigger,
  AccordionItem,
  Accordion,
} from "@/components/ui/accordion";
import { Typography } from "../typography";

export function ServiceFaq({
  items,
}: {
  items: { title: string; description: string }[];
}) {
  return (
    <Accordion className="w-full" type="single" collapsible>
      {items.map((item, id) => (
        <AccordionItem
          key={`item-${id}`}
          className="border-b border-solid border-slate-200"
          value={`item-${id}`}
        >
          <AccordionTrigger className="flex w-full flex-row items-center justify-between px-0 py-3 text-left md:py-4">
            <Typography variant="label-lg">{item.title}</Typography>
          </AccordionTrigger>
          <AccordionContent className="overflow-hidden pb-2">
            <Typography variant="body-md" color="foreground-intermediate">
              {item.description}
            </Typography>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
