import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Skeleton,
  View,
} from "@/components/ui";
import { UserProfileIcon } from "@/components/shared/icons";
import { Typography } from "../../typography";

export function WorkerAvatar({
  fullName,
  avatarUrl,
  loading,
}: {
  fullName: string;
  avatarUrl: string | null;
  loading?: boolean;
}) {
  return (
    <View className="flex flex-row items-center py-2">
      <Avatar alt={fullName ?? "Pending Assignment"} className="items-center">
        <AvatarImage
          src={avatarUrl ?? ""}
          className="size-9 rounded-full p-1"
          width={36}
          height={36}
        />
        <AvatarFallback className="size-7 items-center">
          <UserProfileIcon className="size-7 color-foreground-intermediate" />
        </AvatarFallback>
      </Avatar>
      {loading ? (
        <Skeleton className="mr-4 h-3 w-[200px]" />
      ) : (
        <Typography
          variant="label-md"
          className="overflow-hidden truncate whitespace-nowrap"
        >
          {fullName}
        </Typography>
      )}
    </View>
  );
}
