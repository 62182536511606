import { Dialog, DialogContent } from "@/components/ui";
import { Spin } from "../spin";

export function LoadingDialog({ open }: { open: boolean }) {
  return (
    <Dialog open={open}>
      <DialogContent className="w-40">
        <Spin className="size-10" />
      </DialogContent>
    </Dialog>
  );
}
