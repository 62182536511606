import { View } from "@/components/ui";
import { Divider } from "@/components/shared/divider";
import { useBookingOrder } from "@/components/hooks/use-booking-order";
import { formatPrice } from "@/lib/helpers/number";
import { LineItemList } from "@/components/shared/line-item/line-item-list";
import { ListItem } from "@/components/shared/visits/visit-detail";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const OrderDetails = ({
  showTotal,
  showTotalDivider,
}: {
  showTotal?: boolean;
  showTotalDivider?: boolean;
}) => {
  const intl = useIntl();
  const {
    lineItems,
    totalBeforeDiscount,
    tax,
    total,
    totalDiscount,
    promo,
    percentDiscount,
    serviceDuration,
  } = useBookingOrder();

  return (
    <>
      <View className="max-h-[20vh] overflow-y-auto">
        <LineItemList lineItems={lineItems} />
      </View>
      <Divider className="my-3" />
      <View className="flex flex-col gap-2">
        <ListItem
          label={intl.formatMessage({
            defaultMessage: "Service Duration / Visit",
            id: "service-duration",
          })}
          value={serviceDuration}
        />
        <ListItem
          label={intl.formatMessage({
            defaultMessage: "Total Cost / Visit",
            id: "total-cost",
          })}
          value={formatPrice(totalBeforeDiscount)}
        />
        {!!totalDiscount && (
          <ListItem
            label={`Discount ${percentDiscount}%`}
            value={formatPrice(0 - totalDiscount)}
          />
        )}
        <ListItem label="GST (9%)" value={formatPrice(tax)} />
        {!!promo && (
          <ListItem
            label="Promo"
            value={`${formatPrice(0 - promo.computedDiscount)}`}
          />
        )}
      </View>
      {showTotalDivider && <Divider className="mt-2" />}
      {showTotal && (
        <View className="flex flex-row items-center justify-between py-3">
          <Typography variant={{ sm: "body-lg", md: "body-md" }}>
            {`Total (${lineItems.length || 0} Item${lineItems?.length > 1 ? "s" : ""})`}
          </Typography>
          <View className="flex flex-row items-center gap-1">
            <Typography
              variant={{ sm: "bold-lg", md: "label-lg" }}
              color="brand-primary"
            >
              {formatPrice(total)}
            </Typography>
            <Typography variant="body-md" color="secondary-text">
              {intl.formatMessage({
                defaultMessage: " incl. GST",
                id: "incl-gst",
              })}
            </Typography>
          </View>
        </View>
      )}
    </>
  );
};
