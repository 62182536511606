import { usePathname } from "@/components/hooks/use-pathname";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { WebLayout } from "@/components/shared/layout/homepage";
import { NavTabs } from "@/components/shared/nav-tabs";
import { View } from "@/components/ui";
import { Column, Container } from "@/components/ui/layout";
import { GetTheApp } from "@/containers/get-the-app";
import { VisitIssueContainer } from "@/containers/report-issues/visit-issue";
import { VisitDetailContainer } from "@/containers/visits/visit-detail";
import { Outlet } from "react-router-dom";
import { useIntl } from "react-intl";

export function VisitsPage() {
  const intl = useIntl();
  const visitTabs = [
    {
      label: intl.formatMessage({
        defaultMessage: "Upcoming",
        id: "upcoming",
      }),
      href: "/visits/upcoming",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "History",
        id: "history",
      }),
      href: "/visits/history",
    },
  ];
  const pathname = usePathname();
  const { isMobile } = useWindowDimensions();

  return (
    <WebLayout>
      <Container gap="xl">
        <Column desktop="8">
          <View className="md:my-10">
            <NavTabs items={visitTabs} active={pathname} />
            <Outlet />
          </View>
        </Column>
        <Column desktop="4">
          <View className="px-4 md:my-10 md:px-0">
            <GetTheApp imageVariant={isMobile ? "luceapp" : "qrcode"} />
          </View>
        </Column>
      </Container>
      <VisitDetailContainer />
      <VisitIssueContainer />
    </WebLayout>
  );
}
