import { ClientAccountTypeEnum, CreditTypeEnum } from "@/__generated__/graphql";
import type { UserInfo } from "@/types/users";

export { nations, defaultNationCode } from "./nation";
export const MAX_MOBILE_WIDTH = 768;

export const TABBAR_HEIGHT = 80;
export const HEADER_HEIGHT = 56;

export const STORAGE_PREFIX = "LUCE_FE";

export const USER_INFO_KEY = "USER_INFO";

export const JWT_KEY = "JWT";

export const CS_NUMBER = "+65 3138 4827";

export const SALES_NUMBER = "+6531384827";

export const DEFAULT_USER_DATA: UserInfo = {
  firstName: "",
  lastName: "",
  id: "",
  user: {
    id: "",
    email: "",
    phoneNumber: "",
    phoneNumberVerifiedAt: "",
    emailVerifiedAt: "",
  },
  contacts: [
    {
      id: "",
      firstName: "",
      lastName: "",
      email: [""],
      phoneNumber: [""],
      primary: true,
    },
  ],
  addresses: [],
  accountType: ClientAccountTypeEnum.Individual,
  creditAccounts: [
    {
      id: "",
      creditType: CreditTypeEnum.General,
      balance: 0,
    },
  ],
  paymentMethod: null,
  autoPay: false,
  billingAccount: {
    id: "",
    balance: 0,
    overdueAmount: 0,
    totalOverdueInvoice: 0,
  },
};

export const DEFAULT_JWT_DATA = {
  jwt: "",
  jwtExpiry: 0,
  refreshToken: "",
};

export const links = {
  googlePlayClientApp:
    "https://play.google.com/store/apps/details?id=com.luce.customer.app&pli=1",
  appStoreClientApp: "https://apps.apple.com/id/app/luce-app/id1643684015",
  legalTermCondition: "https://www.luce.sg/legal/terms-and-conditions",
  legalPrivacyPolicy: "https://www.luce.sg/legal/privacy-policy",
  applyCreditHelpCentreLink: "https://help.lucemg.com/applying-a-credit",
  aboutUs: "https://www.luce.sg/about-us",
  career: "https://www.luce.sg/career",
  servicesHomeCleaning: "https://www.luce.sg/services/home-cleaning",
  servicesAirconServicing: "https://www.luce.sg/services/aircon-servicing",
  servicesOfficeCleaning: "https://www.luce.sg/services/office-cleaning",
  sitemap: "https://www.luce.sg/sitemap",
  helpCenter: "https://help.lucemg.com/",
  blogs: {
    airconServicing: "https://www.luce.sg/blog-category/aircon-servicing",
    homeCleaning: "https://www.luce.sg/blog-category/home-cleaning",
    officeCleaning: "https://www.luce.sg/blog-category/office-cleaning",
    upholsteryCleaning: "https://www.luce.sg/blog-category/upholstery-cleaning",
    floorCleaning: "https://www.luce.sg/blog-category/floor-cleaning",
  },
};
