import { WebLayout } from "@/components/shared/layout/homepage";
import { LoginPage } from ".";

export const LoginPageWrapper = () => {
  return (
    <WebLayout>
      <LoginPage />
    </WebLayout>
  );
};
