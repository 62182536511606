import { createRequestFactory } from "@/lib/request-factory";
import {
  CreateAddressDocument,
  type CreateAddressMutation,
  type CreateAddressMutationVariables,
} from "@/__generated__/graphql";

export const useCreateAddressStore = createRequestFactory<
  CreateAddressMutation,
  CreateAddressMutation,
  CreateAddressMutationVariables
>({
  method: "mutation",
  graphqlDocument: CreateAddressDocument,
});
