import { View } from "@/components/ui";
import type { PackageDetailFragmentFragment } from "@/__generated__/graphql";
import {
  PackageDepartmentEnum,
  PackageDetailSortByEnum,
  PackageRecurrenceEnum,
  PackageUnitTypeEnum,
  ServiceTypeEnum,
  SortEnum,
} from "@/__generated__/graphql";
import { useAllClientPackageDetailsStore } from "@/store/booking/allClientPackageDetails";
import { useEffect, useMemo } from "react";
import { useBookingState } from "@/store/booking/useBookingState";
import { BookingItemCard } from "@/components/shared/booking/booking-item-card";
import { formatPrice } from "@/lib/helpers/number";
import { ListHeading } from "@/components/shared/list-header";
import { Cube } from "@/components/shared/icons";
import { Message } from "@/components/shared/message";
import { Spin } from "@/components/shared/spin";
import { getCarpetUpholsteryFromUnit } from "@/constants/booking";
import type { UpholsteryServiceName } from "@/types/service";

type FEPackage = PackageDetailFragmentFragment & {
  recommended?: boolean;
  description?: string;
  title: string;
  img: string;
  sortOrder?: number;
  maxItem: number;
};

export const UpholsteryCleaningBooking = ({
  name,
}: {
  name: UpholsteryServiceName;
}) => {
  const {
    data: { packageDetails, selectedPackageIds },
    setBookingState,
  } = useBookingState();
  const { fetch: getAllClientPackageDetails, loading } =
    useAllClientPackageDetailsStore();

  const upholsteryUnitType = useMemo(() => {
    if (name === "mattress-cleaning") {
      return [PackageUnitTypeEnum.Mattress];
    }
    if (name === "sofa-cleaning") {
      return [PackageUnitTypeEnum.Sofa];
    }
    if (name === "rug-cleaning") {
      return [PackageUnitTypeEnum.Rug];
    }
    if (name === "pram-seat") {
      return [PackageUnitTypeEnum.BabyPram, PackageUnitTypeEnum.ChildSeat];
    }
    return [];
  }, [name]);

  useEffect(() => {
    getAllClientPackageDetails({
      requestPayload: {
        filters: {
          department: PackageDepartmentEnum.CarpetUpholstery,
          sort: [SortEnum.Asc],
          sortBy: [PackageDetailSortByEnum.Duration],
          serviceTypes: [ServiceTypeEnum.DeepExtraction],
          unitTypes: [
            PackageUnitTypeEnum.Mattress,
            PackageUnitTypeEnum.Sofa,
            PackageUnitTypeEnum.Rug,
            PackageUnitTypeEnum.ChildSeat,
            PackageUnitTypeEnum.BabyPram,
            PackageUnitTypeEnum.Carpet,
          ],
        },
      },
    }).then((res) => {
      if (res.error || !res.data) {
        return;
      }
      setBookingState({
        packageDetails: res.data,
        packageDetailsFilter: {
          repeatEvery: [PackageRecurrenceEnum.AdHoc],
        },
      });
    });
  }, []);

  const FEPackages: FEPackage[] = useMemo(() => {
    if (!packageDetails) return [];
    return packageDetails
      .map((pkg) => {
        return {
          ...pkg,
          ...getCarpetUpholsteryFromUnit(pkg.unitType, pkg.unitSize),
        } as FEPackage;
      })
      .filter((pkg) => !!pkg.title);
  }, [packageDetails]);

  const servicePackages: FEPackage[] = useMemo(() => {
    return FEPackages.filter((pkg) =>
      upholsteryUnitType.includes(pkg.unitType),
    ).sort((a, b) => Number(a?.sortOrder || 0) - Number(b.sortOrder || 0));
  }, [FEPackages]);

  // comment for now
  // const upsellPackages: FEPackage[] = useMemo(() => {
  //   return FEPackages.filter(
  //     (pkg) =>
  //       ![PackageUnitTypeEnum.All, ...upholsteryUnitType].includes(pkg.unitType)
  //   );
  // }, [FEPackages]);

  const getSelectedPackageValue = (id: string) => {
    return selectedPackageIds.filter((pkgId) => pkgId === id).length || 0;
  };

  const onAdd = (id: string) => {
    setBookingState({
      selectedPackageIds: [...selectedPackageIds, id],
    });
  };

  const onMinus = (id: string) => {
    selectedPackageIds.splice(selectedPackageIds.indexOf(id), 1);
    setBookingState({
      selectedPackageIds,
    });
  };

  return (
    <View>
      <View className="mx-4 pb-6">
        <ListHeading title="Add Item Type" icon={Cube} />
        <Message
          variant="info"
          message="Get 5% discount per additional item up to 15% off!"
          className="mt-4"
        />

        {loading && <Spin className="native:p-12 m-12 size-8" />}
        {servicePackages.map((pkg) => (
          <BookingItemCard
            key={pkg.id}
            title={pkg.title}
            subTitle={pkg?.description || ""}
            description={`${formatPrice(pkg.unitValue, 0)}/Unit`}
            selected={getSelectedPackageValue(pkg.id) > 0}
            img={pkg.img}
            value={getSelectedPackageValue(pkg.id)}
            onAdd={() => onAdd(pkg.id)}
            onMinus={() => onMinus(pkg.id)}
            max={pkg.maxItem}
          />
        ))}

        {/* hide this part at first launch */}
        {/* <View className="pt-6">
      <HorizontalScrollView
        slideSize={platform === "web" ? "basis-1/3" : "150"}
        HeaderComponent={
          <ListHeading title="Frequently Add Together" icon={PlusSquare} />
        }
      >
        {upsellPackages.map((pkg) => {
          return (
            <RecommendedCard
              key={pkg.id}
              title={pkg.title}
              price={pkg.unitValue}
              recommended={pkg.recommended}
              imageSrc={pkg.img}
              showNumWidget
              value={getSelectedPackageValue(pkg.id)}
              onAdd={() => onAdd(pkg.id)}
              onMinus={() => onMinus(pkg.id)}
            />
          );
        })}
      </HorizontalScrollView>
    </View> */}
      </View>
    </View>
  );
};
