import {
  PackageDepartmentEnum,
  PackageRecurrenceEnum,
} from "@/__generated__/graphql";
import type { BadgeVariant } from "@/components/ui/badge";

export const getPackageBillingTypeText = (
  department: PackageDepartmentEnum,
  repeatEvery: PackageRecurrenceEnum,
) => {
  if (
    department === PackageDepartmentEnum.Aircon &&
    repeatEvery != PackageRecurrenceEnum.AdHoc
  ) {
    return "Prepaid" as const;
  } else {
    return "Postpaid" as const;
  }
};

export const getPackageBillingTypeVariant = (
  department: PackageDepartmentEnum,
  repeatEvery: PackageRecurrenceEnum,
): BadgeVariant => {
  if (getPackageBillingTypeText(department, repeatEvery) === "Prepaid") {
    return "success";
  } else {
    return "primary";
  }
};
