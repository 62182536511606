import { View } from "@/components/ui";
import { Ratings } from "@/components/ui/ratings";
import { cn } from "@/lib/utils";
import { useMemo } from "react";
import { Typography } from "../../typography";

export function VisitRatingInput({
  rating,
  onChange,
  disabled,
}: {
  rating: number;
  onChange(rating: number): void;
  disabled?: boolean;
}) {
  return (
    <View className="flex flex-col gap-2">
      <View className="px-14">
        <Ratings
          rating={rating}
          onClick={onChange}
          disabled={disabled}
          size={40}
          variant="yellow"
        />
      </View>
      <RatingLabel rating={rating} />
    </View>
  );
}

function RatingLabel({ rating }: { rating: number }) {
  const label = useMemo(() => {
    switch (rating) {
      case 1:
        return "I regretted the booking";
      case 2:
        return "Not recommended";
      case 3:
        return "Nothing special";
      case 4:
        return "Hits the spot";
      case 5:
        return "Will 100% Rebook!";

      default:
        return "";
    }
  }, [rating]);

  return (
    <Typography
      variant="bold-sm"
      className={cn(
        "text-center",
        rating > 3 ? "color-success" : "color-foreground",
      )}
    >
      {label}
    </Typography>
  );
}
