import { View, Button } from "@/components/ui";
import type { CarouselApi } from "@/components/ui/carousel";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { chunkBySize, cn } from "@/lib/utils";
import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { DotNavigation } from "../dot-navigation";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { useIntl } from "react-intl";

export function CollapsibleScrollCard<T>({
  items,
  column = 4,
  row = 1,
  renderItem,
  className,
}: {
  items: T[];
  renderItem(item: T): ReactNode;
  column?: number;
  row?: number;
  className?: string;
}) {
  const intl = useIntl();
  const [api, setApi] = useState<CarouselApi>();
  const [showAll, setShowAll] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const toggleShowAll = () => setShowAll((val) => !val);

  const { isMobile } = useWindowDimensions();

  const renderedItems = items.map(renderItem);

  const numberItem = row * column;
  const numberDots = Math.ceil(items.length / numberItem);

  const carouselItems = chunkBySize(renderedItems, numberItem);

  const hasViewAll = items.length > numberItem;

  const serviceItems = useMemo(() => {
    return showAll ? renderedItems : renderedItems.slice(0, numberItem);
  }, [items, showAll, renderItem]);

  useEffect(() => {
    if (!api) return;
    const setActiveIndex = () => setActiveSlide(api.selectedScrollSnap());

    api.on("scroll", setActiveIndex);
  }, [api]);

  return (
    <View className={cn("CollapsibleScrollCard bg-white", className)}>
      {isMobile ? (
        <View className="flex flex-col gap-4 overflow-hidden">
          <Carousel setApi={setApi}>
            <CarouselContent className="-ml-0">
              {carouselItems.map((item, index) => (
                <CarouselItem key={`carousel-item-${index}`}>
                  <View className="h-full w-full">
                    <View
                      className={cn(column === 4 && "grid grid-cols-4 gap-2")}
                    >
                      {item}
                    </View>
                  </View>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
          <DotNavigation size={numberDots} activeIndex={activeSlide} />
        </View>
      ) : (
        <View className="flex flex-col justify-center">
          <View className={cn(column <= 4 && "mb-8 grid grid-cols-4 gap-2")}>
            {serviceItems}
          </View>
          {hasViewAll && (
            <Button
              variant="tertiary"
              color="CTA2"
              onClick={toggleShowAll}
              iconAlignment="end"
              iconName={showAll ? "caretUp" : "caretDown"}
              className="gap-2"
              children={
                showAll
                  ? intl.formatMessage({ defaultMessage: "Close", id: "close" })
                  : intl.formatMessage({
                      defaultMessage: "View All",
                      id: "view-all",
                    })
              }
            />
          )}
        </View>
      )}
    </View>
  );
}
