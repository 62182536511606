import { View } from "@/components/ui";
// import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { CustomerReviewsVertical } from "@/components/shared/customer-reviews/customer-reviews-vertical";
import type { ServiceReviews, Review } from "@/types/booking";
import { MobileFullscreenModal } from "../../mobile-fullscreen-modal";

export const CustomerReviewsModal = ({
  open,
  serviceReviews,
  setOpen,
  onClickCard,
}: {
  serviceReviews: ServiceReviews;
  open: boolean;
  setOpen(open: boolean): void;
  onClickCard(review: Review): void;
}) => {
  return (
    <MobileFullscreenModal
      open={open}
      onClose={() => setOpen(false)}
      title="Customer reviews"
    >
      <View>
        <CustomerReviewsVertical
          serviceReviews={serviceReviews}
          onClickCard={onClickCard}
        />
      </View>
    </MobileFullscreenModal>
  );
};
