import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { parse } from "date-fns";

const tokenizePaymentMethodSchema = z.object({
  name: z.string(),
  number: z
    .string()
    .min(16, { message: "Must be at 16 characters long" })
    .max(16, { message: "Must be at 16 characters long" })
    .refine((value) => !isNaN(Number(value)), {
      message: "Must be a valid number",
    }),
  cvc: z
    .string()
    .min(3, { message: "CVC must be at least 3 digits" })
    .max(4, { message: "CVC must be at most 4 digits" }),
  expDate: z
    .string()
    .length(7, { message: "must be valid format (MM/YYYY)" })
    .refine(
      (value) => {
        const [month, year] = value.split("/").map(Number);

        if (isNaN(month) || isNaN(year) || month < 1 || month > 12) {
          return false;
        }

        const expDate = parse(`01/${value}`, "dd/MM/yyyy", new Date());
        const currentDate = new Date();
        const beginningOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1,
        );

        return expDate >= beginningOfMonth;
      },
      { message: "must be a valid expiration date" },
    ),
});

// Infer the TypeScript type from the schema
export type TokenizePaymentMethodFormData = z.infer<
  typeof tokenizePaymentMethodSchema
>;

export interface AddressFormReturn
  extends UseFormReturn<TokenizePaymentMethodFormData> {
  defaultValues: TokenizePaymentMethodFormData;
}

export const initialFormValues: TokenizePaymentMethodFormData = {
  name: "",
  number: "",
  cvc: "",
  expDate: "",
};

export const useTokenizePaymentMethodForm = (
  initialValues?: TokenizePaymentMethodFormData,
): AddressFormReturn => ({
  defaultValues: initialValues || initialFormValues,
  ...useForm<TokenizePaymentMethodFormData>({
    mode: "onSubmit",
    defaultValues: initialValues,
    resolver: zodResolver(tokenizePaymentMethodSchema),
  }),
});
