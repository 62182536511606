import * as React from "react";
import { Link as RrdLink } from "react-router-dom";

export function Link({
  href,
  replace,
  children,
  target,
  className,
}: {
  href: string;
  replace?: boolean;
  children: React.ReactNode;
  target?: string;
  className?: string;
}) {
  return (
    <RrdLink to={href} replace={replace} target={target} className={className}>
      {children}
    </RrdLink>
  );
}
