import { getCookie, setCookie } from "@/lib/helpers/cookie";

const AD_TRACKING_COOKIE_KEY = '_luce_tracking_key'
const AD_TRACKING_PARAM_KEY = 'tracking-key'

export const getAdTrackingData = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const adTrackingValue = getCookie(AD_TRACKING_COOKIE_KEY)

  if (adTrackingValue) {
    return adTrackingValue
  }

  const adTrackingParam = urlParams.get(AD_TRACKING_PARAM_KEY)

  if (adTrackingParam) {
    setCookie(AD_TRACKING_COOKIE_KEY, adTrackingParam)
    return adTrackingParam
  }

  return null
}
