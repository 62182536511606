import { View } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { ShareReferralBanner } from "@/containers/banners/share-referral";
import { WarningCircle } from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";
import { MakeABooking } from "@/containers/make-a-booking";
import { useAvailableClientVouchersStore } from "@/store/invoices/useAvailableClientVouchers";
import { Spin } from "@/components/shared/spin";
import { useAuthState } from "@/store/auth";
import { useEffect } from "react";
import { IfElse } from "@/components/shared/if-else";
import { VoucherCard } from "@/components/shared/voucher-card";

export const ClientVoucherContainer = () => {
  const clientId = useAuthState((state) => state.data.userInfo.id);
  const {
    fetch: getVouchers,
    data = [],
    loading,
  } = useAvailableClientVouchersStore();

  useEffect(() => {
    if (clientId) {
      getVouchers({
        requestPayload: {
          clientId,
        },
      });
    }
  }, [clientId]);

  return (
    <View className="flex flex-col gap-3 py-4">
      <View className="mx-4 mt-1 md:mx-0">
        <ShareReferralBanner />
        <View className="border-grey-blue-300 mt-4 flex flex-row gap-2 rounded-xl border bg-background-intermediate p-3">
          <WarningCircle
            weight="fill"
            className="text-secondary-text"
            size={20}
          />
          <View className="flex flex-col gap-1 w-full native:max-w-[300px]">
            <Typography color="secondary-text" variant="body-md">
              Vouchers can be applied to invoices to reduce your bill. You can
              use the vouchers from the invoice page.
            </Typography>
            <Link href="/profile/invoices">
              <Typography color="brand-primary" variant="body-md">
                View my invoices
              </Typography>
            </Link>
          </View>
        </View>
      </View>

      {loading ? (
        <Spin className="my-6 size-8" />
      ) : (
        <IfElse
          if={!!data?.length}
          else={
            <>
              <Typography
                variant={"label-lg"}
                className="my-4"
                align={"center"}
              >
                Client doesn't have any voucher
              </Typography>
            </>
          }
        >
          <View className="flex flex-col gap-4 p-4 md:p-0">
            {data?.map((voucher) => (
              <VoucherCard
                key={voucher.id}
                expirationDate={voucher.expirationDate}
                label={voucher.description}
                discountValues={voucher.discountValues}
                discountType={voucher.discountType}
              />
            ))}
          </View>
        </IfElse>
      )}

      <MakeABooking />
    </View>
  );
};
