import { SelectGroup, SelectItem, View, Image } from "@/components/ui";
import { nations } from "@/constants";
import type { CountryCode } from "@/lib/country-flags";
import { getCountryFlagUrl } from "@/lib/country-flags";
import { Typography } from "../../typography";

export const PhoneNumberSelectGroup = () => {
  return (
    <SelectGroup>
      {nations
        .sort((a, b) => Number(a.tel) - Number(b.tel))
        .map((item) => {
          return (
            <SelectItem
              value={item.short + "/" + item.tel}
              label={item.short}
              key={item.short}
            >
              <View className="flex flex-row items-center gap-2 pr-2">
                <Image
                  src={getCountryFlagUrl(
                    item.short.toLowerCase() as CountryCode,
                  )}
                  width={24}
                  height={17}
                />
                <Typography variant="body-lg" color="secondary-text">
                  +{item.tel}
                </Typography>
              </View>
            </SelectItem>
          );
        })}
    </SelectGroup>
  );
};
