import { useBookingRoute } from "@/components/hooks/use-booking-route";
import { ActionNavigation } from "@/components/shared/booking/action-navigation";
import { NativeActionNavigation } from "@/components/shared/booking/native-action-navigation";
import { Typography } from "@/components/shared/typography";
import { View } from "@/components/ui";
import {
  useBookingState,
  defaultServiceDetails,
} from "@/store/booking/useBookingState";
import type { ServiceName } from "@/types/service";
import type { ServiceDetail as IServiceDetail } from "@/types/booking";
import { useMemo } from "react";
import { safeGet } from "@/lib/utils";
import { formatPrice } from "@/lib/helpers/number";
// import { sendAnalyticData } from "@/lib/monitoring/analytics";
// import { getServiceDepartments } from "@/lib/booking-lib";

export const ServiceDetailNavigation = ({ name }: { name: ServiceName }) => {
  const { forward } = useBookingRoute();
  const {
    data: { serviceDetails, packageDetailsFilter },
    setBookingState,
  } = useBookingState();
  const onForward = () => {
    setBookingState({
      selectedPackageIds: [],
      selectedSlot: null,
      reservations: [],
      selectedWorkers: [],
      promo: null,
    });
    if (name === "home-beauty") {
      setBookingState({
        choosePackageModalOpen: true,
      });
    } else {
      forward();
    }

    // TODO: disable until service type aligned
    // sendAnalyticData({ name: 'select_service_type', values: {
    //   service_type: getServiceDepartments(name)
    // } })
  };

  const pageServiceDetails = useMemo(() => {
    const detailKey =
      name === "aircon-service"
        ? `${name}-${packageDetailsFilter?.serviceTypes?.[0]}`
        : name;
    return safeGet(
      serviceDetails,
      detailKey,
      defaultServiceDetails,
    ) as IServiceDetail;
  }, [name]);

  const { startPrice } = pageServiceDetails;

  return (
    <View>
      <ActionNavigation buttonText="Book Service" onClickButton={onForward} />

      <NativeActionNavigation
        buttonText="Book Service"
        onClickButton={onForward}
        leftRender={
          <View>
            <Typography variant="body-md" color="foreground-intermediate">
              Starting from
            </Typography>
            <Typography variant="label-2xl">{`${formatPrice(startPrice, 0)}/hr`}</Typography>
          </View>
        }
      />
    </View>
  );
};
