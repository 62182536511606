import type {
  GetPromotionQuery,
  GetPromotionQueryVariables,
  PromotionStatusEnum,
} from "@/__generated__/graphql";
import { GetPromotionDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";

type Response = {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  status: PromotionStatusEnum;
  buttonLabel: string;
  buttonUrl: string;
  imgUrl: string;
};

export const getPromotionStore = createRequestFactory<
  GetPromotionQuery,
  Response,
  GetPromotionQueryVariables
>({
  method: "query",
  graphqlDocument: GetPromotionDocument,
  transformFunction: (res) => {
    if (res.getPromotion) {
      return {
        id: res.getPromotion.id,
        title: res.getPromotion.title,
        startDate: res.getPromotion.startDate,
        endDate: res.getPromotion.endDate,
        status: res.getPromotion.status,
        buttonLabel: res.getPromotion.buttonLabel ?? " Get Promo Now",
        buttonUrl: res.getPromotion.url,
        imgUrl: res.getPromotion.promotionImages[0].downloadUrl,
      };
    }
    throw new Error("Unable to fetch promotion");
  },
});
