import type { AppEnv, EnvName } from "@/types/app";
import {
  SENTRY_DSN_STAGING,
  SENTRY_DSN_PRODUCTION,
} from "@/constants/monitoring";

export const app: AppEnv = {
  DEV: import.meta.env.DEV,
  ENV: import.meta.env.VITE_ENV_NAME as EnvName,
  PUBLIC_API_ENDPOINT: import.meta.env.VITE_PUBLIC_API_URL + "/graphql",
  REFRESH_TOKEN_ENDPOINT: import.meta.env.VITE_PUBLIC_API_URL + "/refresh-token",
  SENTRY_DSN:
    import.meta.env.VITE_ENV_NAME != "local"
      ? import.meta.env.VITE_ENV_NAME === "staging"
        ? SENTRY_DSN_STAGING
        : SENTRY_DSN_PRODUCTION
      : "",
  SENTRY_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
  FIREBASE: {
    API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
    APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
    AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    MEASUREMENT_ID: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  },
};
