import { Column, Container } from "@/components/ui/layout";
import { View } from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";
import { getPlatform } from "@/lib/utils";
import { RescheduleVisitContent } from "./reschedule-visit-content";
import { RescheduleVisitInfo } from "./reschedule-visit-info";
import { useRoute } from "@/components/shared/router";
import { PortalHost } from "@/components/shared/portal";
import { Typography } from "@/components/shared/typography";

export function RescheduleVisit({
  visitId,
  token,
}: {
  visitId?: string;
  token?: string;
}) {
  const { pull } = useRoute();

  return (
    <Container gap="xl">
      <Column desktop="8">
        <View className="md:my-10">
          {getPlatform() === "web" && (
            <View className="flex flex-row items-center gap-1.5">
              <IconButton
                variant="ghost"
                color="CTA2"
                iconName="arrowLeft"
                onClick={pull}
              ></IconButton>
              <Typography variant="label-2xl" className="my-4">
                Available Time Slot
              </Typography>
            </View>
          )}

          <RescheduleVisitContent visitId={visitId} token={token} />
        </View>
      </Column>
      <Column desktop="4">
        <View className="p-4 md:my-10">
          <RescheduleVisitInfo />
          <PortalHost name="BottomActions" />
        </View>
      </Column>
    </Container>
  );
}
