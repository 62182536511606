import type {
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables,
} from "@/__generated__/graphql";
import { CreatePaymentMethodDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";

type Response = boolean;

export const useCreatePaymentMethodStore = createRequestFactory<
  CreatePaymentMethodMutation,
  Response,
  CreatePaymentMethodMutationVariables
>({
  method: "mutation",
  graphqlDocument: CreatePaymentMethodDocument,
  transformFunction: (res) => {
    if (res.createPaymentMethod) {
      return !!res.createPaymentMethod.paymentMethod?.id;
    }
    throw new Error("Unable to create payment method");
  },
});
