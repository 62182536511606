import { View } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { ArrowLeft, Package as PackageIcon } from "@/components/shared/icons";
import { PackageList } from "../package-list";
import { ShareReferralBanner } from "@/containers/banners/share-referral";
import { MakeABooking } from "@/containers/make-a-booking";
import { Typography } from "@/components/shared/typography";

export const Packages = () => {
  return (
    <View className="flex flex-col gap-3 p-3 md:p-0">
      <View className="static flex flex-row items-center justify-between">
        <View className="flex flex-row items-center gap-2">
          <Link href="/profile/overview">
            <ArrowLeft className="size-5" />
          </Link>
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            Packages
          </Typography>
        </View>
      </View>

      <ShareReferralBanner />

      <View className="flex flex-row gap-3 py-3">
        <PackageIcon size={28} weight="fill" className="text-primary" />
        <Typography className="text-lg">Your Packages</Typography>
      </View>

      <PackageList />

      <MakeABooking />
    </View>
  );
};
