import { Badge } from "@/components/ui";
import {
  getPackageBillingTypeText,
  getPackageBillingTypeVariant,
} from "../lib";
import type {
  PackageDepartmentEnum,
  PackageRecurrenceEnum,
} from "@/__generated__/graphql";

export const PackageCardLabel = ({
  department,
  repeatEvery,
}: {
  department: PackageDepartmentEnum;
  repeatEvery: PackageRecurrenceEnum;
}) => {
  return (
    <Badge variant={getPackageBillingTypeVariant(department, repeatEvery)}>
      {getPackageBillingTypeText(department, repeatEvery)}
    </Badge>
  );
};
