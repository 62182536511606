import {
  View,
  Text,
  Image,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";
import type { PayNowScreenModalProps } from "..";

export const PayNowScreenModal = ({
  open,
  invoiceId,
  accountNumber,
  eunNumber,
  merchantName,
  onCopyAccountNumber,
  onCopyUenNumber,
  paymentQrUri,
  onDownloadQr,
  onClose,
  onConfirmTransfer,
  loading,
}: PayNowScreenModalProps) => {
  console.log(paymentQrUri)
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-h-[90vh] max-w-[409px] overflow-auto bg-white">
        <DialogTitle>
          <Text className="text-lg">PayNow / Bank Transfer</Text>
        </DialogTitle>
        <View className="flex flex-col gap-6">
          <Text className="text-lg">Our Bank Details</Text>

          <View className="flex flex-col">
            <Text className="text-base font-normal text-foreground-intermediate">
              Account Name (DBS)
            </Text>
            <Text className="text-lg">{merchantName}</Text>
          </View>

          <View className="flex flex-row">
            <View className="flex flex-row items-end p-4 pl-0">
              <View>
                <Text className="text-base font-normal text-foreground-intermediate">
                  Account Number
                </Text>
                <Text className="text-lg">{accountNumber}</Text>
              </View>
              <IconButton
                iconName="copy"
                variant="ghost"
                color="foreground"
                onClick={onCopyAccountNumber}
              />
            </View>

            <View className="flex flex-col p-4">
              <Text className="text-base font-normal text-foreground-intermediate">
                Reference
              </Text>

              <Text className="text-lg">{`P${invoiceId}`}</Text>
            </View>
          </View>
        </View>

        <View className="flex flex-col gap-6">
          <Text className="text-lg">Our PayNow Details</Text>

          <View className="flex flex-col">
            <Text className="text-base font-normal text-foreground-intermediate">
              Account Name (DBS)
            </Text>
            <Text className="text-lg">{merchantName}</Text>
          </View>

          <View className="flex flex-row">
            <View className="flex flex-row items-end p-4 pl-0">
              <View>
                <Text className="text-base font-normal text-foreground-intermediate">
                  PayNow UEN
                </Text>
                <Text className="text-lg">{eunNumber}</Text>
              </View>
              <IconButton
                variant="ghost"
                color="foreground"
                iconName="copy"
                onClick={onCopyUenNumber}
              />
            </View>

            <View className="flex flex-col p-4">
              <Text className="text-base font-normal text-foreground-intermediate">
                Reference
              </Text>
              <Text className="text-lg">{`P${invoiceId}`}</Text>
            </View>
          </View>
          <View className="flex flex-col items-center">
            <Image src={paymentQrUri} />
            <Button
              variant="subtle"
              color="CTA2-subtle"
              onClick={onDownloadQr}
              children="Download QR"
            />
          </View>
        </View>

        <View className="flex w-full flex-col gap-2">
          <Button
            variant="secondary"
            color="CTA2"
            fullWidth="full"
            onClick={onClose}
            children="I’ll transfer my money later"
          />
          <Button
            variant="primary"
            color="CTA"
            className="w-full"
            onClick={onConfirmTransfer}
            disabled={loading}
            children="I’ve made my bank transfer"
          />
        </View>
      </DialogContent>
    </Dialog>
  );
};
