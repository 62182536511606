import { View } from "@/components/ui";
import { useProfileState } from "@/store/profile";
import { ChangePhoneNumberModalEnum } from "@/types/profile";
import { Typography } from "../typography";

export const ChangeNumberAction = () => {
  const { showModal } = useProfileState();
  return (
    <View
      onClick={() => showModal(ChangePhoneNumberModalEnum.CHANGE_PHONE_NUMBER)}
      className="cursor-pointer"
    >
      <Typography
        variant={{ sm: "body-md", md: "body-xl" }}
        color="brand-primary"
      >
        Change
      </Typography>
    </View>
  );
};
