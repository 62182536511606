import { View } from "@/components/ui";
import { ChatWithSales } from "@/components/shared/chat-with-sales";
import {
  BookingNavigationBottom,
  BookingOrderCard,
} from "@/components/shared/booking/order-card";
import { useAuthState } from "@/store/auth";
import { useClientVerifyPhoneNumberStore } from "@/store/auth/clientVerifyPhoneNumber";
import { AuthModals, LoginType } from "@/types/users";
import { useMemo } from "react";
import type { ServiceName } from "@/types/service";
import { useClientCreateReservationStore } from "@/store/auth/clientCreateReservation";
import { addMinutes } from "date-fns";
import { useBookingState } from "@/store/booking/useBookingState";
import type { ReservationDetailFragmentFragment } from "@/__generated__/graphql";
import { PackageRecurrenceEnum } from "@/__generated__/graphql";
import { useBookingRoute } from "@/components/hooks/use-booking-route";
import { getFrequencyOfJobFromRecurrence } from "@/lib/booking-lib";
import {
  sendAnalyticData,
  transformBookingDataToAddToCart,
} from "@/lib/monitoring/analytics";

export const SelectSlotNavigation = (props: { name: ServiceName }) => {
  const { forward } = useBookingRoute();

  const {
    data: { jwtInfo, userInfo },
    setPhone,
    showModal,
    setType,
  } = useAuthState();

  const {
    data: {
      packageDetailsFilter,
      selectedSlot,
      selectedPackageIds,
      packageDetails,
    },
    setBookingState,
  } = useBookingState();
  const {
    loading: clientVerifyPhoneNumberLoading,
    fetch: clientVerifyPhoneNumber,
  } = useClientVerifyPhoneNumberStore();
  const {
    fetch: clientCreateReservation,
    loading: clientCreateReservationLoading,
  } = useClientCreateReservationStore();

  const disabled = useMemo(() => !selectedSlot, [selectedSlot]);

  const onContinue = () => {
    if (!jwtInfo.jwt) {
      showModal(AuthModals.LOGIN);
      return;
    }

    if (!userInfo.user.phoneNumberVerifiedAt) {
      setPhone(userInfo.user.phoneNumber as string);
      clientVerifyPhoneNumber({
        requestPayload: {
          input: {
            phone: userInfo.user.phoneNumber as string,
            enableWhatsappOtp: true,
          },
        },
      }).then((res) => {
        if (res.error) return;
        setType(LoginType.Confirm);
        showModal(AuthModals.OTP);
      });
      return;
    }

    if (!selectedSlot) return;

    clientCreateReservation({
      requestPayload: {
        input: {
          startTime: selectedSlot.startTime,
          endTime: selectedSlot.endTime,
          frequencyOfJob:
            getFrequencyOfJobFromRecurrence(
              packageDetailsFilter.repeatEvery?.[0] as PackageRecurrenceEnum,
            ) || PackageRecurrenceEnum.AdHoc,
          lineItemIds: selectedPackageIds,
          serviceDate: selectedSlot.date,
          slotId: selectedSlot.slotId,
        },
      },
    }).then((res) => {
      if (res.error || !res.data) {
        return;
      }
      const timeStamp = addMinutes(new Date(), 15).getTime();
      setBookingState({
        reservationTimeStamp: timeStamp,
        reservations: res.data as ReservationDetailFragmentFragment[],
      });
      forward();

      sendAnalyticData({
        name: "add_to_cart",
        values: transformBookingDataToAddToCart(packageDetails),
      });
    });
  };

  return (
    <View>
      {/* large screen */}
      <View className="hidden flex-col gap-4 md:flex">
        <BookingOrderCard
          onContinue={onContinue}
          disabled={disabled}
          loading={
            clientVerifyPhoneNumberLoading || clientCreateReservationLoading
          }
        />
        <ChatWithSales />
      </View>
      {/* narrow screen */}
      <BookingNavigationBottom
        onContinue={onContinue}
        disabled={disabled}
        loading={
          clientVerifyPhoneNumberLoading || clientCreateReservationLoading
        }
      />
    </View>
  );
};
