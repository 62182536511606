import type {
  UpdateClientPreferencesMutation,
  UpdateClientPreferencesMutationVariables,
} from "@/__generated__/graphql";
import { UpdateClientPreferencesDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";
import { useAuthState } from "../auth";

type Response = {
  auto_pay?: boolean;
};

export const useUpdateClientPreferencesStore = createRequestFactory<
  UpdateClientPreferencesMutation,
  Response,
  UpdateClientPreferencesMutationVariables
>({
  method: "mutation",
  graphqlDocument: UpdateClientPreferencesDocument,
  transformFunction: (res) => {
    if (res.updateClientPreferences) {
      return res.updateClientPreferences.preferences as Response;
    }
    throw new Error("Unable to update client preferences");
  },
  onFetchSuccess: ({ auto_pay = false }) => {
    const { setUserInfo, data } = useAuthState.getState();
    setUserInfo({
      ...data.userInfo,
      autoPay: auto_pay,
    });
  },
});
