import type {
  PackagesByFiltersQuery,
  PackagesByFiltersQueryVariables,
  Package,
} from "@/__generated__/graphql";
import { PackagesByFiltersDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";
import { usePackageState } from "./usePackageState";

type Response = Package[];

export const usePackageListStore = createRequestFactory<
  PackagesByFiltersQuery,
  Response,
  PackagesByFiltersQueryVariables
>({
  method: "query",
  graphqlDocument: PackagesByFiltersDocument,
  transformFunction: (data) => {
    if (data.packagesByFilters.packages)
      return data.packagesByFilters.packages as Response;
    else throw new Error("");
  },
  onFetchSuccess: (res) => {
    const { setPackageList } = usePackageState.getState();
    setPackageList(res);
  },
});
