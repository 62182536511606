import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const addressSchema = z.object({
  postalCode: z.string().min(1, "Postal code is required"),
  fullAddress: z.string().min(1, "Full address is required"),
  unitFloor: z
    .string()
    .min(1, "If not applicable, enter 0 for Floor or Apt No."),
  apartmentNumber: z
    .string()
    .min(1, "If not applicable, enter 0 for Floor or Apt No."),
  primary: z.boolean(),
});

// Infer the TypeScript type from the schema
export type AddressFormData = z.infer<typeof addressSchema>;

export interface AddressFormReturn extends UseFormReturn<AddressFormData> {
  defaultValues: AddressFormData;
}

export const initialFormValues: AddressFormData = {
  postalCode: "",
  fullAddress: "",
  unitFloor: "",
  apartmentNumber: "",
  primary: true,
};

export const useAddressForm = (
  initialValues?: AddressFormData,
): AddressFormReturn => ({
  defaultValues: initialValues || initialFormValues,
  ...useForm<AddressFormData>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: zodResolver(addressSchema),
  }),
});
