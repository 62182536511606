import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { superRefinePhoneNumber } from "@/lib/helpers/phone-number-validation";
import { nameRE } from "@/constants/regex";

const contactSchema = z.object({
  id: z.string().optional(),
  firstName: z
    .string()
    .min(1, "First name is required")
    .refine((value) => nameRE.test(value), {
      message: "Please avoid using special characters",
    }),
  lastName: z
    .string()
    .min(1, "Last name is required")
    .refine((value) => nameRE.test(value), {
      message: "Please avoid using special characters",
    }),
  email: z
    .array(z.object({ value: z.string().email("Invalid email") }))
    .optional(),
  // nationCode: z.string(),
  phoneNumber: z
    .array(
      z
        .object({
          value: z.string(),
          nationCode: z.string(),
        })
        .superRefine((arg, ctx) => {
          superRefinePhoneNumber({
            code: arg.nationCode,
            phoneNumber: arg.value,
            ctx,
            phoneNumberFieldName: "value",
          });
        }),
    )
    .optional(),
  primary: z.boolean().optional(),
});

// Infer the TypeScript type from the schema
export type ContactFormData = z.infer<typeof contactSchema>;

export interface ContactFormReturn extends UseFormReturn<ContactFormData> {
  defaultValues: ContactFormData;
}

export const initialContactFormValues: ContactFormData = {
  id: "",
  firstName: "",
  lastName: "",
  email: [{ value: "" }],
  phoneNumber: [{ value: "", nationCode: "SG/65" }],
  primary: true,
};

export const useContactForm = (
  initialValues?: ContactFormData,
): ContactFormReturn => ({
  defaultValues: initialValues || initialContactFormValues,
  ...useForm<ContactFormData>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: zodResolver(contactSchema),
  }),
});
