import { View } from "@/components/ui";
import { ListHeading } from "@/components/shared/list-header";
import { CalendarPlus } from "@/components/shared/icons";
import { TimeSlotWithSearch } from "@/components/shared/time-slot-with-search";
import type { TimeSlot } from "@/types/booking";
import type { PackageDepartmentEnum } from "@/__generated__/graphql";
import type { ServiceName } from "@/types/service";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const SelectSlotSection = ({
  name,
  loading,
  showTimeRange,
  timeSlots,
  selectedSlot,
  startDate,
  endDate,
  department,
  setStartDate,
  setEndDate,
  workerIds,
  workerLabel,
  initialStartDate,
  enableChooseWorker,
  onChooseWorkers,
  setSelectedSlot,
  onClickWorkerPortfolio,
}: {
  name: ServiceName;
  loading: boolean;
  showTimeRange?: boolean;
  selectedSlot: TimeSlot | null;
  timeSlots: Record<string, TimeSlot[]> | null;
  startDate: Date;
  endDate: Date;
  initialStartDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  workerIds: string[];
  workerLabel: string;
  enableChooseWorker?: boolean;
  department: PackageDepartmentEnum;
  onChooseWorkers(): void;
  setSelectedSlot(p: TimeSlot): void;
  onClickWorkerPortfolio(slot: TimeSlot): void;
}) => {
  const intl = useIntl();
  return (
    <View className="native:pt-0 flex flex-col gap-2 pb-8">
      <View>
        <ListHeading
          title={intl.formatMessage({
            defaultMessage: "Select Slot",
            id: "select-slot",
          })}
          icon={CalendarPlus}
        />
        <Typography color="foreground-intermediate" variant="body-lg">
          {intl.formatMessage({
            defaultMessage:
              "Choose the slot that works best for your schedule.",
            id: "select-slot-description",
          })}
        </Typography>
      </View>

      <TimeSlotWithSearch
        name={name}
        initialStartDate={initialStartDate}
        department={department}
        showTimeRange={showTimeRange}
        loading={loading}
        selectedSlot={selectedSlot}
        timeSlots={timeSlots}
        workersIds={workerIds}
        workerLabel={workerLabel}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        enableChooseWorker={enableChooseWorker}
        onChooseWorkers={onChooseWorkers}
        onSelectSlot={setSelectedSlot}
        onClickWorkerPortfolio={onClickWorkerPortfolio}
      />
    </View>
  );
};
