import * as React from "react";
import type { SVGProps } from "react";
const SvgLuceSparkle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M6.172 0H5.828C5.49929 3.06476 3.06476 5.49929 0 5.828V6.172C3.06476 6.50071 5.49929 8.93524 5.828 12H6.172C6.50071 8.93524 8.93524 6.50071 12 6.172V5.828C8.93524 5.49929 6.50071 3.06476 6.172 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLuceSparkle;
