import type {
  PayInvoiceMutation,
  PayInvoiceMutationVariables,
} from "@/__generated__/graphql";
import { PayInvoiceDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";

type Response = boolean;

export const usePayInvoiceStore = createRequestFactory<
  PayInvoiceMutation,
  Response,
  PayInvoiceMutationVariables
>({
  method: "mutation",
  graphqlDocument: PayInvoiceDocument,
  transformFunction: (res) => {
    if (res.payInvoice) {
      return !!res.payInvoice.invoice.id;
    }
    throw new Error("Unable to pay invoice");
  },
});
