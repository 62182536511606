import { View } from "@/components/ui";
import { openBrowser } from "@/lib/open-browser";
import { Typography } from "@/components/shared/typography";
import { links } from "@/constants";

export const AgreeTerms = () => {
  return (
    <View>
      <Typography variant="body-md" className="inline">
        I agree and understand that I'm purchasing a service subject to
      </Typography>
      <Typography
        variant="body-md"
        className="ml-1 inline cursor-pointer"
        color="info"
        onClick={() => {
          openBrowser(links.legalTermCondition);
        }}
      >
        Luce Maintenace Group Terms of Usage
      </Typography>
    </View>
  );
};
