import { View } from "@/components/ui";
import { Funnel } from "@/components/shared/icons";
import { DateRange } from "@/components/shared/date-range";
import { TimeSlotGridContainer } from "@/components/shared/time-slot-with-search/time-slot-grid-container";
import type { TimeSlot } from "@/types/booking";
import { useMemo } from "react";
import { isSameDay, addDays, endOfDay, startOfDay } from "date-fns";
import { formatDate, getDateRangeBetween } from "@/lib/helpers/date";
import { Spin } from "@/components/shared/spin";
import type { PackageDepartmentEnum } from "@/__generated__/graphql";
import type { ServiceName } from "@/types/service";
import { Typography } from "../typography";
import { useIntl } from "react-intl";

export const TimeSlotWithSearch = ({
  name,
  showTimeRange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  loading,
  selectedSlot,
  timeSlots,
  defaultDateAmount = 13,
  enableChooseWorker,
  workerLabel,
  department,
  initialStartDate,
  onClickWorkerPortfolio,
  onChooseWorkers,
  onSelectSlot,
}: {
  name: ServiceName;
  showTimeRange?: boolean;
  startDate: Date;
  endDate: Date;
  initialStartDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  loading: boolean;
  selectedSlot: TimeSlot | null;
  timeSlots: Record<string, TimeSlot[]> | null;
  workersIds: string[] | null; // unused?
  enableChooseWorker?: boolean;
  defaultDateAmount?: 13 | 20;
  workerLabel: string;
  onClickWorkerPortfolio(slot: TimeSlot): void;
  onChooseWorkers(): void;
  onSelectSlot(slot: TimeSlot): void;
  department: PackageDepartmentEnum;
}) => {
  const intl = useIntl();
  const minDateRange = useMemo(
    () => endOfDay(initialStartDate),
    [initialStartDate],
  );
  const maxDateRange = useMemo(
    () => addDays(startOfDay(initialStartDate), defaultDateAmount),
    [initialStartDate],
  );
  const dateRange = useMemo(
    () => getDateRangeBetween(startDate, endDate),
    [startDate, endDate],
  );
  const onClickDateChanger = (type: "prev" | "next"): void => {
    let newStart = startDate;
    let newEnd = endDate;
    if (type === "prev") {
      if (isSameDay(newStart, initialStartDate)) {
        return;
      }
      newEnd = addDays(startDate, -1);
      newStart = addDays(newEnd, -6);
    } else {
      newStart = addDays(endDate, 1);
      newEnd = addDays(newStart, 6);
    }
    setStartDate(newStart);
    setEndDate(newEnd);
  };

  const firstNoEmptyIndex = useMemo(() => {
    if (!timeSlots) return 0;
    return dateRange.findIndex((date) => {
      return timeSlots[formatDate(date)]?.length || 0;
    });
  }, [dateRange, timeSlots]);

  return (
    <>
      <View className="flex flex-row gap-2">
        {showTimeRange && (
          <DateRange
            startDate={startDate}
            endDate={endDate}
            minDate={minDateRange}
            maxDate={maxDateRange}
            handleNext={() => onClickDateChanger("next")}
            handlePrev={() => onClickDateChanger("prev")}
          />
        )}

        {enableChooseWorker && (
          <View
            className="flex basis-2/5 cursor-pointer flex-row items-center justify-center rounded-md border border-secondary-border px-1 py-2"
            onClick={onChooseWorkers}
          >
            <Funnel className="mr-1 size-4 md:mr-2 md:size-5" size={16} />
            <Typography
              variant={"body-md"}
            >{`Choose ${workerLabel}s`}</Typography>
          </View>
        )}
      </View>
      <View className="relative">
        {loading && (
          <View className="absolute bottom-0 left-0 right-0 top-0 z-20 flex flex-col items-center justify-center gap-3 bg-background-intermediate">
            <Spin size={20} className="size-10" />
            <Typography variant={{ md: "body-xl", sm: "body-md" }}>
              {intl.formatMessage({
                defaultMessage: "Searching for slot availability",
                id: "timeslot.search",
              })}
            </Typography>
          </View>
        )}
        <TimeSlotGridContainer
          name={name}
          loading={loading}
          selectedSlot={selectedSlot}
          timeSlots={timeSlots}
          onSelectSlot={onSelectSlot}
          dateRange={dateRange}
          onClickWorkerPortfolio={onClickWorkerPortfolio}
          selectedWorkerIds={null}
          department={department}
          firstNoEmptyIndex={firstNoEmptyIndex}
        />
      </View>
    </>
  );
};
