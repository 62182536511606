import { createRequestFactory } from "@/lib/request-factory";
import {
  CreateClientIssueRequestWithTokenDocument,
  type CreateClientIssueRequestWithTokenMutation,
  type CreateClientIssueRequestWithTokenMutationVariables,
} from "@/__generated__/graphql";

export const useCreateClientIssueWithTokenRequestStore = createRequestFactory<
  CreateClientIssueRequestWithTokenMutation,
  CreateClientIssueRequestWithTokenMutation,
  CreateClientIssueRequestWithTokenMutationVariables
>({
  method: "mutation",
  graphqlDocument: CreateClientIssueRequestWithTokenDocument,
});
