import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";

export function OTP({
  onOTPChange,
  onFilled,
  pinCode,
}: {
  onOTPChange: (text: string) => void;
  onFilled?: (text: string) => void;
  pinCode: string;
}) {
  const onChange = (t: string) => {
    onOTPChange(t);
    if (t.length === 6) {
      onFilled?.(t);
    }
  };

  return (
    <InputOTP maxLength={6} onChange={onChange} value={pinCode}>
      <InputOTPGroup>
        <InputOTPSlot index={0} />
      </InputOTPGroup>
      <InputOTPGroup>
        <InputOTPSlot index={1} />
      </InputOTPGroup>
      <InputOTPGroup>
        <InputOTPSlot index={2} />
      </InputOTPGroup>
      <InputOTPGroup>
        <InputOTPSlot index={3} />
      </InputOTPGroup>
      <InputOTPGroup>
        <InputOTPSlot index={4} />
      </InputOTPGroup>
      <InputOTPGroup>
        <InputOTPSlot index={5} />
      </InputOTPGroup>
    </InputOTP>
  );
}
