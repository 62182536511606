import { HorizontalScrollView } from "@/components/shared/horizontal-scroll-view";
import { ReviewCard } from "@/components/shared/review-card";
import { Typography } from "@/components/shared/typography";
import { WorkerProfileCard } from "@/components/shared/worker-profile-card";
import { View } from "@/components/ui";
import type { Review } from "@/types/booking";

export const ServiceProfessional = ({
  reviews,
  cleanerInfo,
}: {
  cleanerInfo: { name: string; star: string; avatar: string };
  reviews: Review[];
}) => {
  return (
    <View className="p-4">
      <View>
        <View>
          <HorizontalScrollView
            nativeContentWidths={[30]}
            contentClassNames={["basis-30"]}
            HeaderComponent={
              <Typography
                variant={{ md: "label-2xl", sm: "label-lg" }}
                color="brand-primary"
                align={{ md: "left", sm: "center" }}
                className="w-full md:justify-start"
              >
                Service Professional
              </Typography>
            }
          >
            {[
              <WorkerProfileCard {...cleanerInfo} key="workerProfile" />,
              ...reviews.map((review, index) => (
                <ReviewCard
                  key={index}
                  {...review}
                  contentClassName="md:p-4 h-48 m-0"
                  showImages={false}
                />
              )),
            ]}
          </HorizontalScrollView>
        </View>
      </View>
    </View>
  );
};
