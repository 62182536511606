import * as React from "react";

import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";

const cardVariants = cva("relative flex flex-1 justify-center", {
  variants: {
    variant: {
      default: "border-secondary-border bg-white text-card-foreground",
      success: "border-green-500 bg-green-50 text-success",
      danger: "border-red-600 bg-red-50 text-red-500",
      info: "border-primary-border bg-primary-surface text-sky-500",
      warning: "border-yellow-500 bg-yellow-50 text-yellow-500",
    },
    direction: {
      col: "flex-col",
      row: "flex-row",
    },
    radius: {
      sm: "rounded-sm",
      base: "rounded",
      md: "rounded-md",
      lg: "rounded-lg",
      xl: "rounded-xl",
      xxl: "rounded-2xl",
      full: "rounded-full",
    },
    gap: {
      none: "gap-0",
      xs: "gap-0.5",
      sm: "gap-1",
      md: "gap-2",
      lg: "gap-3",
      xl: "gap-4",
      xxl: "gap-6",
      "3xl": "gap-8",
      "4xl": "gap-10",
      "5xl": "gap-12",
      "6xl": "gap-14",
      "7xl": "gap-16",
    },
    shadow: {
      sm: "shadow-sm",
      base: "shadow",
      md: "shadow-md",
      lg: "shadow-lg",
      none: "shadow-none",
    },
    border: {
      default: "border",
      none: "border-0",
      hiddenInLargeScreen: "border md:border-0",
    },
  },
  defaultVariants: {
    variant: "default",
    direction: "col",
    radius: "xxl",
    gap: "sm",
    shadow: "none",
    border: "default",
  },
});

interface CardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardVariants> {}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    { className, variant, direction, radius, gap, shadow, border, ...props },
    ref,
  ) => (
    <div
      ref={ref}
      className={cn(
        cardVariants({
          variant,
          direction,
          radius,
          gap,
          shadow,
          border,
          className,
        }),
        props.onClick && "cursor-pointer",
        className,
      )}
      {...props}
    />
  ),
);
Card.displayName = "Card";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-4", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-2xl font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-4", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-4 pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
