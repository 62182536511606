import { showToast } from "@/components/ui/toast/show-toast";
import { useEffect } from "react";
import { useAuthState } from "../auth";
import type {
  PackagesByFiltersInput,
  PackageStatusEnum,
} from "@/__generated__/graphql";
import { usePackageState } from "./usePackageState";
import { offsetCountPagination } from "@/lib/utils";
import { usePackageListStore } from "./packageListStore";
import { usePackageStore } from "./packageStore";
import { getErrorMessage } from "@/lib/helpers/string";

export const usePackageList = ({ limit }: { limit?: number } = {}) => {
  const { data: userData } = useAuthState();
  const { userInfo } = userData;
  const {
    data: { rowsPerPage, packageList, packageStatus },
  } = usePackageState();
  const { fetch: getPackages } = usePackageListStore();

  // will consider to collect this function into one file
  function getStatusFilter(
    status: 'ALL' | PackageStatusEnum,
  ): PackagesByFiltersInput | null {
    const packageFilter: { [key: string]: string } = {};
    if (status && status !== "ALL") {
      packageFilter["status"] = status;
    }
    return packageFilter;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPackages(
          {
            requestPayload: {
              filters: {
                clientId: userInfo.id,
                ...offsetCountPagination(0, limit ?? rowsPerPage),
                ...getStatusFilter(packageStatus),
              },
            },
          },
          { selfHandleError: true },
        );
        if (res.error) throw new Error(res.error);
      } catch (error) {
        const errMsg = getErrorMessage(error, "Error fetching packages");
        showToast({
          title: errMsg,
          type: "error",
        });
      }
    };

    fetchData();
  }, [rowsPerPage, limit, packageStatus]);

  return packageList;
};

export const usePackage = ({ packageId }: { packageId: string }) => {
  const {
    data: { packageData },
  } = usePackageState();
  const { fetch: getPackageData } = usePackageStore();

  useEffect(() => {
    const fetchData = async () => {
      await getPackageData({
        requestPayload: {
          id: packageId,
        },
      });
    };

    fetchData();
  }, []);

  return packageData;
};
