import { CaretDown } from "@/components/shared/icons";
import { View } from "@/components/ui";
import { cn } from "@/lib/utils";

export const AnimatedCaret = ({ isExpanded }: { isExpanded?: boolean }) => {
  return (
    <View
      className={cn("tansition cursor-pointer duration-300 ease-in-out", {
        "rotate-180": isExpanded,
      })}
    >
      <CaretDown />
    </View>
  );
};
