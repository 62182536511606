import { AvatarTimeSlotCard } from "./avatar-time-slot-card";
import { NormalTimeSlotCard } from "./normal-time-slot-card";
import { SimpleTimeSlotCard } from "./simple-time-slot-card";
import { getTitleCase, singaporeTaxCalculation } from "@/lib/utils";
import { useMemo } from "react";
import { formatTimeAMPM } from "@/lib/helpers/date";
import { PackageDepartmentEnum } from "@/__generated__/graphql";
import type { ServiceName } from "@/types/service";

export const TimeSlotCard = ({
  department,
  onCardClick,
  onClickWorkerPortfolio,
  startTime,
  endTime,
  rateValue,
  discount,
  isCheaper,
  workerName,
  workerRating,
  avatarUrl,
  active,
}: {
  active?: boolean;
  department: PackageDepartmentEnum;
  onCardClick(): void;
  onClickWorkerPortfolio(): void;
  date: string; // unused?
  startTime: string;
  endTime: string;
  rateValue?: number;
  discount?: number;
  isCheaper?: boolean;
  selectedWorkerName?: string | null; // unused?
  workerName: string;
  workerRating: number;
  avatarUrl: string;
  name: ServiceName; // unused?
}) => {
  const isHomeBeautyWorker = department === PackageDepartmentEnum.HomeBeauty;

  const formatName = (name: string): string => {
    return getTitleCase(name).slice(0, 15) + (name.length > 15 ? ".." : "");
  };
  const formattedWorkerName = useMemo(() => {
    return formatName(workerName);
  }, [workerName]);

  const priceWithTax = useMemo(() => {
    if (discount) {
      return `(${singaporeTaxCalculation(discount).toFixed(2)} w/GST)`;
    }

    if (rateValue) {
      return `(${singaporeTaxCalculation(rateValue ?? 0).toFixed(2)} w/GST)`;
    }
    return "0";
  }, [discount, rateValue]);

  const timeFormat = (time: string): string => {
    return formatTimeAMPM(time, { separator: ":" });
  };

  const timeRange = useMemo(() => {
    return `${timeFormat(startTime)} - ${timeFormat(endTime)}`;
  }, [startTime, endTime]);

  if (isHomeBeautyWorker)
    return (
      <AvatarTimeSlotCard
        active={active}
        onCardClick={onCardClick}
        timeRange={timeRange}
        workerName={formattedWorkerName}
        workerRating={workerRating}
        avatarUrl={avatarUrl}
        onClickProfolio={onClickWorkerPortfolio}
      />
    );

  if (
    [
      PackageDepartmentEnum.Aircon,
      PackageDepartmentEnum.CarpetUpholstery,
    ].includes(department)
  )
    return (
      <SimpleTimeSlotCard
        timeRange={timeRange}
        active={active}
        onCardClick={onCardClick}
      />
    );

  return (
    <NormalTimeSlotCard
      timeRange={timeRange}
      rateValue={rateValue}
      active={active}
      priceWithTax={priceWithTax}
      onCardClick={onCardClick}
      isCheaper={isCheaper}
    />
  );
};
