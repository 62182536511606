import { View } from "@/components/ui";
import { cn } from "@/lib/utils";
import { Link } from "@/components/shared/link";
import { Typography } from "../typography";

interface Props {
  items: {
    label: string;
    href: string;
  }[];
  active?: string;
}

export function NavTabs({ items, active }: Props) {
  return (
    <View className="flex flex-row justify-evenly border-b border-solid border-slate-200 md:justify-start">
      {items.map((item) => (
        <Link href={item.href} key={item.href}>
          <Nav label={item.label} focused={active === item.href} />
        </Link>
      ))}
    </View>
  );
}

function Nav({ label, focused }: { label: string; focused: boolean }) {
  return (
    <View className="mx-4">
      <Typography
        className={cn(
          "text-center text-base leading-[48px]",
          focused ? "text-primary" : "text-secondary-text",
        )}
      >
        {label}
      </Typography>
      <View
        className={cn(
          "native:-bottom-1 absolute bottom-0 left-0 right-0 h-1 w-full min-w-8 rounded-t-md",
          focused ? "bg-primary" : "bg-transparent",
        )}
      />
    </View>
  );
}
