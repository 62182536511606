import type { ConfirmationType } from "@/__generated__/graphql";
import { useMemo, useState } from "react";

const useConfirmations = () => {
  const [confirmations, setConfirmations] = useState<ConfirmationType[]>([]);

  const updateConfirmations = (key: string, confirmed: boolean) => {
    if (confirmed) {
      const confirmationsDup = [...confirmations];
      const line = confirmations.find((c) => c.key === key);

      if (line) {
        line.confirmed = true;
        setConfirmations(confirmationsDup);
      }
    } else {
      setConfirmations([]);
    }
  };

  const hasUnconfirmed = useMemo(() => {
    const unconfirmed = confirmations.filter((c) => !c.confirmed);

    return confirmations.length && unconfirmed.length;
  }, [confirmations]);

  const firstUnconfirmed = useMemo(() => {
    return confirmations.find((c) => !c.confirmed);
  }, [confirmations]);

  return {
    updateConfirmations,
    hasUnconfirmed,
    firstUnconfirmed,
    setConfirmations,
    confirmations,
  };
};

export { useConfirmations };
