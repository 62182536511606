import { createRequestFactory } from "@/lib/request-factory";
import {
  UpdateAccountPhoneNumberDocument,
  type UpdateAccountPhoneNumberMutationVariables,
  type UpdateAccountPhoneNumberMutation,
} from "@/__generated__/graphql";
import { useAuthState } from ".";

type Response = {
  success: boolean;
};

export const useUpdateAccountPhoneNumberStore = createRequestFactory<
  UpdateAccountPhoneNumberMutation,
  Response,
  UpdateAccountPhoneNumberMutationVariables
>({
  method: "mutation",
  graphqlDocument: UpdateAccountPhoneNumberDocument,
  transformFunction: (res) => {
    if (res.updateAccountPhoneNumber?.success) {
      return { success: res.updateAccountPhoneNumber.success };
    }
    throw new Error("Error");
  },
  onFetchSuccess: () => {
    const { setChangePhoneOtp } = useAuthState.getState();
    setChangePhoneOtp(true);
  },
});
