import { createRequestFactory } from "@/lib/request-factory";
import {
  CancelVisitWithTokenDocument,
  type CancelVisitWithTokenMutation,
  type CancelVisitWithTokenMutationVariables,
} from "@/__generated__/graphql";

export const useCancelVisitWithTokenStore = createRequestFactory<
  CancelVisitWithTokenMutation,
  boolean,
  CancelVisitWithTokenMutationVariables
>({
  method: "mutation",
  graphqlDocument: CancelVisitWithTokenDocument,
  transformFunction(data) {
    return Boolean(data.cancelVisitWithToken?.visit);
  },
});
