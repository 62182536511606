import { View } from "@/components/ui";
import { Timer, Info, Warning, Check } from "@/components/shared/icons";
import { cn } from "@/lib/utils";
import type { Icon } from "@/components/shared/icons";
import { cva } from "class-variance-authority";
import { Typography } from "../typography";

interface MessageProps {
  className?: string;
  message: string;
  title?: string;
  icon?: Icon;
}

const messageWrapperVariants = cva(
  "flex flex-row items-start gap-1 rounded-lg border p-3",
  {
    variants: {
      variant: {
        default: "border-secondary-border bg-white text-primary-text",
        info: "border-info-border bg-info-surface text-info",
        warning: "border-warning-border bg-warning-surface text-warning",
        danger: "border-danger-border bg-danger-surface text-danger",
        success: "border-success-border bg-success-surface text-success",
      },
    },
  },
);

const messageTextVariants = cva("", {
  variants: {
    variant: {
      default: "text-primary-text",
      info: "text-info",
      warning: "text-warning",
      danger: "text-danger",
      success: "text-success",
    },
  },
});

const iconMap = {
  default: Info,
  info: Info,
  danger: Timer,
  warning: Warning,
  success: Check,
};

export const Message = ({
  className,
  message,
  title,
  variant = "default",
  icon,
}: MessageProps & {
  variant?: "default" | "info" | "danger" | "success" | "warning";
}) => {
  const IconComponent = icon || iconMap[variant];

  return (
    <View className={cn(messageWrapperVariants({ variant }), className)}>
      <IconComponent
        className={cn("size-5 shrink-0", messageTextVariants({ variant }))}
        weight="fill"
      />
      {title ? (
        <View className="flex flex-1 flex-col gap-1">
          <Typography
            variant={{ md: "label-lg", sm: "body-lg" }}
            className={cn(messageTextVariants({ variant }))}
          >
            {title}
          </Typography>
          <Typography
            variant={{ md: "body-md", sm: "body-md" }}
            className={cn("", messageTextVariants({ variant }))}
          >
            {message}
          </Typography>
        </View>
      ) : (
        <Typography
          variant="body-lg"
          className={cn("flex-1", messageTextVariants({ variant }))}
        >
          {message}
        </Typography>
      )}
    </View>
  );
};
