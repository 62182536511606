import { Button, Image, View } from "@/components/ui";
import { GradientBackgroundCard } from "@/components/shared/gradient-background-card";
import { Typography } from "../typography";
import { onChatSales } from "@/lib/chat-sales";

export function PromoCard({
  title,
  subtitle,
  imageUrl,
  onClick,
}: {
  title: string;
  subtitle: string;
  imageUrl: string;
  onClick(): void;
}) {
  return (
    <View>
      <GradientBackgroundCard backgroundImage={imageUrl} height={200}>
        <View className="flex flex-col justify-between gap-2 md:flex-row">
          <View>
            <Typography variant="bold-2xl" color="white">
              {title}
            </Typography>
            <Typography variant="body-lg" color="white">
              {subtitle}
            </Typography>
          </View>
          <Button
            variant="primary"
            color="CTA"
            rounded="full"
            iconName="whatsappLogo"
            iconColor="fill-white"
            onClick={onClick}
            children="Contact Us"
          />
        </View>
      </GradientBackgroundCard>
    </View>
  );
}

export const PromoImageChatSales = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <View className="cursor-pointer" onClick={() => onChatSales(true)}>
      <Image src={imageUrl} className="w-full" height={200} />
    </View>
  );
};
