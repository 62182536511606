import { cva } from "class-variance-authority";
import { cn } from "./utils";
import type {
  ClassProp,
  StringToBoolean,
  ClassValue,
  OmitUndefined,
} from "class-variance-authority/types";

export type ResponsiveVariantProps<
  Component extends (...args: [object, ...[rest: unknown]]) => unknown,
> = Omit<OmitUndefined<Parameters<Component>[0]>, "class" | "className">;

type ConfigSchema = Record<string, Record<string, ClassValue>>;
type ConfigVariants<T extends ConfigSchema> = {
  [Variant in keyof T]?: StringToBoolean<keyof T[Variant]> | null | undefined;
};

type Config<T> = T extends ConfigSchema
  ? {
      variants?: T;
      defaultVariants?: ConfigVariants<T>;
    }
  : never;

type Props<T> = T extends ConfigSchema
  ? ConfigVariants<T> & ClassProp
  : ClassProp;

// type CVA<T> = (
//   base?: ClassValue,
//   config?: Config<T> | undefined
// ) => (props?: Props<T> | undefined) => string;

type BreakpointValues = "sm" | "md";

type ResponsiveVariant<T extends Record<string, unknown>> = {
  [Key in keyof T]?:
    | T[Key]
    | { [Breakpoint in BreakpointValues]?: NonNullable<T[Key]> };
};

export type ResponsiveCVA<Variants> = (
  props?: ResponsiveVariant<NonNullable<Props<Variants>>>,
) => string;

export function createResponsiveCVA<
  Variants extends Record<string, Record<string, ClassValue>>,
>(
  base: ClassValue,
  breakpointVariants: { default: Variants } & Partial<
    Record<BreakpointValues, Variants>
  >,
  defaultValues?: Partial<{ [Key in keyof Variants]: keyof Variants[Key] }>,
): ResponsiveCVA<Variants> {
  const { default: baseVariant, sm = baseVariant, md } = breakpointVariants;

  const defaultVariant = cva(base, {
    variants: baseVariant,
    defaultVariants: defaultValues,
  } as Config<Variants>);

  const smVariant = cva("", {
    variants: sm,
  } as Config<Variants>);

  const mdVariant = cva("", {
    variants: md,
  } as Config<Variants>);

  return (props) => {
    let defaultProps: Props<Variants> | undefined = undefined;
    let smProps: Props<Variants> | undefined = undefined;
    let mdProps: Props<Variants> | undefined = undefined;

    for (const key in props) {
      const value = props[key];

      if (typeof value === "object" && value !== null) {
        if (value.sm) {
          smProps = { ...smProps, [key]: value.sm } as Props<Variants>;
        }
        if (value.md) {
          mdProps = { ...mdProps, [key]: value.md } as Props<Variants>;
        }
      } else if (typeof value === "string") {
        defaultProps = { ...defaultProps, [key]: value } as Props<Variants>;
      }
    }

    const classes = [defaultVariant(defaultProps)];

    if (smProps) {
      classes.push(smVariant(smProps));
    }
    if (mdProps) {
      classes.push(mdVariant(mdProps));
    }

    return cn(...classes);
  };
}
