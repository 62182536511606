import { View, Button, Badge } from "@/components/ui";
import {
  User,
  CaretRight,
  Invoice,
  CreditCard,
  FileText,
  ShieldCheck,
} from "@/components/shared/icons";
import { Link } from "@/components/shared/link";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { useAuthState } from "@/store/auth";
import { AuthModals } from "@/types/users";
import { WhatsappSupportBanner } from "@/components/shared/whatsapp-support-banner";
import { links } from "@/constants";

export const AccountOverview = () => {
  const intl = useIntl();
  const {
    showModal,
    data: { userInfo },
  } = useAuthState();

  return (
    <View className="flex flex-col gap-6 md:hidden">
      <View>
        <Typography variant="label-lg" className="mb-3">
          {intl.formatMessage({
            defaultMessage: "Account",
            id: "account",
          })}
        </Typography>
        <View className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3">
          <User className="size-6 text-foreground-intermediate" />
          <Link href="/profile/account-info" className="flex-1">
            <Typography variant="bold-lg">
              {intl.formatMessage({
                defaultMessage: "Account Info",
                id: "account.info",
              })}
            </Typography>
          </Link>
          <CaretRight className="ml-auto size-5" size={20} />
        </View>

        <View className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3">
          <Invoice className="size-6 text-foreground-intermediate" />
          <Link href="/profile/invoices" className="flex-1">
            <Typography variant="bold-lg">
              {intl.formatMessage({
                defaultMessage: "Invoices",
                id: "account.invoices",
              })}
            </Typography>
          </Link>
          {userInfo.billingAccount.totalOverdueInvoice ? (
            <Badge outline={false} variant="danger-fill" className="px-3">
              {userInfo.billingAccount.totalOverdueInvoice}
            </Badge>
          ) : undefined}
          <CaretRight className="ml-auto size-5" size={20} />
        </View>

        <View className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3">
          <CreditCard className="size-6 text-foreground-intermediate" />
          <Link href="/profile/payment" className="flex-1">
            <Typography variant="bold-lg">
              {intl.formatMessage({
                defaultMessage: "Payment",
                id: "account.payment",
              })}
            </Typography>
          </Link>
          <CaretRight className="ml-auto size-5" size={20} />
        </View>
      </View>

      <View className="border-b-2 border-secondary-border pb-4">
        <WhatsappSupportBanner />
      </View>

      <View>
        <Typography variant="label-lg" className="mb-3">
          Other Info
        </Typography>
        <View className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3">
          <FileText className="size-6 text-foreground-intermediate" />
          <Link href={links.legalTermCondition} className="flex-1">
            <Typography variant="bold-lg">Terms of Services</Typography>
          </Link>
          <CaretRight className="ml-auto size-5" size={20} />
        </View>

        <View className="flex w-full flex-1 flex-row items-center gap-3 border-b-2 border-secondary-border py-3">
          <ShieldCheck className="size-6 text-foreground-intermediate" />
          <Link href={links.legalPrivacyPolicy} className="flex-1">
            <Typography variant="bold-lg">Privacy Policy</Typography>
          </Link>
          <CaretRight className="ml-auto size-5" size={20} />
        </View>
      </View>

      <View className="flex flex-col gap-4">
        <Button
          variant="secondary"
          color="danger"
          onClick={() => showModal(AuthModals.LOGOUT)}
          iconName="signOut"
          iconColor="danger"
          children={intl.formatMessage({
            defaultMessage: "Logout",
            id: "logout",
          })}
        />
      </View>
    </View>
  );
};
