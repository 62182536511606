import { Select, View } from "@/components/ui";
import { cn } from "@/lib/utils";
import React, { useId } from "react";
import type {
  ControllerProps,
  FieldError,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { Controller } from "react-hook-form";
import { FormControlError } from "../form-control-error";
import { Typography } from "../typography";

export const InputFormControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  controllerProps: Omit<ControllerProps<TFieldValues, TName>, "render"> & {
    label?: string;
    className?: string;
    componentProps?: Record<string, unknown>;
    rightRender?: React.ReactElement;
    component: React.ComponentType<Record<string, unknown>>;
    changeFallBack?: (value: string, error?: FieldError) => void;
    hideErrorMessage?: boolean;
  },
) => {
  const {
    label,
    className,
    changeFallBack,
    componentProps,
    rightRender,
    component: Component,
    hideErrorMessage,
    disabled,
    ...props
  } = controllerProps;
  const id = useId();
  return (
    <View className={cn("pb-3", className)}>
      {label && (
        <Typography
          htmlFor={id}
          variant={{ md: "label-lg", sm: "label-md" }}
          className="block pb-1"
        >
          {label}
        </Typography>
      )}
      <Controller
        {...props}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {Component === Select ? (
              <View>
                <Component
                  id={id}
                  value={value}
                  onValueChange={(val: string) => {
                    onChange(val);
                    changeFallBack?.(val, error);
                  }}
                  className={cn(
                    "flex-1",
                    error ? "border-destructive" : "border-secondary-border",
                  )}
                  {...componentProps}
                />
                {rightRender}
              </View>
            ) : (
              <View className="flex flex-row gap-2">
                <Component
                  id={id}
                  value={value}
                  onChangeText={(val: string) => {
                    onChange(val);
                    changeFallBack?.(val, error);
                  }}
                  className={cn(
                    "flex-1",
                    error ? "border-destructive" : "border-secondary-border",
                  )}
                  disabled={disabled}
                  {...componentProps}
                />
                {rightRender}
              </View>
            )}
            {!hideErrorMessage && <FormControlError error={error?.message} />}
          </>
        )}
      />
    </View>
  );
};
