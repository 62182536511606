import { View, Button, Input, Text } from "@/components/ui";
import { Description } from "@/components/shared/description";
import {
  SealPercent,
  MapPinLine,
  CalendarDots,
  Receipt,
} from "@/components/shared/icons";
import {
  AccordionContent,
  AccordionTrigger,
  AccordionItem,
  Accordion,
} from "@/components/ui/accordion";
import { ListHeading } from "@/components/shared/list-header";
import { BookingPayLater } from "@/components/shared/booking/pay-later";
import { AgreeTerms } from "../agree-terms";
import { BookingCountDown } from "@/containers/booking-info/booking-count-down";
import type { BookingInfoFormData } from "@/containers/booking-info/useBookingInfoForm";
import type { UseFormReturn } from "react-hook-form";
import type { PromoCodeFormData } from "..";
import { InputFormControl } from "@/components/shared/input-form-control";
import { OrderDetails } from "@/components/shared/booking/order-card/order-details";
import { useIntl } from "react-intl";

export const BookingConfirmationMain = ({
  name,
  phoneNumber,
  email,
  fullAddress,
  accessMode,
  accessInstructions,
  note,
  serviceDate,
  frequencyAndTime,
  promoCodeFormReturn,
  promoCode,
  promoCodeLoading,
  onApplyPromoCode,
  onDeletePromoCode,
}: {
  promoCodeLoading: boolean;
  serviceDate: string;
  frequencyAndTime: string;
  promoCodeFormReturn: UseFormReturn<PromoCodeFormData>;
  promoCode?: string;
  onApplyPromoCode(): void;
  onDeletePromoCode(): void;
} & BookingInfoFormData) => {
  const intl = useIntl();
  return (
    <View>
      <View className="flex flex-col items-start gap-4 border-secondary-border p-4 pt-2">
        <BookingCountDown className="w-full" />
      </View>

      <View className="border-b-2 border-secondary-border p-4 py-0 md:border-b-0">
        <ListHeading
          title={intl.formatMessage({
            defaultMessage: "Booking Timing",
            id: "booking-timing",
          })}
          icon={CalendarDots}
        />
        <Description
          title={intl.formatMessage({
            defaultMessage: "Service date",
            id: "service-date",
          })}
          description={serviceDate}
        />
        <Description
          title={intl.formatMessage({
            defaultMessage: "Frequency & Time",
            id: "frequency-and-time",
          })}
          description={frequencyAndTime}
        />
        <Description
          showLineOnSmallScreen={false}
          title={intl.formatMessage({
            defaultMessage: "Additional Note",
            id: "additional-note",
          })}
          description={note || "-"}
        />
      </View>

      <View className="border-b-2 border-secondary-border p-4 py-0 md:border-b-0 md:py-4">
        <ListHeading
          title={intl.formatMessage({
            defaultMessage: "Contact and Address Info",
            id: "contact-and-address",
          })}
          icon={MapPinLine}
        />
        <Description
          title={intl.formatMessage({
            defaultMessage: "Contact Name",
            id: "contacts",
          })}
          description={name}
        />
        <View className="flex flex-row gap-4">
          <Description
            title={intl.formatMessage({
              defaultMessage: "Email",
              id: "email",
            })}
            description={email}
          />
          <Description
            title={intl.formatMessage({
              defaultMessage: "Phone",
              id: "phone-number",
            })}
            description={phoneNumber}
          />
        </View>
        <Description
          title={intl.formatMessage({
            defaultMessage: "Address",
            id: "address",
          })}
          description={fullAddress}
        />
        <Description
          title={intl.formatMessage({
            defaultMessage: "Access Mode",
            id: "access-mode",
          })}
          description={accessMode}
        />
        <Description
          showLineOnSmallScreen={false}
          title={intl.formatMessage({
            defaultMessage: "Access Instructions",
            id: "access-instructions",
          })}
          description={accessInstructions || "-"}
        />
      </View>

      <View className="border-b-2 border-secondary-border p-4 py-0 md:border-b-0">
        <Accordion type="single" collapsible>
          <AccordionItem value={"promoCode"} className="border-b-0">
            <AccordionTrigger className="p-0 hover:no-underline">
              <ListHeading title="Promo Code" icon={SealPercent} />
            </AccordionTrigger>
            <AccordionContent>
              <>
                <Text className="pb-1 text-sm text-secondary-text">
                  One promo code per transaction
                </Text>
                <InputFormControl
                  name="promoCode"
                  control={promoCodeFormReturn.control}
                  component={Input}
                  componentProps={{
                    placeholder: "Enter Promo Code",
                    disabled: !!promoCode,
                  }}
                  rightRender={
                    promoCode ? (
                      <Button
                        variant="primary"
                        color="danger"
                        onClick={onDeletePromoCode}
                        children="Delete"
                      />
                    ) : (
                      <Button
                        variant="secondary"
                        color="CTA2"
                        onClick={onApplyPromoCode}
                        loading={promoCodeLoading}
                        children="Apply"
                      />
                    )
                  }
                />
              </>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </View>

      <View className="border-b-2 border-secondary-border p-4 py-0 md:hidden md:border-b-0">
        <ListHeading title="Your Order" icon={Receipt} />
        <View>
          <OrderDetails showTotal showTotalDivider />
        </View>
      </View>

      <View className="border-b-2 border-secondary-border p-4 py-4 md:hidden md:border-b-0">
        <BookingPayLater />
      </View>

      <View className="border-b-2 border-secondary-border p-4 py-2 md:hidden md:border-b-0">
        <AgreeTerms />
      </View>
    </View>
  );
};
