import { View } from "@/components/ui";
import { ArrowLeft } from "@/components/shared/icons";
import { Link } from "@/components/shared/link";
import { AccountInfoList } from "@/components/shared/account-info-list";
import { ChangePhoneNumberModalContainer } from "../change-phone-number";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const AccountInfo = () => {
  const intl = useIntl();
  return (
    <View className="z-10 h-full flex-1 px-4 ">
      <View className="mb-4 border-b md:mb-8 md:pb-4">
        <View className="flex flex-row items-center gap-2 py-4 md:p-0">
          <Link href="/profile">
            <ArrowLeft className="visible size-5 md:hidden" />
          </Link>
          <Typography variant={{ md: "label-2xl", sm: "body-xl" }}>
            {intl.formatMessage({
              defaultMessage: "Account Info",
              id: "account.info",
            })}
          </Typography>
        </View>
      </View>
      <AccountInfoList />

      <ChangePhoneNumberModalContainer />
    </View>
  );
};
