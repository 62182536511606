import { View, Image } from "@/components/ui";
import EmptyPackages from "@/assets/images/empty-packages.png";
import { PackageCard } from "@/components/shared/package-card";
import { Link } from "@/components/shared/link";
import { usePackageList } from "@/store/packages/useGetPackage";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export function PackageListOverview() {
  const packageList = usePackageList({ limit: 3 });
  const intl = useIntl();
  return (
    <View className="flex flex-col gap-4">
      <View className="flex flex-row justify-between">
        <Typography variant={{ md: "body-2xl", sm: "body-xl" }}>
          Packages
        </Typography>
        {!!packageList.length && (
          <Link href="/profile/packages">
            <Typography variant="body-md" color="brand-primary">
              {intl.formatMessage({
                defaultMessage: "View All",
                id: "view-all",
              })}
            </Typography>
          </Link>
        )}
      </View>

      <View className="-mx-4 flex flex-row gap-4 overflow-auto pb-4 pl-4 md:mx-0 md:flex-col md:pb-0 md:pl-0">
        {packageList.map((item, index) => (
          <PackageCard key={index} item={item} />
        ))}

        {!packageList.length && (
          <View className="flex flex-col items-center">
            <Image src={EmptyPackages} width={160} height={160} />
            <Typography variant="body-xl">
              {intl.formatMessage({
                defaultMessage: "You don't have any Packages",
                id: "package.emptyMessage",
              })}
            </Typography>
          </View>
        )}
      </View>
    </View>
  );
}
