import { createRequestFactory } from "@/lib/request-factory";
import {
  RateVisitDocument,
  type RateVisitMutation,
  type RateVisitMutationVariables,
} from "@/__generated__/graphql";

export const useRateVisitStore = createRequestFactory<
  RateVisitMutation,
  RateVisitMutation,
  RateVisitMutationVariables
>({
  method: "mutation",
  graphqlDocument: RateVisitDocument,
  hasUpload: true,
});
