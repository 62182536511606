import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import {
  Button,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Image,
  View,
} from "@/components/ui";
import { useVisitDetailStore } from "@/store/visits/visitDetail";
import { RateVisitForm } from "./rate-visit-form";
import { useRateVisitForm } from "@/store/visits/forms/useRateVisitForm";
import { CompletedVisitRateCard } from "@/components/shared/visits/visit-rate/completed-visit-rate-card";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { IconButton } from "@/components/ui/icon-button";
import { useEffect, useState } from "react";
import { ImagePreviewInput } from "@/components/shared/image-preview-input";
import type { VisitDetailData } from "@/components/shared/visits/visit-detail";
import { useRateVisitStore } from "@/store/visits/useRateVisit";
import { useRateVisitWithTokenStore } from "@/store/visits/useRateVisitWithToken";
import { showToast } from "@/components/ui/toast/show-toast";
import { Divider } from "@/components/shared/divider";
import { useImagePreviewStore } from "@/store/image-preview";
import { ImagePreviewGallery } from "@/components/shared/image-preview-gallery";
import { getPlatform } from "@/lib/utils";
import type { ImageFile } from "@/types/app";
import { Typography } from "@/components/shared/typography";

export function VisitRateModalContainer({
  visitData,
  onSuccessRated,
}: {
  visitData: VisitDetailData;
  onSuccessRated?(visitId: string): void;
  loading: boolean;
}) {
  const [reviewImages, setReviewImages] = useState<(string | File)[]>(
    visitData.reviewImageUrls,
  );

  const {
    data: { showRateVisitModal },
    closeRateVisitModal,
  } = useVisitDetailStore();
  const {
    data: { open: showImagePreview },
    openImagePreviewModal,
    closeImagePreview,
  } = useImagePreviewStore();

  const { isDesktop, isMobile } = useWindowDimensions();

  const { fetch: rateVisitWIthToken, loading: loadingRateWithTokenMutation } =
    useRateVisitWithTokenStore();
  const { fetch: rateVisit, loading: loadingRateMutation } =
    useRateVisitStore();

  const [files, setFiles] = useState<File[]>([]);

  const rateVisitForm = useRateVisitForm();

  useEffect(() => {
    if (visitData.rating) {
      rateVisitForm.reset({
        rating: visitData.rating ?? 0,
        comment: visitData.ratingComment ?? "",
      });
      rateVisitForm.clearErrors();
    } else {
      rateVisitForm.reset(rateVisitForm.defaultValues);
    }
    setFiles([]);
  }, [visitData.rating, visitData.ratingComment]);

  const handleSubmit = rateVisitForm.handleSubmit(async (values) => {
    const commonInput = {
      rating: values.rating,
      ratingComment: values.comment,
      files,
    };

    const res = visitData.token
      ? await rateVisitWIthToken({
          requestPayload: {
            input: {
              token: visitData.token,
              ...commonInput,
            },
          },
        })
      : await rateVisit({
          requestPayload: {
            input: {
              visitId: visitData.id,
              ...commonInput,
            },
          },
        });

    if (res.error) {
      return;
    }

    showToast({
      type: "success",
      title: `Thank you for ${values.rating} star(s) rating!`,
    });
    onSuccessRated?.(visitData.id);
    closeRateVisitModal();
    closeImagePreview();
  });

  const handleClose = () => {
    closeRateVisitModal();
    closeImagePreview();
  };

  const handlePreviewImages = (activeIndex: number) => () => {
    openImagePreviewModal(reviewImages, activeIndex);
  };

  const handleRemoveImage = (imageUrl: string | File, index: number) => {
    setReviewImages((images) => {
      return images.filter((url, imgIndex) => {
        if (typeof url === "string") {
          return url !== imageUrl;
        } else {
          return index !== imgIndex;
        }
      });
    });
    closeImagePreview();
  };

  const handleFileChange = ({ file, uri }: ImageFile) => {
    setFiles((prevFiles) => [...prevFiles, file]);
    setReviewImages((prevImages) => [...prevImages, uri]);
  };

  if (getPlatform() === "native" && showImagePreview) {
    return (
      <ImagePreviewGallery
        onRemoveImage={visitData.rating === 0 ? handleRemoveImage : undefined}
      />
    );
  }

  if (showRateVisitModal) {
    return (
      <>
        <BottomDrawerModal open onOpenChange={handleClose}>
          {isDesktop && (
            <DialogHeader>
              <View className="flex flex-row justify-between gap-2 px-1 pb-3 pt-1">
                <DialogTitle>
                  {visitData.rating ? "You have reviewed it" : "Lets Rate It!"}
                </DialogTitle>
                <IconButton
                  variant="ghost"
                  color="foreground"
                  size="md"
                  iconName="x"
                  onClick={handleClose}
                />
              </View>
            </DialogHeader>
          )}
          <View className="flex-1 space-y-3">
            <CompletedVisitRateCard
              department={visitData.department}
              serviceType={visitData.serviceType}
              serviceDate={visitData.serviceDate}
              startTime={visitData.startTime}
              endTime={visitData.endTime}
              worker={visitData.worker}
            />

            <RateVisitForm
              control={rateVisitForm.control}
              readonly={
                visitData.rating !== 0 ||
                loadingRateMutation ||
                loadingRateWithTokenMutation
              }
            />
            <View className="Review-images flex flex-row flex-wrap gap-1">
              {reviewImages.map((image, iImg) => (
                <View
                  className="relative w-[32%] overflow-hidden rounded-md md:w-1/4"
                  key={iImg}
                  onClick={handlePreviewImages(iImg)}
                >
                  <IconButton
                    variant="ghost"
                    color="foreground"
                    size="md"
                    className="absolute right-2 top-2"
                    iconName="trash"
                    onClick={() => handleRemoveImage(image, iImg)}
                  />
                  <Image
                    src={image}
                    className="aspect-square cursor-pointer overflow-hidden rounded-md object-cover"
                    alt={`review image ${iImg + 1}`}
                    contentFit="cover"
                    style={{
                      height: undefined,
                      width: "100%",
                      aspectRatio: 1,
                    }}
                  />
                </View>
              ))}
              {visitData.rating === 0 && reviewImages.length < 3 && (
                <View className="w-[32%] space-y-1 md:w-1/4">
                  <ImagePreviewInput onChange={handleFileChange} />
                  <Typography variant="label-md" align="center">
                    Add Photo
                  </Typography>
                </View>
              )}
            </View>
          </View>
          <DialogFooter>
            {visitData.rating === 0 && (
              <Button
                variant="primary"
                color="CTA"
                fullWidth="full"
                onClick={handleSubmit}
                loading={loadingRateMutation || loadingRateWithTokenMutation}
                children="Rate it!"
              />
            )}

            {isMobile && visitData.rating !== 0 && (
              <Button
                variant="primary"
                color="CTA"
                fullWidth="full"
                onClick={handleClose}
                disabled={loadingRateMutation || loadingRateWithTokenMutation}
                children="Close"
              />
              
            )}
            <Divider className="my-4" />
          </DialogFooter>
        </BottomDrawerModal>
        {getPlatform() === "web" && (
          <ImagePreviewGallery
            onRemoveImage={
              visitData.rating === 0 ? handleRemoveImage : undefined
            }
          />
        )}
      </>
    );
  }

  return null;
}
