import React from "react";

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  contentFit?: never;
};

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  ({ contentFit: _contentFit, ...props }, ref) => {
    return <img ref={ref} {...props} />;
  },
);
