import { useAuthState } from "@/store/auth";
import { useVisitDetailStore } from "@/store/visits/visitDetail";
import { useEffect } from "react";
import { VisitRateModalContainer } from "@/containers/visits/visit-rate";
import { View } from "@/components/ui";
import { useUnratedVisitByFilterStore } from "@/store/visits/unratedVisitByFilters";
import { VisitDetailData } from "../visit-detail";
import { UnratedVisitScreen } from "./unrated-visit-screen";

export type UnratedVisitScreenProps = {
  unratedVisitData: VisitDetailData[];
  openRateVisitModalById: (visitId: string) => void;
};
export function UnratedVisitWidget() {
  const { fetch: unratedVisitByFilters, data: unratedVisitData } =
    useUnratedVisitByFilterStore();
  const clientId = useAuthState(({ data }) => data.userInfo.id);
  const {
    data: { showRateVisitModal, visitId },
    openRateVisitModalById,
  } = useVisitDetailStore();

  useEffect(() => {
    if (clientId) {
      unratedVisitByFilters({
        requestPayload: {
          filters: {
            clientId,
          },
        },
      });
    }
  }, [clientId]);

  if (!unratedVisitData?.length) return null;

  const selectedVisit =
    showRateVisitModal &&
    unratedVisitData.find((visit) => visit.id === visitId);

  return (
    <View className="mt-4">
      <UnratedVisitScreen
        unratedVisitData={unratedVisitData}
        openRateVisitModalById={openRateVisitModalById}
      />
      {selectedVisit && (
        <VisitRateModalContainer visitData={selectedVisit} loading={false} />
      )}
    </View>
  );
}
