import { createRequestFactory } from "@/lib/request-factory";
import type { Contact } from "@/__generated__/graphql";
import {
  UpdateContactDocument,
  type UpdateContactMutation,
  type UpdateContactMutationVariables,
} from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";

type Response = {
  contact: Contact;
};

export const useUpdateContactStore = createRequestFactory<
  UpdateContactMutation,
  Response,
  UpdateContactMutationVariables
>({
  method: "mutation",
  graphqlDocument: UpdateContactDocument,
  transformFunction: (res) => {
    if (res.updateContact?.contact) {
      return { contact: res.updateContact.contact } as Response;
    }
    throw new Error("Update contact failed");
  },
  onFetchSuccess: (data) => {
    const {
      setUserInfo,
      data: { userInfo },
    } = useAuthState.getState();

    const updatedContact = data.contact;
    const existingContacts = userInfo.contacts || [];

    const updatedContacts = existingContacts.map((contact) =>
      contact.id === updatedContact.id ? updatedContact : contact,
    );

    setUserInfo({ ...userInfo, contacts: updatedContacts });
  },
});
