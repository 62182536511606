import { View } from "@/components/ui";
import type { ServiceName } from "@/types/service";
import type { ReactNode } from "react";
import { useRoute } from "@/components/shared/router";
import { bookingRoutes } from "@/components/hooks/use-booking-route";
import { Typography } from "../typography";
// import { sendAnalyticData } from "@/lib/monitoring/analytics";
// import { getServiceDepartments } from "@/lib/booking-lib";

export function ServiceIcon({
  icon,
  title,
  name,
}: {
  icon: ReactNode;
  title: string;
  name: ServiceName;
}) {
  const { push } = useRoute();
  const navigateToBooking = () => {
    // TODO: We will enable this once we have analytics aligned
    // sendAnalyticData({
    //   name: 'view_service',
    //   values: {
    //     service_type: getServiceDepartments(name)
    //   }
    // })
    push(bookingRoutes[name][0]);
  };

  return (
    <View className="cursor-pointer" onClick={navigateToBooking}>
      <View className="native:max-w-[77px] flex flex-1 flex-col items-center gap-1">
        <View className="flex h-[56px] w-[56px] items-center justify-center rounded-lg border border-primary-border bg-primary-surface p-2">
          {icon}
        </View>
        <Typography
          align="center"
          variant="body-md"
          className="line-clamp-2"
          numberOfLines={2}
        >
          {title}
        </Typography>
      </View>
    </View>
  );
}
