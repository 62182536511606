import { createRequestFactory } from "@/lib/request-factory";
import type { ClientAccountTypeEnum } from "@/__generated__/graphql";
import {
  UpdateClientDocument,
  type UpdateClientMutationVariables,
  type UpdateClientMutation,
} from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";

type Response = {
  accountType: ClientAccountTypeEnum;
};

export const useUpdateClientStore = createRequestFactory<
  UpdateClientMutation,
  Response,
  UpdateClientMutationVariables
>({
  method: "mutation",
  graphqlDocument: UpdateClientDocument,
  transformFunction: (res) => {
    if (res.updateClient?.client) {
      return { accountType: res.updateClient.client.accountType };
    }
    throw new Error("Error");
  },
  onFetchSuccess: (data) => {
    const {
      setUserInfo,
      data: { userInfo },
    } = useAuthState.getState();
    setUserInfo({
      ...userInfo,
      accountType: data.accountType,
    });
  },
});
