import * as React from "react";
import type { SVGProps } from "react";
const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 41 40" fill="none" {...props}>
    <path
      d="M40.5 20C40.5 31.0457 31.5457 40 20.5 40C9.4543 40 0.5 31.0457 0.5 20C0.5 8.9543 9.4543 0 20.5 0C31.5457 0 40.5 8.9543 40.5 20ZM4.5 20C4.5 28.8366 11.6634 36 20.5 36C29.3366 36 36.5 28.8366 36.5 20C36.5 11.1634 29.3366 4 20.5 4C11.6634 4 4.5 11.1634 4.5 20Z"
      fill="#EEF7FF"
    />
    <path
      d="M23.6234 37.7269C23.8151 38.8148 23.0882 39.8626 21.9867 39.9447C18.7293 40.1875 15.4509 39.6293 12.4427 38.3052C8.82231 36.7116 5.75574 34.0803 3.63081 30.7438C1.50587 27.4074 0.418021 23.5158 0.504815 19.5612C0.591609 15.6065 1.84915 11.7664 4.11842 8.52639C6.38768 5.28642 9.56675 2.79212 13.2536 1.35892C16.9405 -0.0742792 20.9695 -0.382015 24.8313 0.47463C28.693 1.33127 32.214 3.31383 34.949 6.17157C37.2215 8.54607 38.8666 11.4362 39.7517 14.5804C40.051 15.6437 39.3148 16.6849 38.2269 16.8766C37.1391 17.0683 36.114 16.3361 35.7882 15.2807C35.0576 12.9139 33.7844 10.7399 32.0592 8.93726C29.8712 6.65106 27.0544 5.06502 23.965 4.3797C20.8756 3.69439 17.6524 3.94058 14.7029 5.08714C11.7534 6.2337 9.21015 8.22914 7.39473 10.8211C5.57932 13.4131 4.57329 16.4852 4.50385 19.6489C4.43442 22.8127 5.3047 25.926 7.00464 28.5951C8.70459 31.2642 11.1579 33.3693 14.0542 34.6442C16.3379 35.6494 18.8162 36.1028 21.2901 35.9805C22.3933 35.9259 23.4317 36.6391 23.6234 37.7269Z"
      fill="url(#a)"
    />
    <linearGradient
      id="a"
      x1={20.5}
      y1={0}
      x2={20.5}
      y2={40}
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0.401118} stopColor="#1155D9" />
      <stop offset={1} stopColor="#1150CA" stopOpacity={0} />
    </linearGradient>
  </svg>
);
export default SvgLoading;
