import {
  View,
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  Image,
  Label,
  Input,
} from "@/components/ui";
import type {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { Controller } from "react-hook-form";
import { cn, safeGet } from "@/lib/utils";
import { PhoneNumberSelectGroup } from "./select-group";
import type { CountryCode } from "@/lib/country-flags";
import { getCountryFlagUrl } from "@/lib/country-flags";
import React, { useId } from "react";
import { FormControlError } from "../form-control-error";
import { Typography } from "../typography";
import { useIntl } from "react-intl";

interface PhoneNumberInputProps<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
  phoneNumberName?: Path<T>;
  nationCodeName?: Path<T>;
  label?: string;
  rightRender?: React.ReactElement;
  className?: string;
  trigger(): void;
}

export const PhoneNumberInput = <T extends FieldValues>({
  control,
  errors,
  //to set default when not define the phoneNumber and nationCode
  phoneNumberName = "phoneNumber" as Path<T>,
  nationCodeName = "nationCode" as Path<T>,
  label = "Phone Number",
  rightRender,
  className,
  trigger,
}: PhoneNumberInputProps<T>) => {
  const id = useId();
  const intl = useIntl();
  return (
    <View className={(cn("flex flex-col web:flex-1"), className)}>
      {label && (
        <Label htmlFor={id} className="block pb-1 text-sm">
          {label}
        </Label>
      )}
      <View className="flex flex-row gap-2">
        <View
          className={cn(
            "ease flex flex-1 flex-row overflow-hidden rounded-md border text-secondary-text transition",
            safeGet(errors, phoneNumberName)
              ? "border-destructive"
              : "border-secondary-border",
          )}
        >
          <Controller<T>
            control={control}
            name={nationCodeName}
            defaultValue={"SG/65" as PathValue<T, Path<T>>}
            render={({ field: { onChange, value } }) => {
              const nationShort = (value as string).split("/")[0];
              const nationCode = (value as string).split("/")[1];

              return (
                <Select
                  onValueChange={(code) => {
                    onChange(code);
                    trigger?.();
                  }}
                  value={value}
                >
                  <SelectTrigger className="w-28 rounded-none border-0 border-r">
                    <SelectValue placeholder="">
                      <View className="flex flex-row items-center">
                        <View className="mr-2">
                          <Image
                            src={getCountryFlagUrl(
                              nationShort.toLowerCase() as CountryCode,
                            )}
                            width={24}
                            height={17}
                            className="shrink-0"
                          />
                        </View>
                        <Typography
                          color="secondary-text"
                          variant="label-md"
                        >{`+${nationCode}`}</Typography>
                      </View>
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <PhoneNumberSelectGroup />
                  </SelectContent>
                </Select>
              );
            }}
          />

          <Controller<T>
            control={control}
            name={phoneNumberName}
            defaultValue={"" as PathValue<T, Path<T>>}
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                value={value}
                onBlur={onBlur}
                onChangeText={(text) => onChange(text.replace(/[^0-9]/g, ""))}
                className="w-full border-0"
                inputMode="numeric"
                type="tel"
                placeholder={intl.formatMessage({
                  defaultMessage: "Phone Number",
                  id: "phone-number",
                })}
                id={id}
              />
            )}
          />
        </View>
        {rightRender}
      </View>

      <FormControlError
        error={safeGet(errors, phoneNumberName)?.message as string}
      />
    </View>
  );
};
