import { WebLayout } from "@/components/shared/layout/homepage";
import type { ServiceName } from "@/types/service";
import { useParams } from "@/components/hooks/use-params";
import { SelectService } from "@/containers/select-service";

export function SelectServicePage() {
  const { name } = useParams<{ name: ServiceName }>();

  if (!name) {
    return <div>404</div>;
  }

  return (
    <WebLayout>
      <SelectService name={name} />
    </WebLayout>
  );
}
