import { getStorageKey } from "../utils";

const getItem = <T = unknown>(key: string): Promise<T> => {
  try {
    const value = localStorage.getItem(getStorageKey(key));
    return Promise.resolve(value ? JSON.parse(value) : value) as Promise<T>;
  } catch (e) {
    console.error("Error getting item from localStorage:", e);
    return Promise.resolve(null) as Promise<T>;
  }
};

const setItem = async (key: string, value: unknown): Promise<void> => {
  try {
    localStorage.setItem(getStorageKey(key), JSON.stringify(value));
    return Promise.resolve();
  } catch (e) {
    console.error("Error setting item in localStorage:", e);
  }
};

const removeItem = async (key: string): Promise<void> => {
  try {
    localStorage.removeItem(getStorageKey(key));
    return Promise.resolve();
  } catch (e) {
    console.error("Error deleting item from localStorage:", e);
  }
};

const deleteAll = async (): Promise<void> => {
  try {
    localStorage.clear();
    return Promise.resolve();
  } catch (e) {
    console.error("Error clearing localStorage:", e);
  }
};

export const storage = {
  getItem,
  setItem,
  removeItem,
  deleteAll,
};
