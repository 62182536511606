import type { InvoiceDetailFragmentFragment } from "@/__generated__/graphql";
import {
  PackageDepartmentEnum,
  PackageDetailBillingUnitEnum,
  PackageDetailSessionEnum,
  PackageRecurrenceEnum,
  PackageServiceTypeEnum,
  PackageUnitTypeEnum,
} from "@/__generated__/graphql";
import type { LineItemDetail, PackageDetail } from "@/types/line-item";
import { packageServiceTypeLabel } from "../service/helpers";
import { extractTextAfterColon } from "../helpers/string";

export const PackageUnitTypeMapping: Record<PackageUnitTypeEnum, string> = {
  [PackageUnitTypeEnum.All]: "All Units",
  [PackageUnitTypeEnum.R22]: "R22",
  [PackageUnitTypeEnum.R410A]: "R401",
  [PackageUnitTypeEnum.Ducted]: "Ducted",
  [PackageUnitTypeEnum.Ceiling]: "Casette (Ceiling)",
  [PackageUnitTypeEnum.Portable]: "Portable",
  [PackageUnitTypeEnum.Wall]: "Wall-Mounted",
  [PackageUnitTypeEnum.Window]: "Window",
  [PackageUnitTypeEnum.Carpet]: "Carpet",
  [PackageUnitTypeEnum.Mattress]: "Mattress",
  [PackageUnitTypeEnum.Rug]: "Rug",
  [PackageUnitTypeEnum.Sofa]: "Sofa",
  [PackageUnitTypeEnum.Nail]: "Nail art",
  [PackageUnitTypeEnum.BabyPram]: "Baby Pram",
  [PackageUnitTypeEnum.ChildSeat]: "Child Seat",
  [PackageUnitTypeEnum.Curtain]: "Curtain",
  [PackageUnitTypeEnum.BodyMassage]: "Body Massage",
  [PackageUnitTypeEnum.Electrician]: "Electrician",
  [PackageUnitTypeEnum.FootMassage]: "Foot Massage",
  [PackageUnitTypeEnum.Handyman]: "Handyman",
  [PackageUnitTypeEnum.Installation]: "Installation",
  [PackageUnitTypeEnum.Lashes]: "Lashes",
  [PackageUnitTypeEnum.Mpv]: "MPV",
  [PackageUnitTypeEnum.Plumbing]: "Plumbing",
  [PackageUnitTypeEnum.Sedan]: "Sedan",
  [PackageUnitTypeEnum.Suv]: "SUV",
};

export const PackageDetailSessionMapping: Record<
  PackageDetailSessionEnum,
  string
> = {
  [PackageDetailSessionEnum.All]: "All",
  [PackageDetailSessionEnum.Day]: "Day",
  [PackageDetailSessionEnum.Evening]: "Evening",
};

export const PackageDetailBillingUnitMapping: Record<
  PackageDetailBillingUnitEnum,
  string
> = {
  [PackageDetailBillingUnitEnum.Fixed]: "Units",
  [PackageDetailBillingUnitEnum.Hourly]: "Hours",
  [PackageDetailBillingUnitEnum.PerSqft]: "Per sqft",
};

export function getPackageBillingUnitLabel(
  billingUnit: PackageDetailBillingUnitEnum,
  department: PackageDepartmentEnum,
): string {
  if (
    department === PackageDepartmentEnum.OfficeCleaning &&
    billingUnit === PackageDetailBillingUnitEnum.Fixed
  ) {
    return "Hours";
  }

  return PackageDetailBillingUnitMapping[billingUnit];
}

const HCRepeatEveryLabel = (repeatEvery: number): string => {
  switch (repeatEvery) {
    case 1:
      return "One";
    case 2:
      return "Two";
    case 3:
      return "Three";
    case 4:
      return "Four";
    case 5:
      return "Five";

    default:
      return "";
  }
};

export function getFrequencyLabel(
  repeatEvery: PackageRecurrenceEnum,
  repeatEveryTimes: number,
) {
  switch (repeatEvery) {
    case PackageRecurrenceEnum.Week:
      return "Weekly" as const;
    case PackageRecurrenceEnum.Fortnight:
      return "Fortnightly" as const;
    case PackageRecurrenceEnum.Month:
      return "Monthly" as const;
    case PackageRecurrenceEnum.Year:
      if (repeatEveryTimes === 3) {
        return "Tri-Yearly" as const;
      } else if (repeatEveryTimes === 4) {
        return "Quarterly" as const;
      } else {
        return "Yearly" as const;
      }
    default:
      return "Ad Hoc" as const;
  }
}

function serviceLabelForAirconOrOther(packageDetail: PackageDetail) {
  const serviceTitle = `${packageServiceTypeLabel(
    packageDetail.serviceType,
  )} (${getFrequencyLabel(
    packageDetail.repeatEvery,
    packageDetail.repeatEveryTimes,
  )})`;

  const serviceLabel = PackageUnitTypeMapping[packageDetail.unitType];

  const servicePackageCode = packageDetail.code;
  return {
    serviceTitle,
    serviceLabel,
    servicePackageCode,
  };
}

function serviceLabelForHomeOrOffice(packageDetail: PackageDetail) {
  const serviceTitle = `${getFrequencyLabel(
    packageDetail.repeatEvery,
    packageDetail.repeatEveryTimes,
  )} ${packageServiceTypeLabel(packageDetail.serviceType)} (${
    PackageDetailSessionMapping[packageDetail.session]
  })`;

  const serviceLabel = `${HCRepeatEveryLabel(packageDetail.repeatEveryTimes)} ${
    packageDetail.duration
  }-Hour Session`;

  return {
    serviceTitle,
    serviceLabel,
  };
}

export const getBeautyCodeLabel = (packageCode: string): string => {
  switch (packageCode) {
    case "HBNCCMP":
      return "Classic ManiPedi";
    case "HBNCGMP":
      return "Gel ManiPedi";
    case "HBNCGMCP":
      return "Gel Mani + Classic Pedi";
    case "HBNCCMA":
      return "Classic Manicure";
    case "HBNCCPE":
      return "Classic Pedicure";
    case "HBNCGMA":
      return "Gel Manicure";
    case "HBNCGPE":
      return "Gel Pedicure";
    case "HBNANA":
      return "Nail Art (per nail)";
    case "HBNAFT":
      return "French Tips";
    case "HBNAEX":
      return "Nail Extension";
    case "HBNAGR":
      return "Gel Removal";
    case "HBNAER":
      return "Extension Removal";
    case "HBNAEM":
      return "Extended Massage";
    case "HBNACT":
      return "Callus Treatment";
    case "HBNDFGR":
      return "Free Gel Removal";
    default:
      return "-";
  }
};

export function getLineItemClientDetail(
  packageDetail: PackageDetail,
  options?: {
    unitValue?: number;
    billingValue?: number;
    unitNumber?: number;
    description?: string;
  },
): LineItemDetail {
  const cacheKey = `${packageDetail.id}-${packageDetail.serviceType}-${
    options?.unitValue ?? packageDetail.unitValue
  }-${options?.billingValue ?? packageDetail.serviceBillingValue}-${
    options?.unitNumber ?? packageDetail.units
  }`;

  if (getLineItemClientDetail.cache[cacheKey]) {
    return getLineItemClientDetail.cache[cacheKey];
  }

  const isHomeOrOfficeClean = [
    PackageDepartmentEnum.HomeCleaning,
    PackageDepartmentEnum.OfficeCleaning,
  ].includes(packageDetail.department);

  const isHomeBeautyService =
    PackageDepartmentEnum.HomeBeauty === packageDetail.department;

  let serviceTitle = "";
  let serviceLabel = "";

  if (isHomeOrOfficeClean) {
    const label = serviceLabelForHomeOrOffice(packageDetail);
    serviceTitle = label.serviceTitle;
    serviceLabel = label.serviceLabel;
  } else if (isHomeBeautyService) {
    const label = serviceLabelForAirconOrOther(packageDetail);
    serviceTitle =
      label.serviceTitle === "Discount (Ad Hoc)"
        ? "Free Item"
        : label.serviceTitle;
    serviceLabel = getBeautyCodeLabel(label.servicePackageCode);
  } else {
    const label = serviceLabelForAirconOrOther(packageDetail);
    serviceTitle = label.serviceTitle;
    serviceLabel = label.serviceLabel;
  }

  let descriptionItem = serviceLabel;

  if (options?.description) {
    descriptionItem = extractTextAfterColon(options.description);
  }

  getLineItemClientDetail.cache[cacheKey] = {
    packageDetailId: packageDetail.id,
    serviceTitle,
    serviceLabel,
    description: descriptionItem,
    department: packageDetail.department,
    packageCode: packageDetail.code,
    unitsLabel: getPackageBillingUnitLabel(
      packageDetail.billingUnit,
      packageDetail.department,
    ),
    unitsNumber: options?.unitNumber ?? packageDetail.units,
    unitValue: options?.unitValue ?? packageDetail.unitValue,
    billingValue: options?.billingValue ?? packageDetail.serviceBillingValue,
    serviceType: packageDetail.serviceType,
    duration: packageDetail.duration,
    workerSkillId: packageDetail.workerSkill.id,
  };

  return getLineItemClientDetail.cache[cacheKey];
}
getLineItemClientDetail.cache = {} as Record<string, LineItemDetail>;

function splitOnLastDash(text: string) {
  const lastDashIndex = text.lastIndexOf(" - ");

  if (lastDashIndex === -1) {
    // No dash found, return the original text as a single element array
    return [text];
  }

  const firstPart = text.substring(0, lastDashIndex);
  const secondPart = text.substring(lastDashIndex + 3);

  return [firstPart, secondPart];
}

export function getInvoiceLineItems(
  lineItem: NonNullable<InvoiceDetailFragmentFragment["lineItems"]>[0],
  index: number,
): LineItemDetail {
  let [serviceTitle, serviceLabel] = splitOnLastDash(lineItem.description);
  if (!lineItem.description.includes(" - ")) {
    serviceTitle = lineItem.description;
    serviceLabel = lineItem.description;
  }

  getInvoiceLineItems.cache[lineItem.description] = {
    packageDetailId: lineItem.productCode,
    serviceTitle,
    serviceLabel,
    description: `#${index + 1} ${serviceLabel}`,
    department: PackageDepartmentEnum.HomeCleaning,
    packageCode: lineItem.productCode,
    unitsLabel: "",
    unitsNumber: 0,
    unitValue: lineItem.amount,
    billingValue: lineItem.amount,
    serviceType: PackageServiceTypeEnum.AdHoc,
    duration: 0,
    workerSkillId: "",
  };

  return getInvoiceLineItems.cache[lineItem.description];
}
getInvoiceLineItems.cache = {} as Record<string, LineItemDetail>;
