import { Image, View } from "@/components/ui";
import NoInvoice from "@/assets/images/empty-invoice.png";
import { Typography } from "@/components/shared/typography";

export function EmptyInvoice() {
  return (
    <View className="flex flex-col items-center gap-4 py-8">
      <Image src={NoInvoice} width={160} height={160} />
      <Typography variant="label-xl" color="foreground-intermediate">
        No invoices yet! Your invoice will appear at the end of each month for
        recurring bookings, and the day after for one off bookings
      </Typography>
    </View>
  );
}
