import * as React from "react";
import type { SVGProps } from "react";
const SvgServiceMattressIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M36.0511 23.0907L30.9844 10.3202C30.57 9.27858 29.6223 8.60482 28.57 8.60482H12.3358C11.2835 8.60482 10.3322 9.27858 9.92137 10.3202L4.8511 23.0907C4.66011 23.5747 4.59885 24.0936 4.66732 24.597C4.6493 24.6628 4.63849 24.7364 4.63849 24.81V33.9639C4.63849 35.4546 5.76642 36.6666 7.1538 36.6666H33.7484C35.1358 36.6666 36.2637 35.4546 36.2637 33.9639V24.81C36.2637 24.7364 36.2529 24.6667 36.2349 24.597C36.3034 24.0975 36.2457 23.5747 36.0511 23.0907ZM28.7682 32.0161V27.021H31.2547V32.0161H28.7682ZM24.8439 32.0161V27.021H27.3304V32.0161H24.8439ZM20.916 32.0161V27.021H23.4025V32.0161H20.916ZM16.988 32.0161V27.021H19.4745V32.0161H16.988ZM13.0637 32.0161V27.021H15.5502V32.0161H13.0637ZM9.13579 32.0161V27.021H11.6223V32.0161H9.13579ZM7.01326 32.0161C6.49795 32.0161 6.07993 31.567 6.07993 31.0132V26.7151C6.44029 26.9126 6.8475 27.0171 7.26552 27.0171H7.69435V32.0123H7.01326V32.0161ZM32.6961 27.021H33.6367C34.0583 27.021 34.4619 26.9126 34.8223 26.719V31.0171C34.8223 31.5708 34.4043 32.02 33.8889 32.02H32.6961V27.021ZM6.17723 23.6986L11.2475 10.9281C11.4349 10.4596 11.8601 10.1537 12.3358 10.1537H28.57C29.0457 10.1537 29.4745 10.4557 29.6583 10.9281L34.7286 23.6986C34.8871 24.0975 34.8511 24.535 34.6277 24.899C34.4043 25.263 34.0439 25.4721 33.6403 25.4721H7.26552C6.86191 25.4721 6.50155 25.263 6.27813 24.899C6.05471 24.5389 6.01507 24.1014 6.17723 23.6986ZM33.7484 35.1216H7.1538C6.56281 35.1216 6.07993 34.6028 6.07993 33.9677V33.3598C6.36822 33.4914 6.68173 33.565 7.01326 33.565H33.8925C34.2241 33.565 34.5412 33.4914 34.8259 33.3598V33.9677C34.8223 34.6028 34.343 35.1216 33.7484 35.1216Z"
      fill="currentColor"
    />
    <path
      d="M14.1592 12.6551C14.2277 12.7674 14.343 12.8255 14.4583 12.8255C14.5268 12.8255 14.5952 12.8062 14.6565 12.7597L15.1646 12.3957L15.6727 12.7597C15.734 12.8023 15.8025 12.8255 15.8709 12.8255C15.9862 12.8255 16.1016 12.7636 16.17 12.6551C16.2817 12.477 16.2349 12.2369 16.0691 12.1169L15.8097 11.931L16.0691 11.7452C16.2349 11.6251 16.2781 11.3851 16.17 11.2069C16.0583 11.0288 15.8349 10.9823 15.6691 11.0985L15.161 11.4625L14.6529 11.0985C14.4871 10.9785 14.2637 11.0288 14.152 11.2069C14.0403 11.3851 14.0871 11.6251 14.2529 11.7452L14.5124 11.931L14.2529 12.1169C14.0943 12.2369 14.0511 12.477 14.1592 12.6551Z"
      fill="currentColor"
    />
    <path
      d="M19.4421 12.6551C19.5106 12.7674 19.6259 12.8255 19.7412 12.8255C19.8097 12.8255 19.8781 12.8062 19.9394 12.7597L20.4511 12.3957L20.9592 12.7597C21.0205 12.8023 21.0889 12.8255 21.1574 12.8255C21.2727 12.8255 21.388 12.7636 21.4565 12.6551C21.5682 12.477 21.5214 12.2369 21.3556 12.1169L21.0997 11.931L21.3592 11.7452C21.525 11.6251 21.5682 11.3851 21.4601 11.2069C21.3484 11.0288 21.125 10.9823 20.9592 11.0985L20.4511 11.4664L19.943 11.1024C19.7772 10.9823 19.5538 11.0327 19.4421 11.2108C19.3304 11.3889 19.3772 11.629 19.543 11.749L19.8025 11.9349L19.543 12.1208C19.3772 12.2369 19.3304 12.477 19.4421 12.6551Z"
      fill="currentColor"
    />
    <path
      d="M24.725 12.6551C24.7934 12.7674 24.9088 12.8255 25.0241 12.8255C25.0925 12.8255 25.161 12.8062 25.2223 12.7597L25.7304 12.3957L26.2385 12.7597C26.2998 12.8023 26.3682 12.8255 26.4367 12.8255C26.552 12.8255 26.6673 12.7636 26.7358 12.6551C26.8475 12.477 26.8007 12.2369 26.6349 12.1169L26.3754 11.931L26.6349 11.7452C26.8007 11.6251 26.8439 11.3851 26.7358 11.2069C26.6241 11.0288 26.4007 10.9823 26.2349 11.0985L25.7268 11.4625L25.2187 11.0985C25.0529 10.9785 24.8295 11.0288 24.7178 11.2069C24.6061 11.3851 24.6529 11.6251 24.8187 11.7452L25.0781 11.931L24.8187 12.1169C24.6565 12.2369 24.6133 12.477 24.725 12.6551Z"
      fill="currentColor"
    />
    <path
      d="M11.1358 16.446C11.2043 16.5583 11.3196 16.6164 11.4349 16.6164C11.5034 16.6164 11.5718 16.597 11.6331 16.5506L12.1412 16.1866L12.6493 16.5506C12.7106 16.5932 12.779 16.6164 12.8475 16.6164C12.9628 16.6164 13.0781 16.5544 13.1466 16.446C13.2583 16.2679 13.2115 16.0278 13.0457 15.9078L12.7862 15.7219L13.0457 15.5361C13.2115 15.416 13.2547 15.1759 13.1466 14.9978C13.0349 14.8197 12.8115 14.7732 12.6457 14.8894L12.1376 15.2534L11.6295 14.8894C11.4637 14.7694 11.2403 14.8197 11.1286 14.9978C11.0169 15.1759 11.0637 15.416 11.2295 15.5361L11.4889 15.7219L11.2295 15.9078C11.0673 16.0278 11.0241 16.2679 11.1358 16.446Z"
      fill="currentColor"
    />
    <path
      d="M16.6745 16.446C16.743 16.5583 16.8583 16.6164 16.9736 16.6164C17.0421 16.6164 17.1106 16.597 17.1718 16.5506L17.6799 16.1866L18.188 16.5506C18.2493 16.5932 18.3178 16.6164 18.3862 16.6164C18.5016 16.6164 18.6169 16.5544 18.6853 16.446C18.797 16.2679 18.7502 16.0278 18.5844 15.9078L18.325 15.7219L18.5844 15.5361C18.7502 15.416 18.7934 15.1759 18.6853 14.9978C18.5736 14.8197 18.3502 14.7732 18.1844 14.8894L17.6763 15.2534L17.1682 14.8894C17.0025 14.7694 16.779 14.8197 16.6673 14.9978C16.5556 15.1759 16.6025 15.416 16.7682 15.5361L17.0277 15.7219L16.7682 15.9078C16.6061 16.0278 16.5628 16.2679 16.6745 16.446Z"
      fill="currentColor"
    />
    <path
      d="M22.2097 16.446C22.2781 16.5583 22.3934 16.6164 22.5088 16.6164C22.5772 16.6164 22.6457 16.597 22.707 16.5506L23.2151 16.1866L23.7232 16.5506C23.7844 16.5932 23.8529 16.6164 23.9214 16.6164C24.0367 16.6164 24.152 16.5544 24.2205 16.446C24.3322 16.2679 24.2853 16.0278 24.1196 15.9078L23.8601 15.7219L24.1196 15.5361C24.2853 15.416 24.3286 15.1759 24.2205 14.9978C24.1088 14.8197 23.8853 14.7732 23.7196 14.8894L23.2115 15.2534L22.7034 14.8894C22.5376 14.7694 22.3142 14.8197 22.2024 14.9978C22.0907 15.1759 22.1376 15.416 22.3034 15.5361L22.5628 15.7219L22.3034 15.9078C22.1448 16.0278 22.1016 16.2679 22.2097 16.446Z"
      fill="currentColor"
    />
    <path
      d="M27.7484 16.446C27.8169 16.5583 27.9322 16.6164 28.0475 16.6164C28.116 16.6164 28.1844 16.597 28.2457 16.5506L28.7538 16.1866L29.2619 16.5506C29.3232 16.5932 29.3916 16.6164 29.4601 16.6164C29.5754 16.6164 29.6907 16.5544 29.7592 16.446C29.8709 16.2679 29.8241 16.0278 29.6583 15.9078L29.3988 15.7219L29.6583 15.5361C29.8241 15.416 29.8673 15.1759 29.7592 14.9978C29.6475 14.8197 29.4241 14.7732 29.2583 14.8894L28.7502 15.2534L28.2421 14.8894C28.0763 14.7694 27.8529 14.8197 27.7412 14.9978C27.6295 15.1759 27.6763 15.416 27.8421 15.5361L28.1016 15.7219L27.8421 15.9078C27.6835 16.0278 27.6403 16.2679 27.7484 16.446Z"
      fill="currentColor"
    />
    <path
      d="M14.6421 18.7074C14.5304 18.5293 14.307 18.4828 14.1412 18.599L13.6331 18.9629L13.125 18.599C12.9592 18.4789 12.7358 18.5293 12.6241 18.7074C12.5124 18.8855 12.5592 19.1256 12.725 19.2456L12.9844 19.4315L12.725 19.6174C12.5592 19.7374 12.516 19.9775 12.6241 20.1556C12.6925 20.2679 12.8079 20.326 12.9232 20.326C12.9916 20.326 13.0601 20.3066 13.1214 20.2601L13.6295 19.8961L14.1376 20.2601C14.1988 20.3027 14.2673 20.326 14.3358 20.326C14.4511 20.326 14.5664 20.264 14.6349 20.1556C14.7466 19.9775 14.6997 19.7374 14.534 19.6174L14.2745 19.4315L14.534 19.2456C14.707 19.1256 14.7502 18.8855 14.6421 18.7074Z"
      fill="currentColor"
    />
    <path
      d="M19.4421 20.1556C19.5106 20.2679 19.6259 20.326 19.7412 20.326C19.8097 20.326 19.8781 20.3066 19.9394 20.2601L20.4475 19.8961L20.9556 20.2601C21.0169 20.3027 21.0853 20.326 21.1538 20.326C21.2691 20.326 21.3844 20.264 21.4529 20.1556C21.5646 19.9775 21.5178 19.7374 21.352 19.6174L21.0925 19.4315L21.352 19.2456C21.5178 19.1256 21.561 18.8855 21.4529 18.7074C21.3412 18.5293 21.1178 18.4828 20.952 18.599L20.4439 18.9629L19.9358 18.599C19.77 18.4789 19.5466 18.5293 19.4349 18.7074C19.3232 18.8855 19.37 19.1256 19.5358 19.2456L19.7952 19.4315L19.5358 19.6174C19.3772 19.7374 19.3304 19.9813 19.4421 20.1556Z"
      fill="currentColor"
    />
    <path
      d="M28.2781 18.7074C28.1664 18.5293 27.943 18.4828 27.7772 18.599L27.2691 18.9629L26.761 18.599C26.5952 18.4789 26.3718 18.5293 26.2601 18.7074C26.1484 18.8855 26.1952 19.1256 26.361 19.2456L26.6205 19.4315L26.361 19.6174C26.1952 19.7374 26.152 19.9775 26.2601 20.1556C26.3286 20.2679 26.4439 20.326 26.5592 20.326C26.6277 20.326 26.6961 20.3066 26.7574 20.2601L27.2655 19.8961L27.7736 20.2601C27.8349 20.3027 27.9034 20.326 27.9718 20.326C28.0871 20.326 28.2025 20.264 28.2709 20.1556C28.3826 19.9775 28.3358 19.7374 28.17 19.6174L27.9106 19.4315L28.17 19.2456C28.3466 19.1256 28.3898 18.8855 28.2781 18.7074Z"
      fill="currentColor"
    />
    <path
      d="M8.44389 23.536C8.51236 23.6483 8.62768 23.7064 8.74299 23.7064C8.81146 23.7064 8.87993 23.687 8.94119 23.6406L9.4493 23.2766L9.95741 23.6406C10.0187 23.6832 10.0871 23.7064 10.1556 23.7064C10.2709 23.7064 10.3862 23.6444 10.4547 23.536C10.5664 23.3579 10.5196 23.1178 10.3538 22.9978L10.0943 22.8119L10.3538 22.626C10.5196 22.506 10.5628 22.2659 10.4547 22.0878C10.343 21.9097 10.1196 21.8632 9.9538 21.9794L9.4457 22.3434L8.93759 21.9794C8.77182 21.8594 8.5484 21.9097 8.43669 22.0878C8.32498 22.2659 8.37182 22.506 8.53759 22.626L8.79705 22.8119L8.53759 22.9978C8.37903 23.1178 8.33579 23.3579 8.44389 23.536Z"
      fill="currentColor"
    />
    <path
      d="M15.7772 22.0878C15.6655 22.2659 15.7124 22.506 15.8781 22.626L16.1376 22.8119L15.8781 22.9978C15.7124 23.1178 15.6691 23.3579 15.7772 23.536C15.8457 23.6483 15.961 23.7064 16.0763 23.7064C16.1448 23.7064 16.2133 23.687 16.2745 23.6406L16.7826 23.2766L17.2907 23.6406C17.352 23.6832 17.4205 23.7064 17.4889 23.7064C17.6043 23.7064 17.7196 23.6444 17.788 23.536C17.8997 23.3579 17.8529 23.1178 17.6871 22.9978L17.4277 22.8119L17.6871 22.626C17.8529 22.506 17.8961 22.2659 17.788 22.0878C17.6763 21.9097 17.4529 21.8632 17.2871 21.9794L16.779 22.3434L16.2709 21.9794C16.1124 21.8632 15.8853 21.9097 15.7772 22.0878Z"
      fill="currentColor"
    />
    <path
      d="M23.6079 21.9833C23.4421 21.8632 23.2187 21.9136 23.107 22.0917C22.9952 22.2698 23.0421 22.5099 23.2079 22.6299L23.4673 22.8158L23.2079 23.0017C23.0421 23.1217 22.9988 23.3618 23.107 23.5399C23.1754 23.6522 23.2907 23.7103 23.4061 23.7103C23.4745 23.7103 23.543 23.6909 23.6043 23.6444L24.1124 23.2804L24.6205 23.6444C24.6817 23.687 24.7502 23.7103 24.8187 23.7103C24.934 23.7103 25.0493 23.6483 25.1178 23.5399C25.2295 23.3618 25.1826 23.1217 25.0169 23.0017L24.7574 22.8158L25.0169 22.6299C25.1826 22.5099 25.2259 22.2698 25.1178 22.0917C25.0061 21.9136 24.7826 21.8671 24.6169 21.9833L24.1088 22.3472L23.6079 21.9833Z"
      fill="currentColor"
    />
    <path
      d="M30.4403 23.536C30.5088 23.6483 30.6241 23.7064 30.7394 23.7064C30.8079 23.7064 30.8763 23.687 30.9376 23.6406L31.4457 23.2766L31.9538 23.6406C32.0151 23.6832 32.0835 23.7064 32.152 23.7064C32.2673 23.7064 32.3826 23.6444 32.4511 23.536C32.5628 23.3579 32.516 23.1178 32.3502 22.9978L32.0907 22.8119L32.3502 22.626C32.516 22.506 32.5592 22.2659 32.4511 22.0878C32.3394 21.9097 32.116 21.8632 31.9502 21.9794L31.4421 22.3434L30.934 21.9794C30.7682 21.8594 30.5448 21.9097 30.4331 22.0878C30.3214 22.2659 30.3682 22.506 30.534 22.626L30.7934 22.8119L30.534 22.9978C30.3718 23.1178 30.3286 23.3579 30.4403 23.536Z"
      fill="currentColor"
    />
    <path
      d="M31.8378 8.19639C31.5682 8.04092 31.2739 7.9606 30.9552 7.95542C30.1626 7.9434 30.1679 6.82404 30.9414 6.81274C31.9615 6.79719 32.7277 6.01279 32.9507 4.98954C33.0026 4.7521 32.9454 4.47014 33.0704 4.26379C33.3624 3.78044 34.0293 3.98184 34.0431 4.57049C34.0714 5.82623 34.96 6.78659 36.1366 6.81627C36.4733 6.82475 36.7055 7.11307 36.6621 7.47135C36.6325 7.71656 36.4214 7.93422 36.1912 7.94128C34.9639 7.97944 34.0911 8.88751 34.0392 10.2125C34.0102 10.9503 33.025 10.9552 32.9941 10.2259C32.9546 9.29502 32.5692 8.6185 31.8378 8.19639ZM34.3772 7.3604C34.0357 7.1093 33.755 6.80685 33.5354 6.45304C33.5333 6.44962 33.5305 6.44681 33.5272 6.44487C33.5238 6.44293 33.5201 6.44191 33.5163 6.44191C33.5124 6.44191 33.5086 6.44293 33.5052 6.44487C33.5017 6.44681 33.4988 6.44962 33.4966 6.45304C33.2769 6.80496 32.9963 7.10765 32.6547 7.36111C32.6513 7.36365 32.6485 7.36703 32.6466 7.37097C32.6447 7.37491 32.6437 7.37928 32.6437 7.38372C32.6437 7.38816 32.6447 7.39254 32.6466 7.39648C32.6485 7.40042 32.6513 7.4038 32.6547 7.40634C32.9875 7.64849 33.2688 7.95118 33.4985 8.3144C33.5005 8.31746 33.5031 8.31997 33.5061 8.3217C33.5091 8.32343 33.5125 8.32434 33.516 8.32434C33.5194 8.32434 33.5228 8.32343 33.5258 8.3217C33.5289 8.31997 33.5315 8.31746 33.5334 8.3144C33.7627 7.94976 34.044 7.64731 34.3772 7.40704C34.3808 7.40445 34.3836 7.40097 34.3856 7.3969C34.3876 7.39284 34.3887 7.38832 34.3887 7.38372C34.3887 7.37913 34.3876 7.37461 34.3856 7.37054C34.3836 7.36648 34.3808 7.363 34.3772 7.3604Z"
      fill="currentColor"
    />
    <path
      d="M14.6408 5.33141C14.4976 5.33213 14.3414 5.35279 14.2045 5.31218C13.7464 5.17823 13.7489 4.42873 14.2026 4.2898C14.3445 4.24634 14.502 4.27056 14.6483 4.26486C14.6708 4.26439 14.682 4.25132 14.682 4.22567C14.682 4.0326 14.6526 3.79464 14.7333 3.62151C14.8701 3.32655 15.1964 3.24248 15.4301 3.44197C15.6539 3.63291 15.622 3.93072 15.6151 4.21926C15.6147 4.25061 15.628 4.26629 15.6551 4.26629C15.8089 4.26843 15.9838 4.24278 16.1282 4.30333C16.5244 4.47005 16.5451 5.12338 16.1376 5.2965C15.9895 5.3592 15.8107 5.33141 15.6532 5.33284C15.6307 5.33284 15.6191 5.34566 15.6182 5.37131C15.6139 5.55228 15.6414 5.76317 15.5801 5.92917C15.3745 6.48774 14.6595 6.31888 14.6789 5.69049C14.6822 5.58267 14.6824 5.47723 14.6795 5.37416C14.6792 5.36269 14.675 5.35182 14.6677 5.34384C14.6605 5.33587 14.6508 5.33141 14.6408 5.33141Z"
      fill="currentColor"
    />
    <path
      d="M6.44965 14.1612C6.36352 14.4178 6.281 14.68 6.2021 14.9476C6.13163 15.1874 6.06297 15.3447 5.99611 15.4196C5.68803 15.7642 5.17126 15.6179 5.02987 15.16C4.9085 14.766 4.80385 14.4316 4.71591 14.1568C4.71364 14.15 4.71003 14.1437 4.70535 14.1386C4.70066 14.1335 4.69502 14.1296 4.68881 14.1272C4.39639 14.0154 4.10909 13.9115 3.82691 13.8156C3.68356 13.767 3.57485 13.7035 3.50076 13.6251C3.2749 13.3869 3.27896 12.9854 3.5098 12.7506C3.58057 12.6787 3.68176 12.6204 3.81336 12.5757C4.12806 12.4687 4.42199 12.361 4.69513 12.2524C4.70021 12.2505 4.70483 12.2473 4.70866 12.2431C4.71249 12.2389 4.71543 12.2338 4.71727 12.2281C4.82418 11.8947 4.92958 11.5614 5.03348 11.2283C5.21733 10.6392 5.94191 10.6047 6.13028 11.1977C6.2405 11.5447 6.34921 11.8881 6.45642 12.2276C6.46034 12.2406 6.46832 12.2494 6.48037 12.2539C6.75893 12.3602 7.01853 12.4543 7.25915 12.5363C7.44436 12.5995 7.5743 12.6669 7.64899 12.7385C7.8997 12.9786 7.90557 13.4151 7.64312 13.6528C7.57505 13.7147 7.48335 13.7662 7.36801 13.8073C7.0759 13.9107 6.78122 14.0167 6.48398 14.1252C6.46712 14.1314 6.45567 14.1434 6.44965 14.1612ZM5.0249 12.9626C4.85776 13.0274 4.69514 13.088 4.53703 13.1443C4.50963 13.1541 4.49803 13.1735 4.50225 13.2027C4.50389 13.2132 4.50806 13.223 4.51433 13.2311C4.52061 13.2392 4.52876 13.2453 4.53793 13.2488C4.67767 13.3033 4.81725 13.3546 4.95669 13.4029C5.14235 13.4671 5.29955 13.5891 5.36414 13.7864C5.41444 13.9413 5.46593 14.108 5.51864 14.2866C5.5626 14.435 5.60793 14.4355 5.65461 14.288C5.7058 14.127 5.7567 13.9711 5.80729 13.8205C5.87144 13.6302 5.97895 13.5053 6.12983 13.4457C6.29094 13.3822 6.4614 13.3182 6.64118 13.2537C6.67672 13.2411 6.68861 13.2164 6.67687 13.1798C6.67488 13.1737 6.67167 13.1681 6.66747 13.1634C6.66326 13.1587 6.65815 13.1551 6.65247 13.1526C6.50912 13.0927 6.36759 13.0408 6.22785 12.9971C5.79916 12.8624 5.77025 12.5115 5.65867 12.1314C5.61892 11.995 5.57435 11.9932 5.52496 12.1261C5.40706 12.4439 5.35647 12.8342 5.0249 12.9626Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgServiceMattressIcon;
