import { View, Button } from "@/components/ui";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { useState } from "react";
import {
  DotsThreeOutline,
} from "@/components/shared/icons";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { useAuthState } from "@/store/auth";
import { DropdownMenuGroup } from "@radix-ui/react-dropdown-menu";
import { useUpdateContactStore } from "@/store/profile/updateContact";
import { useDeleteContactStore } from "@/store/profile/deleteContact";
import { useClientStore } from "@/store/auth/client";
import { showToast } from "@/components/ui/toast/show-toast";
import { getErrorMessage } from "@/lib/helpers/string";
import { useIntl } from "react-intl";

export const ContactMoreActions = ({ contactId }: { contactId: string }) => {
  const intl = useIntl();
  const { data } = useAuthState();
  const { userInfo } = data;
  const { isMobile } = useWindowDimensions();
  const [openMoreActions, setOpenMoreActions] = useState<boolean>(false);

  const { fetch: updateContact, loading: updateLoading } =
    useUpdateContactStore();
  const { fetch: deleteContact, loading: deleteLoading } =
    useDeleteContactStore();
  const { fetch: client, loading: clientLoading } = useClientStore();

  const loading = updateLoading || deleteLoading || clientLoading;

  const handleSetPrimaryContact = async () => {
    const existingContacts = userInfo.contacts || [];

    const updatedPrimaryContact = existingContacts.find(
      (cont) => cont.id === contactId,
    );
    const lastPrimaryContact = existingContacts.find((cont) => cont.primary);

    try {
      if (updatedPrimaryContact?.id) {
        const updatedPrimaryRes = await updateContact(
          {
            requestPayload: {
              input: {
                id: updatedPrimaryContact.id,
                firstName: updatedPrimaryContact.firstName,
                lastName: updatedPrimaryContact.lastName,
                email: updatedPrimaryContact.email,
                phoneNumber: updatedPrimaryContact.phoneNumber,
                primary: true,
              },
            },
          },
          { selfHandleError: true },
        );

        if (updatedPrimaryRes.error)
          throw new Error(`Updated primary error: ${updatedPrimaryRes.error}`);
      }

      if (lastPrimaryContact?.id) {
        const lastPrimaryRes = await updateContact(
          {
            requestPayload: {
              input: {
                id: lastPrimaryContact.id,
                firstName: lastPrimaryContact.firstName,
                lastName: lastPrimaryContact.lastName,
                email: lastPrimaryContact.email,
                phoneNumber: lastPrimaryContact.phoneNumber,
                primary: false,
              },
            },
          },
          {
            selfHandleError: true,
          },
        );

        if (lastPrimaryRes.error)
          throw new Error(`Last primary error: ${lastPrimaryRes.error}`);
      }

      //TODO: send error when fail to get primary to sentry later
      client({
        requestPayload: {
          id: userInfo.id,
        },
      });
      showToast({
        type: "success",
        title: "Success update contact",
      });
    } catch (error) {
      const errMsg = getErrorMessage(error, "Failed to update contact");
      showToast({
        title: errMsg,
        type: "error",
      });
    }
  };

  const handleClose = () => {
    setOpenMoreActions(false);
  };

  const handleDeleteContact = async () => {
    try {
      const res = await deleteContact(
        {
          requestPayload: {
            input: {
              id: contactId,
            },
          },
        },
        {
          selfHandleError: true,
        },
      );

      if (res.data?.result && !loading) {
        handleClose();
        client({
          requestPayload: {
            id: userInfo.id,
          },
        });
        showToast({
          title: "Success delete contact",
          type: "success",
        });
      }
      if (res.error) throw new Error(res.error);
    } catch (error) {
      const errMsg = getErrorMessage(error, "Failed to delete contact");
      showToast({
        title: errMsg,
        type: "error",
      });
    }
  };

  return (
    <>
      <DropdownMenu onOpenChange={setOpenMoreActions}>
        <DropdownMenuTrigger>
          <View className="flex items-center rounded-full border border-primary-border p-2">
            <DotsThreeOutline
              weight="duotone"
              className="size-5 text-primary"
            />
          </View>
        </DropdownMenuTrigger>
        {!isMobile && (
          <DropdownMenuContent className="-left-10 p-2 px-3">
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <Button
                  variant="tertiary"
                  color="foreground"
                  loading={loading}
                  className="items-start gap-3 rounded-none border-b border-secondary-border p-0 pb-2"
                  onClick={handleSetPrimaryContact}
                  iconName="addressBook"
                  children={intl.formatMessage({
                    defaultMessage: "Set as default Contact",
                    id: "make-default-contact",
                  })}
                />
              </DropdownMenuItem>

              <DropdownMenuItem>
                <Button
                  variant="secondary"
                  color="danger"
                  loading={loading}
                  className="items-start gap-3 border-0 p-0"
                  onClick={handleDeleteContact}
                  iconName="trash"
                  iconColor="danger"
                  children={intl.formatMessage({
                    defaultMessage: "Delete Contact",
                    id: "delete-contact",
                  })}
                />
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        )}
      </DropdownMenu>
      {isMobile && (
        <BottomDrawerModal
          open={openMoreActions}
          onOpenChange={setOpenMoreActions}
        >
          <View className="flex flex-col gap-4">
            <Button
              variant="tertiary"
              color="foreground"
              fullWidth="full"
              loading={loading}
              className="justify-start gap-4 rounded-none border-b border-secondary-border p-0 pb-5"
              onClick={handleSetPrimaryContact}
              iconName="addressBook"
              children={intl.formatMessage({
                defaultMessage: "Make Default Contact",
                id: "make-default-contact",
              })}
            />

            <Button
              variant="secondary"
              color="danger"
              loading={loading}
              fullWidth="full"
              className="justify-start gap-4 border-0 p-0"
              onClick={handleDeleteContact}
              iconName="trash"
              iconColor="danger"
              children={intl.formatMessage({
                defaultMessage: "Delete Contact",
                id: "delete-contact",
              })}
            />

            <Button
              variant="primary"
              color="CTA"
              loading={loading}
              onClick={handleClose}
              children={intl.formatMessage({
                defaultMessage: "Close",
                id: "close",
              })}
            />
          </View>
        </BottomDrawerModal>
      )}
    </>
  );
};
