import { Card, CardContent, RadioGroupItem } from "@/components/ui";
import { Typography } from "../typography";

type Props = {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
  onChange?(value: string): void;
};

export function RadioCardItem({
  label,
  value,
  selected,
  disabled,
  onChange,
}: Props) {
  return (
    <Card
      variant={selected ? "info" : "default"}
      onClick={() => onChange?.(value)}
    >
      <CardContent className="flex flex-row items-center gap-2 p-3">
        <Typography variant="label-lg" className="flex-1">
          {label}
        </Typography>
        <RadioGroupItem value={value} disabled={disabled} />
      </CardContent>
    </Card>
  );
}
