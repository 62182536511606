import { Divider } from "@/components/shared/divider";
import { FormControlError } from "@/components/shared/form-control-error";
import { InputFormControl } from "@/components/shared/input-form-control";
import { Typography } from "@/components/shared/typography";
import { VisitRatingInput } from "@/components/shared/visits/visit-rate/visit-rating-input";
import { Label, Textarea, View } from "@/components/ui";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

export function RateVisitForm({
  readonly,
  control,
}: {
  readonly: boolean;
  control: Control<{
    rating: number;
    comment: string;
  }>;
}) {
  const ratingState = control.getFieldState("rating");

  return (
    <View className="mt-4 flex flex-col gap-[22px]">
      <Typography variant="label-2xl" color="brand-primary">
        How did we do?
      </Typography>
      <Divider className="mx-6" />
      <Controller
        name="rating"
        control={control}
        render={({ field }) => (
          <VisitRatingInput
            rating={field.value}
            onChange={field.onChange}
            disabled={field.disabled ?? readonly}
          />
        )}
      />
      {ratingState.error?.message && (
        <FormControlError error={ratingState.error.message} />
      )}

      <Divider className="mx-6" />
      <View>
        <Label
          htmlFor="comment"
          className="mb-1 block text-center text-sm font-medium"
        >
          Tell us more!
        </Label>
        <InputFormControl
          disabled={readonly}
          name="comment"
          className="mt-3"
          control={control}
          component={Textarea}
          componentProps={{
            placeholder: "Compliment your cleaner or leave us feedback.",
            readOnly: readonly,
          }}
        />
      </View>
    </View>
  );
}
