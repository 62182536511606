import { Dialog, DialogContent } from "@/components/ui/dialog";
import { View, Image } from "@/components/ui";
import { Ratings } from "@/components/ui/ratings";
import type { Review } from "@/types/booking";
import { maskName } from "@/lib/helpers/string";
import { Typography } from "../../typography";

export const CustomerReviewsDetailModal = ({
  open,
  setOpen,
  review,
}: {
  open: boolean;
  setOpen(open: boolean): void;
  review: Review;
}) => {
  if (!review) return;

  const { clientName, ltv, rating, createdAt, images, service, description } =
    review;
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-80 rounded-xl p-4">
        <View>
          <Typography variant="bold-2xl">{maskName(clientName)}</Typography>
          <View className="-ml-0.5 mb-2 flex flex-row items-center gap-2 overflow-hidden">
            <Ratings variant="yellow" rating={rating} />
          </View>
          <View className="flex flex-row items-center gap-2">
            <Typography variant="body-md" color="secondary-text">
              {new Intl.DateTimeFormat("en-US", {
                month: "short",
                year: "numeric",
              }).format(createdAt)}
            </Typography>
            <Dot />
            <Typography variant="body-md" color="secondary-text">
              {ltv} lifetime bookings
            </Typography>
          </View>
          <Typography variant="body-md" color="secondary-text">
            Service Booked: {service}
          </Typography>
        </View>
        <View className="flex max-h-[60vh] flex-col gap-2 overflow-auto">
          <Typography variant="body-lg">{description}</Typography>
          {images.map((img) => (
            <Image src={img} width={320} height={220} />
          ))}
        </View>
      </DialogContent>
    </Dialog>
  );
};

function Dot() {
  return <View className="size-1 rounded-full bg-secondary-text" />;
}
