import { createRequestFactory } from "@/lib/request-factory";
import {
  CreateClientIssueRequestDocument,
  type CreateClientIssueRequestMutation,
  type CreateClientIssueRequestMutationVariables,
} from "@/__generated__/graphql";

export const useCreateClientIssueRequestStore = createRequestFactory<
  CreateClientIssueRequestMutation,
  CreateClientIssueRequestMutation,
  CreateClientIssueRequestMutationVariables
>({
  method: "mutation",
  graphqlDocument: CreateClientIssueRequestDocument,
});
