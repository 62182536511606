import { View, Button } from "@/components/ui";
import { Card, CardContent } from "@/components/ui/card";
import { SealPercent } from "@/components/shared/icons";
import { Typography } from "../typography";
import type { DiscountTypeEnum } from "@/containers/rewards/lib";
import { formatDateString } from "@/lib/helpers/date";
import { Divider } from "../divider";

type Props = {
  validUntil: string;
  rewardName: string;
  discountValues: string;
  discountType: DiscountTypeEnum;
  handleDetail: () => void;
};

export function RewardCard({
  validUntil,
  rewardName,
  discountValues,
  handleDetail,
  // discountType, // TODO: used to differentiate icons
}: Props) {
  return (
    <View className="relative overflow-hidden">
      <Card radius="xxl">
        <View className="absolute -left-1 bottom-10 z-10 size-4 -translate-x-2/4 -translate-y-2/4 rounded-e-full border border-border-high bg-background" />
        <View className="absolute -right-1 bottom-10 z-10 size-4 -translate-y-2/4 translate-x-2/4 rounded-s-full border border-border-high bg-background" />

        <CardContent className="flex flex-col gap-2 px-4 py-3">
          <View className="flex flex-row items-center">
            <SealPercent className="text-green-400" weight="fill" size="40" />

            <View className="flex-1 gap-1 pl-4">
              <Typography
                variant={{ md: "body-lg", sm: "body-md" }}
                color="foreground-intermediate"
              >
                {rewardName}
              </Typography>

              <Typography
                color="brand-primary"
                variant={{ md: "bold-2xl", sm: "bold-lg" }}
              >
                Max. discount of {discountValues}
              </Typography>
            </View>
          </View>
          <Divider dashed />
          <View className="flex flex-row items-center justify-between">
            <Typography variant="body-md" color="secondary-text">
              Valid until: {formatDateString(validUntil)}
            </Typography>
            {handleDetail && (
              <Button
                variant="primary"
                color="CTA"
                size="sm"
                rounded="full"
                onClick={handleDetail}
                children="Details"
              />
            )}
          </View>
        </CardContent>
      </Card>
    </View>
  );
}
