import { Banner } from "@/components/shared/banner";
import { Button } from "@/components/ui";
import { useIntl } from "react-intl";

export function ShareReferralBanner() {
  const intl = useIntl();

  return (
    <Banner
      type="referral"
      title={intl.formatMessage(
        {
          defaultMessage: "Give ${value}, Get ${value}",
          description: "promotion banner title",
          id: "homepage.promotionBannerTitle",
        },
        { value: 15 },
      )}
      description={intl.formatMessage(
        {
          defaultMessage:
            "Every friend you refer gets $15 off their first booking and you get $15",
          description: "promotion banner description",
          id: "homepage.promotionBannerDescription",
        },
        { value: 15 },
      )}
      action={
        <Button
          variant="primary"
          color="CTA"
          rounded="full"
          iconName="shareFat"
          children={intl.formatMessage(
            {
              defaultMessage: "Share",
              id: "share",
            },
            { value: 15 },
          )}
        />
      }
    />
  );
}
