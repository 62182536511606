import { Message } from "@/components/shared/message";
import { useBookingState } from "@/store/booking/useBookingState";
import { useEffect, useState } from "react";

export const BookingCountDown = ({ className }: { className?: string }) => {
  const {
    data: { reservationTimeStamp },
  } = useBookingState();
  const [timeLeft, setTimeLeft] = useState("");

  const formatter = new Intl.NumberFormat("default", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const diff = reservationTimeStamp - now;
      const min = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const sec = Math.floor((diff % (1000 * 60)) / 1000);
      setTimeLeft(
        diff <= 0
          ? "00:00"
          : `${formatter.format(min)}:${formatter.format(sec)}`,
      );
    });
    return () => clearInterval(timer);
  }, []);

  return (
    <Message
      variant="danger"
      className={className}
      message={`We're holding on to your chosen slot! Please complete your booking in ${timeLeft}`}
    />
  );
};
