import { PackageDepartmentEnum } from "@/__generated__/graphql";
import { Typography } from "@/components/shared/typography";
import { View } from "@/components/ui";
import { getWorkerLabel } from "@/lib/booking-lib";

export const EmptySlotCard = ({
  filtered,
  department = PackageDepartmentEnum.HomeCleaning,
}: {
  filtered?: boolean;
  department: PackageDepartmentEnum;
}) => {
  return (
    <View className="flex-1">
      <View className="rounded-lg bg-background-attenuated py-4">
        <Typography variant="body-sm" color="secondary-text" align="center">
          {filtered
            ? department === PackageDepartmentEnum.HomeCleaning
              ? "Selected cleaners not available.🥹 \nConsider a recurring package to lock in cleaners you like!🌟"
              : `Remove choose ${getWorkerLabel(
                  department,
                )} filter for more availability`
            : "No Service Today"}
        </Typography>
      </View>
    </View>
  );
};
