import { Button, View } from "@/components/ui";
import { useAuthState } from "@/store/auth";
import { AuthModals, LoginType } from "@/types/users";
import { Divider } from "@/components/shared/divider";
import { EmailForm } from "../email-login-modal/email-form";
import { useEmailLoginForm } from "@/store/auth/forms/useEmailLoginForm";
import { useSignInStore } from "@/store/auth/signIn";
import { GoogleIcon, AppleIcon } from "@/components/shared/icons";
import { cn } from "@/lib/utils";
import { useRoute } from "@/components/shared/router";
import { useEffect } from "react";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";
import { sendAnalyticData } from "@/lib/monitoring/analytics";
import { SignInProviderEnum } from "@/__generated__/graphql";

export const EmailLoginContent = ({
  isModal = true,
}: {
  isModal?: boolean;
}) => {
  const intl = useIntl();
  const { replace } = useRoute();
  const formReturn = useEmailLoginForm();
  const { reset, defaultValues } = formReturn;
  const { showModal, setType, setLoginByModal } = useAuthState();

  const { loading, fetch: emailLogin, error } = useSignInStore();

  useEffect(() => {
    setLoginByModal(isModal);
    setType(LoginType.EmailLogin);
  }, []);

  const onSubmitForm = (data: { email: string; password: string }) => {
    emailLogin(
      {
        requestPayload: {
          input: {
            email: data.email,
            password: data.password,
          },
        },
      },
      { selfHandleError: true },
    ).then((res) => {
      const data = res.data;
      if (res.error || !data?.userInfo) return;

      sendAnalyticData({
        name: "login",
        values: {
          method: SignInProviderEnum.Password,
        },
      });

      if (isModal) showModal(AuthModals.WELCOME);
      else replace({ pageKey: "homepage" });
    });
  };
  const openForgotPasswordModal = () => {
    if (isModal) showModal(AuthModals.EMAIL_LOGIN, AuthModals.FORGOT_PASSWORD);
    else showModal(AuthModals.FORGOT_PASSWORD);
  };
  const onGoToLogin = () => {
    reset(defaultValues);
    if (isModal) showModal(AuthModals.LOGIN);
    else setType(LoginType.Login);
  };
  const onGoToSingUp = () => {
    reset(defaultValues);
    if (isModal) showModal(AuthModals.SIGN_UP);
    else replace({ pageKey: "signup" });
  };

  return (
    <View className={cn("flex flex-col", isModal ? "gap-2" : "gap-4")}>
      <View className="pb-3">
        <Typography>
          {intl.formatMessage({
            defaultMessage: "Sign In",
            id: "sign-in",
          })}
        </Typography>
        <Typography variant="body-lg" color="foreground-intermediate">
          {intl.formatMessage({
            defaultMessage:
              "To continue the booking process, you need to Login or create a new account",
            id: "loginRegister.description",
          })}
        </Typography>
      </View>
      <EmailForm
        formReturn={formReturn}
        loading={loading}
        onSubmitForm={onSubmitForm}
        errorMessage={error || ""}
        onForgotPassword={openForgotPasswordModal}
      />

      <Button
        variant="secondary"
        color="CTA2"
        fullWidth="full"
        rounded="full"
        className="mt-2"
        onClick={onGoToLogin}
        children={intl.formatMessage({
          defaultMessage: "Sign in using Phone Number",
          id: "signIn.phoneNumber",
        })}
      />
      <Divider className="my-6">
        {intl.formatMessage({
          defaultMessage: "or continue with",
          id: "or-continue",
        })}
      </Divider>

      <View className="flex flex-row justify-center gap-3">
        <GoogleIcon className="size-10 cursor-pointer" />
        <AppleIcon className="size-10 cursor-pointer" />
      </View>
      <View className="flex flex-row justify-center gap-2 pt-4">
        <Typography variant="body-md" color="secondary-text">
          {intl.formatMessage({
            defaultMessage: "Don't have an account?",
            id: "no-account-question",
          })}
        </Typography>
        <Typography
          variant="body-md"
          className="cursor-pointer color-link"
          onClick={onGoToSingUp}
        >
          {intl.formatMessage({
            defaultMessage: "Sign Up",
            id: "sign-up",
          })}
        </Typography>
      </View>
    </View>
  );
};
