import * as Sentry from "@sentry/react";
import { app } from "@/config/env";
import { isLocal } from "@/lib/utils";

export function init(): void {
  if (isLocal) {
    return;
  }

  if (app.SENTRY_DSN) {
    Sentry.init({
      environment: app.ENV,
      dsn: app.SENTRY_DSN,
      tracesSampleRate: 1.0,
      autoSessionTracking: true,
    });
  }
}

type CollectErrorOptions = {
  context?: { name: string; values: Record<string, unknown> };
};

export function collectError(
  error: unknown,
  options?: CollectErrorOptions,
): void {
  if (isLocal) {
    return;
  }

  Sentry.captureException(error, (scope) => {
    if (options?.context) {
      scope.setContext(options.context.name, options.context.values);
    }
    return scope;
  });
}

export { Sentry }
