import { useMemo } from "react";
import { Drawer, DrawerContent } from "@/components/ui/drawer/nav";
import { SignOut } from "@/components/shared/icons";
import { View, Button } from "@/components/ui";
import { useAuthState } from "@/store/auth";
import { Link } from "@/components/shared/link";
import { Divider } from "@/components/shared/divider";
import { GenerateMenu } from "../generate-menu";
import { GetTheAppNow } from "../get-the-app-now";
import { NavbarListItem } from "../navbar-list-item";
import { UserAvatar } from "../user-avatar";
import { cn } from "@/lib/utils";
import { useRoute } from "@/components/shared/router";
import { useIntl } from "react-intl";
import { AuthModals } from "@/types/users";

export const UserDrawer = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const {
    data: { jwtInfo, userInfo },
  } = useAuthState();
  const { push } = useRoute();
  const isLoggedIn = useMemo(() => !!jwtInfo.jwt, [jwtInfo]);
  const intl = useIntl();
  const { showModal } = useAuthState();

  return (
    <Drawer open={open} onOpenChange={setOpen} direction="top">
      <DrawerContent className="top-0 border-none bg-brand-static">
        <View
          className={`grid p-4 pt-[152px] font-light color-brand-on-static ${
            isLoggedIn ? "gap-3" : "gap-6"
          }`}
        >
          {isLoggedIn && (
            <>
              <Link href="profile">
                <NavbarListItem
                  icon={<UserAvatar />}
                  title={userInfo.firstName}
                  onClick={() => setOpen(false)}
                />
              </Link>
              {/* TODO: Disable notification on first launch */}
              {/* <Divider className="opacity-25" />
              <Link href="/notifications">
                <NavbarListItem
                  icon={<Bell size={22} />}
                  badge={3}
                  title="Notification"
                />
              </Link> */}
            </>
          )}

          {!isLoggedIn && (
            <View className="grid grid-cols-2 gap-2">
              <Button
                variant="secondary"
                rounded="full"
                className="border border-white"
                onClick={() => {
                  setOpen(false);
                  push({ pageKey: "login" });
                }}
                children={intl.formatMessage({
                  defaultMessage: "Sign In",
                  id: "sign-in",
                })}
              />
              <Button
                variant="primary"
                color="CTA"
                rounded="full"
                onClick={() => {
                  setOpen(false);
                  push({ pageKey: "signup" });
                }}
                children={intl.formatMessage({
                  defaultMessage: "Sign Up",
                  id: "sign-up",
                })}
              />
            </View>
          )}

          <Divider className="opacity-25" />
          <View onClick={() => setOpen(false)}>
            <GenerateMenu />
          </View>
          <Divider className="opacity-25" />

          {isLoggedIn && (
            <>
              <NavbarListItem
                icon={<SignOut size={22} />}
                title={intl.formatMessage({
                  defaultMessage: "Logout",
                  id: "logout",
                })}
                showTrailingIcon={false}
                onClick={() => {
                  setOpen(false);
                  showModal(AuthModals.LOGOUT);
                }}
              />
              <Divider className="opacity-25" />
            </>
          )}

          <GetTheAppNow
            size="large"
            className={cn("pt-4", {
              "mt-16": !isLoggedIn,
            })}
          />
        </View>

        <View className="relative flex items-center justify-center">
          <Button
            variant="tertiary"
            rounded="full"
            className="absolute top-4"
            onClick={() => setOpen(false)}
            iconName="x"
            children={intl.formatMessage({
              defaultMessage: "Close",
              id: "close",
            })}
          />
        </View>
      </DrawerContent>
    </Drawer>
  );
};
