import { View, Button } from "@/components/ui";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { IconButton } from "@/components/ui/icon-button";
import { Typography } from "../typography";
import { Link } from "@/components/shared/link";
import { copyClipboard } from "@/lib/helpers/copy-clipboard";

export function RewardModal({
  code,
  validUntil,
  open,
  handleClose,
  description,
}: {
  code: string;
  validUntil: string;
  open: boolean;
  handleClose: () => void;
  description: string;
}) {
  const rewardDescription = (description: string): string[] => {
    const regex = /<[^>]*>([^<]+)<\/[^>]*>/g;
    const extractedText = Array.from(
      description.matchAll(regex),
      (match) => match[1],
    );

    return extractedText;
  };
  const extractedDescription = rewardDescription(description);
  const getServiceBookingUrl = (description: string) => {
    const serviceUrlMap: { [key: string]: string } = {
      "home cleaning": "/service-detail/home-cleaning",
      mattress: "/service-detail/mattress-cleaning",
      aircon: "/service-detail/aircon-service",
      beauty: "/service-detail/home-beauty",
      sofa: "/service-detail/sofa-cleaning",
      rug: "/service-detail/rug-cleaning",
    };

    const serviceType = description
      .match(/\b(rug|mattress|aircon|beauty|sofa|home cleaning)\b/i)?.[0]
      .toLowerCase();

    return serviceType && serviceType in serviceUrlMap
      ? serviceUrlMap[serviceType]
      : "/";
  };

  const handleCopy = () => {
    copyClipboard(code);
  };

  return (
    <BottomDrawerModal open={open}>
      <View>
        <View className="px-4">
          <View className="py-3">
            <Typography variant="label-2xl">Promo Details</Typography>
          </View>
          <Typography variant="label-2xl">{extractedDescription[0]}</Typography>

          <View className="gap-2 py-4">
            <View className="flex flex-row justify-between border border-dashed border-greyBlue-300 px-4 py-3">
              <Typography variant="body-xl">{code}</Typography>
              <IconButton
                iconName="copy"
                onClick={handleCopy}
              />
            </View>
            <Typography variant="body-lg" color="secondary-text">
              Valid until: {validUntil}
            </Typography>
          </View>
          <Typography variant="body-lg" color="secondary-text">
            Terms & Conditions:
          </Typography>

          {extractedDescription.slice(1).map((item, index) => (
            <View key={index} className="flex flex-row gap-1">
              <Typography variant="body-lg" color="secondary-text">
                {index + 1}.
              </Typography>
              <Typography variant="body-lg" color="secondary-text">
                {item}
              </Typography>
            </View>
          ))}
        </View>
        <View className="flex flex-col gap-2 py-4">
          <Link href={getServiceBookingUrl(description)}>
            <View className="flex h-10 items-center justify-center rounded-lg bg-secondary-button">
              <Typography className="text-base font-medium text-white">
                Book Now
              </Typography>
            </View>
          </Link>
          <Button
            variant="secondary"
            color="CTA2"
            onClick={handleClose}
            children="Close"
          />
        </View>
      </View>
    </BottomDrawerModal>
  );
}
