import { View } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { ArrowLeft } from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";
import { UpdateAddressForm } from "../UpdateAddressForm";
import type { UpdateAddressProps } from "..";

export const UpdateAddressScreen = ({
  control,
  loading,
  onAddAddressByPostalCode,
  onDeleteAddress,
  postalCodeLoading,
  onSubmit,
}: UpdateAddressProps) => {
  return (
    <View>
      <View className="static flex flex-row items-center justify-between border-b md:mb-8 md:pb-4">
        <View className="flex flex-row items-center gap-2 p-4 md:p-0">
          <Link href="/profile/addresses">
            <ArrowLeft className="size-5" />
          </Link>
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            Update Address
          </Typography>
        </View>
      </View>

      <UpdateAddressForm
        control={control}
        postalCodeLoading={postalCodeLoading}
        onAddAddressByPostalCode={onAddAddressByPostalCode}
        onDeleteAddress={onDeleteAddress}
        loading={loading}
        onSubmit={onSubmit}
      />
    </View>
  );
};
