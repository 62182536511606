import type {
  ClientCancelReservationMutation,
  ClientCancelReservationMutationVariables,
} from "@/__generated__/graphql";
import { ClientCancelReservationDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";

export const useClientCancelReservationStore = createRequestFactory<
  ClientCancelReservationMutation,
  ClientCancelReservationMutation,
  ClientCancelReservationMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientCancelReservationDocument,
});
