import {
  CreditAccountByIdDocument,
  type CreditAccountByIdQuery,
  type CreditAccountByIdQueryVariables,
} from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";
import type { ClientCreditAccount } from "@/types/profile";

type Response = {
  creditAccount: ClientCreditAccount;
};

export const useCreditAccountByIdStore = createRequestFactory<
  CreditAccountByIdQuery,
  Response,
  CreditAccountByIdQueryVariables
>({
  method: "query",
  graphqlDocument: CreditAccountByIdDocument,
  fetchPolicy: "network-only",
  transformFunction: (res) => {
    if (res.creditAccountById) {
      const { id, balance, creditType, transactions } = res.creditAccountById;
      const creditAccount = {
        id,
        balance,
        creditType,
        transactions,
      };
      return { creditAccount } as Response;
    }
    throw new Error("failed to fetch creditAccount");
  },
});
