import { createRequestFactory } from "@/lib/request-factory";
import {
  SignOutDocument,
  type SignOutMutation,
  type SignOutMutationVariables,
} from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";
import { storage } from "@/lib/storage";
import { DEFAULT_JWT_DATA, DEFAULT_USER_DATA } from "@/constants";

type Response = {
  message: string;
};

export const useSignOutStore = createRequestFactory<
  SignOutMutation,
  Response,
  SignOutMutationVariables
>({
  method: "mutation",
  graphqlDocument: SignOutDocument,
  transformFunction: (res) => {
    if (res.signOut?.user) {
      return { message: "succeed" };
    }

    throw new Error("Sign Out Failed");
  },
  onFetchSuccess: () => {
    const { setUserInfo, setJwtInfo } = useAuthState.getState();
    setUserInfo(DEFAULT_USER_DATA);
    setJwtInfo(DEFAULT_JWT_DATA);
    storage.deleteAll();
  },
});

export function logout() {
  return useSignOutStore
    .getState()
    .fetch()
    .catch((error) => {
      console.log("Unable to handle logout mutation", error);
    })
    .finally(() => {
      const { setUserInfo, setJwtInfo } = useAuthState.getState();
      setUserInfo(DEFAULT_USER_DATA);
      setJwtInfo(DEFAULT_JWT_DATA);
      storage.deleteAll();
    });
}
