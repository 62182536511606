import { View } from "@/components/ui";
import type { ServiceName, UpholsteryServiceName } from "@/types/service";
import { UpholsteryServiceNames } from "@/types/service";
import { Column, Container } from "@/components/ui/layout";
import { useBookingRouterInterceptor } from "@/components/hooks/use-booking-route";
import { getPlatform } from "@/lib/utils";
import { WebBackButtonHeader } from "@/components/shared/back-button";
import { HomeCleaningBooking } from "./services/home-cleaning-booking";
import { UpholsteryCleaningBooking } from "./services/upholstery-cleaning-booking";
import { BookingNavigation } from "./booking-navigation";
import { useBookingState } from "@/store/booking/useBookingState";
import { useEffect, useMemo } from "react";
import { getDepartment } from "@/lib/booking-lib";
import { AirconBooking } from "./services/aircon-booking";
import { HomeBeautyBooking } from "./services/home-beauty-booking";
import { capitalizeFirstLetter } from "@/lib/helpers/string";
import { PackageServiceTypeMapping } from "@/lib/service/helpers";
import type { PackageServiceTypeEnum } from "@/__generated__/graphql";

const ServiceBooking = ({ name }: { name: ServiceName }) => {
  if (UpholsteryServiceNames.includes(name as UpholsteryServiceName)) {
    return <UpholsteryCleaningBooking name={name as UpholsteryServiceName} />;
  }

  switch (name) {
    case "home-cleaning":
      return <HomeCleaningBooking />;
    case "aircon-service":
      return <AirconBooking />;
    case "home-beauty":
      return <HomeBeautyBooking />;
  }
};

export function Booking({ name }: { name: ServiceName }) {
  useBookingRouterInterceptor();
  const platform = getPlatform();
  const {
    data: { packageDetailsFilter },
    setBookingState,
  } = useBookingState();

  useEffect(() => {
    setBookingState({
      department: getDepartment(name),
    });
  }, []);

  const backButtonTitle = useMemo(() => {
    if (name === "home-beauty") {
      return "Manicure Pedicure";
    }

    if (name === "pram-seat") {
      return "Pram & Car Seat";
    }

    if (name === "aircon-service")
      return `Aircon ${
        PackageServiceTypeMapping?.[
          packageDetailsFilter
            ?.serviceTypes?.[0] as unknown as PackageServiceTypeEnum
        ] || ""
      }`;
    return name
      .split("-")
      .map((str) => capitalizeFirstLetter(str))
      .join(" ");
  }, [name]);

  return (
    <Container>
      <Column desktop="8">
        <WebBackButtonHeader title={backButtonTitle} />
        <ServiceBooking name={name} />
      </Column>

      <Column desktop="4">
        {platform === "web" && (
          <View className="py-4">
            <BookingNavigation name={name} />
          </View>
        )}
      </Column>
    </Container>
  );
}
