import type { PackageDetailsFilter } from "@/__generated__/graphql";
import { PackageDepartmentEnum } from "@/__generated__/graphql";
import type {
  BookingInfoFormData,
  BookingInfoFormReturn,
} from "@/containers/booking-info/useBookingInfoForm";
import type {
  AppliedPromo,
  PackageFrequencyGroupEnum,
  ServiceDetail,
  ServiceReviews,
} from "@/types/booking";
import { create } from "zustand";
import type { PastWorker, TimeSlot } from "@/types/booking";
import type { Address } from "@/types/users";
import type {
  PackageDetailFragmentFragment,
  ReservationDetailFragmentFragment,
} from "@/__generated__/graphql";
import { addDays } from "date-fns";
import ServiceDetailData from "@/containers/service-detail/service-detail.json";
import { defaultServiceReviews } from "@/constants/booking";

export const defaultServiceDetails = {
  title: "",
  ratingTotal: 0,
  bookingTotal: 0,
  descriptions: [],
  image: "",
  detailImages: [],
  faqs: [],
  rateFrom: 0,
  rateTo: 0,
  averageRating: 0,
  startPrice: 0,
  guarantee: true,
};

type BookingState = {
  // state "department" is more frequently used so move it to out.
  packageDetailsFilter: Omit<PackageDetailsFilter, "department"> & {
    frontEndFrequency?: PackageFrequencyGroupEnum;
  };
  // general
  department: PackageDepartmentEnum;
  serviceDetails: Record<"home-cleaning" | "mattress-cleaning", ServiceDetail>;
  serviceReviews: ServiceReviews;
  currentPath: string;
  //   select slot
  startDate: Date;
  endDate: Date;
  timeSlots: Record<string, TimeSlot[]> | null;
  selectedSlot: TimeSlot | null;
  reservationTimeStamp: number;
  selectedWorkers: PastWorker[];
  selectedAddress: Address | null;
  packageDetails: PackageDetailFragmentFragment[];
  selectedPackageIds: string[];
  reservations: ReservationDetailFragmentFragment[];
  //   booking info
  bookingInfoFormReturn: BookingInfoFormReturn | null;
  bookingInfo: BookingInfoFormData | null;
  promo: AppliedPromo | null;
  // confirm
  confirmedWorker: {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string | null;
    workerRating: number;
  } | null;
  //   verticals
  homeBeautyFreeGelServiceIds: string[]; // to identify services with Free Gel Removal Checked
  homeCleaningDurationModalOpen: boolean;
  choosePackageModalOpen: boolean;
};

export const useBookingState = create<{
  data: BookingState;
  setBookingState(params: Partial<BookingState>): void;
}>((set, get) => ({
  data: {
    serviceReviews: defaultServiceReviews,
    packageDetailsFilter: {},
    serviceDetails: ServiceDetailData,
    // general
    department: PackageDepartmentEnum.HomeCleaning,
    currentPath: "",
    // select slot
    timeSlots: null,
    selectedSlot: null,
    selectedAddress: null,
    startDate: new Date(),
    endDate: addDays(new Date(), 6),
    reservationTimeStamp: 0,
    selectedWorkers: [],
    packageDetails: [],
    selectedPackageIds: [],
    reservations: [],
    // confirm
    confirmedWorker: null,
    // booking info
    promo: null,
    bookingInfoFormReturn: null,
    bookingInfo: null,
    // home-cleaning
    homeCleaningDurationModalOpen: false,
    choosePackageModalOpen: false,
    homeBeautyFreeGelServiceIds: [],
  },
  setBookingState: (params: Partial<BookingState>) => {
    set({ data: { ...get().data, ...params } });
  },
}));
