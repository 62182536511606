import {
  useVisitIssueStore,
  VisitIssueType,
} from "@/store/report-issue/visit-issue/visitIssue";
import { getWorkerLabel } from "@/lib/service/helpers";
import { useVisitStore } from "@/store/visits/visitStore";
import { PackageDepartmentEnum } from "@/__generated__/graphql";
import { useEffect, useMemo, useState } from "react";
import { InfoDialog } from "@/components/shared/info-dialog";
import { VisitIssueScreen } from "./VisitIssueScreen";
import { useCreateClientIssueRequestStore } from "@/store/report-issue/visit-issue/useCreateClientIssueRequest";
import { useReportIssueForm } from "@/store/report-issue/forms/useReportIssueForm";
import { ConfirmationDialog } from "@/components/shared/confirmation-dialog";
import { useCreateClientIssueWithTokenRequestStore } from "@/store/report-issue/visit-issue/useCreateClientIssueWithTokenRequest";
import { getErrorMessage } from "@/lib/helpers/string";
import { showToast } from "@/components/ui/toast/show-toast";
import { useVisitTokenStore } from "@/store/visits/visitTokenStore";

const getContentIssueType = (
  type: VisitIssueType | null,
  department: PackageDepartmentEnum,
) => {
  if (type === VisitIssueType.changeVisitWorker) {
    return {
      title: `Change ${getWorkerLabel(department)}`,
      description:
        "We can definitely try and find another cleaner for you. First, could you please let us know the reason for the change?",
    };
  }

  return {
    title: "Report an Issue",
    description:
      "Sorry to hear you’re having issue with the visit. We can definitely try and find solutions for that. First, could you please let us know the details?",
  };
};

export function VisitIssueContainer({
  type = "auth",
}: {
  type?: "token" | "auth";
}) {
  const {
    data: { showModal, issueType, visitId },
    closeVisitIssueModal,
  } = useVisitIssueStore();
  const { fetch: reportWithToken, loading: loadingWithToken } =
    useCreateClientIssueWithTokenRequestStore();
  const { fetch: reportIssue, loading } = useCreateClientIssueRequestStore();

  const form = useReportIssueForm();

  const { data: visitData } =
    type === "token" ? useVisitTokenStore() : useVisitStore();

  const { department, lastReportDate, visitToken } = useMemo(() => {
    const department =
      visitData?.department ?? PackageDepartmentEnum.HomeCleaning;
    const lastReportDate = visitData?.reportDate;
    const visitToken = visitData?.token;

    return {
      department,
      lastReportDate,
      visitToken,
    };
  }, [visitData]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { title, description } = getContentIssueType(issueType, department);

  useEffect(() => {
    if (issueType === VisitIssueType.reportVisit && lastReportDate) {
      const dateReportTime = lastReportDate.getTime();
      const todayTime = new Date().getTime();

      const shouldShowConfirmation = todayTime > dateReportTime;

      setShowConfirmation(shouldShowConfirmation);
    }
  }, [lastReportDate, issueType]);

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      if (visitToken) {
        const res = await reportWithToken({
          requestPayload: {
            input: {
              comment: values.comment,
              issueId: values.reason,
              token: visitToken,
            },
          },
        });

        if (res.error) {
          throw new Error(res.error);
        }
      } else {
        if (!visitId) {
          return;
        }

        const res = await reportIssue(
          {
            requestPayload: {
              input: {
                comment: values.comment,
                issueId: values.reason,
                visitId,
              },
            },
          },
          {
            selfHandleError: true,
          },
        );

        if (res.error) {
          throw new Error(res.error);
        }
      }

      setShowSuccessModal(true);
    } catch (error) {
      const errMessage = getErrorMessage(error, "Unable to report visit issue");
      showToast({ type: "error", title: errMessage });
    }
  });

  const onClose = (shouldOpenVisitDetail: boolean) => () => {
    setShowSuccessModal(false);
    closeVisitIssueModal(shouldOpenVisitDetail);
    form.reset();
  };

  return (
    <>
      <VisitIssueScreen
        title={title}
        description={description}
        onClose={onClose(true)}
        onSubmit={onSubmit}
        form={form}
        open={showModal}
        loading={loading || loadingWithToken}
      />
      <InfoDialog
        open={showSuccessModal}
        onClose={onClose(false)}
        subtitle="Our support specialist will be getting in touch with you soon"
      />
      <ConfirmationDialog
        cancelText="Cancel"
        title="Report an Issue"
        description="We have received your previous submission, are you sure you want to submit it again?"
        onConfirm={() => setShowConfirmation(false)}
        onCancel={onClose(true)}
        open={showModal && showConfirmation}
      />
    </>
  );
}
