import type {
  ClientViewVouchersQuery,
  ClientViewVouchersQueryVariables,
} from "@/__generated__/graphql";
import {
  ClientViewVouchersDocument,
  VoucherDiscountTypeEnum,
} from "@/__generated__/graphql";
import { DiscountTypeEnum } from "@/containers/rewards/lib";
import { formatPrice } from "@/lib/helpers/number";

import { createRequestFactory } from "@/lib/request-factory";
import type { VoucherItem } from "@/types/reward";


export const useAvailableClientVouchersStore = createRequestFactory<
  ClientViewVouchersQuery,
  VoucherItem[],
  ClientViewVouchersQueryVariables
>(
  {
    method: "query",
    graphqlDocument: ClientViewVouchersDocument,
    transformFunction(data) {
      return data.clientViewVouchers.map((voucher) => {
        return {
          id: voucher.id,
          expirationDate: voucher.validUntil,
          description: voucher.description ?? "",
          discountValues: `${voucher.discountType === VoucherDiscountTypeEnum.Fixed ? formatPrice(voucher.discountValue, 0) : `${voucher.discountValue}%`}`,
          discountType:
            voucher.discountType === VoucherDiscountTypeEnum.Fixed
              ? DiscountTypeEnum.FIXED
              : DiscountTypeEnum.PERCENT,
        } satisfies VoucherItem;
      });
    },
  },
  [],
);
