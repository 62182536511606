import { View, Input, Button } from "@/components/ui";
import { InputFormControl } from "@/components/shared/input-form-control";
import { PhoneNumberInput } from "@/components/shared/phone-number-input";
import { useFieldArray, useFormState } from "react-hook-form";
import { defaultNationCode } from "@/constants";
import { PrimaryAccountCheckbox } from "../primary-account-checkbox";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { IconButton } from "@/components/ui/icon-button";
import type { CreateContactProps } from ".";

export const CreateContactForm = ({
  control,
  onSubmit,
  createContactLoading,
  trigger,
}: CreateContactProps) => {
  const intl = useIntl();
  const { errors } = useFormState({ control });

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: "phoneNumber",
  });

  return (
    <View className="p-4 md:p-0">
      <InputFormControl
        name="firstName"
        label={intl.formatMessage({
          defaultMessage: "First Name",
          id: "first-name",
        })}
        className="mt-2"
        control={control}
        component={Input}
        componentProps={{
          placeholder: intl.formatMessage({
            defaultMessage: "Your First Name",
            id: "enter-first-name",
          }),
        }}
      />
      <InputFormControl
        name="lastName"
        label={intl.formatMessage({
          defaultMessage: "Last Name",
          id: "last-name",
        })}
        className="mt-2"
        control={control}
        component={Input}
        componentProps={{
          placeholder: intl.formatMessage({
            defaultMessage: "Your Last Name",
            id: "enter-last-name",
          }),
        }}
      />

      <View className="mt-2">
        <Typography variant="body-md">
          {intl.formatMessage({
            defaultMessage: "Email",
            id: "email",
          })}
        </Typography>
        <View className="flex flex-col gap-1">
          {emailFields.map((field, index) => (
            <View
              key={field.id}
              className="mt-2 flex flex-row items-center gap-2"
            >
              <InputFormControl
                className="flex-1 pb-0"
                name={`email.${index}.value`}
                control={control}
                component={Input}
                componentProps={{
                  placeholder: "example@mail.com",
                }}
              />
              {index === 0 ? (
                <IconButton
                  iconName="plus"
                  disabled={emailFields.length >= 3}
                  onClick={() => appendEmail({ value: "" })}
                />
              ) : (
                <IconButton
                  iconName="minus"
                  onClick={() => removeEmail(index)}
                />
              )}
            </View>
          ))}
        </View>
      </View>

      <View className="mt-2">
        <View className="flex flex-col gap-1">
          {phoneFields.map((field, index) => (
            <View key={field.id} className="mt-2 flex flex-row items-end gap-2">
              <PhoneNumberInput
                trigger={trigger}
                label={intl.formatMessage({
                  defaultMessage: "Phone",
                  id: "phone-number",
                })}
                className="flex-1"
                control={control}
                errors={errors}
                phoneNumberName={`phoneNumber.${index}.value`}
                nationCodeName={`phoneNumber.${index}.nationCode`}
              />
              {index === 0 ? (
                <IconButton
                  iconName="plus"
                  disabled={emailFields.length >= 3}
                  onClick={() =>
                    appendPhone({ value: "", nationCode: defaultNationCode })
                  }
                />
              ) : (
                <IconButton
                  iconName="minus"
                  onClick={() => removePhone(index)}
                />
              )}
            </View>
          ))}
        </View>
      </View>

      <PrimaryAccountCheckbox control={control} />

      <View className="mt-2 flex flex-row items-end gap-2 md:mt-1">
        <Button
          variant="primary"
          color="CTA"
          rounded="full"
          loading={createContactLoading}
          onClick={onSubmit}
          className="native:hidden ml-auto mt-2"
          children={intl.formatMessage({
            defaultMessage: "Save",
            id: "save",
          })}
        />
      </View>
    </View>
  );
};
