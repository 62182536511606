import { Image, View } from "@/components/ui";
import NoVisit from "@/assets/images/visits-no-visit.svg";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export function EmptyVisit({ type }: { type: "upcoming" | "history" }) {
  const intl = useIntl();
  return (
    <View className="flex flex-col items-center gap-4 py-8">
      <Image src={NoVisit} width={120} height={120} />
      <Typography variant="label-xl" color="foreground-intermediate">
        {intl.formatMessage(
          {
            defaultMessage: "You don't have any {type} visits",
            id: `emptyVisit.${type}`,
          },
          { type },
        )}
      </Typography>
    </View>
  );
}
