import { createRequestFactory } from "@/lib/request-factory";
import {
  RescheduleVisitWithTokenDocument,
  type RescheduleVisitWithTokenMutation,
  type RescheduleVisitWithTokenMutationVariables,
} from "@/__generated__/graphql";

export const useClientRescheduleWithToken = createRequestFactory<
  RescheduleVisitWithTokenMutation,
  NonNullable<
    RescheduleVisitWithTokenMutation["rescheduleVisitWithToken"]
  >["visit"],
  RescheduleVisitWithTokenMutationVariables
>({
  method: "mutation",
  graphqlDocument: RescheduleVisitWithTokenDocument,
  transformFunction(data) {
    if (data.rescheduleVisitWithToken?.visit) {
      return data.rescheduleVisitWithToken.visit;
    }

    throw new Error("Failed to reschedule visit");
  },
});
