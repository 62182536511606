import * as React from "react";
import type { SVGProps } from "react";
const SvgServiceAllIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V15C2 17.2091 3.79086 19 6 19H15C17.2091 19 19 17.2091 19 15V6C19 3.79086 17.2091 2 15 2H6ZM6 4C4.89543 4 4 4.89543 4 6V15C4 16.1046 4.89543 17 6 17H15C16.1046 17 17 16.1046 17 15V6C17 4.89543 16.1046 4 15 4H6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 2C22.7909 2 21 3.79086 21 6V15C21 17.2091 22.7909 19 25 19H34C36.2091 19 38 17.2091 38 15V6C38 3.79086 36.2091 2 34 2H25ZM25 4C23.8954 4 23 4.89543 23 6V15C23 16.1046 23.8954 17 25 17H34C35.1046 17 36 16.1046 36 15V6C36 4.89543 35.1046 4 34 4H25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 21C3.79086 21 2 22.7909 2 25V34C2 36.2091 3.79086 38 6 38H15C17.2091 38 19 36.2091 19 34V25C19 22.7909 17.2091 21 15 21H6ZM6 23C4.89543 23 4 23.8954 4 25V34C4 35.1046 4.89543 36 6 36H15C16.1046 36 17 35.1046 17 34V25C17 23.8954 16.1046 23 15 23H6Z"
      fill="currentColor"
    />
    <path
      d="M30.2293 22H29.7707C29.3324 26.0863 26.0863 29.3324 22 29.7707V30.2293C26.0863 30.6676 29.3324 33.9137 29.7707 38H30.2293C30.6676 33.9137 33.9137 30.6676 38 30.2293V29.7707C33.9137 29.3324 30.6676 26.0863 30.2293 22Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgServiceAllIcon;
