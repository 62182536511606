import { View, Button } from "@/components/ui";
import { IfElse } from "@/components/shared/if-else";
import { useIntl } from "react-intl";

export const PaymentActionsButton = ({
  onRemoveConfirmation,
  onRemoveToUpdate,
  hasPayment,
  onConfirm,
  loading,
  onPay,
  hasCreditCardPayment,
  isUpdateCard,
  onCancelUpdate,
  onConfirmUpdateCard
}: {
  onRemoveConfirmation: () => void;
  onRemoveToUpdate: () => void;
  hasPayment: boolean;
  onConfirm: () => void;
  loading: boolean;
  onPay: () => Promise<void>;
  hasCreditCardPayment: boolean;
  isUpdateCard: boolean;
  onCancelUpdate: () => void
  onConfirmUpdateCard: () => void
}) => {
  const intl = useIntl();
  return (
    <View className="native:p-4 mt-4 flex w-full flex-row gap-2 md:p-0">
      <IfElse if={!hasPayment}>
        <Button
          variant="primary"
          color="CTA"
          onClick={onConfirm}
          loading={loading}
          className="w-full md:ml-auto md:w-auto"
          children="Confirm"
        />
      </IfElse>
      <IfElse if={hasPayment && !isUpdateCard}>
        <View className="flex flex-1 flex-col gap-2 md:flex-row">
          <Button
            variant="secondary"
            color="CTA2"
            onClick={onRemoveConfirmation}
            className="md:ml-auto"
            loading={loading}
            children={intl.formatMessage({
              defaultMessage: "Remove Credit Card",
              id: "remove-card",
            })}
          />
          <Button
            variant="primary"
            color="CTA"
            onClick={hasCreditCardPayment ? onPay : onRemoveToUpdate}
            loading={loading}
            children={
              hasCreditCardPayment
                ? intl.formatMessage({
                    defaultMessage: "Pay",
                    id: "pay",
                  })
                : intl.formatMessage({
                    defaultMessage: "Update Credit Card",
                    id: "update-card",
                  })
            }
          />
        </View>
      </IfElse>
      <IfElse if={hasPayment && isUpdateCard}>
        <View className="flex flex-1 flex-col gap-2 md:flex-row">
          <Button
            variant="secondary"
            color="CTA2"
            onClick={onCancelUpdate}
            className="md:ml-auto"
            loading={loading}
            children="Cancel"
          />
          <Button
            variant="primary"
            color="CTA"
            onClick={onConfirmUpdateCard}
            loading={loading}
            children="Confirm Update"
          />
        </View>
      </IfElse>
    </View>
  );
};
