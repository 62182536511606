import { View, Button } from "@/components/ui";
import { SignUpForm } from "../sign-up-modal/sign-up-form";
import { Divider } from "@/components/shared/divider";
import { useRoute } from "@/components/shared/router";
import { useSignUpForm } from "@/store/auth/forms/useSignUpForm";
import { useAuthState } from "@/store/auth";
import { AuthModals, LoginType } from "@/types/users";
import { useClientVerifyPhoneNumberStore } from "@/store/auth/clientVerifyPhoneNumber";
import { cn } from "@/lib/utils";
import { useEffect } from "react";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";

export const SignUpContent = ({ isModal = true }: { isModal?: boolean }) => {
  const { replace } = useRoute();
  const formReturn = useSignUpForm();
  const { reset, defaultValues } = formReturn;
  const intl = useIntl();
  const {
    loading,
    fetch: verifyPhoneNumber,
    error,
  } = useClientVerifyPhoneNumberStore();

  const { setPhone, setType, showModal, setLoginByModal, setSignUpFormReturn } =
    useAuthState();

  useEffect(() => {
    setLoginByModal(isModal);
    setSignUpFormReturn(formReturn);
  }, []);

  const onSubmitForm = (data: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    nationCode: string;
    email: string;
  }) => {
    setType(LoginType.SignUp);
    const code = data.nationCode.split("/")[1];
    setPhone(`+${code}${data.phoneNumber}`);
    reset(data);
    verifyPhoneNumber(
      {
        requestPayload: {
          input: {
            phone: `+${code}${data.phoneNumber}`,
            onSignUp: true,
            enableWhatsappOtp: true,
          },
        },
      },
      { selfHandleError: true },
    ).then((res) => {
      if (res.error) return;
      showModal(AuthModals.OTP);
    });
  };
  const onGoToLogin = () => {
    reset(defaultValues);
    if (isModal) showModal(AuthModals.LOGIN);
    else replace({ pageKey: "login" });
  };

  return (
    <View className={cn("flex flex-col", isModal ? "gap-2" : "gap-4")}>
      <View className="pb-3">
        <Typography variant="label-2xl">
          {" "}
          {intl.formatMessage({
            defaultMessage: "Sign Up",
            id: "sign-up",
          })}
        </Typography>
        <Typography variant="label-lg" color="foreground-intermediate">
          {intl.formatMessage({
            defaultMessage:
              "To continue the booking process, you need to Login or create a new account",
            id: "loginRegister.description",
          })}
        </Typography>
      </View>
      <SignUpForm
        signUpFormReturn={formReturn}
        onSubmitForm={onSubmitForm}
        errorMessage={error || ""}
        loading={loading}
      />
      <Divider>
        {intl.formatMessage({
          defaultMessage: "Have an account already?",
          id: "existing-account-question",
        })}
      </Divider>
      <Button
        variant="secondary"
        color="CTA2"
        fullWidth="full"
        rounded="full"
        className="mt-4"
        onClick={onGoToLogin}
        children={intl.formatMessage({
          defaultMessage: "Sign In",
          id: "sign-in",
        })}
      />
    </View>
  );
};
