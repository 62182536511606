import * as React from "react";
import type { SVGProps } from "react";
const SvgPasswordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M15.5 8.45866C15.4699 8.45866 15.4386 8.44637 15.4129 8.42073C15.3873 8.39509 15.375 8.36381 15.375 8.33366V6.66699C15.375 5.33196 15.1924 4.0828 14.4337 3.17456C13.6579 2.24583 12.3854 1.79199 10.5 1.79199C8.61455 1.79199 7.34211 2.24583 6.56627 3.17456C5.80756 4.0828 5.625 5.33196 5.625 6.66699V8.33366C5.625 8.36381 5.61272 8.39509 5.58707 8.42073C5.56143 8.44637 5.53015 8.45866 5.5 8.45866C5.46985 8.45866 5.43857 8.44637 5.41293 8.42073C5.38728 8.39509 5.375 8.36381 5.375 8.33366V6.66699C5.375 5.47988 5.52312 4.20729 6.20595 3.23575C6.86327 2.3005 8.09282 1.54199 10.5 1.54199C12.9072 1.54199 14.1367 2.3005 14.7941 3.23575C15.4769 4.20729 15.625 5.47988 15.625 6.66699V8.33366C15.625 8.36381 15.6127 8.39509 15.5871 8.42073C15.5614 8.44637 15.5301 8.45866 15.5 8.45866Z"
      stroke="#68778D"
    />
    <path
      d="M14.666 18.458H6.33268C4.5124 18.458 3.49873 18.1732 2.91228 17.5867C2.32583 17.0003 2.04102 15.9866 2.04102 14.1663V12.4997C2.04102 10.6794 2.32583 9.66572 2.91228 9.07927C3.49873 8.49282 4.5124 8.20801 6.33268 8.20801H14.666C16.4863 8.20801 17.5 8.49282 18.0864 9.07927C18.6729 9.66572 18.9577 10.6794 18.9577 12.4997V14.1663C18.9577 15.9866 18.6729 17.0003 18.0864 17.5867C17.5 18.1732 16.4863 18.458 14.666 18.458ZM6.33268 8.45801C5.57802 8.45801 4.94162 8.49316 4.41743 8.60381C3.88768 8.71564 3.43662 8.91189 3.08919 9.26002C2.74181 9.6081 2.54659 10.0594 2.43553 10.5885C2.32564 11.1121 2.29102 11.7473 2.29102 12.4997V14.1663C2.29102 14.9187 2.32564 15.5539 2.43553 16.0775C2.54659 16.6066 2.74181 17.0579 3.08919 17.406C3.43662 17.7541 3.88768 17.9504 4.41743 18.0622C4.94162 18.1729 5.57802 18.208 6.33268 18.208H14.666C15.4207 18.208 16.0571 18.1729 16.5813 18.0622C17.111 17.9504 17.5621 17.7541 17.9095 17.406C18.2569 17.0579 18.4521 16.6066 18.5632 16.0775C18.6731 15.5539 18.7077 14.9187 18.7077 14.1663V12.4997C18.7077 11.7473 18.6731 11.1121 18.5632 10.5885C18.4521 10.0594 18.2569 9.6081 17.9095 9.26002C17.5621 8.91189 17.111 8.71564 16.5813 8.60381C16.0571 8.49316 15.4207 8.45801 14.666 8.45801H6.33268Z"
      fill="#68778D"
      stroke="#68778D"
    />
    <path
      d="M7.04295 13.6385L7.04303 13.6384L7.03014 13.6334C6.99861 13.6213 6.96971 13.6048 6.92636 13.5677C6.86469 13.4968 6.83398 13.4143 6.83398 13.3334C6.83398 13.3009 6.84184 13.2578 6.86218 13.209L6.86235 13.2091L6.86731 13.1962C6.8791 13.1656 6.89497 13.1374 6.92997 13.096C6.97133 13.061 6.99949 13.0452 7.03014 13.0334L7.04205 13.0288L7.05372 13.0236C7.15498 12.9786 7.30386 13.0016 7.3968 13.087C7.43046 13.1265 7.4549 13.1669 7.47246 13.209L7.91664 13.0239L7.47246 13.209C7.4928 13.2578 7.50065 13.3009 7.50065 13.3334C7.50065 13.41 7.46728 13.5007 7.40512 13.5712C7.33461 13.6333 7.24393 13.6667 7.16732 13.6667C7.1348 13.6667 7.09177 13.6589 7.04295 13.6385Z"
      stroke="#68778D"
    />
    <path
      d="M10.1799 13.24L10.1876 13.2246L10.1942 13.2087C10.2118 13.1666 10.2362 13.1262 10.2699 13.0867C10.3468 13.0159 10.4615 12.9853 10.5508 13.0049L10.5688 13.0088L10.587 13.0114C10.5874 13.0115 10.5877 13.0115 10.588 13.0115C10.5887 13.0119 10.5902 13.0125 10.5924 13.0136L10.6242 13.0295L10.6579 13.0408C10.6602 13.0415 10.6704 13.0453 10.6887 13.0575L10.698 13.0637L10.7047 13.0693L10.7332 13.0939C10.7349 13.0954 10.7367 13.0969 10.7386 13.0986C10.7673 13.1343 10.7887 13.1708 10.8045 13.2087C10.8248 13.2576 10.8327 13.3006 10.8327 13.3331C10.8327 13.4112 10.8041 13.4908 10.7466 13.56L10.6751 13.6171C10.6653 13.6228 10.6596 13.6249 10.6579 13.6254L10.6242 13.6367L10.5924 13.6526C10.5902 13.6537 10.5887 13.6543 10.588 13.6546C10.5877 13.6547 10.5874 13.6547 10.587 13.6548L10.587 13.6547L10.5755 13.6566C10.5544 13.6601 10.5365 13.6628 10.5212 13.6645C10.5073 13.6661 10.5005 13.6664 10.4994 13.6664C10.4993 13.6664 10.4992 13.6664 10.4993 13.6664C10.4227 13.6664 10.3319 13.633 10.2613 13.5707C10.1977 13.4991 10.166 13.4153 10.166 13.3331C10.166 13.2741 10.1754 13.2491 10.1799 13.24Z"
      fill="#68778D"
      stroke="#68778D"
    />
    <path
      d="M13.6748 13.0408L13.7085 13.0295L13.7403 13.0136C13.7456 13.011 13.7489 13.0098 13.7501 13.0094L13.7597 13.0078L13.7704 13.0055C13.8676 12.9849 13.9812 13.0139 14.0703 13.0943C14.1346 13.1662 14.1667 13.2505 14.1667 13.3331C14.1667 13.3656 14.1588 13.4086 14.1385 13.4575L14.1383 13.4574L14.1333 13.4703C14.1212 13.5017 14.1048 13.5306 14.0679 13.5738C13.9978 13.6341 13.9088 13.6664 13.8333 13.6664C13.7528 13.6664 13.6706 13.636 13.5999 13.5748C13.5683 13.5368 13.5451 13.4979 13.5282 13.4575C13.5079 13.4086 13.5 13.3656 13.5 13.3331C13.5 13.2602 13.5302 13.1747 13.5866 13.1058L13.6575 13.0491C13.6674 13.0434 13.6731 13.0413 13.6748 13.0408Z"
      fill="#68778D"
      stroke="#68778D"
    />
  </svg>
);
export default SvgPasswordIcon;
