import { defaultNationCode } from "@/constants";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { superRefinePhoneNumber } from "@/lib/helpers/phone-number-validation";

const clientPhonNumberSchema = z
  .object({
    currentPhoneNumber: z.string().min(6, "Invalid phone number"),
    currentNationCode: z.string().min(1, "Nation code is required"),
    newPhoneNumber: z.string().min(6, "Invalid phone number"),
    newNationCode: z.string().min(1, "Nation code is required"),
  })
  .superRefine((arg, ctx) => {
    superRefinePhoneNumber({
      code: arg.currentNationCode,
      phoneNumber: arg.currentPhoneNumber,
      ctx,
      phoneNumberFieldName: "currentPhoneNumber",
    });
    superRefinePhoneNumber({
      code: arg.newNationCode,
      phoneNumber: arg.newPhoneNumber,
      ctx,
      phoneNumberFieldName: "newPhoneNumber",
    });
  });

export type ChangePhoneNumberFormData = z.infer<typeof clientPhonNumberSchema>;

export interface ChangePhoneNumberFormReturn
  extends UseFormReturn<ChangePhoneNumberFormData> {
  defaultValues: ChangePhoneNumberFormData;
}

const initialFormValues: ChangePhoneNumberFormData = {
  currentPhoneNumber: "",
  currentNationCode: "",
  newPhoneNumber: "",
  newNationCode: defaultNationCode,
};

export const useChangePhoneNumberForm = (
  initialValues?: ChangePhoneNumberFormData,
): ChangePhoneNumberFormReturn => ({
  defaultValues: initialValues || initialFormValues,
  ...useForm<ChangePhoneNumberFormData>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: zodResolver(clientPhonNumberSchema),
  }),
});
