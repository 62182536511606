export const pageConfig = {
  homepage: {
    web: "/",
    native: "/",
  },
  login: {
    web: "/login",
    native: "/login",
  },
  signup: {
    web: "/signup",
    native: "/signup",
  },
  profile: {
    web: "/profile",
    native: "/profile",
  },
  newContact: {
    web: "/profile/contacts/new",
    native: "/profile/contacts/new",
  },
  contacts: {
    web: "/profile/contacts",
    native: "/profile/contacts",
  },
  contact: {
    web: "/profile/contacts/:id",
    native: "/profile/contacts/:id",
  },
  newAddress: {
    web: "/profile/addresses/new",
    native: "/profile/addresses/new",
  },
  addresses: {
    web: "/profile/addresses",
    native: "/profile/addresses",
  },
  address: {
    web: "/profile/addresses/:id",
    native: "/profile/addresses/:id",
  },
  serviceDetail: {
    web: "/service-detail/:name",
    native: "/service-detail/:name",
  },
  selectService: {
    web: "/select-service/:name",
    native: "/select-service/:name",
  },
  booking: {
    web: "/booking/:name",
    native: "/booking/:name",
  },
  packages: {
    web: "profile/packages",
    native: "profile/packages",
  },
  selectSlot: {
    web: "/booking/:name/select-slot",
    native: "/booking/:name/select-slot",
  },
  bookingInfo: {
    web: "/booking/:name/booking-info",
    native: "/booking/:name/booking-info",
  },
  Confirmation: {
    web: "/booking/:name/confirmation",
    native: "/booking/:name/confirmation",
  },
  CompleteBooking: {
    web: "/booking/:name/complete-booking",
    native: "/booking/:name/complete-booking",
  },
  visits: {
    web: "/visits",
    native: "/visits",
  },
  rescheduleVisit: {
    web: "/visits/reschedule",
    native: "/visits/reschedule",
  },
  rewards: {
    web: "/rewards/your-rewards",
    native: "/rewards/your-rewards",
  },
  promos: {
    web: "/rewards/promos",
    native: "/rewards/promos",
  },
  invoices: {
    web: "/profile/invoices",
    native: "/profile/invoices",
  },
  invoiceDetail: {
    web: "/profile/invoices/:id",
    native: "/profile/invoices/:id",
  },
  customerReviews: {
    web: "", // no web page
    native: "/customer-reviews/:name",
  },
  reportIssueVisit: {
    web: "", // no web page
    native: "/report-issues/visit/:type",
  },
  reportIssuePackage: {
    web: "", // no web page
    native: "/report-issues/package/:type",
  },
  onboarding: {
    web: "", //no web page
    native: "/onboarding",
  },
  creditAccount: {
    web: "/profile/credit-account",
    native: "/profile/credit-account",
  },
  myVouchers: {
    web: "/profile/my-vouchers",
    native: "/profile/my-vouchers",
  },
  payment: {
    web: "/profile/payment",
    native: "/profile/payment",
  },
  selectPayment: {
    web: "", //no web page
    native: "/profile/invoices/select-payment",
  },
  accountInfo: {
    web: "/profile/account-info",
    native: "/profile/account-info",
  },
} as const;
