import { Image, View } from "@/components/ui";
import { Card, CardContent } from "@/components/ui/card";
import { Ratings } from "@/components/ui/ratings";
import { maskName } from "@/lib/helpers/string";
import { cn } from "@/lib/utils";
import type { Review } from "@/types/booking";
import { IfElse } from "../if-else";
import { Spin } from "@/components/shared/spin";
import { Typography } from "../typography";

const lineClamps = {
  sm: "line-clamp-2",
  lg: "line-clamp-6",
};

export function ReviewCard({
  loading,
  id,
  clientName,
  rating,
  createdAt,
  ltv,
  service,
  description,
  images,
  showImages = true,
  cardBorderStyle = "default",
  contentClassName,
  onClick,
}: Review & {
  loading?: boolean;
  showImages?: boolean;
  cardBorderStyle?: "default" | "hiddenInLargeScreen" | "none";
  contentClassName?: string;
  onClick?(item: Review): void;
}) {
  return (
    <Card
      shadow="none"
      radius="xl"
      border={cardBorderStyle}
      className="bg-transparent"
      onClick={() =>
        onClick?.({
          id,
          clientName,
          rating,
          createdAt,
          ltv,
          service,
          description,
          images,
        })
      }
    >
      <CardContent
        className={cn(
          "m-2 flex flex-col gap-2 p-4 md:m-0 md:p-1",
          contentClassName,
        )}
      >
        <IfElse
          if={!loading}
          else={
            <View>
              <Spin className="my-10 size-8" />
            </View>
          }
        >
          <>
            <View>
              <View className="inline-flex flex-row items-center gap-2">
                <Ratings variant="yellow" rating={rating} />
                <Typography variant="body-sm" color="foreground-intermediate">
                  {new Intl.DateTimeFormat("en-US", {
                    month: "short",
                    year: "numeric",
                  }).format(createdAt)}
                </Typography>
              </View>
            </View>
            <Typography variant="body-sm">{service}</Typography>
            <View className="ReviewCard-description">
              <Typography
                variant="body-md"
                className={cn(
                  images?.length ? lineClamps["sm"] : lineClamps["lg"],
                )}
              >
                {description}
              </Typography>
            </View>
            {showImages && !!images?.length && (
              <View className="ReviewCard-images flex flex-row flex-wrap gap-1">
                {images.map((image, iImg) => (
                  <View
                    className="w-[32%] overflow-hidden rounded-md"
                    key={iImg}
                  >
                    <Image
                      src={image}
                      className="aspect-square overflow-hidden rounded-md object-cover"
                      contentFit="cover"
                      style={{
                        height: undefined,
                        width: "100%",
                        aspectRatio: 1,
                      }}
                    />
                  </View>
                ))}
              </View>
            )}
            <Typography variant="label-md">{maskName(clientName)}</Typography>
            <Typography variant="body-sm" color="foreground-intermediate">
              {ltv} lifetime bookings
            </Typography>
          </>
        </IfElse>
      </CardContent>
    </Card>
  );
}
