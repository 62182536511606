import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import { View } from "@/components/ui";
import { IfElse } from "@/components/shared/if-else";
import { RadioGroupItem } from "@/components/ui/radio-group";
import { LuceSparkle, CaretRight } from "@/components/shared/icons";
import { Divider } from "@/components/shared/divider";
import { Typography } from "../typography";

const Variants = cva(
  "ease flex w-full cursor-pointer flex-col gap-4 overflow-hidden overflow-visible rounded-xl border p-4 transition",
  {
    variants: {
      state: {
        default: "border-secondary-border bg-white",
        selected: "border-primary-border bg-primary-surface",
        disabled: "cursor-not-allowed",
      },
    },
    defaultVariants: {
      state: "default",
    },
  },
);

export const ServiceRadioItem = ({
  value,
  title,
  subTitle,
  slogan,
  selected,
  description,
  showSparkle,
  className,
  showSelectIcon,
  onSelect,
  disabled,
  upsellText,
}: {
  title: string;
  subTitle: string;
  value: string;
  slogan?: string;
  description?: string;
  selected?: boolean;
  className?: string;
  showSparkle?: boolean;
  showSelectIcon?: boolean;
  onSelect(): void;
  upsellText?: string;
  disabled?: boolean;
}) => {
  return (
    <View>
      <View
        className={cn(
          Variants({
            state: selected ? "selected" : disabled ? "disabled" : "default",
          }),
          className,
        )}
        onClick={() => {
          if (disabled) return;
          onSelect();
        }}
      >
        {!disabled && slogan && (
          <View className="absolute -top-2 left-[-1px] rounded-br-xl rounded-tl-xl bg-success px-4 shadow-sm">
            <Typography variant="label-sm" color="white">
              {slogan}
            </Typography>
          </View>
        )}
        <View className="flex flex-row gap-2">
          <View className="flex flex-1 flex-row items-center">
            <View className={"mr-auto flex flex-1 flex-col gap-1"}>
              <View className="flex flex-row justify-between">
                <View className="flex flex-row items-center gap-2">
                  <IfElse if={!!showSparkle}>
                    <LuceSparkle className="w-3 text-brand-primary" />
                  </IfElse>
                  <Typography
                    className={cn({
                      "text-foreground-intermediate": disabled,
                    })}
                    variant="label-lg"
                  >
                    {title}
                  </Typography>
                </View>

                <View className="flex flex-row items-center gap-2">
                  <IfElse
                    if={!disabled}
                    else={
                      <Typography
                        variant={"label-lg"}
                        color="foreground-intermediate"
                      >
                        Not Available
                      </Typography>
                    }
                  >
                    <>
                      <Typography variant="label-lg" color="brand-primary">
                        {subTitle}
                      </Typography>
                      <IfElse
                        if={!!showSelectIcon}
                        else={
                          <RadioGroupItem
                            value={value}
                            disabled={disabled}
                            checkCircle
                          />
                        }
                      >
                        <CaretRight
                          size="18"
                          className="text-foreground-attenuated"
                        />
                      </IfElse>
                    </>
                  </IfElse>
                </View>
              </View>

              <IfElse if={!!description}>
                <>
                  <Divider
                    className={cn("my-1", {
                      "border-[#8DCEFF]": selected,
                    })}
                  />
                  <Typography variant="body-lg" color="foreground-intermediate">
                    {description}
                  </Typography>
                </>
              </IfElse>
            </View>
          </View>
        </View>
      </View>
      <IfElse if={!!upsellText}>
        <Typography
          variant="body-md"
          color="brand-primary"
          className={cn("ml-2 h-0 opacity-0 transition-all", {
            "h-3 pt-1 opacity-100": selected,
          })}
        >
          {upsellText}
        </Typography>
      </IfElse>
    </View>
  );
};
