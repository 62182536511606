import { SALES_NUMBER } from "@/constants";

export const onChatSupport = () => {
  window.open(
    "//wa.me/" +
      SALES_NUMBER +
      "?text=" +
      "Hi! I would like to speak to sales about booking a new appointment through your web application.",
  );
};
