import type {
  ClientContactsQuery,
  ClientContactsQueryVariables,
} from "@/__generated__/graphql";
import { ClientContactsDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";
import type { ClientContacts } from "@/types/profile";

type Response = {
  clientContacts: ClientContacts[];
};

export const useClientContactsStore = createRequestFactory<
  ClientContactsQuery,
  Response,
  ClientContactsQueryVariables
>({
  method: "query",
  graphqlDocument: ClientContactsDocument,
  fetchPolicy: 'network-only',
  transformFunction: (res) => {
    if (res.clientContacts) {
      return { clientContacts: res.clientContacts.contacts };
    }
    throw new Error("Fetch failed");
  },
});
