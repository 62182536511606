import { ServiceFaq } from "@/components/shared/service-faq";
import { Typography } from "@/components/shared/typography";
import { View } from "@/components/ui";
import { useIntl } from "react-intl";

export function ServiceFaqSection({
  faqs,
}: {
  faqs: { title: string; description: string }[];
}) {
  const intl = useIntl();
  return (
    <View className="flex flex-col gap-2 border-t-4 border-secondary-border pt-4 md:border-none">
      <View className="mx-4 md:mx-0">
        <Typography className="label-xl">
          {intl.formatMessage({
            defaultMessage: "Frequently asked questions",
            id: "faq.header",
          })}
        </Typography>
        <ServiceFaq items={faqs} />
      </View>
    </View>
  );
}
