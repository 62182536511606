import type { ReactNode } from "react";
import { createPortal } from "react-dom";

export function PortalHost({ name }: { name: string }) {
  return <div id={name} />;
}

export function Portal({
  name,
  hostName,
  children,
}: {
  name: string;
  hostName: string;
  children: ReactNode;
}) {
  const rootPortal = document.getElementById(hostName ?? name);

  if (!rootPortal) {
    return null;
  }

  return <>{createPortal(children, rootPortal)}</>;
}
