import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientConfirmPhoneNumberDocument,
  type ClientConfirmPhoneNumberMutationVariables,
  type ClientConfirmPhoneNumberMutation,
} from "@/__generated__/graphql";
import { storage } from "@/lib/storage";

type Response = {
  success: boolean;
};

export const useClientConfirmPhoneNumberStore = createRequestFactory<
  ClientConfirmPhoneNumberMutation,
  Response,
  ClientConfirmPhoneNumberMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientConfirmPhoneNumberDocument,
  transformFunction: (res) => {
    if (res.clientConfirmPhoneNumber?.result) {
      storage.setItem("ONBOARDING", "true").catch((e) => {
        console.log("unable to set ONBOARDING. Error:", e);
      });
      return { success: true };
    }
    throw new Error("Invalid pin code");
  },
});
