import { View } from "@/components/ui";
import "./backdrop.css";

export function WebLayout({
  children,
  expandBackdrop,
}: {
  children: React.ReactNode;
  expandBackdrop?: boolean;
}) {
  return (
    <>
      <View className="WebLayout relative z-10 mx-auto flex flex-col items-center">
        {expandBackdrop && <View className="background-backdrop" />}
        <View className="flex w-full flex-col">{children}</View>
      </View>
    </>
  );
}
