import React from "react";
import ReactDOM from "react-dom/client";
import App from "./router";
import "../assets/global.css";
import "../assets/hamburger.css";
import * as Sentry from "@/lib/monitoring/sentry";
import { getFirebaseApp } from "@/lib/firebase";
import { getAdTrackingData } from "@/lib/monitoring/oci";

Sentry.init();
getFirebaseApp();
getAdTrackingData();

const el = document.getElementById("root");

if (!el) {
  throw new Error("Unable to find root element");
}

ReactDOM.createRoot(el).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
