import { Typography } from "@/components/shared/typography";
import { Dialog, DialogContent, View, Button } from "@/components/ui";
import { LuceSparkle } from "@/components/shared/icons";

const slogans = [
  {
    title: "Pay Less: ",
    description: "Just $18/hour vs. $23/hour for one-time.",
  },
  {
    title: "Same Trusted Cleaner: ",
    description: "Your preferred cleaner every time",
  },
  {
    title: "Hassle-Free: ",
    description:
      "Set it and forget it with recurring cleaning on your schedule.",
  },
  {
    title: "Priority Booking: ",
    description: "Guaranteed slots with your recurring plan.",
  },
];

export const UnlockMoreModal = ({
  open,
  loading,
  setOpen,
  onTryRecurring,
  onKeepOneTime,
}: {
  open: boolean;
  loading: boolean;
  setOpen(open: boolean): void;
  onTryRecurring(): void;
  onKeepOneTime(): void;
}) => {
  return (
    <Dialog open={open} onOpenChange={() => setOpen(false)}>
      <DialogContent className="max-w-[343px] rounded-xl md:w-[375px]">
        <View className="flex flex-col gap-8">
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            Unlock More Value with Weekly/Fortnightly Cleaning!
          </Typography>
          <View>
            {slogans.map((slogan) => (
              <View
                key={slogan.title}
                className="flex flex-row items-start gap-2"
              >
                <LuceSparkle className="mt-2 inline size-3 shrink-0 text-secondary-button md:mt-1 md:size-4" />
                <View>
                  <Typography
                    variant={{ md: "body-lg", sm: "body-md" }}
                    className="inline font-bold"
                  >
                    {slogan.title}
                  </Typography>
                  <Typography
                    variant={{ md: "body-lg", sm: "body-md" }}
                    className="inline"
                  >
                    {slogan.description}
                  </Typography>
                </View>
              </View>
            ))}
          </View>
          <View className="flex flex-col gap-2">
            <Button
              variant="primary"
              color="CTA"
              onClick={onTryRecurring}
              children="Try a Recurring Package"
            />

            <Button
              variant="secondary"
              color="CTA2"
              onClick={onKeepOneTime}
              loading={loading}
              children="Keep One-Time Cleaning"
            />
          </View>
        </View>
      </DialogContent>
    </Dialog>
  );
};
