import {
  View,
  Button,
  Image,
  Textarea,
  DialogHeader,
  DialogTitle,
} from "@/components/ui";
import { Typography } from "../typography";
import { Dialog, DialogContent } from "@/components/ui";
import { ClipboardText } from "@/components/shared/icons";
import { IconButton } from "@/components/ui/icon-button";
import { useState } from "react";
import { InputFormControl } from "@/components/shared/input-form-control";

import Stars from "@/assets/images/star-cluster.png";
import { Sentry } from "@/lib/monitoring/sentry";
import { useAuthState } from "@/store/auth";
import { useFeedbackForm } from "@/store/feedback/useFeedbackForm";

type FormValues = {
  feedback?: string;
};

function sendFeedback(comments: string): void {
  try {
    const email = useAuthState.getState().data.userInfo.user.email ?? "Not Set";
    const event_id = Sentry.captureMessage("Switch to Old", {
      level: "info",
      extra: {
        email,
        comments,
      },
    });

    Sentry.captureUserFeedback({
      event_id,
      name: email,
      email,
      comments,
    });
  } catch (error) {
    console.log("Unable to send sentry feedback", error);
  }
}

export function FeedBackModal({
  feedbackModalOpen,
  onClose,
}: {
  feedbackModalOpen: boolean;
  onClose(): void;
}) {
  const form = useFeedbackForm();

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (values: FormValues) => {
    setSubmitted(true);

    if (values.feedback) {
      sendFeedback(values.feedback);
    }
    setTimeout(() => {
      window.location.href = "https://app.lucemg.com";
    }, 3000);
  };

  return (
    <Dialog open={feedbackModalOpen} onOpenChange={onClose}>
      <DialogContent className="w-[343px] max-w-full rounded-lg p-4">
        {!submitted ? (
          <View className="transition-all">
            <DialogHeader className="flex flex-row justify-between gap-2">
              <View className="flex flex-row gap-3">
                <ClipboardText
                  size={20}
                  weight="fill"
                  className="text-primary"
                />
                <DialogTitle>Tell Us What You Think!</DialogTitle>
              </View>
              <IconButton
                variant="ghost"
                color="fill-foreground"
                className="p-0"
                iconName="x"
                onClick={onClose}
              />
            </DialogHeader>
            <View className="flex flex-col gap-3 py-3">
              <Typography variant="body-lg">
                What did you think of the new version? How can we make it
                better? (Optional)
              </Typography>
              <InputFormControl
                name="feedback"
                control={form.control}
                component={Textarea}
                componentProps={{
                  placeholder:
                    "e.g. The new design is easier to navigate, especially in the booking flow., etc.",
                }}
              />
            </View>

            <Button
              variant="primary"
              color="CTA"
              fullWidth="full"
              rounded="full"
              onClick={form.handleSubmit(onSubmit)}
              children="Submit, and switch to old app"
            />
          </View>
        ) : (
          <View className="flex flex-col items-center gap-4">
            <Image src={Stars} className="h-14 w-14" />

            <Typography variant="body-lg" color="brand-primary" align="center">
              Thank you for your feedback! You're now switching back to the old
              version.
            </Typography>
          </View>
        )}
      </DialogContent>
    </Dialog>
  );
}
