import type {
  UpdatePaymentIndicatedMutation,
  UpdatePaymentIndicatedMutationVariables,
} from "@/__generated__/graphql";
import { UpdatePaymentIndicatedDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";

type Response = boolean;

export const useUpdatePaymentIndicatedStore = createRequestFactory<
  UpdatePaymentIndicatedMutation,
  Response,
  UpdatePaymentIndicatedMutationVariables
>({
  method: "mutation",
  graphqlDocument: UpdatePaymentIndicatedDocument,
  transformFunction: (res) => {
    if (res.updatePaymentIndicated) {
      return !!res.updatePaymentIndicated.result;
    }
    throw new Error("Unable to pay invoice");
  },
});
