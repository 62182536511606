import type {
  ClientIssueDetailsQuery,
  ClientIssueDetailsQueryVariables,
} from "@/__generated__/graphql";
import { ClientIssueDetailsDocument } from "@/__generated__/graphql";

import { createRequestFactory } from "@/lib/request-factory";

const EXCLUDE_PACKAGE_ISSUES = ["Worker: Attendance", "Worker: No Show"];

const clientIssueOptions = (
  clientIssues: ClientIssueDetailsQuery["clientIssueDetails"],
) => {
  return clientIssues
    .map((issue) => ({
      label: issue.name,
      value: issue.id,
    }))
    .sort((a, b) => {
      const aReason = a.label;
      const bReason = b.label;
      if (aReason.toLowerCase().includes("other")) {
        return 1;
      } else if (bReason.toLowerCase().includes("other")) {
        return -1;
      } else {
        return aReason.localeCompare(bReason);
      }
    })
    .filter((issue) => !EXCLUDE_PACKAGE_ISSUES.includes(issue.label));
};

export const useIssueDetailsStore = createRequestFactory<
  ClientIssueDetailsQuery,
  ReturnType<typeof clientIssueOptions>,
  ClientIssueDetailsQueryVariables
>({
  method: "query",
  graphqlDocument: ClientIssueDetailsDocument,
  transformFunction(data) {
    return clientIssueOptions(data.clientIssueDetails ?? []);
  },
});
