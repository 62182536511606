import { Text } from "@/components/ui";
import type { TextProps } from "@/components/ui/text";
import type { ResponsiveVariantProps } from "@/lib/styles";
import { createResponsiveCVA } from "@/lib/styles";

export type TypographyVariants = {
  variant: {
    h1: string;
    h2: string;
    h3: string;
    h4: string;
    h5: string;
    h6: string;
    h7: string;
    "bold-2xl": string;
    "bold-xl": string;
    "bold-lg": string;
    "bold-md": string;
    "bold-sm": string;
    "bold-xs": string;
    "label-2xl": string;
    "label-xl": string;
    "label-lg": string;
    "label-md": string;
    "label-sm": string;
    "label-xs": string;
    "body-2xl": string;
    "body-xl": string;
    "body-lg": string;
    "body-md": string;
    "body-sm": string;
    "body-xs": string;
  };
  color: {
    "brand-primary": string;
    "button-cta": string;
    "foreground-intermediate": string;
    "foreground-default": string;
    "muted-foreground": string;
    "secondary-text": string;
    white: string;
    danger: string;
    success: string;
    info: string;
    warning: string;
  };
  align: {
    left: string;
    center: string;
    right: string;
  };
  family: {
    inter: string;
    epilogue: string;
  };
};

const typographyVariants = createResponsiveCVA<TypographyVariants>(
  "",
  {
    default: {
      variant: {
        h1: "font-semibold text-[48px] leading-[56px]",
        h2: "font-semibold text-[40px] leading-[52px]",
        h3: "font-semibold text-[36px] leading-[44px]",
        h4: "font-semibold text-[32px] leading-[40px]",
        h5: "font-semibold text-[28px] leading-[36px]",
        h6: "font-semibold text-[24px] leading-[32px]",
        h7: "font-semibold text-[20px] leading-[28px]",

        "bold-2xl": "font-semibold text-[18px] leading-[26px]",
        "bold-xl": "font-semibold text-[16px] leading-[24px]",
        "bold-lg": "font-semibold text-[14px] leading-[20px]",
        "bold-md": "font-semibold text-[12px] leading-[16px]",
        "bold-sm": "font-semibold text-[10px] leading-[14px]",
        "bold-xs": "font-semibold text-[8px] leading-[12px]",

        "label-2xl": "font-medium text-[18px] leading-[26px]",
        "label-xl": "font-medium text-[16px] leading-[24px]",
        "label-lg": "font-medium text-[14px] leading-[20px]",
        "label-md": "font-medium text-[12px] leading-[16px]",
        "label-sm": "font-medium text-[10px] leading-[14px]",
        "label-xs": "font-medium text-[8px] leading-[12px]",

        "body-2xl": "font-normal text-[18px] leading-[26px]",
        "body-xl": "font-normal text-[16px] leading-[24px]",
        "body-lg": "font-normal text-[14px] leading-[20px]",
        "body-md": "font-normal text-[12px] leading-[16px]",
        "body-sm": "font-normal text-[10px] leading-[14px]",
        "body-xs": "font-normal text-[8px] leading-[12px]",
      },
      color: {
        "brand-primary": "color-brand-primary",
        "button-cta": "color-button-cta",
        "foreground-intermediate": "text-foreground-intermediate",
        "foreground-default": "text-foreground",
        "muted-foreground": "text-muted-foreground",
        "secondary-text": "text-secondary-text",
        white: "color-white",
        danger: "color-danger",
        success: "text-success",
        info: "text-info",
        warning: "text-warning",
      },
      align: {
        left: "text-left",
        center: "text-center",
        right: "text-right",
      },
      family: {
        inter: "font-inter",
        epilogue: "font-epilogue",
      },
    },
    md: {
      variant: {
        h1: "md:font-semibold md:text-[48px] md:leading-[56px]",
        h2: "md:font-semibold md:text-[40px] md:leading-[52px]",
        h3: "md:font-semibold md:text-[36px] md:leading-[44px]",
        h4: "md:font-semibold md:text-[32px] md:leading-[40px]",
        h5: "md:font-semibold md:text-[28px] md:leading-[36px]",
        h6: "md:font-semibold md:text-[24px] md:leading-[32px]",
        h7: "md:font-semibold md:text-[20px] md:leading-[28px]",

        "bold-2xl": "md:font-semibold md:text-[18px] md:leading-[26px]",
        "bold-xl": "md:font-semibold md:text-[16px] md:leading-[24px]",
        "bold-lg": "md:font-semibold md:text-[14px] md:leading-[20px]",
        "bold-md": "md:font-semibold md:text-[12px] md:leading-[16px]",
        "bold-sm": "md:font-semibold md:text-[10px] md:leading-[14px]",
        "bold-xs": "md:font-semibold md:text-[8px] md:leading-[12px]",

        "label-2xl": "md:font-medium md:text-[18px] md:leading-[26px]",
        "label-xl": "md:font-medium md:text-[16px] md:leading-[24px]",
        "label-lg": "md:font-medium md:text-[14px] md:leading-[20px]",
        "label-md": "md:font-medium md:text-[12px] md:leading-[16px]",
        "label-sm": "md:font-medium md:text-[10px] md:leading-[14px]",
        "label-xs": "md:font-medium md:text-[8px] md:leading-[12px]",

        "body-2xl": "md:font-normal md:text-[18px] md:leading-[26px]",
        "body-xl": "md:font-normal md:text-[16px] md:leading-[24px]",
        "body-lg": "md:font-normal md:text-[14px] md:leading-[20px]",
        "body-md": "md:font-normal md:text-[12px] md:leading-[16px]",
        "body-sm": "md:font-normal md:text-[10px] md:leading-[14px]",
        "body-xs": "md:font-normal md:text-[8px] md:leading-[12px]",
      },
      color: {
        "brand-primary": "md:color-brand-primary",
        "button-cta": "md:color-button-cta",
        "foreground-intermediate": "md:text-foreground-intermediate",
        "foreground-default": "md:text-foreground",
        "muted-foreground": "md:text-muted-foreground",
        "secondary-text": "md:text-secondary-text",
        white: "md:color-white",
        danger: "md:color-danger",
        success: "md:text-success",
        info: "md:text-info",
        warning: "md:text-warning",
      },
      align: {
        left: "md:text-left",
        center: "md:text-center",
        right: "md:text-right",
      },
      family: {
        inter: "md:font-inter",
        epilogue: "md:font-epilogue",
      },
    },
  },
  {
    color: "foreground-default",
    variant: "body-2xl",
    family: "inter",
  },
);

interface TypographyProps
  extends ResponsiveVariantProps<typeof typographyVariants>,
    TextProps {}

export function Typography({
  color,
  variant,
  align,
  family,
  className,
  ...props
}: TypographyProps) {
  const style = typographyVariants({
    color,
    variant,
    align,
    family,
    className,
  });

  return <Text {...props} className={style} />;
}
