import { Card, CardContent } from "@/components/ui";
import { CameraPlus } from "@/components/shared/icons";
import { useRef } from "react";
import { makeFileFromPicker } from "@/lib/platform/index.web";
import type { ImageFile } from "@/types/app";

export function ImagePreviewInput({
  onChange,
}: {
  onChange(imageFile: ImageFile): void;
}) {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputFileRef.current?.click();
  };

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const raw = event.target.files?.[0];
    if (raw) {
      const file = await makeFileFromPicker(raw);
      const uri = URL.createObjectURL(raw);
      onChange({ file, uri, raw });
    }
  };

  return (
    <Card variant="info" onClick={handleClick} className="aspect-square">
      <CardContent className="flex items-center justify-center p-0">
        <CameraPlus className="size-6 color-brand-primary" weight="fill" />
        <input
          accept="image/*"
          type="file"
          ref={inputFileRef}
          hidden
          onChange={handleFile}
        />
      </CardContent>
    </Card>
  );
}
