import { View, Button } from "@/components/ui";
import { useRoute } from "@/components/shared/router";
import { ArrowLeft } from "@/components/shared/icons";
import { getPlatform } from "@/lib/utils";
import { Typography } from "../typography";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";

export const WebBackButtonHeader = ({ title = "Back" }: { title?: string }) => {
  const { pull } = useRoute();
  const platform = getPlatform();
  const { isDesktop } = useWindowDimensions();

  if (platform === "native") {
    return null;
  }

  if (isDesktop) {
    return (
      <View className="flex items-center gap-2 p-4">
        <Button
          variant="secondary"
          onClick={pull}
          iconName="arrowLeft"
          className="z-20 gap-2 rounded-full border border-slate-300 bg-white"
          children={title}
        />
      </View>
    );
  }

  return (
    <View className="sticky top-16 z-50 block w-full bg-white p-4 shadow-sm">
      <View className="flex flex-row items-center gap-4">
        <View className="cursor-pointer" onClick={pull}>
          <ArrowLeft size={18} />
        </View>
        <Typography variant="label-xl">{title}</Typography>
      </View>
    </View>
  );
};
