import { View, Button } from "@/components/ui";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Typography } from "@/components/shared/typography";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function CancelPackageConfirmation({ open, onClose, onConfirm }: Props) {
	return (
		<BottomDrawerModal open={open} onOpenChange={onClose}>
			<View className="flex flex-col flex-1 w-full gap-3">
				<Typography variant="body-lg">Cancel Plan</Typography>
				<Typography variant="body-md" color="muted-foreground">
					Are you sure you want to cancel your plan?
				</Typography>
				<View className="flex flex-col gap-2">
					<Button 
						variant="primary" 
						color="CTA" 
						fullWidth="full"
						onClick={onClose} 
						children="Keep my plan"
					/>
					<Button
						variant="tertiary"
						color="CTA2"
						fullWidth="full"
						onClick={onConfirm}
						children="I want to cancel my plan"
						/>	
				</View>
			</View>
		</BottomDrawerModal>
	);
}
