import { Banner } from "@/components/shared/banner";
import { useRoute } from "@/components/shared/router";

import { Button } from "@/components/ui";
import { useIntl } from "react-intl";

export function ClaimRewardBanner() {
  const intl = useIntl();
  const { push } = useRoute();

  return (
    <Banner
      type="my-reward"
      title={intl.formatMessage({
        defaultMessage: "Claim your reward!",
        description: "reward banner title",
        id: "homepage.rewardBannerTitle",
      })}
      description={intl.formatMessage({
        defaultMessage:
          "Explore your exclusive offers: check out eligible promo codes for our services.",
        description: "reward banner description",
        id: "homepage.rewardBannerDescription",
      })}
      action={
        <Button
          variant="tertiary"
          color="CTA"
          rounded="full"
          children={intl.formatMessage({
            defaultMessage: "See my reward",
            description: "reward banner button",
            id: "homepage.rewardBannerButton",
          })}
          onClick={() => {
            push({ pageKey: "rewards" });
          }}
        />
      }
    />
  );
}
