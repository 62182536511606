import { WebLayout } from "@/components/shared/layout/homepage";
import { SignUpPage } from ".";

export const SignUpPageWrapper = () => {
  return (
    <WebLayout>
      <SignUpPage />
    </WebLayout>
  );
};
