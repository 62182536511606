import { create } from "zustand";
import { useIssueDetailsStore } from "./issueDetails";
import { IssueTypeEnum } from "@/__generated__/graphql";
import { useVisitDetailStore } from "../../visits/visitDetail";

export enum VisitIssueType {
  changeVisitWorker = "changeVisitWorker",
  reportVisit = "reportVisit",
}

type VisitIssueState = {
  showModal: boolean;
  issueType: VisitIssueType | null;
  visitId: string | null;
};

type VisitIssueStore = {
  data: VisitIssueState;
  openVisitIssueModal(issueType: VisitIssueType, visitId: string): void;
  closeVisitIssueModal(shouldOpenVisitDetail?: boolean): void;
};

const initialData: VisitIssueState = {
  showModal: false,
  issueType: null,
  visitId: null,
};

export const useVisitIssueStore = create<VisitIssueStore>((set, get) => ({
  data: initialData,
  openVisitIssueModal: (issueType: VisitIssueType, visitId: string) => {
    set({
      data: {
        ...get().data,
        showModal: true,
        issueType,
        visitId,
      },
    });

    const type =
      issueType === VisitIssueType.reportVisit
        ? IssueTypeEnum.General
        : IssueTypeEnum.CleanerReplacement;

    useIssueDetailsStore.getState().fetch({
      requestPayload: {
        type,
      },
    });

    useVisitDetailStore.getState().toggleVisitModal();
  },
  closeVisitIssueModal: (shouldOpenVisitDetail = true) => {
    set({
      data: initialData,
    });
    useIssueDetailsStore.getState().clear();
    if (shouldOpenVisitDetail) {
      useVisitDetailStore.getState().toggleVisitModal();
    }
  },
}));
