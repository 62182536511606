import { Card, CardContent, View } from "@/components/ui";
import { HorizontalScrollView } from "../../../horizontal-scroll-view";
import { Divider } from "../../../divider";
import { formatVisitSchedule } from "../../utils";
import { Ratings } from "@/components/ui/ratings";
import { packageServiceTypeLabel } from "@/lib/service/helpers";
import { WorkerAvatar } from "../../visit-card/worker-avatar";
import { VisitStatusBadge } from "../../visit-card/visit-status-badge";
import { cn } from "@/lib/utils";
import { Typography } from "../../../typography";
import { UnratedVisitScreenProps } from "..";

export function UnratedVisitScreen({
  openRateVisitModalById,
  unratedVisitData,
}: UnratedVisitScreenProps) {
  return (
      <Card shadow="sm">
        <HorizontalScrollView
          className="p-4"
          HeaderComponent={
            <CardContent className="flex flex-row items-center justify-between gap-2 p-0">
              <Typography variant="bold-xl">Let's Rate It</Typography>
              <Divider />
            </CardContent>
          }
        >
          {unratedVisitData.map((visit, index) => (
            <CardContent
              key={visit.id}
              className={cn(
                "border-t p-0 pt-4",
                index === 0 ? "-ml-2" : "ml-0",
              )}
            >
              <CardContent className="px-0 pb-3 pt-0">
                <Typography variant="label-md">
                  {packageServiceTypeLabel(visit.serviceType, visit.department)}
                </Typography>
                <Typography variant="body-sm" color="foreground-intermediate">
                  {formatVisitSchedule(
                    visit.startTime,
                    visit.endTime,
                    visit.serviceDate,
                  )}
                </Typography>
              </CardContent>
              <Divider dashed />
              <CardContent className="flex flex-row justify-between p-0">
                <WorkerAvatar
                  fullName={visit.worker.fullName}
                  avatarUrl={visit.worker.avatarUrl}
                />
                <VisitStatusBadge status={visit.status} />
              </CardContent>
              <Divider dashed />
              <CardContent>
                <View>
                  <Ratings
                    variant="yellow"
                    size={32}
                    rating={visit.rating}
                    onClick={() => openRateVisitModalById(visit.id)}
                  />
                </View>
              </CardContent>
            </CardContent>
          ))}
        </HorizontalScrollView>
      </Card>
  );
}
