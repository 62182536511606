import { Dialog, DialogContent } from "@/components/ui";
import { useAuthState } from "@/store/auth";
import { AddAddressContent } from "../add-address-content";
import type { AddressFormData } from "@/store/auth/forms/useAddressForm";

export const AddAddressModal = ({
  isDefaultAddress,
  onSubmitForm,
  loading,
  error,
  open,
  onClose,
}: {
  open: boolean;
  isDefaultAddress?: boolean;
  onSubmitForm(data?: AddressFormData): void;
  loading: boolean;
  error?: string;
  onClose(): void;
}) => {
  const { setPinCode, setPhone } = useAuthState();
  const onModalClose = () => {
    onClose();
    setPinCode("");
    setPhone("");
  };

  return (
    <Dialog open={open} onOpenChange={onModalClose}>
      <DialogContent className="w-[343px]">
        <AddAddressContent
          loading={loading}
          error={error || ""}
          onAddAddress={onSubmitForm}
          isDefaultAddress={isDefaultAddress}
        />
      </DialogContent>
    </Dialog>
  );
};
