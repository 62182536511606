import { View } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { ArrowLeft } from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { CreateContactForm } from "../CreateContactForm";
import type { CreateContactProps } from "..";

export const CreateContactScreen = ({
  control,
  createContactLoading,
  onSubmit,
  trigger,
}: CreateContactProps) => {
  const intl = useIntl();
  return (
    <View>
      <View className="static flex flex-row items-center justify-between border-b md:mb-8 md:pb-4">
        <View className="flex flex-row items-center gap-2 p-4 md:p-0">
          <Link href="/profile/contacts">
            <ArrowLeft className="size-5" />
          </Link>
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            {intl.formatMessage({
              defaultMessage: "Add new Contact",
              id: "add-contact",
            })}
          </Typography>
        </View>
      </View>

      <CreateContactForm
        trigger={trigger}
        control={control}
        onSubmit={onSubmit}
        createContactLoading={createContactLoading}
      />
    </View>
  );
};
