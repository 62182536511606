import { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  View,
  Button,
} from "@/components/ui";
import { OTP } from "@/components/shared/otp";
import { useCountdown } from "@/components/hooks/use-count-down";
import { ErrorMessage } from "@/components/shared/login-register/error-message";
import { useProfileState } from "@/store/profile";
import { ChangePhoneNumberModalEnum } from "@/types/profile";
import { useClientConfirmPhoneNumberStore } from "@/store/auth/clientConfirmPhoneNumber";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { useAuthState } from "@/store/auth";
import { useClientStore } from "@/store/auth/client";

export function ChangeNumberOtpVerificationModal({ open }: { open: boolean }) {
  const intl = useIntl();
  const {
    data: { userInfo },
  } = useAuthState();
  const { count, time, resetCount } = useCountdown(59);
  const {
    showModal,
    setPinCode,
    data: { pinCode, updatedPhone },
  } = useProfileState();

  useEffect(() => {
    if (open) {
      resetCount();
      setPinCode("");
    }
  }, [open]);

  const {
    fetch: clientConfirmPhoneNumber,
    error: clientConfirmPhoneNumberError,
    loading: clientConfirmPhoneNumberLoading,
  } = useClientConfirmPhoneNumberStore();

  const { fetch: client, loading: clientLoading } = useClientStore();

  const loading = clientConfirmPhoneNumberLoading || clientLoading;

  const handleContinue = async () => {
    try {
      const { data } = await clientConfirmPhoneNumber({
        requestPayload: {
          input: {
            phone: updatedPhone,
            code: pinCode,
            onSignUp: false,
          },
        },
      });

      if (data?.success && !clientConfirmPhoneNumberLoading) {
        client({
          requestPayload: {
            id: userInfo.id,
          },
        });

        showModal(ChangePhoneNumberModalEnum.CHANGE_PHONE_NUMBER_SUCCESS);
      }
    } catch (error) {
      console.error("Failed to confirm phone number:", error);
    }
  };

  const handleCancel = () => {
    showModal(ChangePhoneNumberModalEnum.CANCEL_CHANGE_PHONE_NUMBER);
  }

  return (
    <Dialog open={open}>
      <DialogContent className="w-[343px]">
        <DialogHeader className="flex flex-col gap-1">
          <DialogTitle>OTP Verification</DialogTitle>
          <DialogDescription>
            {`A verification phone number has been sent to (${updatedPhone}).`}
          </DialogDescription>
        </DialogHeader>
        <View className="flex flex-col items-stretch">
          <View className="mb-4 mt-2">
            <OTP pinCode={pinCode} onOTPChange={setPinCode} />
          </View>

          <View className="flex flex-col gap-2">
            <Button
              variant="primary"
              color="CTA"
              loading={loading}
              disabled={count === 0 || pinCode.length !== 6}
              onClick={handleContinue}
              children={intl.formatMessage({
                defaultMessage: "Continue",
                id: "proceed",
              })}
            />
            <Button
              variant="secondary"
              color="CTA2"
              loading={loading}
              onClick={handleCancel}
              children="Cancel"
            />
          </View>
          <Typography
            variant="body-md"
            color="secondary-text"
            align="center"
            className="pb-2 pt-4"
          >
            Remaining time: {time}s
          </Typography>
        </View>
        {clientConfirmPhoneNumberError && (
          <ErrorMessage
            errorMessage={clientConfirmPhoneNumberError}
            className="mt-2"
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
