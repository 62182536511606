// PRIMITIVES

export const red = {
  50: "hsl(0, 100%, 97.3%)",
  100: "hsl(0, 100%, 94.1%)",
  200: "hsl(0, 100%, 89%)",
  300: "hsl(0, 100%, 81.4%)",
  400: "hsl(0, 100%, 73.5%)",
  500: "hsl(0, 93.1%, 60.2%)",
  600: "hsl(0, 79.4%, 50.6%)",
  700: "hsl(0, 81.2%, 41.8%)",
  800: "hsl(0, 77.8%, 35.3%)",
  900: "hsl(0, 69.2%, 30.6%)",
  950: "hsl(0, 82.3%, 15.5%)",
} as const;

export const yellow = {
  50: "hsl(40, 100%, 96.5%)",
  100: "hsl(40.5, 100%, 91.6%)",
  200: "hsl(37.9, 100%, 82.9%)",
  300: "hsl(35.9, 100%, 72.2%)",
  400: "hsl(32.3, 100%, 66.1%)",
  500: "hsl(29.9, 99.2%, 53.1%)",
  600: "hsl(25.9, 94.3%, 48.2%)",
  700: "hsl(22.7, 92.2%, 40.4%)",
  800: "hsl(20.3, 82.6%, 33.7%)",
  900: "hsl(20.7, 77.5%, 27.8%)",
  950: "hsl(17.4, 83.8%, 14.5%)",
} as const;

export const blue = {
  50: "hsl(217.5, 100%, 96.9%)",
  100: "hsl(217.7, 94.6%, 92.7%)",
  200: "hsl(216.4, 93.8%, 87.3%)",
  300: "hsl(214, 94.5%, 78.4%)",
  400: "hsl(215.6, 91.5%, 67.8%)",
  500: "hsl(219.8, 88.9%, 57.5%)",
  600: "hsl(223.6, 81.5%, 53.3%)",
  700: "hsl(226.9, 74.7%, 48%)",
  800: "hsl(228.5, 68.8%, 40.2%)",
  900: "hsl(227, 63.1%, 32.9%)",
  950: "hsl(229.8, 55.1%, 21%)",
} as const;

export const green = {
  50: "hsl(147.3, 52.4%, 95.9%)",
  100: "hsl(142.2, 52.9%, 90%)",
  200: "hsl(145.2, 50%, 80.4%)",
  300: "hsl(149.6, 46.7%, 66.9%)",
  400: "hsl(151.2, 42.4%, 46.3%)",
  500: "hsl(153, 55.2%, 39.4%)",
  600: "hsl(154.7, 61.3%, 30.4%)",
  700: "hsl(155.5, 61.3%, 24.3%)",
  800: "hsl(155.6, 58.4%, 19.8%)",
  900: "hsl(156.3, 57.1%, 16.5%)",
  950: "hsl(158.6, 60.9%, 9%)",
} as const;

export const orange = {
  50: "hsl(23.3, 100%, 96.5%)",
  100: "hsl(25.1, 100%, 91.6%)",
  200: "hsl(23.4, 100%, 82.9%)",
  300: "hsl(21.5, 100%, 72.2%)",
  400: "hsl(17.9, 100%, 63.1%)",
  500: "hsl(15.4, 99.2%, 53.1%)",
  600: "hsl(11.6, 94.3%, 48.2%)",
  700: "hsl(8.2, 92.2%, 40.4%)",
  800: "hsl(5.9, 82.6%, 33.7%)",
  900: "hsl(6, 77.5%, 27.8%)",
  950: "hsl(3.9, 83.8%, 14.5%)",
} as const;

export const smalt = {
  50: "hsl(208.2, 100%, 96.7%)",
  100: "hsl(208.4, 100%, 92.5%)",
  200: "hsl(207.4, 100%, 86.7%)",
  300: "hsl(205.8, 100%, 77.6%)",
  400: "hsl(207.9, 100%, 67.1%)",
  500: "hsl(212.5, 100%, 59.4%)",
  600: "hsl(216.5, 93.3%, 53.3%)",
  700: "hsl(219.6, 85.5%, 45.9%)",
  800: "hsl(221.2, 79.5%, 40.2%)",
  900: "hsl(219.6, 100%, 30%)",
  950: "hsl(221.7, 64.5%, 21%)",
} as const;

export const grey = {
  10: "hsl(0, 0%, 100%)",
  50: "hsl(0, 0%, 96.5%)",
  100: "hsl(0, 0%, 90.6%)",
  200: "hsl(0, 0%, 82%)",
  300: "hsl(0, 0%, 69%)",
  400: "hsl(0, 0%, 53.3%)",
  500: "hsl(0, 0%, 42.7%)",
  600: "hsl(0, 0%, 36.5%)",
  700: "hsl(0, 0%, 31%)",
  800: "hsl(0, 0%, 27.1%)",
  900: "hsl(0, 0%, 22.7%)",
  950: "hsl(0, 0%, 12.5%)",
} as const;

export const greyBlack = {
  80: "hsla(0, 0%, 12.5%, 0.8)",
  50: "hsla(0, 0%, 12.5%, 0.5)",
  20: "hsla(0, 0%, 12.5%, 0.2)",
} as const;

export const greyBlue = {
  10: "hsl(0, 0%, 98%)",
  50: "hsl(220, 42.9%, 97.3%)",
  100: "hsl(217.5, 40%, 96.1%)",
  200: "hsl(214.3, 31.8%, 91.4%)",
  300: "hsl(211.4, 25.3%, 83.7%)",
  400: "hsl(212.6, 25.5%, 73.1%)",
  500: "hsl(214.3, 20.6%, 60%)",
  600: "hsl(215.7, 15.1%, 48%)",
  700: "hsl(218, 16.9%, 34.9%)",
  800: "hsl(211.8, 14.5%, 22.9%)",
  900: "hsl(214.3, 9.9%, 13.9%)",
  950: "hsl(220, 8.1%, 7.3%)",
} as const;

// TOKENS

export const greyBlueBlack = {
  20: "hsla(214.3, 9.9%, 13.9%, 0.2)",
  50: "hsla(214.3, 9.9%, 13.9%, 0.5)",
  80: "hsla(214.3, 9.9%, 13.9%, 0.8)",
} as const;

export const greyBlueWhite = {
  20: "hsla(0, 0%, 98%, 0.2)",
  50: "hsla(0, 0%, 98%, 0.5)",
  80: "hsla(0, 0%, 98%, 0.8)",
} as const;

export const overlay = {
  80: "hsla(214.3, 9.9%, 13.9%, 0.8)",
  50: "hsla(214.3, 9.9%, 13.9%, 0.5)",
  20: "hsla(214.3, 9.9%, 13.9%, 0.2)",
} as const;

export const overlayWhite = {
  20: "hsla(0, 0%, 98%, 0.2)",
  50: "hsla(0, 0%, 98%, 0.5)",
  80: "hsla(0, 0%, 98%, 0.8)",
} as const;

export const system = {
  onWarningSurface: yellow[800],
  warningSurface: yellow[50],
  warningBorder: yellow[200],
  onWarning: grey[10],
  warning: yellow[400],
  onDangerSurface: red[800],
  dangerSurface: red[50],
  dangerBorder: red[200],
  onDanger: grey[10],
  danger: red[400],
  onInfoSurface: blue[800],
  infoSurface: blue[50],
  infoBorder: blue[300],
  onInfo: grey[10],
  info: blue[500],
  onSuccessSurface: green[800],
  successSurface: green[50],
  successBorder: green[200],
  onSuccess: grey[10],
  success: green[400],
} as const;

export const neutral = {
  borderHighEmphasis: greyBlue[300],
  borderMediumEmphasis: greyBlue[200],
  borderLowEmphasis: greyBlue[200],
  foregroundInverse: greyBlue[10],
  foregroundAttenuated: greyBlue[400],
  foregroundIntermediate: greyBlue[600],
  foregroundDefault: greyBlue[800],
  backgroundInverse: greyBlue[900],
  backgroundAttenuated: greyBlue[200],
  backgroundIntermediate: greyBlue[200],
  backgroundDefault: greyBlue[10],
} as const;

export const brand = {
  onPrimarySurface: smalt[800],
  primarySurface: smalt[50],
  primaryBorder: smalt[500],
  onPrimary: grey[10],
  primary: smalt[700],
  primaryHover: smalt[800],
  static: smalt[700],
  onStatic: greyBlue[10],
  staticBorder: smalt[500],
  staticHover: smalt[800],
} as const;

export const button = {
  cta: orange[400],
  onCta: grey[10],
  ctaBorder: orange[200],
  ctaSurface: orange[50],
  onCtaSurface: orange[800],
  ctaHover: orange[300],
  ctaBorderHover: orange[300],
  ctaSurfaceHover: orange[100],
  cta2: smalt[900],
  cta2Hover: smalt[700],
  onCta2: grey[10],
  cta2Border: smalt[300],
  cta2BorderHover: smalt[400],
  cta2Surface: smalt[50],
  cta2SurfaceHover: smalt[100],
  onCta2Surface: smalt[900],
};

export const gradient = {
  blue0: smalt[500],
  blue100: smalt[700],
  orange0: orange[300],
  orange100: orange[400],
  purple0: "hsl(306.2, 100%, 65.9%)",
  purple100: "hsl(295.1, 100%, 38.4%)",
};

const Colors = {
  red,
  yellow,
  blue,
  green,
  orange,
  smalt,
  grey,
  greyBlack,
  greyBlue,
  greyBlueBlack,
  greyBlueWhite,
  overlay,
  overlayWhite,
  system,
  neutral,
  brand,
  button,
  gradient,
};

export default Colors;
