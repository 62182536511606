import type { UserInfo, JwtInfo } from "@/types/users";
import { AuthModals, LoginType, SignUpStep } from "@/types/users";
import { create } from "zustand";
import type { StateStorage } from "zustand/middleware";
import { persist, createJSONStorage } from "zustand/middleware";
import type { AddressFormReturn } from "./forms/useAddressForm";
import type { SignUpFormReturn } from "./forms/useSignUpForm";
import { DEFAULT_JWT_DATA, DEFAULT_USER_DATA } from "@/constants";
import { storage } from "@/lib/storage";
import { useUserInfo } from "./useUserInfo";
import { updateToken } from "@/lib/update-token";

type AuthState = {
  phone: string;
  pinCode: string;
  loginModalOpen: boolean;
  otpModalOpen: boolean;
  signUpModalOpen: boolean;
  emailLoginModalOpen: boolean;
  forgotPasswordModalOpen: boolean;
  addressModalOpen: boolean;
  logoutModalOpen: boolean;
  accessToken?: string;
  userInfo: UserInfo;
  jwtInfo: JwtInfo;
  welcomeModalOpen: boolean;
  type: LoginType;
  addressFormReturn: AddressFormReturn | null;
  signUpFormReturn: SignUpFormReturn | null;
  changePhoneOtp: boolean;
  loginByModal: boolean;
  signUpStep: SignUpStep;
};

const stateStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return await storage.getItem<string | null>(name);
  },
  setItem: async (key: string, value: unknown) => {
    return await storage.setItem(key, value);
  },
  removeItem: async (key: string) => {
    return await storage.removeItem(key);
  },
};

export const useAuthState = create(
  persist<{
    data: AuthState;
    tokenReady: boolean;
    initDataFetch: () => void;
    setLoginByModal: (loginByModal: boolean) => void;
    setPhone: (phone: string) => void;
    setPinCode: (pinCode: string) => void;
    setUserInfo: (userInfo: UserInfo) => void;
    setJwtInfo: (jwtInfo: JwtInfo) => void;
    setType: (type: LoginType) => void;
    setAddressFormReturn: (data: AddressFormReturn | null) => void;
    setSignUpFormReturn: (data: SignUpFormReturn | null) => void;
    setChangePhoneOtp: (changePhoneOtp: boolean) => void;
    showModal: (...modalNames: AuthModals[]) => void;
    setSignUpStep: (signUpStep: SignUpStep) => void;
  }>(
    (set, get) => ({
      data: {
        phone: "",
        pinCode: "",
        loginModalOpen: false,
        otpModalOpen: false,
        signUpModalOpen: false,
        emailLoginModalOpen: false,
        forgotPasswordModalOpen: false,
        addressModalOpen: false,
        welcomeModalOpen: false,
        logoutModalOpen: false,
        type: LoginType.Login,
        addressFormReturn: null,
        signUpFormReturn: null,
        userInfo: DEFAULT_USER_DATA,
        jwtInfo: DEFAULT_JWT_DATA,
        changePhoneOtp: false,
        loginByModal: true,
        signUpStep: SignUpStep.ValidateNumber,
      },
      tokenReady: false,
      initDataFetch: () => {
        // general API
      },
      setSignUpStep: (signUpStep: SignUpStep) => {
        set({ data: { ...get().data, signUpStep } });
      },
      setLoginByModal: (loginByModal: boolean) => {
        set({ data: { ...get().data, loginByModal } });
      },
      setAddressFormReturn: (addressFormReturn: AddressFormReturn | null) => {
        set({ data: { ...get().data, addressFormReturn } });
      },
      setSignUpFormReturn: (signUpFormReturn: SignUpFormReturn | null) => {
        set({ data: { ...get().data, signUpFormReturn } });
      },
      setType: (type: LoginType) => {
        set({ data: { ...get().data, type } });
      },
      setPhone: (phone: string) => {
        set({ data: { ...get().data, phone } });
      },
      setPinCode: (pinCode: string) => {
        set({ data: { ...get().data, pinCode } });
      },
      setUserInfo: (userInfo: UserInfo) => {
        set({
          data: {
            ...get().data,
            userInfo: { ...DEFAULT_USER_DATA, ...userInfo },
          },
        });
      },
      setJwtInfo: (jwtInfo: JwtInfo) => {
        set({ data: { ...get().data, jwtInfo } });
      },
      setChangePhoneOtp: (changePhoneOtp: boolean) => {
        set({ data: { ...get().data, changePhoneOtp } });
      },
      showModal: (...modalNames: (AuthModals | null)[]) => {
        set({
          data: {
            ...get().data,
            loginModalOpen: modalNames.includes(AuthModals.LOGIN),
            otpModalOpen: modalNames.includes(AuthModals.OTP),
            signUpModalOpen: modalNames.includes(AuthModals.SIGN_UP),
            emailLoginModalOpen: modalNames.includes(AuthModals.EMAIL_LOGIN),
            forgotPasswordModalOpen: modalNames.includes(
              AuthModals.FORGOT_PASSWORD,
            ),
            addressModalOpen: modalNames.includes(AuthModals.ADDRESS),
            welcomeModalOpen: modalNames.includes(AuthModals.WELCOME),
            logoutModalOpen: modalNames.includes(AuthModals.LOGOUT),
          },
        });
      },
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => stateStorage),
      onRehydrateStorage: () => {
        return (state, error) => {
          if (error) {
            console.info("an error happened during hydration", error);
          } else {
            console.info("hydration finished");

            if (!state?.data.jwtInfo.jwt) return;

            async function updateJWTToken() {
              const { fetch } = useUserInfo.getState();
              const { data } = await fetch({
                requestPayload: {
                  id: state?.data?.userInfo.id || "",
                },
              });
              if (data?.userInfo && state) {
                state.setUserInfo(data.userInfo);
                const newJwtInfo = await updateToken(state.data.jwtInfo.jwt);
                if (newJwtInfo) {
                  console.info("token updated");
                  state.tokenReady = true;
                  state.setJwtInfo(newJwtInfo);
                }
              }
            }

            updateJWTToken();
          }
        };
      },
    },
  ),
);
