import React from "react";
import { useAuthState } from "@/store/auth";
import { LoginPageWrapper } from "@/containers/auth/login/login-page-wrapper";

interface PrivateRouteProps {
  children: React.ReactElement;
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  const {
    data: { jwtInfo },
  } = useAuthState();

  if (!jwtInfo.jwt) {
    return <LoginPageWrapper />;
  }

  return children;
}
