import type {
  TokenizePaymentMethodMutation,
  TokenizePaymentMethodMutationVariables,
} from "@/__generated__/graphql";
import { TokenizePaymentMethodDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";

type Response = {
  token?: string | null;
};

export const useTokenizePaymentMethodStore = createRequestFactory<
  TokenizePaymentMethodMutation,
  Response,
  TokenizePaymentMethodMutationVariables
>({
  method: "mutation",
  graphqlDocument: TokenizePaymentMethodDocument,
  transformFunction: (res) => {
    if (res.tokenizePaymentMethod) {
      return { token: res.tokenizePaymentMethod.token };
    }
    throw new Error("Unable to tokenize payment method");
  },
});
