import { Dialog, DialogContent, View, Image, Button } from "@/components/ui";
import { useState, useEffect } from "react";
import { getPromotionStore } from "@/store/promotions/getPromotion";
import { useAuthState } from "@/store/auth";
import { PromotionStatusEnum } from "@/__generated__/graphql";
import { storage } from "@/lib/storage";
import { IconButton } from "@/components/ui/icon-button";

export function PromoPopup() {
  const {
    data: {
      userInfo: { id },
    },
  } = useAuthState();

  const [modalOpen, setModalOpen] = useState(true);
  const [hasSeenPromo, setHasSeenPromo] = useState(false);
  const { data, fetch } = getPromotionStore();

  useEffect(() => {
    const checkPromoVisibilityAndData = async () => {
      const promo = await storage.getItem("showPromo");
      setHasSeenPromo(!!promo);

      if (id) {
        fetch();
        setModalOpen(true);
      }
    };

    checkPromoVisibilityAndData();
  }, [id]);

  const title = data?.title;
  const imgUrl = data?.imgUrl;
  const buttonLabel = data?.buttonLabel ?? "";
  const buttonUrl = data?.buttonUrl;

  const currentDate = new Date();
  const startDate = new Date(data?.startDate ?? 0);
  const endDate = new Date(data?.endDate ?? 0);
  const status = data?.status;

  const isShowPromotion =
    !!data &&
    modalOpen &&
    !hasSeenPromo &&
    currentDate >= startDate &&
    currentDate <= endDate &&
    status === PromotionStatusEnum.Active;

  const handleGetPromo = (): void => {
    window.open(`${buttonUrl}, _blank`);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    storage.setItem("showPromo", "true");
  };

  return (
    <Dialog open={isShowPromotion} onOpenChange={handleCloseModal}>
      <DialogContent className="h-[482px] w-[343px] overflow-visible border-0 bg-transparent p-0 shadow-none">
        <View className="relative -z-10 flex flex-col items-center overflow-visible">
          <View className="relative z-10 h-full w-full">
            <Image
              src={imgUrl}
              alt={title}
              className="h-full w-full rounded-3xl object-cover radius-3xl"
            />
          </View>

          <Button
            variant="primary"
            color="success"
            iconName="whatsappLogo"
            rounded="full"
            children={buttonLabel}
            className="absolute bottom-8 z-10 flex h-10 w-60 flex-row gap-2"
            onClick={() => handleGetPromo}
          />
        </View>

        <View className="absolute -bottom-12 z-50 flex w-full flex-1 flex-col items-center overflow-visible">
          <IconButton
            variant="tertiary"
            rounded="full"
            color="foreground"
            iconName="x"
            onClick={handleCloseModal}
          />
        </View>
      </DialogContent>
    </Dialog>
  );
}
