import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { View, Button } from "@/components/ui";
import { onChatSales } from "@/lib/chat-sales";
import { Typography } from "../typography";
import { useIntl } from "react-intl";

export const FindBestHelperModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen(open: boolean): void;
}) => {
  const intl = useIntl();
  return (
    <BottomDrawerModal open={open} onOpenChange={setOpen} className="md:p-2">
      <View>
        <Typography
          variant={{ md: "h7", sm: "label-xl" }}
          className="md:text-left"
        >
          Let us find the best helper for your needs!
        </Typography>
        <Typography
          className="pb-8 pt-6"
          variant="body-xl"
          color="foreground-intermediate"
        >
          You have chosen more than one session a week. Let our sales
          specialists understand your needs and find you the best possible
          match!
        </Typography>
        <Button
          variant="primary"
          color="success"
          fullWidth="full"
          rounded="full"
          onClick={() => {onChatSales(true)}}
          iconName="whatsappLogo"
          children={intl.formatMessage({
            defaultMessage: "Chat Sales",
            id: "chat-sales",
          })}
        />
      </View>
    </BottomDrawerModal>
  );
};
