import type {
  ClientIssueDetailsQuery,
  ClientIssueDetailsQueryVariables,
} from "@/__generated__/graphql";
import { ClientIssueDetailsDocument } from "@/__generated__/graphql";

import { createRequestFactory } from "@/lib/request-factory";

const packageIssueOptions = (
  clientIssues: ClientIssueDetailsQuery["clientIssueDetails"],
) => {
  return clientIssues
    .map((issue) => ({
      label: issue.name,
      value: issue.id,
    }))
    .sort((a, b) => {
      const aReason = a.label;
      const bReason = b.label;
      if (aReason === "Other reason") {
        return 1;
      } else if (bReason === "Other reason") {
        return -1;
      } else {
        return aReason.localeCompare(bReason);
      }
    });
};

export const usePackageIssueDetailsStore = createRequestFactory<
  ClientIssueDetailsQuery,
  ReturnType<typeof packageIssueOptions>,
  ClientIssueDetailsQueryVariables
>({
  method: "query",
  graphqlDocument: ClientIssueDetailsDocument,
  transformFunction(data) {
    return packageIssueOptions(data.clientIssueDetails ?? []);
  },
});
