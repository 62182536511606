import { Message } from "@/components/shared/message";
import { ShieldCheck } from "@/components/shared/icons";

export const BookingPayLater = () => {
  return (
    <Message
      variant="info"
      icon={ShieldCheck}
      title="Book Now, Pay Later ✨"
      message="We only collect payment after the job is done, not before to give
            you that extra peace of mind! We take all Credit Cards and PayNow,
            and will send you more details on payment once you book 😊"
    />
  );
};
