import type {
  PackageDepartmentEnum,
  VisitsByFiltersQueryVariables,
} from "@/__generated__/graphql";
import { VisitStatusEnum } from "@/__generated__/graphql";
import { ListHeading } from "@/components/shared/list-header";
import { Button, View } from "@/components/ui";
import type { Icon } from "@/components/shared/icons";
import { CalendarDots, CalendarX, MapPinArea } from "@/components/shared/icons";
import { addDays, addMonths, startOfYesterday } from "date-fns";
import { formatDate } from "@/lib/helpers/date";
import { useEffect } from "react";
import { useAuthState } from "@/store/auth";
import { useNext7DayVisitsStore } from "@/store/visits/next7dayVisits";
import { useNext30DayVisitsStore } from "@/store/visits/next30dayVisits";
import { useUnscheduledVisitsStore } from "@/store/visits/unscheduledVisits";
import { usePast30DayVisitsStore } from "@/store/visits/past30dayVisits";
import type { VisitData } from "@/components/shared/visits/visit-card";
import { VisitCard } from "@/components/shared/visits/visit-card";
import { EmptyVisit } from "../empty-visit";
import type { RequestPaginatedFactoryType } from "@/lib/request-factory";
import type { VisitListType } from "@/store/visits/visitList";
import { useVisitDetailStore } from "@/store/visits/visitDetail";
import { useVisitStore } from "@/store/visits/visitStore";
import { useShallow } from "zustand/react/shallow";
import { useIntl } from "react-intl";
import { useRoute } from "@/components/shared/router";
import { bookingRoutes } from "@/components/hooks/use-booking-route";
import { getServiceNameFromDepartment } from "@/lib/booking-lib";

type VisitListOptions = {
  title: string;
  tlId: string;
  icon: Icon;
  subtitle?: string;
  hasLoadMore: boolean;
  useStore: RequestPaginatedFactoryType<
    VisitData,
    VisitsByFiltersQueryVariables
  >;
  initialFilters: (
    clientId: string,
  ) => VisitsByFiltersQueryVariables["filters"];
};

const listType: Record<VisitListType, VisitListOptions> = {
  next7days: {
    title: "Visits in the next 7 days",
    tlId: "visits.7Days",
    icon: MapPinArea,
    hasLoadMore: false,
    useStore: useNext7DayVisitsStore,
    initialFilters: (clientId) => ({
      clientId: [clientId],
      from: formatDate(new Date()),
      to: formatDate(addDays(new Date(), 6)),
    }),
  },
  unscheduled: {
    title: "Unscheduled Visits",
    tlId: "visits.unscheduled",
    icon: CalendarX,
    hasLoadMore: false,
    useStore: useUnscheduledVisitsStore,
    initialFilters: (clientId) => ({
      clientId: [clientId],
      from: formatDate(new Date()),
      to: formatDate(addMonths(new Date(), 1)),
      status: [
        VisitStatusEnum.PendingClientSchedule,
        VisitStatusEnum.UnableToSchedule,
      ],
    }),
  },
  next30days: {
    title: "Visits in the next 30 days",
    tlId: "visits.30Days",
    icon: CalendarDots,
    hasLoadMore: true,
    useStore: useNext30DayVisitsStore,
    initialFilters: (clientId) => ({
      clientId: [clientId],
      from: formatDate(addDays(new Date(), 7)),
      to: formatDate(addMonths(new Date(), 1)),
    }),
  },
  past30days: {
    title: "Past Visits",
    tlId: "visits.past",
    icon: MapPinArea,
    hasLoadMore: true,
    useStore: usePast30DayVisitsStore,
    initialFilters: (clientId) => ({
      clientId: [clientId],
      from: formatDate(addMonths(startOfYesterday(), -1)),
      to: formatDate(startOfYesterday()),
    }),
  },
};

export function VisitList({ type }: { type: VisitListType }) {
  const intl = useIntl();
  const { push } = useRoute();

  const user = useAuthState((state) => state.data.userInfo);
  const handleViewDetail = useVisitDetailStore((state) => state.openVisitModal);
  const openRateVisitModalById = useVisitDetailStore(
    (state) => state.openRateVisitModalById,
  );
  const openRescheduleVisitConfirmation = useVisitDetailStore(
    (state) => state.openRescheduleVisitConfirmation,
  );

  const { title, tlId, subtitle, icon, hasLoadMore, initialFilters, useStore } =
    listType[type];
  const {
    fetch: getVisits,
    data: visits,
    loading,
    fetchMore,
    pagination,
  } = useStore(useShallow((state) => state));

  const shouldShowLoadMore = hasLoadMore && pagination.total > visits.length;

  useEffect(() => {
    if (user.id) {
      getVisits({
        requestPayload: {
          filters: initialFilters(user.id),
        },
      });
    }
  }, [user.id, initialFilters]);

  const onLoadMore = () => {
    fetchMore({
      requestPayload: {
        filters: {
          ...initialFilters(user.id),
        },
      },
    });
  };

  const handleRebook = (department: PackageDepartmentEnum) => {
    const serviceName = getServiceNameFromDepartment(department);
    push(bookingRoutes[serviceName][0]);
  };

  const handleSetSchedule = (visitId: string) => {
    openRescheduleVisitConfirmation(visitId);
  };

  const handleRateVisit = (visitId: string) => {
    openRateVisitModalById(visitId);
    useVisitStore.getState().fetch({
      requestPayload: {
        id: visitId,
      },
    });
  };

  return (
    <View className="flex flex-col gap-4">
      <ListHeading
        icon={icon}
        title={intl.formatMessage({
          defaultMessage: title,
          id: tlId,
        })}
        subtitle={subtitle}
      />
      {visits?.length ? (
        visits.map((visit) => (
          <VisitCard
            key={`${visit.id}-type`}
            visitData={visit}
            handleViewDetail={handleViewDetail}
            handleRateVisit={handleRateVisit}
            handleRebook={handleRebook}
            handleSetSchedule={handleSetSchedule}
          />
        ))
      ) : (
        <EmptyVisit type={type === "past30days" ? "history" : "upcoming"} />
      )}
      {shouldShowLoadMore && (
        <Button
          variant="tertiary"
          className="w-full"
          loading={loading}
          onClick={onLoadMore}
          iconName="arrowCounterClockwise"
          iconAlignment="end"
          children={intl.formatMessage({
            defaultMessage: "Show More",
            id: "show-more",
          })}
        />
      )}
    </View>
  );
}
