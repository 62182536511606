import type { ReactNode } from "react";
import { View } from "@/components/ui";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

//to enhance styles flexibility set the overflow to visible here, then let the children to define the overflow
const columnVariants = cva("overflow-visible", {
  variants: {
    mobile: {
      none: "hidden w-0",
      auto: "block w-auto",
      full: "block w-full",
      "1": "block w-1/12",
      "2": "block w-2/12",
      "3": "block w-3/12",
      "4": "block w-4/12",
      "5": "block w-5/12",
      "6": "block w-6/12",
      "7": "block w-7/12",
      "8": "block w-8/12",
      "9": "block w-8/12",
      "10": "block w-8/12",
      "11": "block w-11/12",
    },
    desktop: {
      none: "md:hidden md:w-0",
      auto: "md:block md:w-auto",
      full: "md:block md:w-full",
      "1": "md:block md:w-1/12",
      "2": "md:block md:w-2/12",
      "3": "md:block md:w-3/12",
      "4": "md:block md:w-4/12",
      "5": "md:block md:w-5/12",
      "6": "md:block md:w-6/12",
      "7": "md:block md:w-7/12",
      "8": "md:block md:w-8/12",
      "9": "md:block md:w-9/12",
      "10": "md:block md:w-10/12",
      "11": "md:block md:w-11/12",
    },
  },
  defaultVariants: {
    mobile: "full",
  },
});

type Props = VariantProps<typeof columnVariants> & { children: ReactNode };

export function Column({ mobile, desktop, children }: Props) {
  return (
    <View className={columnVariants({ mobile, desktop })}>{children}</View>
  );
}
