import { View } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { ArrowLeft } from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { CreateAddressForm } from "../CreateAddressForm";
import type { CreateAddressProps } from "..";

export const CreateAddressScreen = ({
  control,
  onAddAddressByPostalCode,
  postalCodeLoading,
  createAddressLoading,
  onSubmit,
}: CreateAddressProps) => {
  const intl = useIntl();
  return (
    <View>
      <View className="static mb-4 flex flex-row items-center justify-between border-b md:mb-8 md:pb-4">
        <View className="flex flex-row items-center gap-2 p-4 md:p-0">
          <Link href="/profile/addresses">
            <ArrowLeft className="size-5" />
          </Link>
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            {intl.formatMessage({
              defaultMessage: "Add new Address",
              id: "add-new-address",
            })}
          </Typography>
        </View>
      </View>

      <CreateAddressForm
        control={control}
        postalCodeLoading={postalCodeLoading}
        onAddAddressByPostalCode={onAddAddressByPostalCode}
        onSubmit={onSubmit}
        createAddressLoading={createAddressLoading}
      />
    </View>
  );
};
