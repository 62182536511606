import { useState } from "react";
import { UserDrawer } from "./user-drawer";
import { NavbarHeader } from "./navbar-header";

export function AppNavbar() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <NavbarHeader open={open} setOpen={setOpen} />
      <UserDrawer open={open} setOpen={setOpen} />
    </>
  );
}
