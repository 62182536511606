import { useBookingRoute } from "@/components/hooks/use-booking-route";
import { ActionNavigation } from "@/components/shared/booking/action-navigation";
import { NativeActionNavigation } from "@/components/shared/booking/native-action-navigation";
import {
  BookingNavigationBottom,
  BookingOrderCard,
} from "@/components/shared/booking/order-card";
import { ChatWithSales } from "@/components/shared/chat-with-sales";
import { View } from "@/components/ui";
import { getHomeBeautyPackageFromCode } from "@/constants/booking";
import { getPlatform } from "@/lib/utils";
import { useBookingState } from "@/store/booking/useBookingState";
import type { ServiceName } from "@/types/service";
import { UpholsteryServiceNames } from "@/types/service";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const BookingNavigation = ({ name }: { name: ServiceName }) => {
  const intl = useIntl();
  const {
    data: { selectedPackageIds, homeBeautyFreeGelServiceIds, packageDetails },
    setBookingState,
  } = useBookingState();
  const { forward } = useBookingRoute();
  const platform = getPlatform();
  const isOrderNavigation = useMemo(
    () =>
      [...UpholsteryServiceNames, "aircon-service", "home-beauty"].includes(
        name,
      ),
    [name],
  );

  const disabled = useMemo(() => {
    if (isOrderNavigation) {
      // make sure enough amount of service is selected for freeGelRemoval
      if (name === "home-beauty") {
        if (homeBeautyFreeGelServiceIds.length) {
          const arr = homeBeautyFreeGelServiceIds.map((id) => {
            const code = packageDetails.find((pkg) => pkg.id === id)
              ?.code as string;
            const pkg = getHomeBeautyPackageFromCode(code);
            return (
              selectedPackageIds.filter((selectedId) => selectedId === id)
                .length >= (pkg.minFreeGelAmount || 1000)
            );
          });
          if (arr.indexOf(false) !== -1) return true;
        }
      }
      return !selectedPackageIds.length;
    }
    return false;
  }, [name, selectedPackageIds.length]);

  const onClickButton = () => {
    switch (name) {
      case "home-cleaning":
        setBookingState({
          homeCleaningDurationModalOpen: true,
        });
        break;
      default:
        forward();
    }
  };

  if (isOrderNavigation) {
    return (
      <View>
        {/* large screen */}
        {platform === "web" && (
          <View className="hidden flex-col gap-4 md:flex">
            <BookingOrderCard
              buttonText={intl.formatMessage({
                defaultMessage: "Proceed",
                id: "proceed",
              })}
              onContinue={onClickButton}
              disabled={disabled}
              loading={false}
            />
            <ChatWithSales />
          </View>
        )}

        {/* narrow screen */}
        <BookingNavigationBottom
          buttonText={intl.formatMessage({
            defaultMessage: "Proceed",
            id: "proceed",
          })}
          showExtendIcon={!!selectedPackageIds.length}
          onContinue={onClickButton}
          disabled={disabled}
        />
      </View>
    );
  }

  return (
    <View>
      {/* large screen */}
      <ActionNavigation
        buttonText={intl.formatMessage({
          defaultMessage: "Proceed",
          id: "proceed",
        })}
        onClickButton={onClickButton}
      />
      <View className="hidden md:block">
        <ChatWithSales />
      </View>
      {/* narrow screen */}
      <NativeActionNavigation
        buttonText={intl.formatMessage({
          defaultMessage: "Proceed",
          id: "proceed",
        })}
        onClickButton={onClickButton}
      />
    </View>
  );
};
