import { useFragment } from "@/__generated__";
import type { VisitQuery, VisitQueryVariables } from "@/__generated__/graphql";
import {
  VisitDocument,
  PackageDepartmentEnum,
  PackageServiceTypeEnum,
  VisitStatusEnum,
  VisitClientFragmentFragmentDoc,
} from "@/__generated__/graphql";
import type { VisitDetailData } from "@/components/shared/visits/visit-detail";
import { mapToVisitDetailData } from "@/components/shared/visits/visit-detail";
import { createRequestFactory } from "@/lib/request-factory";

export const initialVisitData: VisitDetailData = {
  id: "",
  department: PackageDepartmentEnum.HomeCleaning,
  serviceType: PackageServiceTypeEnum.AdHoc,
  status: VisitStatusEnum.Started,
  value: 0,
  serviceDate: new Date(),
  startTime: "00:00:00",
  endTime: "00:00:00",
  address: "",
  postalCode: "",
  worker: {
    fullName: "",
    avatarUrl: null,
    phoneNumber: null,
  },
  lineItems: [],
  rating: 0,
  ratingComment: "",
  reviewImageUrls: [],
  reportDate: null,
  token: null,
};

export const useVisitStore = createRequestFactory<
  VisitQuery,
  VisitDetailData,
  VisitQueryVariables
>(
  {
    method: "query",
    graphqlDocument: VisitDocument,
    transformFunction(data) {
      const visit = useFragment(VisitClientFragmentFragmentDoc, data.visit);
      return mapToVisitDetailData(visit);
    },
  },
);
