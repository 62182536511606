import type { RecommendedService } from "@/components/shared/recommended-card";
import { RecommendedCard } from "@/components/shared/recommended-card";
import { View } from "@/components/ui";
import { cn } from "@/lib/utils";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";

export const SuggestionServiceList = ({
  items,
}: {
  items: RecommendedService[];
}) => {
  const { isDesktop } = useWindowDimensions();

  if (isDesktop)
    return (
      <View className="columns-2 md:columns-3">
        {items.map((card, index) => (
          <View className="mb-4" key={`${card.title}-${index}`}>
            <RecommendedCard {...card} />
          </View>
        ))}
      </View>
    );

  return (
    <View className="flex flex-row flex-wrap">
      {items.map((card, index) => (
        <View
          key={`${card.title}-${index}`}
          className={cn("mb-4 basis-1/2", {
            "pr-1": index % 2 === 0,
            "pl-1": index % 2 === 1,
          })}
        >
          <RecommendedCard {...card} />
        </View>
      ))}
    </View>
  );
};
