import { WebLayout } from "@/components/shared/layout/homepage.web";
import { Container } from "@/components/ui/layout";
import { NotFoundScreen } from "@/containers/page-not-found";

export function NotFoundPage() {
  return (
    <WebLayout>
      <Container gap="xl">
        <NotFoundScreen />
      </Container>
    </WebLayout>
  );
}
