import { Typography } from "@/components/shared/typography";
import { View } from "@/components/ui";
import { Info } from "@/components/shared/icons";
import { useAuthState } from "@/store/auth";
import { formatPrice } from "@/lib/helpers/number";
import { Link } from "@/components/shared/link";

export default function OverdueInvoiceBanner() {
  const isLoggedIn = useAuthState((state) => state.data.jwtInfo.jwt);
  const billingAccount = useAuthState(
    (state) => state.data.userInfo.billingAccount,
  );

  if (!isLoggedIn || billingAccount.totalOverdueInvoice === 0) return null;

  return (
    <View className="w-full bg-danger-surface px-6 py-4">
      <View className="container">
        <View className="flex flex-row items-start gap-2">
          <Info weight="fill" className="h-4 w-4 color-danger" />
          <View className="flex flex-1 flex-col items-start justify-between gap-2 md:flex-row md:items-center">
            <View className="flex flex-col">
              <Typography variant="label-lg" color="danger">
                Overdue invoice
              </Typography>
              <Typography variant="label-lg" color="danger">
                You have {billingAccount.totalOverdueInvoice} unpaid invoice
                totaling {formatPrice(billingAccount.overdueAmount)}.
              </Typography>
            </View>
            <View className="self-start md:self-center">
              <Link href="/profile/invoices">
                <Typography variant="label-lg" color="brand-primary">
                  View Invoice Detail
                </Typography>
              </Link>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
