import { Typography } from "@/components/shared/typography";
import { Card } from "@/components/ui";
import { cn } from "@/lib/utils";

export const SimpleTimeSlotCard = ({
  onCardClick,
  active,
  timeRange,
}: {
  onCardClick?: () => void;
  timeRange: string;
  active?: boolean;
}) => {
  return (
    <Card
      onClick={onCardClick}
      radius={"lg"}
      className={cn(
        "grow-0 cursor-pointer items-center px-2 py-3 hover:divide-primary-border hover:bg-primary-surface md:p-4",
        {
          "border-primary-border bg-primary-surface": active,
        },
      )}
    >
      <Typography variant="label-sm" align="center">
        {timeRange}
      </Typography>
    </Card>
  );
};
