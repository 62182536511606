import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import type { Address } from "@/types/users";
import { View, Button, RadioGroup, RadioGroupItem } from "@/components/ui";
import { MapPinSimpleArea } from "@/components/shared/icons";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { IfElse } from "../../if-else";

const AddButton = ({
  onClick,
  className,
  disabled,
}: {
  onClick(): void;
  disabled: boolean;
  className?: string;
  size?: "sm" | "default" | "xs" | "lg";
}) => {
  return (
    <Button
      variant="secondary"
      color="CTA2"
      rounded="full"
      onClick={onClick}
      className={cn(className)}
      size="md"
      disabled={disabled}
      iconName="plus"
      iconColor="CTA2"
      children="Add"
    />
  );
};

export const SelectAddressModal = ({
  open,
  addresses,
  selectedAddress,
  setOpen,
  onAddAddress,
  setSelectedAddress,
}: {
  open: boolean;
  addresses: Address[];
  selectedAddress: Address | null;
  setOpen(open: boolean): void;
  onAddAddress(): void;
  setSelectedAddress(address: Address): void;
}) => {
  const [currentSelectedAddress, setCurrentSelectedAddress] =
    useState(selectedAddress);

  useEffect(() => {
    setCurrentSelectedAddress(selectedAddress);
  }, [open, selectedAddress]);
  const intl = useIntl();
  const { isDesktop } = useWindowDimensions();

  return (
    <BottomDrawerModal open={open} onOpenChange={setOpen}>
      <View className="flex flex-row items-center justify-between">
        <View className="flex flex-row items-center gap-3">
          <MapPinSimpleArea className="text-primary" size={24} weight="fill" />
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            {intl.formatMessage({
              defaultMessage: "Select Service Address",
              id: "select-address",
            })}
          </Typography>
        </View>
        {!isDesktop && (
          <AddButton
            onClick={onAddAddress}
            disabled={addresses.length >= 4}
            size="xs"
          />
        )}
      </View>

      <RadioGroup
        value={currentSelectedAddress?.id}
        onValueChange={(id) => {
          const currentAddress = addresses.find(
            (addr) => addr.id === id,
          ) as Address;
          setCurrentSelectedAddress(currentAddress);
        }}
      >
        {addresses.map((addr) => (
          <View
            key={addr.id}
            className="flex cursor-pointer flex-row items-center justify-between border-b p-2 pb-4"
            onClick={() => {
              setCurrentSelectedAddress(addr);
            }}
          >
            <Typography variant="label-md">{addr.fullAddress}</Typography>
            <RadioGroupItem value={addr.id as string} checkCircle />
          </View>
        ))}
      </RadioGroup>
      <IfElse
        if={isDesktop}
        else={
          <Button
            variant="primary"
            color="CTA"
            onClick={() => {
              setSelectedAddress(currentSelectedAddress as Address);
              setOpen(false);
            }}
            children={intl.formatMessage({
              defaultMessage: "Save",
              id: "save",
            })}
          />
        }
      >
        <View className="flex flex-row gap-2">
          <AddButton
            className="flex-1"
            onClick={onAddAddress}
            disabled={addresses.length >= 4}
          />
          <Button
            className="flex-1 rounded-full"
            variant="primary"
            color="CTA"
            onClick={() => {
              setSelectedAddress(currentSelectedAddress as Address);
              setOpen(false);
            }}
            children={intl.formatMessage({
              defaultMessage: "Save",
              id: "save",
            })}
          />
        </View>
      </IfElse>
    </BottomDrawerModal>
  );
};
