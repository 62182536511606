import { View } from "@/components/ui";
import { useAuthState } from "@/store/auth";
import { AccountInfoCard } from "@/components/shared/account-info-card";
import { fullName } from "@/lib/helpers/string";
import { NotePencil } from "@/components/shared/icons";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { ContactMoreActions } from "./contact-more-actions";
import { useClientContactsStore } from "@/store/profile/clientContacts";
import { useEffect } from "react";
import { Spin } from "@/components/shared/spin";
import { InfoCard } from "@/components/shared/info-card";
import { Typography } from "@/components/shared/typography";
import { useRoute } from "@/components/shared/router";
import { cn } from "@/lib/utils";

const ContactActions = ({ primary, id }: { primary: boolean; id: string }) => {
  const { push } = useRoute();

  const handleClick = () => {
    push({
      pageKey: "contact",
      params: {
        id,
      },
    });
  };
  return (
    <View className="flex w-full flex-1 flex-row items-center gap-2">
      <View
        className="flex w-full flex-1 flex-row items-center justify-center gap-1 rounded-full border border-primary-border p-2"
        onClick={handleClick}
      >
        <NotePencil className="size-5 text-primary" size={20} />
        <Typography variant="label-md" color="brand-primary">
          Edit Contact
        </Typography>
      </View>

      {!primary && <ContactMoreActions contactId={id} />}
    </View>
  );
};

export const ContactListContainer = () => {
  const userInfo = useAuthState((state) => state.data.userInfo);
  const { fetch, data, loading } = useClientContactsStore();
  const { isMobile, height } = useWindowDimensions();

  const isScroll = height <= 800;

  useEffect(() => {
    fetch({
      requestPayload: {
        filters: {
          clientId: userInfo.id,
        },
      },
    });
  }, [userInfo.contacts]);

  const contacts = data?.clientContacts;

  //TODO: report to sentry if address null
  if (!contacts) return null;

  //TODO: use sort from BE, need to create a FS task to update this to align
  const sortedContacts = [...contacts].sort((a, b) => {
    return Number(b.primary) - Number(a.primary);
  });

  return (
    <View
      className={cn(
        "flex flex-col gap-4 px-4 py-4 md:px-0 md:pt-0",
        isScroll && "max-h-[500px] overflow-scroll pb-20",
      )}
    >
      <InfoCard
        description={
          "Changing the phone number on a contact does not update your account login number. If you need to change your login number, please update it in your account settings."
        }
      />
      {loading ? (
        <Spin />
      ) : (
        sortedContacts.map((item) => (
          <AccountInfoCard
            key={item.id}
            primary={item.primary}
            header={
              fullName({
                firstName: item.firstName,
                lastName: item.lastName,
              }) ?? ""
            }
            bodyText={item.email[0] ?? ""}
            subBodyText={item.phoneNumber[0] ?? ""}
            actions={<ContactActions primary={item.primary} id={item.id} />}
            direction={isMobile ? "col" : "row"}
          />
        ))
      )}
    </View>
  );
};
