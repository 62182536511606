import { View } from "@/components/ui";
import type { TimeSlotGridProps } from "@/components/shared/time-slot-with-search/time-slot-grid";
import { TimeSlotGrid } from "@/components/shared/time-slot-with-search/time-slot-grid";
import { useEffect, useMemo, useRef } from "react";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";

export const TimeSlotGridContainer = (props: TimeSlotGridProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowDimensions();
  const width = useMemo(() => {
    return (isMobile ? 980 : 1200) / 7;
  }, [isMobile]);

  useEffect(() => {
    scrollRef.current?.scrollTo({
      left: props.firstNoEmptyIndex > 0 ? width * props.firstNoEmptyIndex : 0,
      behavior: "smooth",
    });
  }, [props.firstNoEmptyIndex]);

  return (
    <div
      className="relative min-h-80 overflow-x-scroll rounded-lg border border-secondary-border bg-background-intermediate"
      ref={scrollRef}
    >
      <View className="w-[980px] md:w-[1200px]">
        <TimeSlotGrid {...props} />
      </View>
    </div>
  );
};
