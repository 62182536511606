import { Input, View } from "@/components/ui";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { Typography } from "../typography";
import type { MaskedTextInputProps } from "./type";

export const MaskedTextInput = ({
  label,
  name,
  mask,
  placeHolder,
  defaultValue = "",
  control,
}: MaskedTextInputProps) => {
  return (
    <View className="flex flex-col gap-1 pb-3">
      <Typography variant="body-lg">{label}</Typography>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <InputMask
              mask={mask}
              maskChar={null}
              value={value}
              onChange={(e) => {
                const formattedValue = e.target.value.replace(/\s/g, "");
                onChange(formattedValue);
              }}
            >
              <Input placeholder={placeHolder} className="flex-1" />
            </InputMask>
            {error && (
              <Typography variant="body-sm" className="text-red-600">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
    </View>
  );
};
