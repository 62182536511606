import { Dialog, DialogContent, View, Button } from "@/components/ui";
import { Typography } from "../typography";
import { logout } from "@/store/auth/signOut";
import { useRoute } from "@/components/shared/router";
import { useAuthState } from "@/store/auth";

export const LogoutDialog = () => {
  const { replace } = useRoute();
  const {
    data: { logoutModalOpen },
    showModal,
  } = useAuthState();
  const handleClose = () => {
    showModal();
  };

  const handleLogout = () => {
    logout().then((res) => {
      if (res?.error) return;
      replace({ pageKey: "homepage" });
    });
    handleClose()
  }
  return (
    <Dialog open={logoutModalOpen} onOpenChange={handleClose}>
      <DialogContent className="w-[343px] max-w-full justify-stretch rounded-lg">
        <View className="native:p-1 flex flex-col gap-2">
          <Typography variant="label-2xl">
            Are you sure you want to log out?
          </Typography>
          <Typography variant="label-lg" color="foreground-intermediate">
            After logging out, you’ll need to log in again to access your
            account.
          </Typography>
          <View className="mt-4 flex w-full flex-row gap-2">
            <Button
              variant="secondary"
              color="danger"
              rounded="full"
              fullWidth="full"
              className="mt-2"
              onClick={handleLogout}
              children="Logout"
            />
            <Button
              variant="primary"
              color="CTA"
              rounded="full"
              fullWidth="full"
              className="mt-2"
              onClick={handleClose}
              children="Cancel"
            />
          </View>
        </View>
      </DialogContent>
    </Dialog>
  );
};
