import { WebLayout } from "@/components/shared/layout/homepage";
import { BookingInfo } from "@/containers/booking-info";
import type { ServiceName } from "@/types/service";
import { useParams } from "@/components/hooks/use-params";

export const BookingInfoPage = () => {
  const { name } = useParams<{ name: ServiceName }>();
  if (!name) {
    return <div>404</div>;
  }
  return (
    <WebLayout>
      <BookingInfo name={name} />
    </WebLayout>
  );
};
