import { Image, View } from "@/components/ui";
import { Card, CardContent } from "@/components/ui/card";
import BannerReferralImage from "@/assets/images/banner-referral-icon.svg";
import BannerRewardImage from "@/assets/images/banner-reward-icon.svg";
import { cn, getPlatform } from "@/lib/utils";
import type { ReactNode } from "react";
import { Typography } from "../typography";

const iconImg = {
  "my-reward": BannerRewardImage,
  referral: BannerReferralImage,
};

export function Banner({
  type,
  title,
  description,
  action,
}: {
  title: string;
  description: string;
  action?: ReactNode;
  type: "my-reward" | "referral";
}) {
  const platform = getPlatform();

  return (
    <Card
      shadow="none"
      border="none"
      className={cn("overflow-hidden", {
        "bg-gradient-to-r from-primary to-[#308FFF]": type === "referral",
        "bg-gradient-to-r from-[#FF7B43] to-[#FFA471]": type === "my-reward",
        "bg-primary": platform === "native" && type === "referral",
        "bg-secondary-button": platform === "native" && type === "my-reward",
      })}
    >
      <CardContent
        className={cn("px-3 py-4", {
          "bg-[url('@/assets/images/banner-blue-bg.png')]": type === "referral",
          "bg-[url('@/assets/images/banner-orange-bg.png')]":
            type === "my-reward",
        })}
      >
        <View className="flex flex-row items-center gap-4">
          <Image width={44} height={44} src={iconImg[type]} alt={type} />
          <View className="flex flex-1 flex-col gap-2">
            <Typography color="white" variant={{ md: "h6", sm: "body-2xl" }}>
              {title}
            </Typography>
            <Typography
              color="white"
              variant={{ md: "body-lg", sm: "body-md" }}
            >
              {description}
            </Typography>
          </View>
          {action}
        </View>
      </CardContent>
    </Card>
  );
}
