import { View } from "@/components/ui";
import { cn } from "@/lib/utils";
import React from "react";
import { Typography } from "../typography";

export const Divider = ({
  vertical,
  children,
  className,
  dashed,
}: {
  vertical?: boolean;
  children?: React.ReactNode;
  className?: string;
  dashed?: boolean;
}) => {
  if (children) {
    return (
      <View
        className={cn(
          "flex items-center",
          vertical ? "flex-col" : "flex-row",
          className,
        )}
      >
        <View
          className={cn(
            "flex-1 bg-border-medium",
            vertical ? "pr-px" : "pt-px",
          )}
        ></View>
        <Typography
          className={cn(
            "px-2 text-sm text-secondary-text",
            vertical && "rotate-90",
          )}
        >
          {children}
        </Typography>
        <View
          className={cn(
            "flex-1 bg-border-medium",
            vertical ? "pr-px" : "pt-px",
          )}
        ></View>
      </View>
    );
  }
  return (
    <View
      className={cn(
        "border-border-medium",
        vertical ? "w-px border-r" : "h-px border-b",
        { "border-dashed": dashed },
        className,
      )}
    />
  );
};
