import { WebLayout } from "@/components/shared/layout/homepage";
import { StickyScrollNavbar } from "@/components/shared/sticky-scroll-navbar";
import { ServiceDetail } from "@/containers/service-detail";
import type { ServiceName } from "@/types/service";
import { useParams } from "@/components/hooks/use-params";

const menuItems = [
  { label: "Service", key: "service" },
  { label: "Reviews", key: "reviews" },
  { label: "Details", key: "details" },
  { label: "FAQs", key: "faqs" },
];

export function ServiceDetailPage() {
  const { name } = useParams<{ name: ServiceName }>();

  if (!name) {
    return <div>404</div>;
  }

  return (
    <WebLayout>
      <StickyScrollNavbar items={menuItems} activeMenuKey="service">
        <ServiceDetail name={name} />
      </StickyScrollNavbar>
    </WebLayout>
  );
}
