import { ShareReferralBanner } from "@/containers/banners/share-referral";
import { View } from "@/components/ui";
import { VisitList } from "../visit-list";
import { MakeABooking } from "@/containers/make-a-booking";
import { useNext7DayVisitsStore } from "@/store/visits/next7dayVisits";
import { useNext30DayVisitsStore } from "@/store/visits/next30dayVisits";
import { useUnscheduledVisitsStore } from "@/store/visits/unscheduledVisits";
import { EmptyVisit } from "../empty-visit";
import { ServiceAllIcon } from "@/components/shared/icons";
import { SuggestionServiceSection } from "@/containers/suggestion-services";
import type { RecommendedService } from "@/components/shared/recommended-card";
import { useEffect } from "react";
import { useVisitListState } from "@/store/visits/visitList";

const services: RecommendedService[] = [
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 56,
    sessionBooked: "1.5k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 55,
    sessionBooked: "1.1k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    recommended: true,
    sessionBooked: "1.9k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    sessionBooked: "1.5k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    sessionBooked: "1.5k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    sessionBooked: "1.5k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    sessionBooked: "1.5k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    sessionBooked: "1.5k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    sessionBooked: "1.5k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    sessionBooked: "1.5k",
  },
  {
    title: "Sofa Cleaning (Chair)",
    imageSrc:
      "https://images.unsplash.com/photo-1493799817216-4b57dda4229f?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 54,
    sessionBooked: "1.5k",
  },
];

export const VisitsUpcoming = ({
  refreshing,
  setRefreshing,
}: {
  refreshing?: boolean;
  setRefreshing?(value: boolean): void;
}) => {
  const next7daysRefetch = useNext7DayVisitsStore((state) => state.refetch);
  const next30daysRefetch = useNext30DayVisitsStore((state) => state.refetch);
  const unscheduledRefetch = useUnscheduledVisitsStore(
    (state) => state.refetch,
  );

  const isEmpty = useVisitListState((state) =>
    Object.values(state.data.listEmpty).every((value) => value),
  );

  useEffect(() => {
    async function refetch() {
      await Promise.all([
        next7daysRefetch(),
        next30daysRefetch(),
        unscheduledRefetch(),
      ]);

      setRefreshing?.(false);
    }

    if (refreshing) {
      refetch();
    }
  }, [refreshing]);

  return (
    <View className="flex flex-col gap-6 px-4 md:px-0">
      <View className="mt-6">
        <ShareReferralBanner />
      </View>
      {isEmpty ? (
        <>
          <EmptyVisit type="upcoming" />
          <MakeABooking />
          <SuggestionServiceSection
            title="You might also want this"
            icon={ServiceAllIcon}
            items={services}
          />
        </>
      ) : (
        <>
          <VisitList type="next7days" />
          <VisitList type="unscheduled" />
          <VisitList type="next30days" />
        </>
      )}
    </View>
  );
};
