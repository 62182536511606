import { Dialog, DialogContent } from "@/components/ui";
import { SignUpContent } from "../signup-content";
import { useAuthState } from "@/store/auth";

export function SignUpModal() {
  const {
    data: { signUpModalOpen },
    showModal,
  } = useAuthState();
  const onClose = () => {
    showModal();
  };
  return (
    <Dialog open={signUpModalOpen} onOpenChange={onClose}>
      <DialogContent className="w-[343px]">
        <SignUpContent />
      </DialogContent>
    </Dialog>
  );
}
