import { Link } from "@/components/shared/link";
import { ArrowLeft } from "@/components/shared/icons";
import { View } from "@/components/ui";
import { GeneralCreditAccountContainer } from "./GeneralCreditAccountContainer";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const CreditAccount = () => {
  const intl = useIntl();
  return (
    <View className="flex flex-1 flex-col gap-6">
      <View className="static flex flex-1 flex-row items-center justify-between border-b pb-4">
        <View className="flex flex-row items-center gap-2 p-4 md:p-0">
          <Link href="/profile/overview">
            <ArrowLeft className="size-5" />
          </Link>
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            {intl.formatMessage({
              defaultMessage: "Account Credit",
              id: "account.credit",
            })}
          </Typography>
        </View>
      </View>
      <GeneralCreditAccountContainer />
    </View>
  );
};
