import { Divider } from "@/components/shared/divider";
import { Link } from "@/components/shared/link";
import { View } from "@/components/ui";
import { ArrowLeft } from "@/components/shared/icons";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { useParams } from "@/components/hooks/use-params";
import { InvoiceDetail } from "@/containers/profile/invoices/invoice-detail";
import { Navigate } from "react-router-dom";
import { PortalHost } from "@/components/shared/portal";
import { Typography } from "@/components/shared/typography";
import { PaymentMethodContainer } from "@/containers/profile/invoices/payment-method";

export function InvoiceDetailPage() {
  const { isMobile } = useWindowDimensions();

  const { id } = useParams<{ id: string }>();

  if (!id) {
    return <Navigate to="/profile/invoices" />;
  }

  return (
    <View>
      <View className="mb-2 flex flex-row items-center justify-between gap-2">
        <View className="flex flex-row items-center gap-2 p-4 md:p-0">
          {isMobile && (
            <Link href="/profile/invoices">
              <ArrowLeft className="size-5" />
            </Link>
          )}
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            Invoice Details
          </Typography>
        </View>
        <PortalHost name="BottomActions" />
      </View>
      <Divider />
      <InvoiceDetail invoiceId={id} />
      <PaymentMethodContainer />
    </View>
  );
}
