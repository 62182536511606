import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  View,
  Image,
} from "@/components/ui";
import { useImagePreviewStore } from "@/store/image-preview";
import { IconButton } from "@/components/ui/icon-button";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import type { CarouselApi } from "@/components/ui/carousel";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { useCallback, useEffect, useState } from "react";
import { cn } from "@/lib/utils";

interface Props {
  onRemoveImage?(imageUrl: string | File, index: number): void;
}

export function ImagePreviewGallery({ onRemoveImage }: Props) {
  const {
    data: { open, imageUrls, activeIndex },
    closeImagePreview,
  } = useImagePreviewStore();

  const { isDesktop } = useWindowDimensions();

  const [currentIndex, setCurrentIndex] = useState<number>(activeIndex);
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);

  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) {
      return;
    }
    setCurrentIndex(api.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (carouselApi) {
      onSelect(carouselApi);
      carouselApi.on("select", () => onSelect(carouselApi));
      return () => {
        carouselApi.off("select", () => onSelect(carouselApi));
      };
    }
  }, [carouselApi, onSelect]);

  const handlePrev = () => {
    carouselApi?.scrollPrev();
  };
  const handleRight = () => {
    carouselApi?.scrollNext();
  };

  if (open) {
    return (
      <Dialog open onOpenChange={closeImagePreview}>
        <DialogContent className="max-w-screen flex h-screen flex-col gap-0 rounded-none bg-transparent p-0 md:h-auto md:max-h-screen md:max-w-lg md:rounded-lg md:bg-background">
          <DialogHeader className="p-4">
            <View className="flex flex-row items-center justify-between gap-2">
              <IconButton
                variant="ghost"
                color="foreground"
                size="md"
                className="p-0"
                iconName={isDesktop ? "arrowLeft" : "x"}
                onClick={closeImagePreview}
              />
              {onRemoveImage && (
                <IconButton
                  variant="ghost"
                  color="foreground"
                  size="md"
                  className="p-0"
                  iconName="trash"
                  onClick={() =>
                    onRemoveImage(imageUrls[currentIndex], currentIndex)
                  }
                />
              )}
            </View>
          </DialogHeader>
          <View className="flex-1 overflow-y-auto p-4">
            <Carousel setApi={setCarouselApi} className="w-full">
              <CarouselContent>
                {imageUrls.map((image, index) => (
                  <CarouselItem key={index} className="w-full pl-0">
                    <Image src={image} className={"w-full object-cover"} />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>
          </View>
          <DialogFooter className="items-center p-4 sm:justify-center">
            <IconButton
              variant="secondary"
              color="foreground"
              size="md"
              className="p-0"
              iconName="caretLeft"
              onClick={handlePrev}
            />
            <View className="bottom-1 flex flex-row justify-center p-1 text-xs font-normal text-white">
              {imageUrls.map((_, index) => (
                <View
                  key={index}
                  className={cn(
                    "mx-1 h-2 w-2 rounded-full",
                    index === currentIndex ? "bg-blue-600" : "bg-gray-300",
                  )}
                />
              ))}
            </View>
            <IconButton
              variant="secondary"
              color="foreground"
              size="lg"
              className="p-0"
              iconName="caretRight"
              onClick={handleRight}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
}
