import { Link } from "@/components/shared/link";
import { ArrowLeft } from "@/components/shared/icons";
import { View } from "@/components/ui";
import { ClientVoucherContainer } from "@/containers/profile/my-vouchers/client-voucher";
import { Typography } from "@/components/shared/typography";

export const MyVouchers = () => {
  return (
    <View className="flex flex-1 flex-col gap-6">
      <View className="static flex flex-1 flex-row items-center justify-between border-b pb-4">
        <View className="flex flex-row items-center gap-2 p-4 md:p-0">
          <Link href="/profile/overview">
            <ArrowLeft className="size-5" />
          </Link>
          <Typography variant="label-2xl" color="foreground-default">
            Vouchers
          </Typography>
        </View>
      </View>

      <ClientVoucherContainer />
    </View>
  );
};
