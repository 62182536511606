import { useRoute } from "@/components/shared/router";
import { useAuthState } from "@/store/auth";
import { useUpdateAddressStore } from "@/store/profile/updateAddress";
import { useAddressByPostalCodeStore } from "@/store/auth/addressByPostalCode";
import {
  createUnitNumber,
  getErrorMessage,
  getUnitNumber,
} from "@/lib/helpers/string";
import type { AddressFormData } from "@/store/auth/forms/useAddressForm";
import { useAddressForm } from "@/store/auth/forms/useAddressForm";
import { useParams } from "@/components/hooks/use-params";
import { useDeleteAddressStore } from "@/store/profile/deleteAddress";
import { useEffect } from "react";
import { showToast } from "@/components/ui/toast/show-toast";
import { useClientStore } from "@/store/auth/client";
import { collectError } from "@/lib/monitoring/sentry";
import type { Control } from "react-hook-form";
import { UpdateAddressScreen } from "./update-address-screen";

export type UpdateAddressProps = {
  control: Control<AddressFormData>;
  onAddAddressByPostalCode: (postalCode: string) => Promise<void>;
  postalCodeLoading: boolean;
  onDeleteAddress: () => void;
  loading: boolean;
  onSubmit?: () => void;
};

export const UpdateAddress = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useRoute();
  const {
    data: { userInfo },
  } = useAuthState();

  const { fetch: updateAddress, loading: updateAddressLoading } =
    useUpdateAddressStore();
  const { fetch: addAddressByPostalCode, loading: postalCodeLoading } =
    useAddressByPostalCodeStore();
  const { fetch: deleteAddress, loading: deleteAddressLoading } =
    useDeleteAddressStore();
  const { fetch: client, loading: clientLoading } = useClientStore();

  const loading = deleteAddressLoading || updateAddressLoading || clientLoading
  const address = userInfo.addresses.find((i) => i.id === id);

  useEffect(() => {
    if (!address) {
      push({
        pageKey: "addresses",
      });
    }
  }, [address]);

  const currentValues: AddressFormData = {
    postalCode: address?.postalCode ?? "",
    fullAddress: address?.fullAddress ?? "",
    ...getUnitNumber(address?.unitNumber),
    primary: address?.primary ?? false,
  };

  const { control, handleSubmit, setValue } = useAddressForm(currentValues);

  const onAddAddressByPostalCode = async (postalCode: string) => {
    const result = await addAddressByPostalCode({
      requestPayload: {
        postalCode,
      },
    });

    if (result.data?.address) {
      setValue("fullAddress", result.data.address);
    }
  };

  const onSubmit = handleSubmit(async (data: AddressFormData) => {
    try {
      if (!id) return;
      const res = await updateAddress(
        {
          requestPayload: {
            input: {
              id: id,
              fullAddress: data.fullAddress,
              postalCode: data.postalCode,
              unitNumber: createUnitNumber(
                data.unitFloor,
                data.apartmentNumber,
              ),
              primary: data.primary,
            },
          },
        },
        {
          selfHandleError: true,
        },
      );

      if (res.data?.address) {
        if (res.data.address.primary) {
          const existingAddresses = userInfo.addresses || [];
          const lastPrimaryAddress = existingAddresses.find(
            (add) => add.primary,
          );

          try {
            if (lastPrimaryAddress?.id) {
              const lastPrimaryRes = await updateAddress(
                {
                  requestPayload: {
                    input: {
                      id: lastPrimaryAddress.id,
                      fullAddress: lastPrimaryAddress.fullAddress,
                      postalCode: lastPrimaryAddress.postalCode,
                      unitNumber: lastPrimaryAddress.unitNumber,
                      primary: false,
                    },
                  },
                },
                { selfHandleError: true },
              );

              if (lastPrimaryRes.error)
                throw new Error(
                  `Last primary address error: ${lastPrimaryRes.error}`,
                );
            }
            
            if (res.error) throw new Error(res.error);
            
            showToast({
              title: "Success update addresses",
              type: "success",
            });
          } catch (error) {
            const errMsg = getErrorMessage(error, "Error update addresses");
            showToast({
              title: errMsg,
              type: "error",
            });
            collectError(error, {
              context: { name: "UpdateAddress", values: data },
            });
          }
        }

        client({
          requestPayload: {
            id: userInfo.id,
          },
        });
        push({
          pageKey: "addresses",
        });
      }

      showToast({
        title: "Success update address",
        type: "success",
      });
      if (res.error) throw new Error(res.error);
    } catch (error) {
      const errMsg = getErrorMessage(error, "Error update address");
      showToast({
        title: errMsg,
        type: "error",
      });
    }
  });

  const onDeleteAddress = async () => {
    try {
      if (!id) return;
      const res = await deleteAddress(
        {
          requestPayload: {
            input: {
              id: id,
            },
          },
        },
        { selfHandleError: true },
      );

      if (res.data?.result) {
        push({
          pageKey: "addresses",
        });
      }

      showToast({
        title: "Success delete address",
        type: "success",
      });
      if (res.error) throw new Error(res.error);
    } catch (error) {
      const errMsg = getErrorMessage(error, "Error delete address");
      showToast({
        title: errMsg,
        type: "error",
      });
    }
  };

  return (
    <UpdateAddressScreen
      control={control}
      loading={loading}
      onAddAddressByPostalCode={onAddAddressByPostalCode}
      onDeleteAddress={onDeleteAddress}
      postalCodeLoading={postalCodeLoading}
      onSubmit={onSubmit}
    />
  );
};
