import {
  VisitStatusEnum,
  type PackageDepartmentEnum,
  type PackageServiceTypeEnum,
} from "@/__generated__/graphql";
import type { VisitWorker } from "../visit-card";
import { Card, CardContent } from "@/components/ui";
import { packageServiceTypeLabel } from "@/lib/service/helpers";
import { formatVisitSchedule } from "../utils";
import { WorkerAvatar } from "../visit-card/worker-avatar";
import { Divider } from "../../divider";
import { VisitStatusBadge } from "../visit-card/visit-status-badge";
import { Typography } from "../../typography";

type Props = {
  department: PackageDepartmentEnum;
  serviceType: PackageServiceTypeEnum;
  serviceDate: Date;
  startTime: string;
  endTime: string;
  worker: VisitWorker;
};

export function CompletedVisitRateCard({
  department,
  serviceType,
  serviceDate,
  startTime,
  endTime,
  worker,
}: Props) {
  return (
    <Card shadow="none">
      <CardContent className="px-4 py-3">
        <Typography variant="label-2xl">
          {packageServiceTypeLabel(serviceType, department)}
        </Typography>
        <Typography variant="body-xl" color="foreground-intermediate">
          {formatVisitSchedule(startTime, endTime, serviceDate)}
        </Typography>
      </CardContent>
      <Divider dashed className="mx-4" />
      <CardContent className="flex flex-row items-center justify-between gap-2 px-4 py-3">
        <WorkerAvatar fullName={worker.fullName} avatarUrl={worker.avatarUrl} />
        <VisitStatusBadge status={VisitStatusEnum.Completed} />
      </CardContent>
    </Card>
  );
}
