import { useWindowDimensions } from "@/components/hooks/use-window-dimension/";
import {
  Dialog,
  DialogContent,
  View,
  DialogHeader,
  DialogTitle,
  Button
} from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";
import type { Icon } from "@/components/shared/icons";
import { Warning } from "@/components/shared/icons";
import { Divider } from "../divider";
import { Typography } from "../typography";

export const ConfirmationDialog = ({
  title = "Confirmation",
  description,
  open,
  icon: Icon = Warning,
  iconClassName = "color-warning",
  onConfirm,
  onCancel,
  confirmText = "Yes",
  cancelText = "No",
}: {
  title?: string;
  description: string;
  open: boolean;
  icon?: Icon;
  iconClassName?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
}) => (
  <Dialog open={open} onOpenChange={onCancel}>
    <DialogContent className="w-[343px] max-w-full rounded-lg">
      <View>
        <DialogHeader className="flex flex-row justify-between gap-2">
          <View className="flex flex-row gap-1">
            <Icon size={24} weight="fill" className={iconClassName} />
            <DialogTitle>{title}</DialogTitle>
          </View>
          {useWindowDimensions().isDesktop && (
            <IconButton
              variant="secondary"
              color="foreground"
              size="md"
              iconName="x"
              onClick={onCancel}
            />
          )}
        </DialogHeader>
        <Divider className="py-1" />
      </View>

      <View className="flex flex-col items-center gap-2">
        <Typography variant="body-lg">
          {description}
        </Typography>
        <Button
          variant="primary"
          color="CTA"
          fullWidth="full"
          rounded="full"
          className="mt-2"
          onClick={onConfirm}
          children={confirmText}/>
          
        <Button
          variant="secondary"
          color="CTA2"
          fullWidth="full"
          rounded="full"
          className="mt-2"
          onClick={onCancel}
          children={cancelText}/>
      </View>
    </DialogContent>
  </Dialog>
);
