import {
	View,
	Text,
	RadioGroup,
	RadioGroupItem,
	Image,
	Card,
	CardContent,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@/components/ui";
import PayNowImage from "@/assets/images/paynow.png";
import VisaImage from "@/assets/images/visa.png";
import MasterCardImage from "@/assets/images/master-card.png";
import AmericanExpressImage from "@/assets/images/american-express.png";
import { Bank } from "@/components/shared/icons";
import { useState } from "react";
import type { SelectPaymentScreenModalProps } from "..";
import { PaymentMethodScreenModal } from "@/types/invoice";

export const SelectPaymentScreenModal = ({
  open,
  onShowModal,
}: SelectPaymentScreenModalProps) => {
  const [selectPayment, setSelectPayment] = useState<string>("credit-card");

  const onPay = () => {
    if (selectPayment === "paynow") {
      onShowModal(PaymentMethodScreenModal.PAYNOW);
    } else {
      onShowModal(PaymentMethodScreenModal.CREDIT_CARD);
    }
  };

  return (
    <Dialog open={open} onOpenChange={() => onShowModal()}>
      <DialogContent>
        <DialogTitle>
          <Text className="text-lg font-medium">Select Payment Method</Text>
        </DialogTitle>

        <View className="flex flex-col gap-6">
          <RadioGroup
            value={selectPayment}
            onValueChange={(val) => {
              setSelectPayment(val);
            }}
          >
            <Card onClick={() => setSelectPayment("credit-card")}>
              <CardContent>
                <View className="flex flex-col gap-1">
                  <View className="flex flex-row justify-between">
                    <View className="flex flex-row gap-[10px]">
                      <Image src={VisaImage} width={46} height={32} />
                      <Image src={MasterCardImage} width={46} height={32} />
                      <Image
                        src={AmericanExpressImage}
                        width={53}
                        height={32}
                      />
                    </View>
                    <RadioGroupItem value="credit-card" checkCircle />
                  </View>

                  <Text className="text-lg font-medium">
                    Credit / Debit Card
                  </Text>

                  {selectPayment === "credit-card" && (
                    <View className="flex flex-col gap-2">
                      <Text className="text-xs text-foreground-intermediate">
                        Pay with your card on file. A processing fee of 3.4% +
                        50 cents will be charged by the card processor.
                        Alternative, pay with PayNow with no additional fees.
                      </Text>
                      <Text className="text-xs text-foreground-intermediate">
                        Payment status will be updated within 7 days due to bank
                        reconciliation process.
                      </Text>
                    </View>
                  )}
                </View>
              </CardContent>
            </Card>
            <Card onClick={() => setSelectPayment("paynow")}>
              <CardContent>
                <View className="flex flex-col gap-1">
                  <View className="flex flex-row justify-between">
                    <View className="flex flex-row items-center gap-[10px]">
                      <Image src={PayNowImage} width={52} height={32} />
                      <Bank weight="fill" className="text-primary" size={32} />
                    </View>
                    <RadioGroupItem value="paynow" checkCircle />
                  </View>

                  <Text className="text-lg font-medium">
                    PayNow / Bank Transfer
                  </Text>

									{selectPayment === "paynow" && (
										<View className="flex flex-col gap-2">
											<Text className="text-xs text-foreground-intermediate">
												You will be redirected to new page to complete the payment via
												Paynow.
											</Text>
											<Text className="text-xs text-foreground-intermediate">
												Payment status will be updated within 7 days due to bank
												reconciliation process.
											</Text>
										</View>
									)}
								</View>
							</CardContent>
						</Card>
					</RadioGroup>
					<View className="flex flex-row w-full gap-2">
						<Button
							variant="secondary"
							color="CTA2"
							fullWidth="full"
							onClick={() => onShowModal()}
							children="Cancel"
            />
						<Button 
							variant="primary"
							color="CTA"
							fullWidth="full"
							onClick={onPay} 
							children="Pay"
            />	
					</View>
				</View>
			</DialogContent>
		</Dialog>
	);
};
