import { Button, View } from "@/components/ui";
import { getPlatform } from "@/lib/utils";

export function ActionNavigation({
  buttonText,
  onClickButton,
  disabled,
}: {
  buttonText: string;
  onClickButton: () => void;
  disabled?: boolean;
}) {
  const platform = getPlatform();
  if (platform === "native") return null;

  return (
    <View className="w-full md:block hidden mb-4">
      <Button
        variant="primary"
        color="CTA"
        disabled={disabled}
        fullWidth="full"
        iconAlignment="end"
        iconName="arrowRight"
        onClick={onClickButton}
        children={buttonText}
      />
    </View>
  );
}
