import type {
  AllClientPackageDetailsQuery,
  AllClientPackageDetailsQueryVariables,
  PackageDetailFragmentFragment,
} from "@/__generated__/graphql";
import { AllClientPackageDetailsDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";
import { useBookingState } from "./useBookingState";

type Response = PackageDetailFragmentFragment[];

export const useAllClientPackageDetailsStore = createRequestFactory<
  AllClientPackageDetailsQuery,
  Response,
  AllClientPackageDetailsQueryVariables
>({
  method: "query",
  graphqlDocument: AllClientPackageDetailsDocument,
  transformFunction: (data) => {
    if (data.allPackageDetails.packageDetails)
      return data.allPackageDetails.packageDetails as Response;
    else throw new Error("");
  },
  onFetchSuccess: (data) => {
    const { setBookingState } = useBookingState.getState();
    setBookingState({
      homeCleaningDurationModalOpen: false,
      packageDetails: data,
    });
  },
});
