import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { View, Button } from "@/components/ui";
import { WorkerListItem } from "./worker-list-item";
import type { PastWorker } from "@/types/booking";
import { useEffect, useState } from "react";
import { useClientFindPastWorkers } from "@/store/booking/clientFindPastWorkers";
import { useAuthState } from "@/store/auth";
import type { PackageDepartmentEnum } from "@/__generated__/graphql";
import { Typography } from "../../typography";
import { Spin } from "@/components/shared/spin";
import { IfElse } from "../../if-else";

export const ChooseWorkerModal = ({
  open,
  workerLabel,
  setOpen,
  department,
  selectedWorkers,
  handleApply,
}: {
  open: boolean;
  workerLabel: string;
  setOpen(open: boolean): void;
  department: PackageDepartmentEnum;
  selectedWorkers: PastWorker[];
  handleApply(workers: PastWorker[]): void;
}) => {
  const {
    fetch: getWorkers,
    data: pastWorkers,
    loading,
  } = useClientFindPastWorkers();
  const {
    data: { userInfo },
  } = useAuthState();

  const [currentSelectedWorkers, setCurrentSelectedWorkers] =
    useState(selectedWorkers);

  const resetWorkers = () => {
    setCurrentSelectedWorkers(selectedWorkers);
  };

  useEffect(() => {
    if (open) resetWorkers();
  }, [open]);

  useEffect(() => {
    if (!open || !userInfo.id) return;

    getWorkers({
      requestPayload: {
        clientId: userInfo.id,
        department,
      },
    });
  }, [open]);

  const onSelectAll = () => {
    setCurrentSelectedWorkers([]);
  };

  const onSelectAllPastWorker = () => {
    setCurrentSelectedWorkers(pastWorkers || []);
  };

  const onSelectItem = (worker: PastWorker) => {
    const isChecked = currentSelectedWorkers.some((w) => w.id === worker.id);
    if (isChecked) {
      setCurrentSelectedWorkers(
        currentSelectedWorkers.filter((w) => w.id !== worker.id),
      );
    } else {
      setCurrentSelectedWorkers([...currentSelectedWorkers, worker]);
    }
  };

  const onApply = () => {
    handleApply(currentSelectedWorkers);
    setOpen(false);
  };

  return (
    <BottomDrawerModal open={open} onOpenChange={setOpen}>
      <Typography variant="label-2xl">
        {`Filter availabilities by ${workerLabel.toLocaleLowerCase()}`}
      </Typography>
      <View className="divide-y">
        <WorkerListItem
          name={`All ${workerLabel}s`}
          onCheck={onSelectAll}
          checked={!currentSelectedWorkers.length}
        />
        <View>
          <View className="flex flex-row items-center justify-between py-2">
            <Typography color="foreground-intermediate" variant="label-md">
              {`Or Select any of your past ${workerLabel.toLocaleLowerCase()}s`}
            </Typography>
            <Typography
              className="cursor-pointer text-primary"
              onClick={onSelectAllPastWorker}
              disabled={loading}
              variant={{ md: "label-md", sm: "label-sm" }}
            >
              Select All
            </Typography>
          </View>
          <View className="divide-y">
            {loading ? (
              <Spin className="my-6 size-8" />
            ) : (
              <IfElse
                if={!!pastWorkers?.length}
                else={
                  <>
                    <Typography
                      variant={"label-lg"}
                      className="my-4"
                      align={"center"}
                    >
                      {`No ${workerLabel}`}
                    </Typography>
                  </>
                }
              >
                <>
                  {pastWorkers?.map((worker) => (
                    <WorkerListItem
                      key={worker.id}
                      name={`${worker.firstName} ${worker.lastName}`}
                      rating={worker.workerRating}
                      avatarUrl={worker.avatarUrl}
                      checked={currentSelectedWorkers.some(
                        (w) => w.id === worker.id,
                      )}
                      onCheck={() => {
                        onSelectItem(worker);
                      }}
                    />
                  ))}
                </>
              </IfElse>
            )}
          </View>
        </View>
      </View>

      <View className="flex flex-row gap-1 md:gap-2">
        <Button
          variant="secondary"
          color="CTA2"
          className="flex-1"
          onClick={() => {
            resetWorkers();
            setOpen(false);
          }}
          children="Cancel"
        />
        <Button
          variant="primary"
          color="CTA"
          className="flex-1"
          onClick={onApply}
          children="Apply"
        />
      </View>
    </BottomDrawerModal>
  );
};
