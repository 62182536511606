import { createRequestFactory } from "@/lib/request-factory";
import {
  SignInWithPhoneDocument,
  type SignInWithPhoneMutation,
  type SignInWithPhoneMutationVariables,
} from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";
import type { UserInfo, JwtInfo } from "@/types/users";
import { useClientStore } from "./client";
import { setAnalyticUserId } from "@/lib/firebase";

type Response = {
  userInfo: UserInfo;
  jwtInfo: JwtInfo;
};

export const useSignInWithPhoneStore = createRequestFactory<
  SignInWithPhoneMutation,
  Response,
  SignInWithPhoneMutationVariables
>({
  method: "mutation",
  graphqlDocument: SignInWithPhoneDocument,
  transformFunction: (res) => {
    if (res.signInWithPhone?.result.__typename === "SignInClientOk") {
      const {
        jwt,
        jwtExpiry,
        refreshToken,
        client: { id, user, contacts, addresses },
      } = res.signInWithPhone.result;
      const userInfo = {
        id,
        firstName: contacts[0].firstName,
        lastName: contacts[0].lastName,
        user,
        contacts,
        addresses,
      };
      const jwtInfo = {
        jwt,
        jwtExpiry,
        refreshToken,
      };
      return { userInfo, jwtInfo } as Response;
    }

    let errMessage = "Send pin code failed";
    if (
      res.signInWithPhone?.result.__typename === "AccountDeleted" ||
      res.signInWithPhone?.result.__typename === "NotWhitelistedProblem" ||
      res.signInWithPhone?.result.__typename === "SignUpRequired" ||
      res.signInWithPhone?.result.__typename === "ValidationProblem"
    ) {
      errMessage = res.signInWithPhone.result.message;
    }

    throw new Error(errMessage);
  },
  onFetchSuccess: (res) => {
    const { setJwtInfo } = useAuthState.getState();
    const { fetch: getUserInfo } = useClientStore.getState();
    setJwtInfo(res.jwtInfo);
    getUserInfo({
      requestPayload: {
        id: res.userInfo.id,
      },
    });
    setAnalyticUserId(res.userInfo.id)
  },
});
