import type { RecommendedService } from "@/components/shared/recommended-card";
import { RecommendedCard } from "@/components/shared/recommended-card";
import { HorizontalScrollView } from "@/components/shared/horizontal-scroll-view";
import { ThumbsUp } from "@/components/shared/icons";
import { ListHeading } from "@/components/shared/list-header";
import { getPlatform } from "@/lib/utils";
import { useIntl } from "react-intl";
import MeniPediCover from "@/assets/images/booking/mani-pedi-cover.png";
import type { Icon } from "@/components/shared/icons";

const cards: RecommendedService[] = [
  {
    title: "Aircon General Servicing",
    imageSrc:
      "https://luce-production.sgp1.cdn.digitaloceanspaces.com/aircon-general-servicing-cover.png",
    price: 16,
    recommended: true,
    name: "aircon-service",
  },
  {
    title: "Mattress Cleaning",
    imageSrc:
      "https://luce-production.sgp1.cdn.digitaloceanspaces.com/mattress-cover.png",
    price: 81,
    recommended: false,
    name: "mattress-cleaning",
  },
  {
    title: "Home Beauty",
    imageSrc: MeniPediCover,
    price: 84,
    recommended: false,
    name: "home-beauty",
  },
  {
    title: "Sofa Cleaning",
    imageSrc:
      "https://luce-production.sgp1.cdn.digitaloceanspaces.com/sofa-cover.png",
    price: 45,
    recommended: false,
    name: "sofa-cleaning",
  },
];

export function RecommendedServices({
  title,
  icon,
}: {
  title?: string;
  icon?: Icon;
}) {
  const intl = useIntl();
  return (
    <HorizontalScrollView
      HeaderComponent={
        <ListHeading
          title={
            title ||
            intl.formatMessage({
              defaultMessage: "Maybe you’ll like it.",
              id: "maybe-you'll-like-it",
            })
          }
          icon={icon || ThumbsUp}
        />
      }
      slideSize={
        getPlatform() === "web" ? "max-[481px]:basis-1/2 basis-1/3" : "146"
      }
    >
      {cards.map((card, index) => (
        <RecommendedCard key={`${card.title}-${index}`} {...card} />
      ))}
    </HorizontalScrollView>
  );
}
