"use client";

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast/toast";
import { useToast } from "@/components/ui/toast/use-toast";
import { Info, WarningCircle, CheckCircle } from "@/components/shared/icons";

export function Toaster() {
  const { toasts } = useToast();

  const getToastIconVariant = ({ variant }: { variant: string }) => {
    switch (variant) {
      case "info":
        return <Info weight="fill" className="h-[20px] w-[20px] text-info" />;
      case "success":
        return (
          <CheckCircle
            weight="fill"
            className="h-[20px] w-[20px] text-success"
          />
        );
      case "error":
        return (
          <WarningCircle
            weight="fill"
            className="h-[20px] w-[20px] text-danger"
          />
        );
      case "warning":
        return (
          <WarningCircle
            weight="fill"
            className="h-[20px] w-[20px] text-warning"
          />
        );
      default:
        return <Info weight="fill" className="h-[20px] w-[20px] text-info" />;
    }
  };

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        variant,
        ...props
      }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              <div className="flex flex-row items-center gap-3">
                {getToastIconVariant({ variant: variant ?? "info" })}
                {title && <ToastTitle>{title}</ToastTitle>}
              </div>
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
