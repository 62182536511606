import type {
  ClientAddressesQuery,
  ClientAddressesQueryVariables,
} from "@/__generated__/graphql";
import { ClientAddressesDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";
import type { ClientAddresses } from "@/types/profile";

type Response = {
  clientAddresses: ClientAddresses[];
};

export const useClientAddressesStore = createRequestFactory<
  ClientAddressesQuery,
  Response,
  ClientAddressesQueryVariables
>({
  method: "query",
  graphqlDocument: ClientAddressesDocument,
  fetchPolicy: 'network-only',
  transformFunction: (res) => {
    if (res.clientAddresses) {
      return { clientAddresses: res.clientAddresses.addresses };
    }
    throw new Error("Fetch failed");
  },
});
