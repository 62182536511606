import { View } from "@/components/ui";
import { isBefore, isAfter } from "date-fns";
import { formatDateRange } from "@/lib/helpers/date";
import { IconButton } from "@/components/ui/icon-button";
import { useMemo } from "react";
import { Typography } from "../typography";
import { cn } from "@/lib/utils";

export const DateRange = ({
  startDate,
  endDate,
  maxDate,
  minDate,
  handleNext,
  handlePrev,
}: {
  label?: string;
  startDate: Date;
  endDate: Date;
  maxDate: Date;
  minDate: Date;
  handlePrev?(): void;
  handleNext?(): void;
}) => {
  const prevDisabled = useMemo(() => isBefore(startDate, minDate), [startDate]);
  const nextDisabled = useMemo(() => isAfter(endDate, maxDate), [endDate]);

  return (
    <View className="flex flex-1 flex-row items-center rounded-md border border-secondary-border px-2 py-2 text-xs font-normal text-primary-text">
      <IconButton
        variant="ghost"
        className={cn(
          "p-0",
          prevDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
        )}
        size="sm"
        iconName="caretLeft"
        onClick={() => {
          if (prevDisabled) return;
          handlePrev?.();
        }}
      />
      <Typography align="center" className="flex-1" variant="body-md">
        {formatDateRange(startDate, endDate)}
      </Typography>

      <IconButton
        variant="ghost"
        className={cn(
          "p-0",
          nextDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
        )}
        size="sm"
        iconName="caretRight"
        onClick={() => {
          if (nextDisabled) return;
          handleNext?.();
        }}
      />
    </View>
  );
};
