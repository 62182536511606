import { View, Button } from "@/components/ui";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const ModalRemoveCard = ({
  open,
  setOpenRemoveConfirmation,
  onDeleteCard,
  onClose,
}: {
  open: boolean;
  setOpenRemoveConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteCard: () => void;
  onClose: () => void;
}) => {
  const intl = useIntl();
  return (
    <BottomDrawerModal
      open={open}
      onOpenChange={setOpenRemoveConfirmation}
      className="max-w-[375px]"
    >
      <View className="flex flex-col gap-4 px-1">
        <Typography>
          {intl.formatMessage({
            defaultMessage: "Remove Credit Card",
            id: "remove-card",
          })}
        </Typography>
        <Typography variant="body-xl">
          {intl.formatMessage({
            defaultMessage:
              "Are you sure you want to remove this credit card? This action cannot be undone.",
            id: "removeCard.warning",
          })}
        </Typography>

        <View className="flex flex-col gap-2 pb-2">
          <Button
            variant="primary"
            color="CTA"
            onClick={onDeleteCard}
            children={intl.formatMessage({
              defaultMessage: "Remove Credit Card",
              id: "remove-card",
            })}
          />
          <Button
            variant="secondary"
            color="CTA2"
            onClick={onClose}
            children={intl.formatMessage({
              defaultMessage: "Cancel",
              id: "cancel",
            })}
          />
        </View>
      </View>
    </BottomDrawerModal>
  );
};
