import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import type { ResponsiveVariantProps } from "@/lib/styles";
import {
  iconButtonLoadingVariants,
  iconButtonVariants,
  iconVariants,
} from "./styles";
import type { IconNames } from "@/components/shared/icons/icon-map";
import { iconMap } from "@/components/shared/icons/icon-map";
import { Spinner } from "@/components/shared/spinner";

export interface IconButtonProps
  extends ResponsiveVariantProps<typeof iconButtonVariants>,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  asChild?: boolean;
  iconName: IconNames;
  icon?: React.ReactNode;
  loading: boolean;
  onClick?: () => void;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      iconName,
      asChild = false,
      color,
      size,
      variant,
      rounded,
      fullWidth,
      loading,
      onClick,
      disabled,
      ...props
    },
    ref,
  ) => {
    const IconComponent = iconMap[iconName];
    const Comp = asChild ? Slot : "button";
    const iconButtonStyles = iconButtonVariants({
      variant,
      color,
      size,
      rounded,
      fullWidth,
      className,
    });
    const iconStyles = iconVariants({
      size,
      color,
      variant,
    });
    const iconLoadingStyles = iconButtonLoadingVariants({
      size,
    });

    const onClickButton = () => {
      if (loading || disabled) {
        return;
      }
      onClick?.();
    };

    const hasFillColor = iconStyles
      .split(" ")
      .find((i) => i.startsWith("fill-"));
    const fillColor = hasFillColor ? "fill" : "regular";

    return (
      <Comp
        className={iconButtonStyles}
        ref={ref}
        onClick={onClickButton}
        {...props}
      >
        {loading ? (
          <Spinner color="CTA" className={iconLoadingStyles} />
        ) : (
          <IconComponent className={iconStyles} weight={fillColor} />
        )}
      </Comp>
    );
  },
);

IconButton.displayName = "IconButton";

export { IconButton };
