import { View } from "@/components/ui";
import { Star } from "@/components/shared/icons";
import { ReviewCard } from "@/components/shared/review-card";
import { formatNumber } from "@/lib/helpers/number";
import type { ServiceReviews, Review } from "@/types/booking";
import { Typography } from "../../typography";

export const CustomerReviewsVertical = ({
  serviceReviews,
  onClickCard,
}: {
  serviceReviews: ServiceReviews;
  onClickCard(review: Review): void;
}) => {
  return (
    <View>
      <View className="flex flex-row items-center gap-2 pb-4">
        <Star weight="fill" className="text-amber-500" size={26} />
        <Typography variant="bold-xl">
          {`${serviceReviews.rating.toFixed(1)} / 5.0 | ${formatNumber(
            serviceReviews.ratingTotal,
          )} Rating`}
        </Typography>
      </View>
      <View className="flex flex-col gap-4">
        {serviceReviews.reviews.map((review) => (
          <ReviewCard
            key={review.id}
            {...review}
            contentClassName="p-4 md:p-4"
            onClick={() => {
              onClickCard(review);
            }}
          />
        ))}
      </View>
    </View>
  );
};
