import { createRequestFactory } from "@/lib/request-factory";
import {
  DeleteClientDocument,
  type DeleteClientMutation,
  type DeleteClientMutationVariables,
} from "@/__generated__/graphql";
import type { UserInfo } from "@/types/users";

type Response = {
  userInfo: UserInfo;
};

export const useDeleteClientStore = createRequestFactory<
  DeleteClientMutation,
  Response,
  DeleteClientMutationVariables
>({
  method: "mutation",
  graphqlDocument: DeleteClientDocument,
  fetchPolicy: "network-only",
});
