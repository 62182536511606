import * as React from "react";

export interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: React.ElementType;
  asChild?: boolean;
  htmlFor?: string;
  numberOfLines?: never;
}

export const Text = React.forwardRef<HTMLDivElement, TextProps>(
  (
    {
      htmlFor,
      as: Element = htmlFor ? "label" : "p",
      asChild: _asChild,
      numberOfLines: _numberOfLines,
      ...props
    },
    ref,
  ) => {
    return <Element {...props} ref={ref} htmlFor={htmlFor} />;
  },
);
