import { ScrollArea, View } from "@/components/ui";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { cn } from "@/lib/utils";
import type { BottomDrawerModalProps } from "./type";

export function BottomDrawerModal({
  open,
  onOpenChange,
  children,
  className,
}: BottomDrawerModalProps) {
  const { mediaQuery } = useWindowDimensions();

  // its weird because the drawer onOpenChange is triggered twice
  // then causing the drawer open state is always false if we don't do this handler to prevent double invocation
  const handleOpenChange = (isOpen: boolean) => {
    if (open !== isOpen) {
      onOpenChange?.(isOpen);
    }
  };

  if (mediaQuery === "desktop") {
    return (
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogContent className={cn("p-0", className)}>
          <ScrollArea className="max-h-[90vh] p-4">
            <View className="flex flex-col gap-2">{children}</View>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={handleOpenChange} direction="bottom">
      <DrawerContent>
        {/* max-height: in mobile web, save some space for navigator */}
        <View className="max-h-[70vh] overflow-auto p-4">
          <View className="flex flex-col gap-2">{children}</View>
        </View>
      </DrawerContent>
    </Drawer>
  );
}
