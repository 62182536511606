import { useFragment } from "@/__generated__";
import type {
  VisitByClientWithTokenQuery,
  VisitByClientWithTokenQueryVariables,
} from "@/__generated__/graphql";
import {
  VisitByClientWithTokenDocument,
  VisitClientFragmentFragmentDoc,
} from "@/__generated__/graphql";
import type { VisitDetailData } from "@/components/shared/visits/visit-detail";
import { mapToVisitDetailData } from "@/components/shared/visits/visit-detail";
import { createRequestFactory } from "@/lib/request-factory";
import { initialVisitData } from "./visitStore";

export const useVisitTokenStore = createRequestFactory<
  VisitByClientWithTokenQuery,
  VisitDetailData,
  VisitByClientWithTokenQueryVariables
>(
  {
    method: "query",
    graphqlDocument: VisitByClientWithTokenDocument,
    transformFunction(data, request) {
      if (!data.visitByClientWithToken) {
        throw new Error("No visit found");
      }

      const visit = useFragment(
        VisitClientFragmentFragmentDoc,
        data.visitByClientWithToken,
      );
      return mapToVisitDetailData(visit, request?.token);
    },
  },
  initialVisitData,
);
