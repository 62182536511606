import { WebLayout } from "@/components/shared/layout/homepage.web";
import { RescheduleVisit } from "@/containers/visits/reschedule-visit";
import { Navigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "@/constants/routes";

export function RescheduleVisitPage({
  paramName,
}: {
  paramName: "id" | "token";
}) {
  const [searchParams] = useSearchParams();

  const params = searchParams.get(paramName);

  if (!params) {
    return <Navigate to={ROUTES.Visits.Root} />;
  }

  return (
    <WebLayout>
      <RescheduleVisit
        {...{ [paramName === "id" ? "visitId" : "token"]: params }}
      />
    </WebLayout>
  );
}
