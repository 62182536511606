import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const reportIssueSchema = z.object({
  reason: z.string({ required_error: "Reason is a required field" }),
  comment: z.string().min(10, "Must be at least 10 characters long"),
});

export type ReportIssueFormData = z.infer<typeof reportIssueSchema>;

export interface ReportIssueFormReturn
  extends UseFormReturn<ReportIssueFormData> {
  defaultValues: ReportIssueFormData;
}

export const initialFormValues: ReportIssueFormData = {
  reason: "",
  comment: "",
};

export const useReportIssueForm = (
  initialValues?: ReportIssueFormData,
): ReportIssueFormReturn => ({
  defaultValues: initialValues || initialFormValues,
  ...useForm<ReportIssueFormData>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: zodResolver(reportIssueSchema),
  }),
});
