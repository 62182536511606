import type { ReactNode } from "react";
import { View } from "@/components/ui";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

const rowVariants = cva("flex flex-row", {
  variants: {
    gap: {
      none: "gap-0",
      xs: "gap-0.5",
      sm: "gap-1",
      md: "gap-2",
      lg: "gap-3",
      xl: "gap-4",
      xxl: "gap-6",
      "3xl": "gap-8",
      "4xl": "gap-10",
      "5xl": "gap-12",
      "6xl": "gap-14",
      "7xl": "gap-16",
    },
    justify: {
      center: "justify-center",
      normal: "justify-normal",
      start: "justify-start",
      end: "justify-end",
      between: "justify-between",
      around: "justify-around",
      evenly: "justify-evenly",
      stretch: "justify-stretch",
    },
    items: {
      center: "items-center",
      start: "items-start",
      end: "items-end",
      baseline: "items-baseline",
      stretch: "items-stretch",
    },
  },
});

type Props = VariantProps<typeof rowVariants> & { children: ReactNode };

export function Row({ gap, justify, items, children }: Props) {
  return (
    <View className={rowVariants({ gap, justify, items })}>{children}</View>
  );
}
