import { View } from "@/components/ui";
import { cn } from "@/lib/utils";
import { Typography } from "../typography";

export const Description = ({
  title,
  description,
  showLineOnSmallScreen = true,
}: {
  title: string;
  description: string;
  showLineOnSmallScreen?: boolean;
}) => {
  return (
    <View
      className={cn(
        "flex flex-1 flex-col border-b border-transparent p-3 py-2 md:border-secondary-border",
        {
          "border-secondary-border": showLineOnSmallScreen,
        },
      )}
    >
      <Typography
        variant={{ md: "body-lg", sm: "body-md" }}
        color="foreground-intermediate"
      >
        {title}
      </Typography>
      <Typography variant={{ md: "body-xl", sm: "body-lg" }}>
        {description}
      </Typography>
    </View>
  );
};
