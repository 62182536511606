import { View, Image } from "@/components/ui";

export function ServiceDetailSection({
  detailImages,
}: {
  detailImages: string[];
}) {
  return (
    <View className="flex flex-col gap-2">
      <View className="service-detail-contents">
        {detailImages.map((img) => (
          <Image
            width={"100%"}
            key={img}
            height={751}
            className="w-full"
            src={img}
          />
        ))}
      </View>
    </View>
  );
}
