import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientConfirmReservationDocument,
  type ClientConfirmReservationMutation,
  type ClientConfirmReservationMutationVariables,
} from "@/__generated__/graphql";

export const useClientConfirmReservationStore = createRequestFactory<
  ClientConfirmReservationMutation,
  ClientConfirmReservationMutation,
  ClientConfirmReservationMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientConfirmReservationDocument,
});
