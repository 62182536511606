import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  View,
} from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";
import { cn } from "@/lib/utils";
import type { ReactNode } from "react";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { Divider } from "../divider";

type Props = {
  open: boolean;
  title: string;
  children?: ReactNode;
  onClose?: (value: boolean) => void;
  className?: string;
  FooterComponent?: ReactNode;
};

export function MobileFullscreenModal({
  open,
  onClose,
  title,
  children,
  className,
  FooterComponent,
}: Props) {
  const { isMobile } = useWindowDimensions();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        showClose={!isMobile}
        className={cn(
          "max-w-screen flex h-screen flex-col gap-0 rounded-none p-0 md:h-auto md:max-h-[70vh] md:max-w-lg md:rounded-lg",
          className,
        )}
      >
        <DialogHeader className="p-4">
          <View className="flex flex-row items-center justify-between gap-2">
            {isMobile && (
              <IconButton
                variant="ghost"
                color="foreground"
                size="md"
                iconName="arrowLeft"
                onClick={() => onClose?.(true)}
              />
            )}
            <DialogTitle className="flex-1">{title}</DialogTitle>
          </View>
        </DialogHeader>
        {isMobile && <Divider />}
        <View className="flex-1 overflow-y-auto p-4">{children}</View>
        {FooterComponent && (
          <DialogFooter className="p-4">{FooterComponent}</DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
}
