import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientCancelVisitDocument,
  type ClientCancelVisitMutation,
  type ClientCancelVisitMutationVariables,
} from "@/__generated__/graphql";

export const useClientCancelVisitStore = createRequestFactory<
  ClientCancelVisitMutation,
  boolean,
  ClientCancelVisitMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientCancelVisitDocument,
  transformFunction(data) {
    return Boolean(data.clientCancelVisit?.visit);
  },
});
