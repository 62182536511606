import { createRequestFactory } from "@/lib/request-factory";
import {
  SendPinCodeToPhoneDocument,
  type SendPinCodeToPhoneMutation,
  type SendPinCodeToPhoneMutationVariables,
} from "@/__generated__/graphql";

type EmailLoginResponse = {
  success: boolean;
};

export const useSendPinCodeToPhoneStore = createRequestFactory<
  SendPinCodeToPhoneMutation,
  EmailLoginResponse,
  SendPinCodeToPhoneMutationVariables
>({
  method: "mutation",
  graphqlDocument: SendPinCodeToPhoneDocument,
  transformFunction: (res) => {
    if (
      res.sendPinCodeToPhone?.result?.__typename === "AccountDeleted" ||
      res.sendPinCodeToPhone?.result?.__typename === "ValidationProblem"
    ) {
      throw new Error(
        res.sendPinCodeToPhone.result.message ?? "Send pin code failed",
      );
    }

    return { success: true };
  },
});
