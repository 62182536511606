import { TimeSlotCard } from "../time-slot-card";
import { View } from "@/components/ui";
import { useIntl } from "react-intl";
import { cn } from "@/lib/utils";
import type { TimeSlot } from "@/types/booking";
import { formatDate } from "@/lib/helpers/date";
import { EmptySlotCard } from "../time-slot-card/empty-slot-card";
import { useMemo } from "react";
import { isSameDay, addDays } from "date-fns";
import type { PackageDepartmentEnum } from "@/__generated__/graphql";
import type { ServiceName } from "@/types/service";
import { Typography } from "../../typography";

export interface TimeSlotGridProps {
  name: ServiceName;
  loading: boolean;
  selectedSlot: TimeSlot | null;
  timeSlots: Record<string, TimeSlot[]> | null;
  dateRange: Date[];
  department: PackageDepartmentEnum;
  selectedWorkerIds: string[] | null;
  onSelectSlot: (slot: TimeSlot) => void;
  onClickWorkerPortfolio(slot: TimeSlot): void;
  firstNoEmptyIndex: number;
}

export const TimeSlotGrid = ({
  name,
  dateRange,
  loading,
  timeSlots,
  department,
  selectedSlot,
  selectedWorkerIds,
  onSelectSlot,
  onClickWorkerPortfolio,
  firstNoEmptyIndex,
}: TimeSlotGridProps) => {
  const intl = useIntl();

  const allWorkersSlected = useMemo(() => {
    return selectedWorkerIds === null || selectedWorkerIds.length === 0;
  }, [selectedWorkerIds]);

  return (
    <>
      <View
        className={cn(
          "space-around flex flex-row gap-4 border-b border-secondary-border bg-white px-4",
        )}
      >
        {dateRange.map((date, index) => {
          const formattedDate = formatDate(date);
          const isPrimaryDate =
            selectedSlot?.date === formattedDate ||
            (!selectedSlot &&
              firstNoEmptyIndex >= 0 &&
              index === firstNoEmptyIndex);
          return (
            <View
              className="flex flex-1 flex-col items-center text-center"
              key={formattedDate}
            >
              <Typography
                variant="label-md"
                color="foreground-intermediate"
                className="py-3"
              >
                {intl.formatDate(date, {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                })}
              </Typography>
              <View
                className={cn("h-[3px] w-20 rounded-t", {
                  "bg-primary": isPrimaryDate,
                })}
              />
            </View>
          );
        })}
      </View>
      <View className="relative flex flex-row gap-4 bg-background-intermediate p-4 px-4 py-2">
        {dateRange.map((date) => {
          const formattedDate = formatDate(date);
          if (timeSlots?.[formattedDate]) {
            return (
              <View className="flex flex-1 flex-col gap-2" key={formattedDate}>
                {timeSlots[formattedDate].map((slot) => {
                  return (
                    <TimeSlotCard
                      name={name}
                      key={slot.id}
                      active={selectedSlot?.id === slot.id}
                      date={slot.date}
                      startTime={slot.startTime}
                      endTime={slot.endTime}
                      onClickWorkerPortfolio={() =>
                        onClickWorkerPortfolio(slot)
                      }
                      onCardClick={() => {
                        if (!loading) {
                          onSelectSlot(slot);
                        }
                      }}
                      department={department}
                      workerName={slot.workerName || ""}
                      avatarUrl={slot.avatarUrl || ""}
                      isCheaper={
                        slot.partOfDay === "Evening" ||
                        isSameDay(date, addDays(new Date(), 1))
                      }
                      rateValue={slot.rateValue}
                      discount={slot.discount}
                      workerRating={slot.workerRating || 0}
                      selectedWorkerName={
                        selectedWorkerIds?.includes(slot.workerId)
                          ? slot.workerName
                          : null
                      }
                    />
                  );
                })}
              </View>
            );
          } else {
            return (
              <EmptySlotCard
                key={formattedDate}
                filtered={!allWorkersSlected}
                department={department}
              />
            );
          }
        })}
      </View>
    </>
  );
};
