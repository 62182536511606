import { RateVisitCard } from "@/components/shared/visits/rate-visit-card";
import { useVisitDetailStore } from "@/store/visits/visitDetail";
import { useVisitTokenStore } from "@/store/visits/visitTokenStore";
import { useEffect } from "react";
import { VisitRateModalContainer } from "../visit-rate";
import { Message } from "@/components/shared/message";
import { Skeleton, View } from "@/components/ui";
import { MapPinArea } from "@/components/shared/icons";
import { VisitCard } from "@/components/shared/visits/visit-card";
import { ListHeading } from "@/components/shared/list-header";

export function VisitWithToken({
  token,
  type,
}: {
  token: string;
  type: "rate" | "reschedule";
}) {
  const { fetch, data: visit, loading, error } = useVisitTokenStore();

  const {
    openRateVisitModalById,
    openVisitModal,
    openRescheduleVisitConfirmation,
    data: { showRateVisitModal },
  } = useVisitDetailStore();

  useEffect(() => {
    fetch({
      requestPayload: {
        token,
      },
    });
  }, [token]);

  const handleSetSchedule = () => {
    if (visit) {
      openRescheduleVisitConfirmation(visit.id, token);
    }
  };

  if (!visit) {
    // this unnecessary since visit has default values
    return null;
  }

  if (loading) {
    return <Skeleton className="h-[200px] w-full" />;
  }

  if (error) {
    return <Message variant="danger" message={error} />;
  }

  if (type === "reschedule") {
    return (
      <View className="flex flex-col gap-4">
        <ListHeading
          icon={MapPinArea}
          title="Your Upcoming Visits"
          subtitle="Manage your upcoming visit with ease. Click to make changes hassle-free."
        />

        <VisitCard
          visitData={visit}
          handleViewDetail={(visitId) => openVisitModal(visitId, token)}
          handleSetSchedule={handleSetSchedule}
        />
      </View>
    );
  }

  return (
    <>
      <RateVisitCard
        visitData={visit}
        handleRateVisit={openRateVisitModalById}
      />
      {showRateVisitModal && (
        <VisitRateModalContainer visitData={visit} loading={false} />
      )}
    </>
  );
}
