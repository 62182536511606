import Logo from "@/assets/images/logo.png";
import { Image, View } from "@/components/ui";
import { IfElse } from "@/components/shared/if-else";
import { LanguageToggle } from "@/components/shared/language-toggle";
import { DesktopNavButton } from "../desktop-nav-button";
import { useSegments } from "@/components/hooks/use-segments";
import { UserDropdown } from "../user-dropdown";
import { useMemo } from "react";
import { bookingRoutes } from "@/components/hooks/use-booking-route";
import { usePathname } from "@/components/hooks/use-pathname";
import { useRoute } from "@/components/shared/router";
// import { SearchBar } from "@/components/shared/search-bar";

// TODO for Yilin: code changed, please translate this again
export enum HeaderLinkEnum {
  Home = "Home",
  Visits = "Visits",
  Invoices = "Invoices",
  Rewards = "Rewards",
}

const HeaderLinks = [
  { title: HeaderLinkEnum.Home, href: "" },
  { title: HeaderLinkEnum.Visits, href: "/visits" },
  { title: HeaderLinkEnum.Invoices, href: "profile/invoices" },
  { title: HeaderLinkEnum.Rewards, href: "/rewards" },
];

export const NavbarHeader = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const segments = useSegments();
  const activeLink = useMemo(() => {
    if (segments.length === 0) return HeaderLinkEnum.Home;
    if (segments[0] === "visits") return HeaderLinkEnum.Visits;
    if (segments[1] === "invoices") return HeaderLinkEnum.Invoices;
    if (segments[0] === "rewards") return HeaderLinkEnum.Rewards;
  }, [segments]);
  const path = usePathname();

  const showNavigation = useMemo(() => {
    return !Object.values(bookingRoutes)
      .flat()
      .filter(
        (route) =>
          !route.includes("service-detail") &&
          !route.includes("complete-booking"),
      )
      .includes(path);
  }, [segments]);

  const { push } = useRoute();

  return (
    <View className="pointer-events-auto sticky top-0 z-50 flex w-full flex-col bg-brand-static">
      <View className="container flex h-[64px] w-full items-center justify-between px-4 lg:px-0">
        <View
          className="flex cursor-pointer items-center justify-center"
          onClick={() => push({ pageKey: "homepage" })}
        >
          <Image src={Logo} alt="Luce" className="h-7" />
        </View>

        <IfElse if={showNavigation}>
          <>
            <View className="md:hidden">
              <View
                className={
                  open ? "hamburger hamburger--slider is-active" : "hamburger"
                }
                onClick={() => setOpen(!open)}
              >
                <View className="hamburger-box">
                  <View className="hamburger-inner"></View>
                </View>
              </View>
            </View>

            <View className="hidden gap-4 md:flex">
              {/* TODO: Disable notification on first launch */}
              {/* <IfElse if={segments.length !== 0}>
                <SearchBar isNavigationBar />
              </IfElse> */}
              <View className="hidden grow flex-row items-center justify-around gap-1 text-white md:flex">
                {HeaderLinks.map((link) => {
                  return (
                    <DesktopNavButton
                      key={link.title}
                      title={link.title}
                      href={link.href}
                      active={link.title === activeLink}
                    />
                  );
                })}
              </View>
              <UserDropdown />
              <LanguageToggle />
            </View>
          </>
        </IfElse>
      </View>
    </View>
  );
};
