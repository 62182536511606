import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientRescheduleVisitDocument,
  type ClientRescheduleVisitMutation,
  type ClientRescheduleVisitMutationVariables,
} from "@/__generated__/graphql";

export const useClientRescheduleVisit = createRequestFactory<
  ClientRescheduleVisitMutation,
  NonNullable<ClientRescheduleVisitMutation["clientRescheduleVisit"]>["visit"],
  ClientRescheduleVisitMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientRescheduleVisitDocument,
  transformFunction(data) {
    if (data.clientRescheduleVisit?.visit) {
      return data.clientRescheduleVisit.visit;
    }

    throw new Error("Failed to reschedule visit");
  },
});
