import { FormControlError } from "@/components/shared/form-control-error";
import { InputFormControl } from "@/components/shared/input-form-control";
import { RadioCardItem } from "@/components/shared/radio-card-item";
import { Label, RadioGroup, Skeleton, Textarea, View } from "@/components/ui";
import type { ReportIssueFormReturn } from "@/store/report-issue/forms/useReportIssueForm";
import { usePackageIssueDetailsStore } from "@/store/report-issue/package-issue/issueDetails";
import { useEffect, useId } from "react";
import { Controller } from "react-hook-form";

export function ReportIssueForm({ form }: { form: ReportIssueFormReturn }) {
  const { data, loading } = usePackageIssueDetailsStore();

  useEffect(() => {
    if (data) {
      const [firstReason] = data;
      form.setValue("reason", firstReason.value);
    }
  }, [data]);

  const reasonId = useId();

  return (
    <View className="flex flex-col gap-3">
      <Controller
        control={form.control}
        name="reason"
        render={({
          field: { onChange, value, disabled },
          fieldState: { error },
        }) => (
          <View>
            <Label
              htmlFor={reasonId}
              className="mt-6 block pb-1 text-sm font-medium"
            >
              Reason
            </Label>
            <RadioGroup id={reasonId} value={value} onValueChange={onChange}>
              {loading ? (
                <View className="space-y-2">
                  <Skeleton className="h-6 w-full" />
                </View>
              ) : (
                data?.map((option) => (
                  <RadioCardItem
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    selected={option.value === value}
                    disabled={disabled}
                    onChange={onChange}
                  />
                ))
              )}
            </RadioGroup>
            <FormControlError error={error?.message} />
          </View>
        )}
      />

      <InputFormControl
        name="comment"
        label="Comment"
        className="mt-3"
        control={form.control}
        component={Textarea}
        componentProps={{
          placeholder:
            "Please provide a brief description of the issue you are experiencing",
        }}
      />
    </View>
  );
}
