import { MAX_MOBILE_WIDTH } from "@/constants";
import { useState, useEffect, useMemo } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions(): {
  mediaQuery: "mobile" | "desktop";
  width: number;
  height: number;
  isMobile: boolean;
  isDesktop: boolean;
} {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { mediaQuery, isMobile, isDesktop } = useMemo(() => {
    if (windowDimensions.width <= MAX_MOBILE_WIDTH) {
      return {
        mediaQuery: "mobile" as const,
        isMobile: true,
        isDesktop: false,
      };
    }
    return { mediaQuery: "desktop" as const, isMobile: false, isDesktop: true };
  }, [windowDimensions.width]);

  return { ...windowDimensions, mediaQuery, isMobile, isDesktop };
}
