import { createRequestFactory } from "@/lib/request-factory";
import {
  CreatePackageIssueRequestDocument,
  type CreatePackageIssueRequestMutation,
  type CreatePackageIssueRequestMutationVariables,
} from "@/__generated__/graphql";

export const useCreatePackageIssueRequestStore = createRequestFactory<
  CreatePackageIssueRequestMutation,
  CreatePackageIssueRequestMutation,
  CreatePackageIssueRequestMutationVariables
>({
  method: "mutation",
  graphqlDocument: CreatePackageIssueRequestDocument,
});
