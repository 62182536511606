import { Button, Card, CardContent } from "@/components/ui";
import { onChatSales } from "@/lib/chat-sales";
import { useVisitDetailStore } from "@/store/visits/visitDetail";
import { Typography } from "@/components/shared/typography";

export function RescheduleVisitInfo() {
  const setRescheduleWorkerType = useVisitDetailStore(
    (state) => state.setRescheduleVisitWorkerType,
  );
  const rescheduleWorkerType = useVisitDetailStore(
    (state) => state.data.rescheduleVisitWorkerType,
  );

  return (
    <Card gap="none">
      <CardContent className="flex flex-col gap-1 pb-1 md:pb-4">
        <Typography variant="label-xl">
          Don’t see a spot that works for you?
        </Typography>
        <Typography
          variant={{ sm: "body-md", md: "body-lg" }}
          color="foreground-intermediate"
        >
          {rescheduleWorkerType === "ANY_WORKER"
            ? "Contact one of our sales representatives so we can help you reschedule our visit."
            : "How about checking out the availability based on all our cleaners."}
        </Typography>
      </CardContent>
      <CardContent className="pt-0">
        {rescheduleWorkerType === "CURRENT_WORKER" ? (
          <Button
            variant="primary"
            color="CTA2"
            fullWidth={{ sm: "none", md: "full" }}
            onClick={() => setRescheduleWorkerType("ANY_WORKER")}
            children="Reschedule with Any Cleaner"
          />
        ) : (
          <Button
            variant="primary"
            color="success"
            fullWidth={{ sm: "none", md: "full" }}
            onClick={onChatSales}
            children="Chat with Sales"
            iconName="whatsappLogo"
            iconColor="fill-white"
          />
        )}
      </CardContent>
    </Card>
  );
}
