import { ListHeading } from "@/components/shared/list-header";
import { Cube, StackPlus } from "@/components/shared/icons";
import { View, Checkbox, Label } from "@/components/ui";
import { ServiceAddOnCard } from "@/components/shared/booking/service-add-on-card";
import { useId, useMemo } from "react";
import { useBookingState } from "@/store/booking/useBookingState";
import { PackageServiceTypeEnum } from "@/__generated__/graphql";
import { getHomeBeautyPackageFromCode } from "@/constants/booking";
import { formatPrice } from "@/lib/helpers/number";
import { showToast } from "@/components/ui/toast/show-toast";
import { Divider } from "@/components/shared/divider";
import { Typography } from "@/components/shared/typography";

const FreeGelRemovalCode = "HBNDFGR";

const AdditionalServiceCheckbox = ({
  duration,
  title,
  description,
  checked,
  error,
  onCheckChange,
}: {
  checked: boolean;
  onCheckChange(checked: boolean): void;
  duration: string;
  error?: string;
  title: string;
  description: string;
}) => {
  const id = useId();
  return (
    <>
      <View className="flex flex-row items-center gap-4">
        <Checkbox checked={checked} onCheckedChange={onCheckChange} id={id} />
        <Label htmlFor={id} className="flex-1 cursor-pointer">
          <Typography variant="body-md" color="foreground-intermediate">
            {title}
          </Typography>
          <Typography variant="body-sm" color="foreground-intermediate">
            {description}
          </Typography>
        </Label>
        <Typography variant="body-sm" color="foreground-intermediate">
          {duration}
        </Typography>
      </View>
      <Typography variant="body-sm" color="danger">
        {error}
      </Typography>
    </>
  );
};

export const HomeBeautyBooking = () => {
  const {
    data: { packageDetails, selectedPackageIds, homeBeautyFreeGelServiceIds },
    setBookingState,
  } = useBookingState();

  const { upgradePackages, addonPackages } = useMemo(() => {
    const codeMappedPackages = packageDetails
      .map((pkg) => {
        const fePkg = getHomeBeautyPackageFromCode(pkg.code);
        return {
          ...fePkg,
          ...pkg,
        };
      })
      .filter((pkg) => !pkg.undefinedCode);

    return {
      upgradePackages: codeMappedPackages.filter(
        (pkg) => pkg.serviceType === PackageServiceTypeEnum.Upgrade,
      ),
      addonPackages: codeMappedPackages
        .filter((pkg) => pkg.serviceType === PackageServiceTypeEnum.AddOn)
        .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)),
    };
  }, [packageDetails]);

  const onAdd = (id: string) => {
    setBookingState({
      selectedPackageIds: [...selectedPackageIds, id],
    });
  };

  const onRemove = (id: string) => {
    selectedPackageIds.splice(
      selectedPackageIds.findIndex((pkgId) => pkgId === id),
      1,
    );
    setBookingState({
      selectedPackageIds,
    });
  };

  const onCheckChange = (checked: boolean, id: string) => {
    const freeGelRemovalId = packageDetails.find(
      (pkg) => pkg.code === FreeGelRemovalCode,
    )?.id as string;

    if (checked) {
      if (homeBeautyFreeGelServiceIds.length >= 2) {
        return showToast({
          title: "You can only have 2 Gel Removal within your booking",
          type: "info",
        });
      }
      setBookingState({
        homeBeautyFreeGelServiceIds: [...homeBeautyFreeGelServiceIds, id],
        selectedPackageIds: [...selectedPackageIds, freeGelRemovalId],
      });
    } else {
      const index = selectedPackageIds.findIndex(
        (id) => id === freeGelRemovalId,
      );
      setBookingState({
        homeBeautyFreeGelServiceIds: homeBeautyFreeGelServiceIds.filter(
          (serviceId) => serviceId !== id,
        ),
        selectedPackageIds: selectedPackageIds.filter(
          (id, idx) => idx !== index,
        ),
      });
    }
  };

  return (
    <View className="flex flex-col gap-4">
      <View className="mx-4 flex flex-col">
        <ListHeading icon={Cube} title="Upgrade your nails" />
        <Typography
          variant="body-lg"
          color="foreground-intermediate"
          className="mb-6"
        >
          Make your session more worthwhile and upgrade to enjoy our other nail
          service offerings.
        </Typography>
        <View className="flex flex-col gap-4">
          {upgradePackages.map((pkg) => {
            const checked = homeBeautyFreeGelServiceIds.includes(pkg.id);
            const shouldAddMoreItem =
              checked &&
              selectedPackageIds.filter((id) => id === pkg.id).length <
                (pkg.minFreeGelAmount || 1000);
            return (
              <ServiceAddOnCard
                key={pkg.id}
                title={pkg.title}
                subTitle={formatPrice(pkg.serviceBillingValue) + pkg.suffix}
                thirdTitle={`${60 * pkg.duration} mins`}
                description={pkg.description}
                imgUrl={pkg.imgUrl}
                slogan={pkg.mostPopular ? "Most Popular" : ""}
                onAdd={() => onAdd(pkg.id)}
                onMinus={() => onRemove(pkg.id)}
                value={selectedPackageIds.filter((id) => id === pkg.id).length}
                max={pkg.maxItem}
                footer={
                  <AdditionalServiceCheckbox
                    checked={checked}
                    onCheckChange={(checked) => onCheckChange(checked, pkg.id)}
                    title="Include Free Gel Removal"
                    error={
                      shouldAddMoreItem
                        ? "Add more nails to get free gel removal"
                        : ""
                    }
                    description="(Max 2 items)" // this stands for free gel removal amount
                    duration="30 mins"
                  />
                }
              />
            );
          })}
        </View>
      </View>

      <Divider className="my-2 border-b-2" />

      <View className="mx-4 flex flex-col">
        <ListHeading icon={StackPlus} title="Treatment & Add-ons" />
        <Typography
          variant="body-lg"
          color="foreground-intermediate"
          className="mb-6"
        >
          Elevate your home beauty experience with our other available
          treatments and add-ons for the full self-care package.
        </Typography>
        <View className="flex flex-col gap-4">
          {addonPackages.map((pkg) => {
            return (
              <ServiceAddOnCard
                key={pkg.id}
                title={pkg.title}
                subTitle={
                  formatPrice(pkg.serviceBillingValue) + (pkg.suffix || "")
                }
                thirdTitle={`${60 * pkg.duration} mins`}
                description={pkg.description}
                imgUrl={pkg.imgUrl}
                slogan={pkg.mostPopular ? "Most Popular" : ""}
                onAdd={() => onAdd(pkg.id)}
                onMinus={() => onRemove(pkg.id)}
                value={selectedPackageIds.filter((id) => id === pkg.id).length}
                max={pkg.maxItem}
              />
            );
          })}
        </View>
      </View>

      <Divider className="my-2 border-b-2" />
    </View>
  );
};
