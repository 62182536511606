import { View } from "@/components/ui";
import { UserCircle, EmailIcon, Phone } from "@/components/shared/icons";
import { useAuthState } from "@/store/auth";
import { fullName } from "@/lib/helpers/string";
import { Card, CardContent } from "@/components/ui/card";
import { Link } from "@/components/shared/link";
import { ChangeNumberAction } from "../change-number-action";
import { ChangeAccountType } from "./change-account-type";
import { Typography } from "../typography";
import { useIntl } from "react-intl";
import { AccountDeletionCard } from "./account-deletion-card";

export const AccountInfoList = () => {
  const intl = useIntl();
  const userInfo = useAuthState((state) => state.data.userInfo);
  const account = userInfo?.user;
  const primaryContact = userInfo?.contacts.find((i) => i.primary === true);
  const primaryAddress = userInfo?.addresses.find((i) => i.primary === true);

  const [primaryEmail] = primaryContact?.email ?? [""];
  const [primaryPhoneNumber] = primaryContact?.phoneNumber ?? [""];
  const primaryPostalCode = primaryAddress?.postalCode;
  const primaryFullAddress = primaryAddress?.fullAddress;

  return (
    <View>
      <View className="mb-5 flex flex-col gap-2">
        <View className="mb-2 px-0 md:px-4">
          <Typography variant={{ md: "label-2xl", sm: "label-lg" }}>
            {intl.formatMessage({
              defaultMessage: "Account",
              id: "account",
            })}
          </Typography>
        </View>

        <Card>
          <CardContent className="flex flex-row items-center gap-2 py-4 pr-0">
            <UserCircle className="size-7 text-secondary-text" />
            <View className="flex flex-1 flex-row items-center justify-between pr-4">
              <View>
                <Typography
                  variant={{ md: "body-xl", sm: "body-lg" }}
                  color="secondary-text"
                >
                  {intl.formatMessage({
                    defaultMessage: "Account Type",
                    id: "account-type",
                  })}
                </Typography>
                <Typography variant={{ md: "label-xl", sm: "label-lg" }}>
                  {intl.formatMessage({
                    defaultMessage: `${userInfo?.accountType}`,
                    id: `${userInfo?.accountType.toLowerCase()}`,
                  })}
                </Typography>
              </View>
              <ChangeAccountType />
            </View>
          </CardContent>

          <CardContent className="mx-4 flex flex-row items-center gap-2 border-y border-secondary-border px-0 py-4">
            <EmailIcon className="size-6 text-secondary-text" />
            <View>
              <Typography
                variant={{ md: "body-xl", sm: "body-lg" }}
                color="secondary-text"
              >
                {intl.formatMessage({
                  defaultMessage: "Email",
                  id: "email",
                })}
              </Typography>
              <Typography variant={{ md: "label-xl", sm: "label-lg" }}>
                {account?.email}
              </Typography>
            </View>
          </CardContent>

          <CardContent className="flex flex-row items-center gap-2 py-4 pr-0">
            <Phone className="size-6 text-secondary-text" />
            <View className="flex flex-1 flex-row items-center justify-between pr-4">
              <View>
                <Typography
                  variant={{ md: "body-xl", sm: "body-lg" }}
                  color="secondary-text"
                >
                  {intl.formatMessage({
                    defaultMessage: "Phone Number",
                    id: "phone-number",
                  })}
                </Typography>
                <Typography variant={{ md: "label-xl", sm: "label-lg" }}>
                  {account?.phoneNumber}
                </Typography>
              </View>
              <ChangeNumberAction />
            </View>
          </CardContent>
        </Card>
      </View>

      <View className="mb-5 flex flex-col gap-2">
        <View className="flex flex-1 flex-row justify-between py-2">
          <Typography variant={{ md: "label-2xl", sm: "label-lg" }}>
            {intl.formatMessage({
              defaultMessage: "Default Contact",
              id: "default-contact",
            })}
          </Typography>
          <Link href="/profile/contacts">
            <Typography
              variant={{ md: "label-2xl", sm: "label-md" }}
              color="brand-primary"
            >
              {intl.formatMessage({
                defaultMessage: "View All",
                id: "view-all",
              })}
            </Typography>
          </Link>
        </View>

        <Card>
          <CardContent className="flex flex-col gap-2 py-4">
            <Typography variant={{ md: "body-2xl", sm: "body-md" }}>
              {fullName({
                firstName: userInfo?.firstName,
                lastName: userInfo?.lastName,
              })}
            </Typography>
            <Typography
              variant={{ md: "body-xl", sm: "body-md" }}
              color="secondary-text"
            >{`${primaryEmail}・ ${primaryPhoneNumber}`}</Typography>
          </CardContent>
        </Card>
      </View>

      <View className="mb-8 flex flex-col gap-2">
        <View className="flex flex-1 flex-row justify-between py-2">
          <Typography variant={{ md: "label-2xl", sm: "label-lg" }}>
            {intl.formatMessage({
              defaultMessage: "Default address",
              id: "default-address",
            })}
          </Typography>
          <Link href="/profile/addresses">
            <Typography
              variant={{ md: "label-2xl", sm: "label-md" }}
              color="brand-primary"
            >
              {" "}
              {intl.formatMessage({
                defaultMessage: "View All",
                id: "view-all",
              })}
            </Typography>
          </Link>
        </View>

        <Card>
          <CardContent className="flex flex-col gap-2 py-4">
            <Typography variant={{ md: "label-2xl", sm: "label-lg" }}>
              {primaryPostalCode}
            </Typography>
            <Typography
              variant={{ md: "body-xl", sm: "body-md" }}
              color="secondary-text"
            >
              {primaryFullAddress}
            </Typography>
          </CardContent>
        </Card>
      </View>

      <View>
        <AccountDeletionCard />
      </View>
    </View>
  );
};
