import { create } from "zustand";

export type Lang = "en" | "zh-CN";

export const useLanguageStore = create<{
  language: Lang;
  setLanguage: (language: Lang) => void;
}>((set) => ({
  language: "en",
  setLanguage: (language: Lang) => set({ language }),
}));
