import { Button, View } from "@/components/ui";
import { PhoneNumberInput } from "@/components/shared/phone-number-input";
import { ErrorMessage } from "@/components/shared/login-register/error-message";
import { useProfileState } from "@/store/profile";
import { ChangePhoneNumberModalEnum } from "@/types/profile";
import type { ChangePhoneNumberFormData } from "@/store/profile/forms/useChangePhoneNumberForm";
import { useChangePhoneNumberForm } from "@/store/profile/forms/useChangePhoneNumberForm";
import { useAuthState } from "@/store/auth";
import { defaultNationCode } from "@/constants";
import { useEffect } from "react";
import { useUpdateAccountPhoneNumberStore } from "@/store/auth/updateAccountPhoneNumber";
import { getPhoneNumber, getNationCode } from "@/lib/helpers/string";
import { useClientVerifyPhoneNumberStore } from "@/store/auth/clientVerifyPhoneNumber";
import { useIntl } from "react-intl";

export const ChangeNumberForm = () => {
  const intl = useIntl();
  const { data } = useAuthState();
  const { setPhone, setUpdatedPhone } = useProfileState();
  const { userInfo } = data;
  const contact = userInfo.user.phoneNumber;
  const formValues: ChangePhoneNumberFormData = {
    currentPhoneNumber: getPhoneNumber(contact ?? ""),
    currentNationCode: getNationCode(contact ?? ""),
    newPhoneNumber: "",
    newNationCode: defaultNationCode,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useChangePhoneNumberForm(formValues);

  // When at the first time the contact is an empty string because there is a delay,
  // update the form when the contact exists
  useEffect(() => {
    if (contact) {
      setValue("currentPhoneNumber", getPhoneNumber(contact));
      setPhone(contact);
    }
  }, [contact, setValue]);

  const { showModal } = useProfileState();

  const {
    fetch: updateAccountPhoneNumber,
    loading: updateAccountPhoneNumberLoading,
    error: updateAccountPhoneNumberError,
  } = useUpdateAccountPhoneNumberStore();

  const {
    fetch: clientVerifyPhoneNumber,
    loading: clientVerifyPhoneNumberLoading,
    error: clientVerifyPhoneNumberError,
  } = useClientVerifyPhoneNumberStore();

  const onSubmitFormData = handleSubmit(
    async (data: ChangePhoneNumberFormData) => {
      const updatedCode = data.newNationCode.split("/")[1];
      setUpdatedPhone(`+${updatedCode}${data.newPhoneNumber}`);
      try {
        await updateAccountPhoneNumber({
          requestPayload: {
            input: {
              clientId: userInfo.id,
              phoneNumber: `+${updatedCode}${data.newPhoneNumber}`,
            },
          },
        });

        await clientVerifyPhoneNumber({
          requestPayload: {
            input: {
              phone: `+${updatedCode}${data.newPhoneNumber}`,
              enableWhatsappOtp: true,
            },
          },
        });
        showModal(
          ChangePhoneNumberModalEnum.CHANGE_PHONE_NUMBER_OTP_VERIFICATION,
        );
      } catch (error) {
        console.error("Failed to update phone number:", error);
      }
    },
  );
  const loading =
    updateAccountPhoneNumberLoading || clientVerifyPhoneNumberLoading;
  const error = updateAccountPhoneNumberError || clientVerifyPhoneNumberError;

  return (
    <View className="flex flex-col gap-6">
      <PhoneNumberInput
        trigger={trigger}
        control={control}
        errors={errors}
        label="Current Phone Number"
        phoneNumberName="currentPhoneNumber"
        nationCodeName="currentNationCode"
      />

      <PhoneNumberInput
        trigger={trigger}
        control={control}
        errors={errors}
        label="New Phone Number"
        phoneNumberName="newPhoneNumber"
        nationCodeName="newNationCode"
      />

      {error && <ErrorMessage errorMessage={error} className="mt-4" />}

      <Button
        variant="primary"
        color="CTA"
        fullWidth="full"
        loading={loading}
        onClick={onSubmitFormData}
        disabled={!!error}
        children={intl.formatMessage({
          defaultMessage: "Continue",
          id: "proceed",
        })}
      />
    </View>
  );
};
