import { View } from "@/components/ui";
import { Typography } from "../typography";
import { LuceSparkle } from "@/components/shared/icons";
import { FeedBackModal } from "@/components/shared/feedback-popup";
import { useState } from "react";

export function FeedBackOverlay() {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  function openModal() {
    setFeedbackModalOpen(true);
  }

  function closeModal() {
    setFeedbackModalOpen(false);
  }

  return (
    <View className="sticky z-50 flex w-full flex-row gap-2 bg-primary-surface px-6 py-4 sm:gap-0">
      <LuceSparkle className="h-4 w-4 color-brand-primary sm:hidden" />

      <View className="flex w-full flex-col items-start gap-2 sm:flex-row sm:items-center sm:justify-between">
        <View className="flex flex-row gap-4">
          <LuceSparkle className="hidden h-4 w-4 color-brand-primary sm:block" />

          <Typography color="brand-primary" variant="body-md">
            Enjoying the New Luce? You can still switch back to the old version
            anytime during this transition.
          </Typography>
        </View>
        <View onClick={openModal} className="cursor-pointer">
          <Typography variant="label-md" color="button-cta">
            Switch to Old App
          </Typography>
        </View>
      </View>
      {feedbackModalOpen && (
        <FeedBackModal
          feedbackModalOpen={feedbackModalOpen}
          onClose={closeModal}
        />
      )}
    </View>
  );
}
