import { InvoiceCard } from "@/components/shared/invoices/invoice-card";
import { useScrollRefresh } from "@/components/shared/scroll-content/scroll-refresh-context";
import { Button, View } from "@/components/ui";
import { usePaidInvoicesStore } from "@/store/invoices/paidInvoices";
import { useUnpaidInvoicesStore } from "@/store/invoices/unpaidInvoices";
import { InvoiceFilterStatusEnum } from "@/types/invoice";
import { getInvoiceFilters } from "../lib";
import { useAuthState } from "@/store/auth";
import { EmptyInvoice } from "../empty-invoice";
import { useEffect, useState } from "react";
import { useRoute } from "@/components/shared/router";

export function InvoiceList({ type }: { type: InvoiceFilterStatusEnum }) {
  const { refreshing, setRefreshing } = useScrollRefresh();
  const [shouldShowLoadMore, setShouldShowLoadMore] = useState(true);

  const { push } = useRoute();

  const {
    fetch: getInvoices,
    data: invoices,
    loading,
    fetchMore,
    refetch,
    pagination,
  } = type === InvoiceFilterStatusEnum.UNPAID
    ? useUnpaidInvoicesStore()
    : usePaidInvoicesStore();

  const user = useAuthState((state) => state.data.userInfo);

  useEffect(() => {
    if (user.id) {
      getInvoices({
        requestPayload: { filters: getInvoiceFilters(user.id, type) },
      });
    }
  }, [user.id, type]);

  useEffect(() => {
    if (refreshing) {
      refetch();
      setRefreshing(false);
    }
  }, [refreshing]);

  const onLoadMore = () => {
    fetchMore({
      requestPayload: {
        filters: getInvoiceFilters(user.id, type),
      },
    });
    if (invoices.length >= pagination.total) {
      setShouldShowLoadMore(false);
    }
  };

  const handleViewInvoice = (invoiceId: string) => () => {
    push({
      pageKey: "invoiceDetail",
      params: {
        id: invoiceId,
      },
    });
  };

  return (
    <View className="mt-4 flex flex-col gap-4 px-4 md:px-0">
      {invoices.length ? (
        invoices.map((invoice) => (
          <InvoiceCard
            key={invoice.id}
            {...invoice}
            onClick={handleViewInvoice(invoice.id)}
          />
        ))
      ) : (
        <EmptyInvoice />
      )}
      {shouldShowLoadMore && (
        <Button
          variant="tertiary"
          color='CTA2'
          fullWidth="full"
          loading={loading}
          onClick={onLoadMore}
          children="Load more"
        />
      )}
    </View>
  );
}
