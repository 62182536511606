import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientCreateReservationDocument,
  type ClientCreateReservationMutation,
  type ClientCreateReservationMutationVariables,
} from "@/__generated__/graphql";
import type { Reservation } from "@/types/booking";

type Response = Reservation[];

export const useClientCreateReservationStore = createRequestFactory<
  ClientCreateReservationMutation,
  Response,
  ClientCreateReservationMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientCreateReservationDocument,
  transformFunction: (res) => {
    if (
      res.clientCreateReservation?.result?.__typename ===
      "ClientCreateReservationOk"
    ) {
      return res.clientCreateReservation.result.reservations;
    }

    throw new Error(
      res.clientCreateReservation?.result?.message ||
        "create reservation failed",
    );
  },
});
