import { Divider } from "@/components/shared/divider";
import { Link } from "@/components/shared/link";
import { View } from "@/components/ui";
import { ArrowLeft } from "@/components/shared/icons";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { Outlet } from "react-router-dom";
import { NavTabs } from "@/components/shared/nav-tabs";
import { invoiceTabs } from "@/containers/profile/invoices/lib";
import { usePathname } from "@/components/hooks/use-pathname";
import { AutoPayWidget } from "@/components/shared/invoices/auto-pay";
import { Typography } from "@/components/shared/typography";

export function InvoicePage() {
  const pathname = usePathname();
  const { isMobile } = useWindowDimensions();

  return (
    <View>
      <View className="flex flex-row items-center gap-2 p-4 md:mb-4 md:p-0">
        {isMobile && (
          <Link href="/profile">
            <ArrowLeft className="size-5" />
          </Link>
        )}
        <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
          Invoices
        </Typography>
      </View>
      <Divider />
      <View className="px-4 md:px-0 mt-4">
        <AutoPayWidget />
      </View>
      <NavTabs items={invoiceTabs} active={pathname} />
      <Outlet />
    </View>
  );
}
