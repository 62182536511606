import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { WebLayout } from "@/components/shared/layout/homepage.web";
import { View } from "@/components/ui";
import { Column, Container } from "@/components/ui/layout";
import { ROUTES } from "@/constants/routes";
import { GetTheApp } from "@/containers/get-the-app";
import { VisitWithToken } from "@/containers/visits/visit-with-token";
import { Navigate, useSearchParams } from "react-router-dom";

export function RateVisit() {
  const [searchParams] = useSearchParams();
  const { isMobile } = useWindowDimensions();

  if (!searchParams.get("token")) {
    return <Navigate to={ROUTES.Root} />;
  }

  return (
    <WebLayout>
      <Container gap="xl">
        <Column desktop="8">
          <View className="md:my-10">
            <VisitWithToken
              type="rate"
              token={searchParams.get("token") as string}
            />
          </View>
        </Column>
        <Column desktop="4">
          <View className="px-4 md:my-10 md:px-0">
            <GetTheApp imageVariant={isMobile ? "luceapp" : "qrcode"} />
          </View>
        </Column>
      </Container>
    </WebLayout>
  );
}
