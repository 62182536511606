import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientFindPastWorkersDocument,
  type ClientFindPastWorkersQuery,
  type ClientFindPastWorkersQueryVariables,
} from "@/__generated__/graphql";
import type { PastWorker } from "@/types/booking";

type Response = PastWorker[];

export const useClientFindPastWorkers = createRequestFactory<
  ClientFindPastWorkersQuery,
  Response,
  ClientFindPastWorkersQueryVariables
>({
  method: "query",
  graphqlDocument: ClientFindPastWorkersDocument,
  transformFunction: (res) => {
    if (res.clientFindPastWorkers)
      return res.clientFindPastWorkers as PastWorker[];
    throw new Error("Request failed");
  },
});
