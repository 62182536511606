import { View } from "@/components/ui/view";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import type { TypographyVariants } from "@/components/shared/typography";
import { Typography } from "@/components/shared/typography";
import type { ReactNode } from "react";
import { useMemo } from "react";

const badgeVariants = cva(
  "inline-flex items-center rounded-full transition-colors",
  {
    variants: {
      variant: {
        primary: "border-brand-primary-border bg-brand-primary-surface",
        success: "border-success-border bg-success-surface",
        danger: "border-danger-border bg-danger-surface",
        "danger-fill": "border-danger bg-danger",
        info: "border-info-border bg-info-surface",
        warning: "border-warning-border bg-warning-surface",
        neutral: "border-border-high bg-background-intermediate",
      },
      size: {
        sm: "px-2 py-1",
        md: "px-2 py-1",
        lg: "px-3 py-1.5",
      },
      outline: {
        true: "border",
        false: "border-0",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
      outline: true,
    },
  },
);
export type BadgeProps = VariantProps<typeof badgeVariants>;

export type BadgeVariant = NonNullable<BadgeProps["variant"]>;

export function Badge({
  className,
  variant,
  size,
  outline,
  children,
  ...props
}: BadgeProps & {
  className?: string;
  children: ReactNode;
}) {
  const textColor: keyof TypographyVariants["color"] = useMemo(() => {
    switch (variant) {
      case "primary":
        return "brand-primary";
      case "danger":
        return "danger";
      case "success":
        return "success";
      case "info":
        return "info";
      case "warning":
        return "warning";
      case "danger-fill":
        return "white";
      default:
        return "foreground-intermediate";
    }
  }, [variant]);

  const textSize: keyof TypographyVariants["variant"] = useMemo(() => {
    switch (size) {
      case "sm":
        return "body-sm";
      case "md":
        return "body-md";
      case "lg":
        return "body-lg";
      default:
        return "body-md";
    }
  }, [size]);

  return (
    <View
      className={cn(badgeVariants({ variant, size, outline }), className)}
      {...props}
    >
      {["string", "number"].includes(typeof children) ? (
        <Typography as="span" color={textColor} variant={textSize}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </View>
  );
}
