import * as React from "react";
import type { SVGProps } from "react";
const SvgEmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M14.666 17.2087H6.33268C4.90676 17.2087 3.85006 16.8002 3.14978 16.0999C2.4495 15.3996 2.04102 14.3429 2.04102 12.917V7.08366C2.04102 5.65774 2.4495 4.60103 3.14978 3.90075C3.85006 3.20047 4.90676 2.79199 6.33268 2.79199H14.666C16.0919 2.79199 17.1486 3.20047 17.8489 3.90075C18.5492 4.60103 18.9577 5.65774 18.9577 7.08366V12.917C18.9577 14.3429 18.5492 15.3996 17.8489 16.0999C17.1486 16.8002 16.0919 17.2087 14.666 17.2087ZM6.33268 3.04199C5.06753 3.04199 4.03066 3.34941 3.31455 4.06552C2.59843 4.78163 2.29102 5.8185 2.29102 7.08366V12.917C2.29102 14.1821 2.59843 15.219 3.31455 15.9351C4.03066 16.6512 5.06753 16.9587 6.33268 16.9587H14.666C15.9312 16.9587 16.968 16.6512 17.6842 15.9351C18.4003 15.219 18.7077 14.1821 18.7077 12.917V7.08366C18.7077 5.8185 18.4003 4.78163 17.6842 4.06552C16.968 3.34941 15.9312 3.04199 14.666 3.04199H6.33268Z"
      fill="#68778D"
      stroke="#68778D"
    />
    <path
      d="M10.499 10.725C9.799 10.725 9.09067 10.5083 8.549 10.0666L5.94067 7.98331C5.674 7.76664 5.624 7.37497 5.84067 7.10831C6.05734 6.84164 6.44901 6.79164 6.71567 7.00831L9.324 9.09164C9.95733 9.59998 11.0323 9.59998 11.6657 9.09164L14.274 7.00831C14.5407 6.79164 14.9407 6.83331 15.149 7.10831C15.3657 7.37497 15.324 7.77498 15.049 7.98331L12.4407 10.0666C11.9073 10.5083 11.199 10.725 10.499 10.725Z"
      fill="#68778D"
    />
  </svg>
);
export default SvgEmailIcon;
