import type {
  ClientApplyVoucherMutation,
  ClientApplyVoucherMutationVariables,
} from "@/__generated__/graphql";
import {
  ClientApplyVoucherDocument,
  InvoiceDetailFragmentFragmentDoc,
} from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";
import { useInvoiceDetailStore } from "./invoiceDetail";
import { useUnpaidInvoicesStore } from "./unpaidInvoices";
import { useFragment } from "@/__generated__";
import type { InvoiceDetailData } from "@/containers/profile/invoices/invoice-detail";
import { getInvoiceDetailData } from "@/containers/profile/invoices/invoice-detail";

export const useApplyVoucherInvoiceStore = createRequestFactory<
  ClientApplyVoucherMutation,
  InvoiceDetailData,
  ClientApplyVoucherMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientApplyVoucherDocument,
  transformFunction(data) {
    if (!data.clientApplyVoucher?.invoice) {
      throw new Error("Invoice not found");
    }
    const invoice = useFragment(
      InvoiceDetailFragmentFragmentDoc,
      data.clientApplyVoucher.invoice,
    );
    return getInvoiceDetailData(invoice);
  },
  onFetchSuccess() {
    useInvoiceDetailStore.getState().refetch();
    useUnpaidInvoicesStore.getState().refetch();
  },
});
