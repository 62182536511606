import * as React from "react";
import type { SVGProps } from "react";
const SvgAppleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 41 40" fill="none" {...props}>
    <rect x={1} y={0.5} width={39} height={39} rx={7.5} fill="#EEF7FF" />
    <rect x={1} y={0.5} width={39} height={39} rx={7.5} stroke="#003499" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6001 27.16C28.1901 26.26 28.4101 25.8 28.8601 24.79C25.5401 23.53 25.0101 18.8 28.2901 16.99C27.2901 15.73 25.8801 15 24.5501 15C23.5901 15 22.9301 15.25 22.3401 15.48C21.8401 15.67 21.3901 15.84 20.8301 15.84C20.2301 15.84 19.7001 15.65 19.1401 15.45C18.5301 15.23 17.8901 15 17.0901 15C15.6001 15 14.0101 15.91 13.0001 17.47C11.5801 19.67 11.8301 23.79 14.1201 27.31C14.9401 28.57 16.0401 29.98 17.4701 30C18.0701 30.01 18.4601 29.83 18.8901 29.64C19.3801 29.42 19.9101 29.18 20.8401 29.18C21.7701 29.17 22.2901 29.42 22.7801 29.64C23.2001 29.83 23.5801 30.01 24.1701 30C25.6201 29.98 26.7801 28.42 27.6001 27.16Z"
      fill="#1155D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3404 10C24.5004 11.1 24.0504 12.19 23.4604 12.95C22.8304 13.77 21.7304 14.41 20.6704 14.37C20.4804 13.31 20.9704 12.22 21.5704 11.49C22.2404 10.69 23.3704 10.07 24.3404 10Z"
      fill="#1155D9"
    />
  </svg>
);
export default SvgAppleIcon;
