import { getRoutePath } from "@/lib/utils";
import type { PushParams } from "@/types/global/route";
import { useNavigate } from "react-router-dom";

export const useRoute = () => {
  const navigate = useNavigate();

  return {
    push: (p: PushParams | string) => {
      return navigate(typeof p === "string" ? p : getRoutePath(p));
    },
    pull: () => navigate(-1),
    replace: (p: PushParams | string) => {
      return navigate(typeof p === "string" ? p : getRoutePath(p), {
        replace: true,
      });
    },
  };
};
