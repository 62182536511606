import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { app } from "@/config/env";
import { createUploadLink } from "apollo-upload-client";

const initClientInstance = () => {
  const API_URI = app.PUBLIC_API_ENDPOINT;

  const httpLink: HttpLink = new HttpLink({
    uri: API_URI,
  });

  const uploadLink = createUploadLink({
    uri: API_URI,
  });

  const link = ApolloLink.split(
    (operation) => !!operation.getContext().hasUpload,
    uploadLink,
    httpLink,
  );

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        FreeTimeslot: {
          keyFields: ["id", "startAt", "endAt"],
        },
      },
    }),
  });

  return client;
};

export const ClientInstance = initClientInstance();
