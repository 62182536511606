import type {
  ClientReviewsQuery,
  ClientReviewsQueryVariables,
} from "@/__generated__/graphql";
import { ClientReviewsDocument } from "@/__generated__/graphql";
import { formatText } from "@/lib/helpers/string";
import { createRequestFactory } from "@/lib/request-factory";
import type { ServiceReviews } from "@/types/booking";
import { useBookingState } from "./useBookingState";

type Response = ServiceReviews;

export const useClientReviewsStore = createRequestFactory<
  ClientReviewsQuery,
  Response,
  ClientReviewsQueryVariables
>({
  method: "query",
  graphqlDocument: ClientReviewsDocument,
  transformFunction: (data) => {
    if (data.clientReviews) {
      return {
        rating: data.clientReviews.averageRating,
        ratingTotal: data.clientReviews.count,
        reviews: data.clientReviews.clientReviews.map((reviews) => ({
          id: reviews.id,
          clientName: reviews.clientName,
          rating: reviews.rating,
          createdAt: reviews.serviceDate
            ? new Date(reviews.serviceDate).getTime()
            : 0,
          ltv: reviews.clientLifetimeBooking ?? 1,
          service: `${formatText(reviews.department)} ${formatText(
            reviews.serviceType?.[0],
          )}`,
          description: reviews.ratingComment ?? "No rating comment",
          images:
            reviews.ratingAttachment?.map(
              (attachment) => attachment.downloadUrl,
            ) || [],
        })),
      };
    }
    throw new Error("Request failed");
  },
  onFetchSuccess: (res) => {
    const { setBookingState } = useBookingState.getState();
    setBookingState({ serviceReviews: res });
  },
});
