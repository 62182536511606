import { useFragment } from "@/__generated__";
import type {
  InvoiceByIdQuery,
  InvoiceByIdQueryVariables,
} from "@/__generated__/graphql";
import {
  InvoiceByIdDocument,
  InvoiceDetailFragmentFragmentDoc,
  InvoiceStatusEnum,
  InvoicePaymentStatusEnum,
} from "@/__generated__/graphql";
import type { InvoiceDetailData } from "@/containers/profile/invoices/invoice-detail";
import { getInvoiceDetailData } from "@/containers/profile/invoices/invoice-detail";

import { createRequestFactory } from "@/lib/request-factory";

const initialInvoiceData: InvoiceDetailData = {
  id: "",
  amount: 0,
  balance: 0,
  paidAmount: 0,
  baseAmount: 0,
  paymentStatus: InvoicePaymentStatusEnum.Unpaid,
  fromDate: new Date(),
  toDate: new Date(),
  issueDate: new Date(),
  dueDate: new Date(),
  status: InvoiceStatusEnum.New,
  pdfUrl: null,
  totalAmount: 0,
  pdfReceiptUrl: null,
  clientPaymentIndicated: false,
  appliedVoucher: null,
  lineItems: [],
};

export const useInvoiceDetailStore = createRequestFactory<
  InvoiceByIdQuery,
  InvoiceDetailData,
  InvoiceByIdQueryVariables
>(
  {
    method: "query",
    graphqlDocument: InvoiceByIdDocument,
    transformFunction(data) {
      const invoice = useFragment(
        InvoiceDetailFragmentFragmentDoc,
        data.invoiceById,
      );
      return getInvoiceDetailData(invoice);
    },
  },
  initialInvoiceData,
);
