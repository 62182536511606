import type { InvoicesByFiltersQueryVariables } from "@/__generated__/graphql";
import {
  InvoicePaymentStatusEnum,
  InvoiceSortByEnum,
  InvoiceStatusEnum,
  SortEnum,
} from "@/__generated__/graphql";
import { InvoiceFilterStatusEnum } from "@/types/invoice";

export const getInvoiceFilters = (
  clientId: string,
  filterStatus: InvoiceFilterStatusEnum,
): InvoicesByFiltersQueryVariables["filters"] => {
  const filters = {
    clientId: [clientId],
    status: [InvoiceStatusEnum.Confirmed],
    sortBy: [InvoiceSortByEnum.IssueDate],
    sort: [SortEnum.Desc],
  };

  switch (filterStatus) {
    case InvoiceFilterStatusEnum.UNPAID:
      return {
        ...filters,
        paymentStatus: [
          InvoicePaymentStatusEnum.Unpaid,
          InvoicePaymentStatusEnum.Underpaid,
        ],
      };
    case InvoiceFilterStatusEnum.PAID:
      return {
        ...filters,
        paymentStatus: [
          InvoicePaymentStatusEnum.Paid,
          InvoicePaymentStatusEnum.StripeProcessing,
        ],
      };

    default:
      return filters;
  }
};

export const InvoiceFilterOptions: Record<InvoiceFilterStatusEnum, string> = {
  [InvoiceFilterStatusEnum.UNPAID]: "Unpaid",
  [InvoiceFilterStatusEnum.PAID]: "Paid",
};

export const invoiceTabs = [
  { label: "Unpaid", href: "/profile/invoices/unpaid" },
  { label: "Paid", href: "/profile/invoices/paid" },
];
