import type { VisitsByClientQuery } from "@/__generated__/graphql";
import { compareAsc, compareDesc, parseISO } from "date-fns";

export const sortVisitByDateAsc = (
  visits: VisitsByClientQuery["visitsByClient"],
) => {
  return [...visits].sort((a, b) => {
    const dateA = `${a.serviceDate}T${a.startTime}`;
    const dateB = `${b.serviceDate}T${b.startTime}`;

    return compareAsc(parseISO(dateA), parseISO(dateB));
  });
};
export const sortVisitByDateDesc = (
  visits: VisitsByClientQuery["visitsByClient"],
) => {
  return [...visits].sort((a, b) => {
    const dateA = `${a.serviceDate}T${a.startTime}`;
    const dateB = `${b.serviceDate}T${b.startTime}`;

    return compareDesc(parseISO(dateA), parseISO(dateB));
  });
};
