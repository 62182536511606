import { createRequestFactory } from "@/lib/request-factory";
import {
  DiscountTypeEnum as DiscountType,
  RewardsByFiltersDocument,
  type RewardsByFiltersQuery,
  type RewardsByFiltersQueryVariables,
} from "@/__generated__/graphql";
import type { RewardItem } from "@/types/reward";
import { formatPrice } from "@/lib/helpers/number";
import { DiscountTypeEnum } from "@/containers/rewards/lib";

const getRewardListValues = (
  rewardsData: RewardsByFiltersQuery["rewardsByFilters"]["rewards"],
): RewardItem[] => {
  return rewardsData.map((item) => {
    const incentiveObject = item.masterReward.incentiveObject;

    let discountValues: string = "";
    let discountType: DiscountTypeEnum = DiscountTypeEnum.FIXED;
    if (incentiveObject?.__typename === "MasterPromoCode") {
      discountValues =
        incentiveObject.discountType === DiscountType.Fixed
          ? formatPrice(incentiveObject.discountValueFloat, 0)
          : `${incentiveObject.discountValueFloat}%`;
      discountType =
        incentiveObject.discountType === DiscountType.Fixed
          ? DiscountTypeEnum.FIXED
          : DiscountTypeEnum.PERCENT;
    } else if (incentiveObject?.__typename === "MasterVoucher") {
      discountValues =
        incentiveObject.discountTypeString === "FIXED"
          ? formatPrice(incentiveObject.discountValueInt, 0)
          : `${incentiveObject.discountValueInt}%`;
      discountType =
        incentiveObject.discountTypeString === "FIXED"
          ? DiscountTypeEnum.FIXED
          : DiscountTypeEnum.PERCENT;
    }

    const reward: RewardItem = {
      rewardName: item.masterReward.name,
      validUntil: item.validTo ?? "",
      description: item.masterReward.description ?? "",
      code: incentiveObject?.promoCode ?? "-",
      discountValues,
      discountType,
    };

    return reward;
  });
};

export const useAllRewardsByFiltersQuery = createRequestFactory<
  RewardsByFiltersQuery,
  RewardItem[],
  RewardsByFiltersQueryVariables
>({
  method: "query",
  graphqlDocument: RewardsByFiltersDocument,
  transformFunction(data) {
    return getRewardListValues(data.rewardsByFilters.rewards);
  },
});
