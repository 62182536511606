import { Homepage } from "@/containers/homepage";
import { WebLayout } from "@/components/shared/layout/homepage";
import OverdueInvoiceBanner from "@/containers/profile/invoices/overdue-invoice-banner";

export function HomepagePage() {
  return (
    <>
      <WebLayout expandBackdrop>
        <OverdueInvoiceBanner />
        <Homepage />
      </WebLayout>
    </>
  );
}
