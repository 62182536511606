import { create } from "zustand";
import { useVisitStore } from "./visitStore";
import { useVisitTokenStore } from "./visitTokenStore";
import { useNext30DayVisitsStore } from "./next30dayVisits";
import { useNext7DayVisitsStore } from "./next7dayVisits";
import { useUnscheduledVisitsStore } from "./unscheduledVisits";

export type RescheduleVisitWorkerType = "CURRENT_WORKER" | "ANY_WORKER";

type VisitDetailState = {
  showModal: boolean;
  showRateVisitModal: boolean;
  showSkipVisitModal: boolean;
  showRescheduleVisitConfirmation: boolean;
  visitId: string | null;
  visitToken: string | null;
  rescheduleVisitWorkerType: RescheduleVisitWorkerType | null;
};

type VisitDetailStore = {
  data: VisitDetailState;
  openVisitModal(visitId: string, token?: string): void;
  closeVisitModal(): void;
  toggleVisitModal(): void;
  openRateVisitModalById(visitId: string): void;
  closeRateVisitModal(): void;
  openSkipVisitModalById(visitId: string): void;
  closeSkipVisitModal(): void;
  openRescheduleVisitConfirmation(visitId: string, token?: string): void;
  closeRescheduleVisitConfirmation(): void;
  setRescheduleVisitWorkerType(type: RescheduleVisitWorkerType | null): void;
  refreshVisitList(): void;
};

const initialData: VisitDetailState = {
  showModal: false,
  showRateVisitModal: false,
  showSkipVisitModal: false,
  showRescheduleVisitConfirmation: false,
  visitId: null,
  visitToken: null,
  rescheduleVisitWorkerType: null,
};

export const useVisitDetailStore = create<VisitDetailStore>((set, get) => ({
  data: initialData,
  openVisitModal: (visitId: string, token?: string) => {
    set({
      data: {
        ...get().data,
        showModal: true,
        visitId,
        visitToken: token ?? null,
      },
    });

    if (token) {
      useVisitTokenStore.getState().fetch({
        requestPayload: {
          token,
        },
      });
    } else {
      useVisitStore.getState().fetch({
        requestPayload: {
          id: visitId,
        },
      });
    }
  },
  closeVisitModal: () => {
    set({
      data: initialData,
    });
    useVisitStore.getState().clear();
  },
  toggleVisitModal: () => {
    const currentData = get().data;

    set({
      data: {
        ...currentData,
        showModal: !currentData.showModal,
      },
    });
  },
  openRateVisitModalById: (visitId: string) => {
    set({
      data: {
        ...get().data,
        showRateVisitModal: true,
        visitId,
      },
    });
  },
  closeRateVisitModal: () => {
    set({
      data: {
        ...get().data,
        showRateVisitModal: false,
      },
    });
  },
  openSkipVisitModalById: (visitId: string) => {
    set({
      data: {
        ...get().data,
        showSkipVisitModal: true,
        visitId,
      },
    });
  },
  closeSkipVisitModal: () => {
    set({
      data: {
        ...get().data,
        showSkipVisitModal: false,
      },
    });
  },
  openRescheduleVisitConfirmation: (visitId: string, token?: string) => {
    set({
      data: {
        ...get().data,
        showRescheduleVisitConfirmation: true,
        visitId,
        visitToken: token ?? null,
      },
    });

    if (token) {
      useVisitTokenStore.getState().fetch({
        requestPayload: {
          token,
        },
      });
    } else {
      useVisitStore.getState().fetch({
        requestPayload: {
          id: visitId,
        },
      });
    }
  },
  closeRescheduleVisitConfirmation: () => {
    set({
      data: {
        ...get().data,
        showRescheduleVisitConfirmation: false,
        showModal: false,
      },
    });
  },
  setRescheduleVisitWorkerType: (type: RescheduleVisitWorkerType | null) => {
    set({
      data: {
        ...get().data,
        rescheduleVisitWorkerType: type,
      },
    });
  },
  refreshVisitList: () => {
    console.log("refreshing visits data...");
    useNext30DayVisitsStore.getState().refetch();
    useNext7DayVisitsStore.getState().refetch();
    useUnscheduledVisitsStore.getState().refetch();
  },
}));
