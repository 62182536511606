import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { Text } from "@/components/ui";
import type { ResponsiveVariantProps } from "@/lib/styles";
import {
  buttonLoadingVariants,
  buttonTextVariants,
  buttonVariants,
  iconVariants,
} from "./styles";
import { cn } from "@/lib/utils";
import type { Icon } from "@/components/shared/icons";
import { iconMap, type IconNames } from "@/components/shared/icons/icon-map";
import { Spinner } from "@/components/shared/spinner";

export interface ButtonProps
  extends ResponsiveVariantProps<typeof buttonVariants>,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  asChild?: boolean;
  iconName?: IconNames;
  loading: boolean;
  children: string;
  onClick?: () => void;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      iconSize,
      loading,
      rounded,
      fullWidth,
      color,
      iconColor,
      iconAlignment,
      asChild = false,
      children,
      iconName,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    const IconComponent: Icon | null = iconName ? iconMap[iconName] : null;
    const buttonStyles = buttonVariants({
      variant,
      color,
      size,
      rounded,
      fullWidth,
      iconAlignment,
      className,
    });
    const iconStyles = iconVariants({
      iconSize,
      iconColor,
      variant,
    });
    const textStyles = buttonTextVariants({ size, color, variant });
    const loadingStyle = buttonLoadingVariants({ size });

    const onClickButton = () => {
      if (loading || disabled) {
        return;
      }
      onClick?.();
    };

    const hasFillColor = iconStyles
      .split(" ")
      .find((i) => i.startsWith("fill-"));
    const fillColor = hasFillColor ? "fill" : "regular";

    return (
      <Comp
        className={cn(
          buttonStyles,
          disabled &&
            "border-background-attenuated bg-background-attenuated hover:border-background-attenuated hover:bg-background-attenuated",
        )}
        ref={ref}
        {...props}
        onClick={onClickButton}
      >
        {loading ? (
          <Spinner className={loadingStyle} />
        ) : (
          <>
            {IconComponent && (
              <IconComponent
                className={cn(
                  iconStyles,
                  disabled && "text-foreground-attenuated",
                )}
                weight={fillColor}
              />
            )}
            <Text
              className={cn(
                textStyles,
                disabled && "text-foreground-attenuated",
              )}
            >
              {children}
            </Text>
          </>
        )}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonTextVariants, buttonVariants, iconVariants };
