import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { emailRE } from "@/constants/regex";

const emailSchema = z.object({
  // cannot use zod email validation since we allowed "+" in email previously
  // email: z.string().email("Invalid email"),
  email: z.string().regex(emailRE, "Invalid email"),
  password: z.string().min(6, "Password is too short"),
});

export type EmailLoginFormData = z.infer<typeof emailSchema>;

export interface EmailFormReturn extends UseFormReturn<EmailLoginFormData> {
  defaultValues: EmailLoginFormData;
}

const initialFormValues = {
  email: "",
  password: "",
};

export const useEmailLoginForm = (): EmailFormReturn => ({
  defaultValues: initialFormValues,
  ...useForm<EmailLoginFormData>({
    mode: "onChange",
    defaultValues: initialFormValues,
    resolver: zodResolver(emailSchema),
  }),
});
