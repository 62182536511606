import { View } from "@/components/ui/view";
import { cn } from "@/lib/utils";

import { cva } from "class-variance-authority";

const DotVariants = cva("h-[6px] rounded-full", {
  variants: {
    state: {
      default: "w-[6px] bg-gray-300",
      active: "w-[12px] bg-primary",
    },
  },
  defaultVariants: {
    state: "default",
  },
});

export function DotNavigation({
  size,
  activeIndex,
}: {
  size: number;
  activeIndex?: number;
}) {
  const createDotNavigation = () => {
    return [...Array(size)].map((_, index) => (
      <View
        className={cn(
          DotVariants({ state: activeIndex === index ? "active" : "default" }),
        )}
        key={`dot-navigation-${index}`}
      />
    ));
  };

  return (
    <View className="flex flex-row justify-center gap-1">
      {createDotNavigation()}
    </View>
  );
}
