import { createResponsiveCVA } from "@/lib/styles";

type ButtonVariants = {
  variant: {
    primary: string;
    secondary: string;
    tertiary: string;
    subtle: string;
    link: string;
  };

  color: {
    CTA: string;
    CTA2: string;
    danger: string;
    success: string;
    foreground: string;
    white: string;

    "CTA2-subtle": string;
    "danger-subtle": string;

    "CTA2-tertiary": string;
  };

  iconColor: {
    CTA: string;
    CTA2: string;
    danger: string;
    success: string;
    foreground: string;
    white: string;

    "CTA2-subtle": string;
    "danger-subtle": string;

    "CTA2-tertiary": string;

    "fill-CTA": string;
    "fill-CTA2": string;
    "fill-danger": string;
    "fill-success": string;
    "fill-foreground": string;
    "fill-white": string;

    "fill-CTA2-tertiary": string;
  };

  size: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };

  iconSize: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };

  rounded: {
    lg: string;
    full: string;
  };

  fullWidth: {
    none: string;
    full: string;
  };

  iconAlignment: {
    start: string;
    end: string;
    top: string;
    bottom: string;
  };
};

export const buttonVariants = createResponsiveCVA<ButtonVariants>(
  "flex cursor-pointer items-center justify-center whitespace-nowrap transition-colors gap-2 web:ring-offset-background web:focus-visible:outline-none web:focus-visible:ring-2 web:focus-visible:ring-offset-2 rounded-lg",
  {
    // the latest class will be prioritized
    // thats mean the latest class on the line will be override the previously class

    // for subtle variant, use color with SUBTLE/subtle prefix

    default: {
      color: {
        CTA: "bg-button-cta hover:bg-button-cta-hover border border-button-cta",
        CTA2: "bg-button-cta2 hover:bg-button-cta2-hover border border-button-cta2-border hover:border-button-cta2-border-hover",
        danger: "bg-danger border border-danger",
        success: "bg-success border border-success",
        foreground:
          "bg-foreground hover:bg-foreground-intermediate border border-input",
        white: "bg-transparent",
        "CTA2-subtle":
          "bg-button-cta2-surface hover:bg-button-cta2-surface-hover border border-button-cta2-surface hover:border-button-cta2-surface-hover",
        "danger-subtle": "bg-danger-surface border border-danger-surface",
        "CTA2-tertiary":
          "bg-button-cta2 hover:bg-button-cta2-hover border border-button-cta2 hover:border-button-cta2-hover",
      },

      iconColor: {
        CTA: "text-button-cta",
        CTA2: "text-button-cta2",
        danger: "text-danger",
        success: "text-success",
        foreground: "text-foreground",
        white: "text-white",

        "CTA2-subtle": "hover:text-button-cta2-hover text-button-cta2",
        "danger-subtle": "text-danger",
        "CTA2-tertiary": "text-primary",

        "fill-CTA": "fill-button-cta",
        "fill-CTA2": "fill-button-cta2",
        "fill-danger": "fill-danger",
        "fill-success": "fill-success",
        "fill-foreground": "fill-text-foreground",
        "fill-white": "fill-text-white",
        "fill-CTA2-tertiary": "fill-text-primary",
      },

      variant: {
        primary:
          "hover:text-button-on-cta disabled:bg-background-attenuated disabled:text-foreground-attenuated disabled:border-0",
        secondary:
          "bg-transparent hover:bg-transparent disabled:border-secondary-border",
        tertiary:
          "border-0 bg-white hover:bg-white disabled:bg-background-attenuated disabled:text-foreground-attenuated disabled:border-0",
        subtle:
          "disabled:bg-background-attenuated disabled:text-foreground-attenuated disabled:border-0",
        link: "bg-transparent hover:bg-transparent border-0 disabled:border-0",
      },

      size: {
        xs: "px-2 py-1.5",
        sm: "px-4 py-2",
        md: "px-5 py-2",
        lg: "px-6 py-2.5",
        xl: "px-7 py-3",
      },

      iconSize: {
        xs: "size-2",
        sm: "size-4",
        md: "size-5",
        lg: "size-6",
        xl: "size-8",
      },

      rounded: {
        lg: "rounded-lg",
        full: "rounded-full",
      },

      fullWidth: {
        none: "w-auto",
        full: "w-full flex-1",
      },

      iconAlignment: {
        start: "flex-row",
        end: "flex-row-reverse",
        top: "flex-col",
        bottom: "flex-col-reverse",
      },
    },
    md: {
      color: {
        CTA: "md:bg-button-cta md:hover:bg-button-cta-hover md:border md:border-button-cta",
        CTA2: "md:bg-button-cta2 md:hover:bg-button-cta2-hover md:border md:border-button-cta2-border md:hover:border-button-cta2-border-hover",
        danger: "md:bg-danger md:border md:border-danger",
        success: "md:bg-success md:border md:border-success",
        foreground:
          "md:bg-foreground md:hover:bg-foreground-intermediate md:border md:border-input",
        white: "md:bg-transparent",
        "CTA2-subtle":
          "md:bg-button-cta2-surface md:hover:bg-button-cta2-surface-hover md:border md:border-button-cta2-surface md:hover:border-button-cta2-surface-hover",
        "danger-subtle":
          "md:bg-danger-surface md:border md:border-danger-surface",
        "CTA2-tertiary":
          "md:bg-button-cta2 md:hover:bg-button-cta2-hover md:border md:border-button-cta2 md:hover:border-button-cta2-hover",
      },

      iconColor: {
        CTA: "md:text-button-cta",
        CTA2: "md:text-button-cta2",
        danger: "md:text-danger",
        success: "md:text-success",
        foreground: "md:text-foreground",
        white: "md:text-white",

        "CTA2-subtle": "md:hover:text-button-cta2-hover md:text-button-cta2",
        "danger-subtle": "md:text-danger",
        "CTA2-tertiary": "md:text-primary",

        "fill-CTA": "md:fill-button-cta",
        "fill-CTA2": "md:fill-button-cta2",
        "fill-danger": "md:fill-danger",
        "fill-success": "md:fill-success",
        "fill-foreground": "md:fill-text-foreground",
        "fill-white": "md:fill-text-white",
        "fill-CTA2-tertiary": "md:fill-text-primary",
      },

      variant: {
        primary:
          "md:hover:text-button-on-cta md:disabled:bg-background-attenuated md:disabled:text-foreground-attenuated md:disabled:border-0",
        secondary:
          "md:bg-transparent md:hover:bg-transparent md:disabled:border-secondary-border",
        tertiary:
          "md:border-0 md:bg-white md:hover:bg-white md:disabled:bg-background-attenuated md:disabled:text-foreground-attenuated md:disabled:border-0",
        subtle:
          "md:disabled:bg-background-attenuated md:disabled:text-foreground-attenuated md:disabled:border-0",
        link: "md:bg-transparent md:hover:bg-transparent md:border-0 md:disabled:border-0",
      },

      size: {
        xs: "md:px-2 md:py-1.5",
        sm: "md:px-4 md:py-2",
        md: "md:px-5 md:py-2",
        lg: "md:px-6 md:py-2.5",
        xl: "md:px-7 md:py-3",
      },

      iconSize: {
        xs: "md:size-2",
        sm: "md:size-4",
        md: "md:size-5",
        lg: "md:size-6",
        xl: "md:size-8",
      },

      rounded: {
        lg: "md:rounded-lg",
        full: "md:rounded-full",
      },

      fullWidth: {
        none: "md:w-auto",
        full: "md:w-full md:flex-1",
      },

      iconAlignment: {
        start: "md:flex-row",
        end: "md:flex-row-reverse",
        top: "md:flex-col",
        bottom: "md:flex-col-reverse",
      },
    },
  },
  {
    variant: "primary",
    size: "md",
    rounded: "full",
    iconAlignment: "start",
    // will be adjust the default button on next fix
  },
);

export const iconVariants = createResponsiveCVA(
  "",
  {
    default: {
      iconSize: {
        xs: "size-2",
        sm: "size-4",
        md: "size-5",
        lg: "size-6",
        xl: "size-8",
      },

      iconColor: {
        CTA: "text-button-cta",
        CTA2: "text-button-cta2",
        danger: "text-danger",
        success: "text-success",
        foreground: "text-foreground",
        white: "text-white",

        "CTA2-subtle": "hover:text-button-cta2-hover text-button-cta2",
        "danger-subtle": "text-danger",

        "CTA2-tertiary": "text-primary",

        "fill-CTA": "fill-button-cta",
        "fill-CTA2": "fill-button-cta2",
        "fill-danger": "fill-danger",
        "fill-success": "fill-success",
        "fill-foreground": "fill-text-foreground",
        "fill-white": "fill-text-white",

        "fill-CTA2-tertiary": "fill-text-primary",
      },

      variant: {
        primary: "text-button-on-cta",
        secondary: "",
        tertiary: "",
        subtle: "",
        link: "",
      },
    },
    md: {
      iconSize: {
        xs: "md:size-2",
        sm: "md:size-4",
        md: "md:size-5",
        lg: "md:size-6",
        xl: "md:size-8",
      },

      iconColor: {
        CTA: "md:text-button-cta",
        CTA2: "md:text-button-cta2",
        danger: "md:text-danger",
        success: "md:text-success",
        foreground: "md:text-foreground",
        white: "md:text-white",

        "CTA2-subtle": "md:hover:text-button-cta2-hover md:text-button-cta2",
        "danger-subtle": "md:text-danger",

        "CTA2-tertiary": "md:text-primary",

        "fill-CTA": "md:fill-button-cta",
        "fill-CTA2": "md:fill-button-cta2",
        "fill-danger": "md:fill-danger",
        "fill-success": "md:fill-success",
        "fill-foreground": "md:fill-text-foreground",
        "fill-white": "md:fill-text-white",

        "fill-CTA2-tertiary": "md:fill-text-primary",
      },

      variant: {
        primary: "md:text-button-on-cta",
        secondary: "",
        tertiary: "",
        subtle: "",
        link: "",
      },
    },
  },
  {
    iconColor: "foreground",
    iconSize: "md",
  },
);

export const buttonTextVariants = createResponsiveCVA("", {
  default: {
    size: {
      xs: "text-[10px] leading-[12px]",
      sm: "text-[12px] leading-4",
      md: "text-[14px] leading-[20px]",
      lg: "text-4 leading-6",
      xl: "text-[20px] leading-7",
    },
    color: {
      CTA: "text-button-cta",
      CTA2: "text-button-cta2",
      danger: "text-danger",
      success: "text-success",
      foreground: "text-foreground",
      white: "text-white",

      "CTA2-subtle": "hover:text-button-cta2-hover text-button-cta2",
      "danger-subtle": "text-danger",

      "CTA2-tertiary": "text-primary",
    },

    variant: {
      primary: "text-button-on-cta",
      secondary: "",
      tertiary: "",
      subtle: "",
      link: "",
    },
  },
  md: {
    size: {
      xs: "md:text-[10px] md:leading-[12px]",
      sm: "md:text-[12px] md:leading-4",
      md: "md:text-[14px] md:leading-[20px]",
      lg: "md:text-4 md:leading-6",
      xl: "md:text-[20px] md:leading-7",
    },
    color: {
      CTA: "md:text-button-cta",
      CTA2: "md:text-button-cta2",
      danger: "md:text-danger",
      success: "md:text-success",
      foreground: "md:text-foreground",
      white: "md:text-white",

      "CTA2-subtle": "md:hover:text-button-cta2-hover md:text-button-cta2",
      "danger-subtle": "md:text-danger",

      "CTA2-tertiary": "md:text-primary",
    },

    variant: {
      primary: "md:text-button-on-cta",
      secondary: "",
      tertiary: "",
      subtle: "",
      link: "",
    },
  },
});

export const buttonLoadingVariants = createResponsiveCVA(
  "animate-spin",
  {
    default: {
      size: {
        xs: "size-2",
        sm: "size-4",
        md: "size-6",
        lg: "size-8",
        xl: "size-10",
      },
    },
  },
  {
    size: "md",
  },
);
