import { Dialog, DialogContent } from "@/components/ui";
import { Payment } from "@/containers/profile/payment";

export const CreditCardScreenModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <Payment />
      </DialogContent>
    </Dialog>
  );
};
