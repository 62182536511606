import { WarningCircle } from "@/components/shared/icons";
import { View } from "@/components/ui";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "@/components/ui/dropdown-menu";
import { Link } from "@/components/shared/link";
import { generateWALink } from "@/lib/helpers/string";
import { Typography } from "@/components/shared/typography";
import { SALES_NUMBER } from "@/constants";

const waLink = generateWALink(
  SALES_NUMBER,
  "Hi! I would like to speak to sales about booking a new appointment for Home Cleaning service",
);

export const InfoHelper = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <View className="cursor-pointer">
          <WarningCircle
            size={24}
            className="scale-y-[-1] transform text-primary"
          />
        </View>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="-left-10 p-2 px-3">
        <View className="max-w-[311px]">
          <Typography variant="body-md">
            Did you know, if you refer enough people that you have more than
            $200 in account credit, we'll give it to you in cash! You can
            contact
            <Link
              href={waLink}
              className="text-primary"
              target="_blank"
            >{` ${SALES_NUMBER}`}</Link>
          </Typography>
        </View>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
