import { useVisitDetailStore } from "@/store/visits/visitDetail";
import { useVisitStore } from "@/store/visits/visitStore";
import { VisitDetailModal } from "@/components/shared/visits/visit-detail-modal";
import {
  useVisitIssueStore,
  VisitIssueType,
} from "@/store/report-issue/visit-issue/visitIssue";
import { VisitRateModalContainer } from "../visit-rate";
import { SkipVisitModalContainer } from "../skip-visit";
import { useVisitTokenStore } from "@/store/visits/visitTokenStore";
import { RescheduleVisitConfirmation } from "../reschedule-visit/reschedule-visit-confirmation";
import { LoadingDialog } from "@/components/shared/loading-dialog";
import type { VisitDetailData } from "@/components/shared/visits/visit-detail";

export function VisitDetailContainer({
  type = "auth",
}: {
  type?: "token" | "auth";
}) {
  const {
    data: {
      showModal,
      showRateVisitModal,
      showSkipVisitModal,
      showRescheduleVisitConfirmation,
    },
    closeVisitModal,
    openRateVisitModalById,
    openSkipVisitModalById,
    openRescheduleVisitConfirmation,
  } = useVisitDetailStore();
  const openVisitIssueModal = useVisitIssueStore(
    (state) => state.openVisitIssueModal,
  );
  const { data, loading } =
    type === "token" ? useVisitTokenStore() : useVisitStore();

  const handleReschedule = (visitId: string) => {
    openRescheduleVisitConfirmation(visitId);
  };
  const handleChangeWorker = (visitId: string) => {
    openVisitIssueModal(VisitIssueType.changeVisitWorker, visitId);
  };
  const handleReportVisit = (visitId: string) => {
    openVisitIssueModal(VisitIssueType.reportVisit, visitId);
  };
  const handleSkipVisit = (visitId: string) => {
    openSkipVisitModalById(visitId);
  };

  const otherModalOpen =
    showSkipVisitModal || showRateVisitModal || showRescheduleVisitConfirmation;

  if (otherModalOpen && loading) {
    return <LoadingDialog open />;
  }

  if (showSkipVisitModal && data) {
    return <SkipVisitModalContainer visitData={data} loading={loading} />;
  }

  if (showRateVisitModal && data) {
    return <VisitRateModalContainer visitData={data} loading={loading} />;
  }

  if (showRescheduleVisitConfirmation && data) {
    return (
      <RescheduleVisitConfirmation
        visitId={data.id}
        department={data.department}
        visitStatus={data.status}
        serviceDate={data.serviceDate}
        hasWorkerAssignment={!!data.worker}
      />
    );
  }

  if (showModal) {
    return (
      <VisitDetailModal
        handleClose={closeVisitModal}
        visitData={data as VisitDetailData}
        loading={loading}
        handleReschedule={handleReschedule}
        handleChangeWorker={handleChangeWorker}
        handleReportVisit={handleReportVisit}
        handleSkipVisit={handleSkipVisit}
        handleRateVisit={openRateVisitModalById}
      />
    );
  }

  return null;
}
