import { createRequestFactory } from "@/lib/request-factory";
import {
  UpdateAddressDocument,
  type UpdateAddressMutation,
  type UpdateAddressMutationVariables,
} from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";
import type { Address } from "@/types/users";

type Response = {
  address: Address;
};

export const useUpdateAddressStore = createRequestFactory<
  UpdateAddressMutation,
  Response,
  UpdateAddressMutationVariables
>({
  method: "mutation",
  graphqlDocument: UpdateAddressDocument,
  transformFunction: (res) => {
    if (res.updateAddress?.address) {
      return { address: res.updateAddress.address } as Response;
    }
    throw new Error("Update address failed");
  },
  onFetchSuccess: (data) => {
    const {
      setUserInfo,
      data: { userInfo },
    } = useAuthState.getState();

    const updatedAddress = data.address;
    const existingAddress = userInfo.addresses || [];

    const updatedAddresses = existingAddress.map((address) =>
      address.id === updatedAddress.id ? updatedAddress : address,
    );

    setUserInfo({ ...userInfo, addresses: updatedAddresses });
  },
});
