import type {
  ClientAccountTypeEnum,
  ClientQuery,
  CreditTypeEnum,
  StripePaymentMethod,
} from "@/__generated__/graphql";
import type { NonNullableCollection } from "./app";

export type Contact = {
  id: string;
  firstName: string;
  lastName: string;
  primary: boolean;
  email: string[];
  phoneNumber: string[];
};

export type Address = {
  id?: string;
  postalCode: string;
  fullAddress?: string;
  unitNumber?: string; //eg: #05-518
  primary: boolean;
};

export type User = {
  id: string;
  email: string;
  phoneNumber?: string;
  phoneNumberVerifiedAt?: string;
  emailVerifiedAt?: string;
};

export type UserCreditAccount = {
  id: string;
  creditType: CreditTypeEnum;
  balance: number;
};

export type UserPaymentMethod = Omit<
  StripePaymentMethod,
  "__typename" | "country" | "cardType"
>;

type UserInfoBillingAccount = NonNullableCollection<
  Omit<ClientQuery["client"]["billingAccount"], "__typename">
>;

export type UserInfo = {
  id: string;
  firstName: string;
  lastName: string;
  user: User;
  contacts: Contact[];
  addresses: Address[];
  accountType: ClientAccountTypeEnum;
  creditAccounts: UserCreditAccount[];
  paymentMethod: UserPaymentMethod | null;
  autoPay: boolean;
  billingAccount: UserInfoBillingAccount;
};

export type JwtInfo = {
  jwt: string;
  jwtExpiry: number;
  refreshToken: string;
};

export enum LoginType {
  Login = "Login",
  EmailLogin = "EmailLogin",
  SignUp = "SignUp",
  Confirm = "Confirm",
}

export enum AuthModals {
  LOGIN = "login",
  OTP = "otp",
  SIGN_UP = "signUp",
  EMAIL_LOGIN = "emailLogin",
  FORGOT_PASSWORD = "forgotPassword",
  ADDRESS = "address",
  WELCOME = "welcome",
  LOGOUT = "logout",
}

export enum SignUpStep {
  ValidateNumber = "ValidateNumber",
  AddAddress = "AddAddress",
}
