import { View } from "@/components/ui";
import { ChatWithSales } from "@/components/shared/chat-with-sales";
import {
  BookingNavigationBottom,
  BookingOrderCard,
} from "@/components/shared/booking/order-card";
import type { ServiceName } from "@/types/service";
import { useBookingState } from "@/store/booking/useBookingState";
import { useBookingRoute } from "@/components/hooks/use-booking-route";
import { showToast } from "@/components/ui/toast/show-toast";

export const BookingInfoNavigation = (props: { name: ServiceName }) => {
  const { forward } = useBookingRoute();
  const {
    data: { bookingInfoFormReturn, reservationTimeStamp },
    setBookingState,
  } = useBookingState();

  const { handleSubmit } = bookingInfoFormReturn || {};

  const onContinue = handleSubmit?.((data) => {
    const now = new Date().getTime();
    if (now > reservationTimeStamp) {
      showToast({
        title: "Your selected slot already expired, please select slot again",
        type: "error",
      });
      return;
    }
    setBookingState({ bookingInfo: data });
    forward();
  });

  return (
    <View>
      {/* large screen */}
      <View className="hidden flex-col gap-4 md:flex">
        <BookingOrderCard onContinue={onContinue} />
        <ChatWithSales />
      </View>
      {/* narrow screen */}
      <BookingNavigationBottom onContinue={onContinue} />
    </View>
  );
};
