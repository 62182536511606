import type {
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables,
} from "@/__generated__/graphql";
import { DeletePaymentMethodDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";

type Response = boolean;

export const useDeletePaymentMethodStore = createRequestFactory<
  DeletePaymentMethodMutation,
  Response,
  DeletePaymentMethodMutationVariables
>({
  method: "mutation",
  graphqlDocument: DeletePaymentMethodDocument,
  transformFunction: (res) => {
    if (res.deletePaymentMethod) {
      return res.deletePaymentMethod.result;
    }
    throw new Error("Unable to delete payment method");
  },
});
