import { View, Card, CardContent } from "@/components/ui";
import { Info } from "@/components/shared/icons";
import { Typography } from "../typography";

export const InfoCard = ({
  title,
  description,
}: {
  title?: string;
  description: string;
}) => {
  return (
    <Card variant="info" className="w-full flex-1">
      <CardContent className="flex flex-row items-start gap-2 p-3">
        <Info className="text-primary" weight="fill" size={20} />
        <View className="pr-4 md:max-w-max md:pr-0">
          {title && (
            <Typography variant="label-lg" color="info">
              {title}
            </Typography>
          )}
          <Typography variant="body-lg" color="info">
            {description}
          </Typography>
        </View>
      </CardContent>
    </Card>
  );
};
