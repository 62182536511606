import { ListHeading } from "@/components/shared/list-header";
import { Button, Card, CardContent, View } from "@/components/ui";
import { SquaresFour } from "@/components/shared/icons";
import { ServiceIcon } from "@/components/shared/service-icon";
import { useIntl } from "react-intl";
import { popularServiceItems } from "@/containers/homepage/lib";
import { useState } from "react";
import { AllServicesModal } from "../homepage/all-services";

export function MakeABooking() {
  const intl = useIntl();

  const [modalOpen, handleOpenChange] = useState(false);

  const viewAll = () => {
    handleOpenChange(true);
  };

  return (
    <View>
      <Card border="none" shadow="sm">
        <CardContent className="flex h-auto flex-col gap-4 p-4">
          <ListHeading
            icon={SquaresFour}
            title={intl.formatMessage({
              defaultMessage: "Make a Booking",
              id: "make-a-booking",
            })}
            action={
              <Button
                variant="tertiary"
                color="CTA2"
                onClick={viewAll}
                children={intl.formatMessage({
                  defaultMessage: "View All",
                  id: "view-all",
                })}
              />
            }
          />
          <View className="flex flex-row justify-between">
            {popularServiceItems(intl).map((service) => (
              <View className="flex-1" key={service.title}>
                <ServiceIcon
                  icon={service.icon}
                  title={service.title}
                  name={service.name}
                />
              </View>
            ))}
          </View>
        </CardContent>
      </Card>
      <AllServicesModal
        modalOpen={modalOpen}
        handleOpenChange={handleOpenChange}
      />
    </View>
  );
}
