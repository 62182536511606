import { View, Image, Button } from "@/components/ui";
import NotFoundCatus from "@/assets/images/not-found-catus.png";
import NotFoundCharacter from "@/assets/images/not-found-character.png";
import { useRoute } from "@/components/shared/router";
import { Typography } from "@/components/shared/typography";

export function NotFoundScreen() {
  const images = [NotFoundCatus, NotFoundCharacter];
  const randomImage = images[Math.floor(Math.random() * images.length)];

  const { replace } = useRoute();

  const onBack = () => {
    replace({ pageKey: "homepage" });
  };

  return (
    // I changed style here so it looks similar to error boundary page.
    <View className="mx-6 my-10 flex items-center justify-center">
      <View className="flex w-96 flex-col gap-4">
        <View className="flex max-h-80 justify-center">
          <Image src={randomImage} className="object-cover" />
        </View>
        <Typography variant="label-lg" align="center">
          Ooops! We Couldn't find this page
        </Typography>
        <Typography variant="body-md" color="secondary-text" align="center">
          It seems the page you are looking for has been removed or never
          existed. Please check the link you entered, or return to the homepage
          to find other content.
        </Typography>
        <Button
          variant="primary"
          color="CTA"
          onClick={onBack}
          children="Back to Homepage"
        />
      </View>
    </View>
  );
}
