import { defaultNationCode } from "@/constants";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { superRefinePhoneNumber } from "@/lib/helpers/phone-number-validation";

const loginSchema = z
  .object({
    phoneNumber: z.string(),
    nationCode: z.string().min(1, "Nation code is required"),
  })
  .superRefine((arg, ctx) => {
    superRefinePhoneNumber({
      code: arg.nationCode,
      phoneNumber: arg.phoneNumber,
      ctx,
    });
  });

export type LoginFormData = z.infer<typeof loginSchema>;

export interface LoginFormReturn extends UseFormReturn<LoginFormData> {
  defaultValues: LoginFormData;
}

const initialFormValues = { nationCode: defaultNationCode, phoneNumber: "" };

export const useLoginForm = (): LoginFormReturn => ({
  defaultValues: initialFormValues,
  ...useForm<LoginFormData>({
    mode: "onChange",
    defaultValues: initialFormValues,
    resolver: zodResolver(loginSchema),
  }),
});
