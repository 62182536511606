import { Badge, View } from "@/components/ui";
import type { Icon } from "@/components/shared/icons";
import {
  CreditCard,
  FileText,
  Invoice,
  ShieldCheck,
  SquaresFour,
  User,
} from "@/components/shared/icons";
import { Divider } from "../divider";
import { ROUTES } from "@/constants/routes";
import { useLocation } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useSegments } from "@/components/hooks/use-segments";
import { useGetProfilePath } from "@/containers/profile/utils";
import { Typography } from "../typography";
import { useIntl } from "react-intl";
import { links } from "@/constants";
import { WhatsappSupportBanner } from "../whatsapp-support-banner";
import { Link } from "../link";
import { useAuthState } from "@/store/auth";

// const ListItem = ({
//   title,
//   iconName,
//   active,
//   onClick,
// }: {
//   title: string;
//   iconName?: IconNames;
//   active: boolean;
//   onClick?(): void;
// }) => {
//   return (
//     <Button
//       className={cn(
//         "w-full justify-start gap-1 border border-transparent px-2 text-xs hover:border-primary-border hover:bg-primary-surface",
//         {
//           "border-primary-border bg-primary-surface text-primary": active,
//         },
//       )}
//       variant="tertiary"
//       onClick={onClick}
//       children={title}
//       iconName={iconName}
//       iconColor={active ? "fill-CTA2-tertiary" : "foreground"}
//     />
//   );
// };

const ListItem = ({
  title,
  icon: IconComponent,
  active,
  to,
  external,
  badge,
}: {
  title: string;
  icon: Icon;
  active?: boolean;
  to: string;
  external?: boolean;
  badge?: string;
}) => {
  return (
    <Link
      href={to}
      target={external ? "_blank" : undefined}
      className={cn(
        "flex items-center justify-between rounded-full border border-transparent p-2 hover:border-primary-border hover:bg-primary-surface",
        {
          "border-primary-border bg-primary-surface": active,
        },
      )}
    >
      <View className="flex flex-1 flex-row items-center gap-2">
        <IconComponent
          className={
            active ? "color-brand-primary" : "color-foreground-default"
          }
          weight={active ? "fill" : "regular"}
          size={20}
        />
        <Typography
          variant={active ? "label-md" : "body-md"}
          color={active ? "brand-primary" : "foreground-default"}
        >
          {title}
        </Typography>
      </View>
      {badge && (
        <Badge
          variant="danger-fill"
          outline={false}
          size="sm"
          className="px-1.5 py-0"
        >
          {badge}
        </Badge>
      )}
    </Link>
  );
};

export const SideNavbar = () => {
  const intl = useIntl();
  const segments = useSegments();
  const profilePath = segments.at(-1);
  const { pathname } = useLocation();
  const { isInsideAccountInfo, isInsideOverview } = useGetProfilePath(
    profilePath ?? "",
    pathname,
  );

  const totalOverdueInvoice = useAuthState(
    ({ data }) => data.userInfo.billingAccount.totalOverdueInvoice,
  );

  return (
    <View className="SideNavbar relative w-full">
      <View className="flex flex-col gap-3 rounded-lg border bg-white p-2">
        <View className="flex flex-col gap-2">
          <Typography
            variant="label-md"
            color="foreground-intermediate"
            className="ml-3"
          >
            {intl.formatMessage({
              defaultMessage: "Account",
              id: "account",
            })}
          </Typography>
          <ListItem
            title={intl.formatMessage({
              defaultMessage: "Overview",
              id: "account.overview",
            })}
            icon={SquaresFour}
            active={isInsideOverview}
            to={ROUTES.Profile.Children.Overview}
          />
          <ListItem
            title={intl.formatMessage({
              defaultMessage: "Account Info",
              id: "account.info",
            })}
            icon={User}
            active={isInsideAccountInfo}
            to={ROUTES.Profile.Children.AccountInfo}
          />
          <ListItem
            title={intl.formatMessage({
              defaultMessage: "Invoices",
              id: "account.invoices",
            })}
            icon={Invoice}
            active={!!segments.includes(ROUTES.Profile.Children.Invoices.Root)}
            to={ROUTES.Profile.Children.Invoices.Root}
            badge={totalOverdueInvoice ? `${totalOverdueInvoice}` : undefined}
          />
          <ListItem
            title={intl.formatMessage({
              defaultMessage: "Payment",
              id: "account.payment",
            })}
            icon={CreditCard}
            active={profilePath === ROUTES.Profile.Children.Payment}
            to={ROUTES.Profile.Children.Payment}
          />
        </View>
        <Divider />
        <View className="flex flex-col gap-2">
          <Typography
            variant="label-md"
            color="foreground-intermediate"
            className="ml-3"
          >
            Other Info
          </Typography>
          <ListItem
            title={intl.formatMessage({
              defaultMessage: "Terms of Services",
              id: "account.termsOfService",
            })}
            icon={FileText}
            to={links.legalTermCondition}
            external
          />
          <ListItem
            title={intl.formatMessage({
              defaultMessage: "Privacy Policy",
              id: "account.privacyPolicy",
            })}
            icon={ShieldCheck}
            to={links.legalPrivacyPolicy}
            external
          />
        </View>
      </View>
      <WhatsappSupportBanner />
    </View>
  );
};
