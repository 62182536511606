import { View } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { ArrowLeft } from "@/components/shared/icons";
import { ContactListContainer } from "../../contact-list";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const ContactsPage = () => {
  const intl = useIntl();
  return (
    <View>
      <View className="static flex flex-row items-center justify-between border-b md:mb-8 md:pb-4">
        <View className="flex flex-row items-center gap-2 p-4 md:p-0">
          <Link href="/profile/account-info">
            <ArrowLeft className="size-5" />
          </Link>
          <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
            {intl.formatMessage({
              defaultMessage: "Contacts",
              id: "contacts",
            })}
          </Typography>
        </View>
        <View className="fixed bottom-0 z-50 w-full bg-white px-4 py-6 text-sm md:static md:w-auto md:p-0">
          <View className="native:hidden flex justify-center rounded-full bg-secondary-button px-4 py-2 text-white">
            <Link href={`/profile/contacts/new`}>
              <Typography variant="label-lg" color="white">
                {intl.formatMessage({
                  defaultMessage: "Add new Contacts",
                  id: "add-contact",
                })}
              </Typography>
            </Link>
          </View>
        </View>
      </View>

      <ContactListContainer />
    </View>
  );
};
