import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { ListHeading } from "@/components/shared/list-header";
import type {
  DurationOption,
  FrequencyOption,
} from "@/components/shared/service-radio-item/types";
import { Button, View, RadioGroup } from "@/components/ui";
import { Clock } from "@/components/shared/icons";
// import { UpsellContainer } from "./upsell-container";
import { ServiceRadioItem } from "@/components/shared/service-radio-item";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import { PackageFrequencyGroupEnum } from "@/types/booking";

export function CleaningDurationModal({
  open,
  durationOptions,
  // upsellOptions,
  selectedDurationOptionValue,
  // selectedFrequencyOption,
  onProceed,
  setOpen,
  selectedFrequencyOption,
  // onSelectUpsellCard,
  onSelectDurationOption,
  loading,
}: {
  open: boolean;
  loading: boolean;
  durationOptions: DurationOption[];
  upsellOptions: FrequencyOption[];
  selectedDurationOptionValue: string;
  selectedFrequencyOption: FrequencyOption;
  onProceed(): void;
  setOpen: (open: boolean) => void;
  onSelectUpsellCard(title: string): void;
  onSelectDurationOption(item: DurationOption): void;
}) {
  const intl = useIntl();
  const durationSubTitle = useMemo(() => {
    switch (selectedFrequencyOption.frequency) {
      case PackageFrequencyGroupEnum.WEEKLY:
      case PackageFrequencyGroupEnum.FORTNIGHT:
        return ["S$18/hr - S$20/hr", "S$18/hr - S$22/hr"];
      case PackageFrequencyGroupEnum.AD_HOC:
        return ["S$16/hr - S$25/hr", "S$16/hr - S$25/hr"];
      case PackageFrequencyGroupEnum.NEXT_DAY_AD_HOC:
        return ["S$16/hr", "S$16/hr"];
      default:
        return ["", ""];
    }
  }, [selectedFrequencyOption.frequency]);
  return (
    <BottomDrawerModal scroll open={open} onOpenChange={setOpen}>
      <View className="flex flex-col overflow-hidden">
        <ListHeading
          title={intl.formatMessage({
            defaultMessage: "Cleaning Duration",
            id: "select-slot",
          })}
          icon={Clock}
        />
        <Typography
          variant="body-lg"
          color="foreground-intermediate"
          className="mb-8 mt-2"
        >
          {intl.formatMessage({
            defaultMessage:
              "We recommend at least 3 hrs for up to 2 bedrooms, and 4 hrs and up for larger apartments. You can always change this again. (Min 3 hours)",
            id: "cleaningModal.description",
          })}
        </Typography>
        <RadioGroup
          value={selectedDurationOptionValue}
          onValueChange={(value) => {
            onSelectDurationOption(
              durationOptions.find(
                (opt) => opt.value === value,
              ) as DurationOption,
            );
          }}
        >
          {durationOptions.map((item, index) => {
            return (
              <ServiceRadioItem
                key={item.value}
                title={item.title}
                subTitle={durationSubTitle[index]}
                selected={item.value === selectedDurationOptionValue}
                slogan={item.slogan}
                value={String(item.value)}
                onSelect={() => onSelectDurationOption(item)}
              />
            );
          })}
        </RadioGroup>

        {/* hide for the first launch */}
        {/* {!selectedFrequencyOption.isRecurring && (
          <View className="border-t-2 border-secondary-border p-4">
            <Typography>
              {intl.formatMessage({
                defaultMessage: "Try our Recurring Services & Save More!",
                id: "cleaningModal.upsell",
              })}
            </Typography>

            <UpsellContainer
              options={upsellOptions}
              onSelect={onSelectUpsellCard}
            />
          </View>
        )} */}

        <Button
          variant="primary"
          color="CTA"
          className="mt-4"
          iconAlignment="end"
          iconName="arrowRight"
          onClick={onProceed}
          loading={loading}
          children={intl.formatMessage({
            defaultMessage: "Proceed",
            id: "proceed",
          })}
        />
      </View>
    </BottomDrawerModal>
  );
}
