import { View } from "@/components/ui";
import { WarningCircle } from "@/components/shared/icons";
import { Typography } from "../typography";

export const FormControlError = ({ error }: { error?: string }) => {
  if (!error) return null;
  return (
    <View className="mt-1 flex flex-row gap-2">
      <WarningCircle
        className="shrink-0 color-danger"
        weight="fill"
        size={16}
      />
      <Typography
        color="danger"
        variant={{ md: "body-md", sm: "body-sm" }}
        className="flex-1"
      >
        {error}
      </Typography>
    </View>
  );
};
