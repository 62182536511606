import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { VoucherCard } from "@/components/shared/voucher-card";
import { Typography } from "@/components/shared/typography";
import { Button, View } from "@/components/ui";
import { useAuthState } from "@/store/auth";
import { useAvailableClientVouchersStore } from "@/store/invoices/useAvailableClientVouchers";
import { useEffect, useState } from "react";
import type { InvoiceDetailData } from "../invoice-detail";
import { useApplyVoucherInvoiceStore } from "@/store/invoices/applyVoucherInvoice";
import { useRemoveVoucherInvoiceStore } from "@/store/invoices/removeVoucherInvoice";
import { useRoute } from "@/components/shared/router";

export function ApplyVoucherModal({
  invoice,
  open,
  onClose,
}: {
  invoice: InvoiceDetailData;
  open: boolean;
  onClose(): void;
}) {
  const clientId = useAuthState((state) => state.data.userInfo.id);
  const { fetch: getVouchers, data = [] } = useAvailableClientVouchersStore();
  const { fetch: applyVoucher, loading: loadingApply } =
    useApplyVoucherInvoiceStore();
  const { fetch: removeVoucher, loading: loadingRemove } =
    useRemoveVoucherInvoiceStore();

  const loading = loadingApply || loadingRemove;

  const [selectedVoucherId, setSelectedVoucherId] = useState<string | null>(
    invoice.appliedVoucher?.id ?? null,
  );

  const { replace } = useRoute();

  useEffect(() => {
    if (clientId) {
      getVouchers({
        requestPayload: {
          clientId,
        },
      });
    }
  }, [clientId]);

  const handleConfirm = async () => {
    if (
      !selectedVoucherId ||
      selectedVoucherId === invoice.appliedVoucher?.id
    ) {
      return;
    }

    if (invoice.appliedVoucher) {
      await handleRemoveVoucher(false);
    }

    const res = await applyVoucher({
      requestPayload: {
        input: {
          invoiceId: invoice.id,
          voucherId: selectedVoucherId,
        },
      },
    });

    if (res.data) {
      onClose();
      replace({
        pageKey: "invoiceDetail",
        params: {
          id: res.data.id,
        },
      });
    }
  };

  const handleRemoveVoucher = async (redirect: boolean = true) => {
    const res = await removeVoucher({
      requestPayload: {
        input: {
          invoiceId: invoice.id,
        },
      },
    });

    if (redirect && res.data) {
      replace({
        pageKey: "invoiceDetail",
        params: {
          id: res.data.id,
        },
      });
    }
  };

  return (
    <BottomDrawerModal open={open} onOpenChange={onClose}>
      <View className="flex w-full flex-1 flex-col gap-3">
        <View className="flex flex-row items-center justify-between gap-1">
          <Typography variant="label-2xl">Choose Voucher</Typography>
          {invoice.appliedVoucher && (
            <Button
              variant="tertiary"
              size="sm"
              color="danger"
              onClick={() => handleRemoveVoucher()}
              disabled={loading}
              children="Remove Voucher"
            />
          )}
        </View>

        <View className="flex flex-col gap-4">
          {data?.map((voucher) => (
            <VoucherCard
              key={voucher.id}
              expirationDate={voucher.expirationDate}
              label={voucher.description}
              discountValues={voucher.discountValues}
              discountType={voucher.discountType}
              selected={selectedVoucherId === voucher.id}
              onClick={() => !loading && setSelectedVoucherId(voucher.id)}
            />
          ))}
        </View>
        <Button
          variant="primary"
          color="CTA"
          fullWidth="full"
          onClick={handleConfirm}
          disabled={loading}
          loading={loading}
          children={invoice.appliedVoucher ? "Update" : "Confirm"}
        />
      </View>
    </BottomDrawerModal>
  );
}
