import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const postalCodeSchema = z.object({
  postalCode: z.string().min(1, "Postal code is required"),
});

export type PostalCodeFormData = z.infer<typeof postalCodeSchema>;

export interface PostalCodeFormReturn
  extends UseFormReturn<PostalCodeFormData> {
  defaultValues: PostalCodeFormData;
}

const initialFormValues = { postalCode: "" };

export const usePostalCodeForm = (): PostalCodeFormReturn => ({
  defaultValues: initialFormValues,
  ...useForm<PostalCodeFormData>({
    mode: "onChange",
    defaultValues: initialFormValues,
    resolver: zodResolver(postalCodeSchema),
  }),
});
