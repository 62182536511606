import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientFindBookingScheduleDocument,
  type ClientFindBookingScheduleQuery,
  type ClientFindBookingScheduleQueryVariables,
} from "@/__generated__/graphql";
import {
  groupTimeSlotByDate,
  transformScheduleVariantToTimeSlot,
} from "@/lib/booking-lib";
import type { TimeSlot } from "@/types/booking";

type Response = Record<string, TimeSlot[]>;

export const useClientFindBookingScheduleStore = createRequestFactory<
  ClientFindBookingScheduleQuery,
  Response,
  ClientFindBookingScheduleQueryVariables
>({
  method: "query",
  graphqlDocument: ClientFindBookingScheduleDocument,
  fetchPolicy: "network-only",
  transformFunction: (res) => {
    return groupTimeSlotByDate(
      transformScheduleVariantToTimeSlot(res.clientFindBookingSchedule),
    );
  },
});
