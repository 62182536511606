import { getSecondsRemaining } from "@/lib/helpers/date";
import { useState, useEffect, useMemo } from "react";

export const useCountdownByTimeStamp = (time: number) => {
  const secondsRemain = useMemo(() => getSecondsRemaining(time), [time]);
  return useCountdown(secondsRemain);
};

export const useCountdown = (initialValue: number, onComplete?: () => void) => {
  const [count, setCount] = useState(initialValue);

  useEffect(() => {
    setCount(initialValue);
  }, [initialValue]);

  const formatter = new Intl.NumberFormat("default", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const time = useMemo(() => {
    const min = Math.floor(count / 60);
    const sec = count % 60;
    return `${formatter.format(min)}:${formatter.format(sec)}`;
  }, [count]);

  const resetCount = () => {
    setCount(initialValue);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (count > 0) {
      interval = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    } else {
      onComplete?.();
    }

    return () => clearInterval(interval);
  }, [count, onComplete]);

  return { count, resetCount, time };
};
