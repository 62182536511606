import { PaymentMethodScreenModal } from "@/types/invoice";
import { create } from "zustand";

type InvoiceState = {
  invoiceId: string;
  totalAmount: number;
  selectPaymentScreenModal: boolean;
  paynowScreenModal: boolean;
  creditCardScreenModal: boolean;
};

export const useInvoiceState = create<{
  data: InvoiceState;
  setInvoiceId: (id: string) => void;
  setTotalAmount: (totalAmount: number) => void;
  showPaymentMethodModal: (...modalNames: PaymentMethodScreenModal[]) => void;
}>((set, get) => ({
  data: {
    invoiceId: "",
    totalAmount: 0,
    selectPaymentScreenModal: false,
    paynowScreenModal: false,
    creditCardScreenModal: false,
  },
  setInvoiceId: (invoiceId: string) => {
    set({ data: { ...get().data, invoiceId } });
  },
  setTotalAmount: (totalAmount: number) => {
    set({ data: { ...get().data, totalAmount } });
  },
  showPaymentMethodModal: (
    ...modalNames: (PaymentMethodScreenModal | null)[]
  ) => {
    set({
      data: {
        ...get().data,
        selectPaymentScreenModal: modalNames.includes(
          PaymentMethodScreenModal.SELECT_PAYMENT,
        ),
        paynowScreenModal: modalNames.includes(PaymentMethodScreenModal.PAYNOW),
        creditCardScreenModal: modalNames.includes(
          PaymentMethodScreenModal.CREDIT_CARD,
        ),
      },
    });
  },
}));
