import { showToast } from "@/components/ui/toast/show-toast";
import { getErrorMessage } from "../string";

export async function copyClipboard(msg: string) {
  try {
    if (msg) {
      await navigator.clipboard.writeText(msg).then(() => {
        showToast({
          title: "Copied to clipboard",
          type: "success",
        });
      });
    } else {
      throw new Error("Failed to copy clipboard");
    }
  } catch (error) {
    const errMsg = getErrorMessage(error, "Error copy clipboard");
    showToast({
      title: errMsg,
      type: "error",
    });
  }
}
