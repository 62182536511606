import { View } from "@/components/ui";
import { SuggestionServiceList } from "./suggestion-service-list";
import { ListHeading } from "@/components/shared/list-header";
import type { Icon } from "@/components/shared/icons";
import type { RecommendedService } from "@/components/shared/recommended-card";

export function SuggestionServiceSection({
  title,
  icon,
  items,
}: {
  title: string;
  icon: Icon;
  items: RecommendedService[];
}) {
  return (
    <View className="flex flex-col gap-2">
      <ListHeading title={title} icon={icon} />
      <SuggestionServiceList items={items} />
    </View>
  );
}
