import { View, Button, Input } from "@/components/ui";
import { PhoneNumberInput } from "@/components/shared/phone-number-input";
import { ErrorMessage } from "../../error-message";
import { InputFormControl } from "@/components/shared/input-form-control";
import { EmailIcon } from "@/components/shared/icons";
import type {
  SignUpFormData,
  SignUpFormReturn,
} from "@/store/auth/forms/useSignUpForm";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export const SignUpForm = ({
  onSubmitForm,
  loading,
  errorMessage,
  signUpFormReturn,
}: {
  loading?: boolean;
  errorMessage?: string;
  signUpFormReturn: SignUpFormReturn;
  onSubmitForm: (data: SignUpFormData) => void;
}) => {
  const intl = useIntl();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    trigger,
  } = signUpFormReturn;
  const onSubmit = handleSubmit(onSubmitForm);
  const SignUpMessage = () => (
    <ErrorMessage
      errorMessage={
        <View>
          <Typography variant="body-md">
            {intl.formatMessage({
              defaultMessage:
                "Account already exists. We've sent an OTP to your number for verification",
              id: "login.verifyOTP",
            })}
          </Typography>
          <Typography
            variant="body-md"
            className="cursor-pointer"
            onClick={() => {}}
          >
            {intl.formatMessage({
              defaultMessage: "Verify OTP and Login",
              id: "login.newOTP",
            })}
          </Typography>
        </View>
      }
    />
  );

  return (
    <>
      <InputFormControl
        control={control}
        name={"firstName"}
        label={intl.formatMessage({
          defaultMessage: "First Name",
          id: "first-name",
        })}
        component={Input}
        componentProps={{
          placeholder: intl.formatMessage({
            defaultMessage: "Enter Your First Name",
            id: "enter-first-name",
          }),
        }}
      />

      <InputFormControl
        control={control}
        name={"lastName"}
        label={intl.formatMessage({
          defaultMessage: "Last Name",
          id: "last-name",
        })}
        component={Input}
        componentProps={{
          placeholder: intl.formatMessage({
            defaultMessage: "Enter Your Last Name",
            id: "enter-last-name",
          }),
        }}
      />

      <InputFormControl
        control={control}
        name="email"
        label={intl.formatMessage({
          defaultMessage: "Email",
          id: "email",
        })}
        component={Input}
        componentProps={{
          placeholder: "youremail@example.com",
          prefix: <EmailIcon className="size-5" />,
        }}
      />

      <PhoneNumberInput control={control} errors={errors} trigger={trigger} />

      {isSubmitted && (
        <ErrorMessage
          errorMessage={
            errorMessage ===
            intl.formatMessage({
              defaultMessage: "Account already exists",
              id: "account-already-exists",
            }) ? (
              <SignUpMessage />
            ) : (
              errorMessage
            )
          }
          className="mt-4"
        />
      )}

      <Button
        loading={loading}
        onClick={onSubmit}
        variant="primary"
        color="CTA"
        fullWidth="full"
        rounded="full"
        className="mb-4 mt-4"
        disabled={
          !!(
            errors.nationCode ||
            errors.phoneNumber ||
            errors.firstName ||
            errors.lastName
          )
        }
        children={intl.formatMessage({
          defaultMessage: "Sign Up",
          id: "sign-up",
        })}
      />
    </>
  );
};
