import { VisitStatusEnum } from "@/__generated__/graphql";
import { formatDate, formatTimeAMPM } from "@/lib/helpers/date";
import { fullName } from "@/lib/helpers/string";

export function formatVisitSchedule(
  startTime: string,
  endTime: string,
  serviceDate: Date,
) {
  const visitTime = `${formatTimeAMPM(
    startTime ?? "",
  )} - ${formatTimeAMPM(endTime ?? "")}`;

  return `${formatDate(serviceDate, "iiii, LLL d")} at ${visitTime}`;
}

export function formatVisitAddress(
  fullAddress: string,
  unitNumber: string,
  postalCode: string,
) {
  return `${fullAddress} ${unitNumber}, Singapore ${postalCode}`;
}

export function visitWorkerFullName(
  status: VisitStatusEnum,
  worker?: { firstName: string; lastName: string } | null,
) {
  if (!worker) {
    return status === VisitStatusEnum.UnableToSchedule
      ? "Unassigned"
      : "Pending Assignment";
  }

  return fullName(worker);
}
