import { View, Input, Button } from "@/components/ui";
import { InputFormControl } from "@/components/shared/input-form-control";
import { FormControlError } from "@/components/shared/form-control-error";
import { Spin } from "@/components/shared/spin";
import { PrimaryAddressCheckbox } from "../primary-address-checkbox";
import { useFormState } from "react-hook-form";
import { Typography } from "@/components/shared/typography";
import type { UpdateAddressProps } from ".";

export const UpdateAddressForm = ({
  control,
  onAddAddressByPostalCode,
  loading,
  onDeleteAddress,
  postalCodeLoading,
  onSubmit,
}: UpdateAddressProps) => {
  const { errors } = useFormState({ control });

  return (
    <View className="p-4 md:p-0">
      <InputFormControl
        name="postalCode"
        label="Postal Code"
        control={control}
        component={Input}
        componentProps={{
          placeholder: "Enter Postal Code",
          suffix: postalCodeLoading && <Spin />,
        }}
        changeFallBack={(value) => {
          if (!value || value.length < 6 || value.length > 10) return;
          onAddAddressByPostalCode(value);
        }}
      />
      <InputFormControl
        name="fullAddress"
        label="Address"
        className="mt-3"
        control={control}
        component={Input}
        componentProps={{
          placeholder: "Enter Address",
        }}
      />
      <Typography variant="body-md" className="mt-3">
        Unit Number
      </Typography>
      <View className="flex flex-row gap-3">
        <InputFormControl
          name="unitFloor"
          className="flex-1"
          control={control}
          component={Input}
          componentProps={{
            placeholder: "Floor",
          }}
          hideErrorMessage
        />
        <InputFormControl
          name="apartmentNumber"
          className="flex-1"
          control={control}
          component={Input}
          componentProps={{
            placeholder: "Apt no",
          }}
          hideErrorMessage
        />
      </View>
      {errors?.unitFloor?.type === "required" ||
      errors.apartmentNumber?.type === "required" ? (
        <FormControlError error="Floor and Apt No. required. Please indicate 0 if not applicable." />
      ) : (
        <Typography
          variant={{ md: "body-md", sm: "body-sm" }}
          color="secondary-text"
          className="mt-1"
        >
          If not applicable, enter 0 for Floor or Apt No.
        </Typography>
      )}

      <PrimaryAddressCheckbox control={control} />

      <View className="mt-3 flex flex-col gap-2 md:mt-1 md:flex-row md:items-end">
        <Button
          variant="secondary"
          color="danger"
          loading={loading}
          onClick={onDeleteAddress}
          className="md:ml-auto"
          iconName="trash"
          iconColor="danger"
          children="Delete Address"
        />
        <Button
          variant="primary"
          color="CTA"
          loading={loading}
          onClick={onSubmit}
          className="native:hidden"
          children="Update"
        />
      </View>
    </View>
  );
};
