import { createRequestFactory } from "@/lib/request-factory";
import type { DiscountTypeEnum } from "@/__generated__/graphql";
import {
  ClientValidatePromoCodeDocument,
  type ClientValidatePromoCodeMutation,
  type ClientValidatePromoCodeMutationVariables,
} from "@/__generated__/graphql";

type Response = {
  discountType: DiscountTypeEnum;
  discountValue: number;
};

export const useClientValidatePromoCodeStore = createRequestFactory<
  ClientValidatePromoCodeMutation,
  Response | null,
  ClientValidatePromoCodeMutationVariables
>({
  method: "mutation",
  graphqlDocument: ClientValidatePromoCodeDocument,
  transformFunction(data) {
    const promo = data.clientValidatePromoCode?.promo;
    if (promo) {
      return {
        discountType: promo.discountType,
        discountValue: promo.discountValue,
      };
    }
    return null;
  },
});
