import { create } from "zustand";

type ImagePreviewState = {
  open: boolean;
  activeIndex: number;
  imageUrls: (string | File)[];
};

type ImagePreviewStore = {
  data: ImagePreviewState;
  openImagePreviewModal(images: (string | File)[], activeIndex: number): void;
  closeImagePreview(): void;
};

const initialData: ImagePreviewState = {
  open: false,
  activeIndex: 0,
  imageUrls: [],
};

export const useImagePreviewStore = create<ImagePreviewStore>((set, get) => ({
  data: initialData,
  openImagePreviewModal: (
    images: (string | File)[],
    activeIndex: number = 0,
  ) => {
    set({
      data: {
        ...get().data,
        open: true,
        imageUrls: images,
        activeIndex: activeIndex,
      },
    });
  },
  closeImagePreview: () => {
    set({
      data: initialData,
    });
  },
}));
