import { createRequestFactory } from "@/lib/request-factory";
import {
  RateVisitWithTokenDocument,
  type RateVisitWithTokenMutation,
  type RateVisitWithTokenMutationVariables,
} from "@/__generated__/graphql";

export const useRateVisitWithTokenStore = createRequestFactory<
  RateVisitWithTokenMutation,
  RateVisitWithTokenMutation,
  RateVisitWithTokenMutationVariables
>({
  method: "mutation",
  graphqlDocument: RateVisitWithTokenDocument,
  hasUpload: true,
});
