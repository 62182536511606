import { UserDeviceEnum } from "@/__generated__/graphql";

export const handleWhatsappNumber = (number: string, template = "") => {
  const phone = number.replace(/\D/g, "");
  window.open("//wa.me/" + phone + "?text=" + template);
};

export const getUserDevice = () => {
  return UserDeviceEnum.Browser;
};

export function makeFileFromPicker(fileInfo: File, customFilename?: string) {
  const fileName = fileInfo.name.replace(/\.[^/.]+$/, "");
  const type = fileInfo.type.split("/");
  const format = type[1];

  const file = new File(
    [fileInfo],
    customFilename ? `${customFilename}.${format}` : `${fileName}.${format}`,
    { type: fileInfo.type },
  );

  return Promise.resolve(file);
}
