import { WebLayout } from "@/components/shared/layout/homepage";
import { View } from "@/components/ui";
import { Column, Container } from "@/components/ui/layout";
import { GetTheApp } from "@/containers/get-the-app";
import { Outlet } from "react-router-dom";
import { NavTabs } from "@/components/shared/nav-tabs";
import { usePathname } from "@/components/hooks/use-pathname";
import { ShareReferralBanner } from "@/containers/banners/share-referral";
import { UnratedVisitWidget } from "@/components/shared/visits/unrated-visit-widget";
import { useIntl } from "react-intl";
import { MakeABooking } from "@/containers/make-a-booking";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";

export function RewardsPage() {
  const intl = useIntl();
  const rewardTabs = [
    {
      label: intl.formatMessage({
        defaultMessage: "Promos",
        id: "rewards.promos",
      }),
      href: "/rewards/promos",
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Your Rewards",
        id: "rewards.yourRewards",
      }),
      href: "/rewards/your-rewards",
    },
  ];

  const { isMobile } = useWindowDimensions();

  const pathname = usePathname();
  return (
    <WebLayout>
      <Container gap="xl">
        <Column desktop="8">
          <View className="flex flex-col gap-6 py-4 md:pt-10">
            <NavTabs items={rewardTabs} active={pathname} />
            <ShareReferralBanner />
            <Outlet />
            <MakeABooking />
          </View>
        </Column>
        <Column desktop="4">
          <View className="flex flex-col gap-4 p-4 pt-0 md:my-10 md:px-0">
            <View className="order-2 md:order-1">
              <GetTheApp imageVariant={isMobile ? "luceapp" : "qrcode"} />
            </View>
            <View className="order-1 md:order-2">
              <UnratedVisitWidget />
            </View>
          </View>
        </Column>
      </Container>
    </WebLayout>
  );
}
