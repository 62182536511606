import type { Address, Contact, CreditAccount } from "@/__generated__/graphql";

export enum ChangePhoneNumberModalEnum {
    CHANGE_PHONE_NUMBER = 'changePhoneNumber',
    CHANGE_PHONE_NUMBER_OTP_VERIFICATION = 'changePhoneNumberVerification',
    CANCEL_CHANGE_PHONE_NUMBER = 'cancelChangePhoneNumber',
    CHANGE_PHONE_NUMBER_SUCCESS = 'changePhoneNumberSuccess'
  }

export type ClientCreditAccount = Omit<CreditAccount, "__typename">;

export type ClientAddresses = Omit<Address, "__typename" | "client">;

export type ClientContacts = Omit<Contact, "__typename" | "client">;
