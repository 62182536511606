import { View } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { cn } from "@/lib/utils";
import { Typography } from "../../typography";

export function DesktopNavButton({
  title,
  active,
  href,
}: {
  title: string;
  active?: boolean;
  href: string;
}) {
  return (
    <View
      className={cn(
        "flex flex-col items-center rounded-full border border-transparent py-1 hover:bg-brand-static-hover",
        {
          "border-brand-static-border bg-brand-over-static": active,
        },
      )}
    >
      <Link href={href}>
        <Typography
          variant="body-xl"
          align="center"
          className="cursor-pointer px-3"
          color="white"
        >
          {title}
        </Typography>
      </Link>
    </View>
  );
}
