import React from "react";
import { cn } from "@/lib/utils";
import { View } from "@/components/ui";
import { Star } from "@/components/shared/icons";

const ratingVariants = {
  default: {
    star: "text-foreground",
    emptyStar: "text-slate-200",
  },
  destructive: {
    star: "text-red-500",
    emptyStar: "text-red-200",
  },
  yellow: {
    star: "text-warning",
    emptyStar: "text-slate-200",
  },
};

interface RatingsProps {
  rating: number;
  totalStars?: number;
  size?: number;
  fill?: boolean;
  Icon?: React.ReactElement;
  variant?: keyof typeof ratingVariants;
  className?: string;
  onClick?(value: number): void;
  disabled?: boolean;
}

const Ratings = ({ ...props }: RatingsProps) => {
  const {
    rating = 0,
    totalStars = 5,
    size = 20,
    fill = true,
    Icon = <Star weight="fill" />,
    variant = "default",
    className,
    onClick,
    disabled,
  } = props;

  const handleClick = (value: number) => () => {
    onClick?.(value);
  };

  const fullStars = Math.floor(rating);
  const partialStar =
    rating % 1 > 0 ? (
      <PartialStar
        fillPercentage={rating % 1}
        size={size}
        className={cn(ratingVariants[variant].star)}
        Icon={Icon}
      />
    ) : null;

  return (
    <View
      className={cn(
        "flex flex-row items-center justify-around gap-1",
        className,
      )}
    >
      {[...Array(fullStars)].map((_, i) => (
        <View
          key={i}
          className={onClick && !disabled ? "cursor-pointer" : "cursor-default"}
          onClick={disabled ? undefined : handleClick(i + 1)}
        >
          {React.cloneElement(Icon, {
            size,
            className: cn(
              fill ? "fill-current" : "fill-transparent",
              ratingVariants[variant].star,
            ),
          })}
        </View>
      ))}
      {partialStar}
      {[...Array(totalStars - fullStars - (partialStar ? 1 : 0))].map(
        (_, i) => (
          <View
            key={i + fullStars + 1}
            className={
              onClick && !disabled ? "cursor-pointer" : "cursor-default"
            }
            onClick={disabled ? undefined : handleClick(i + fullStars + 1)}
          >
            {React.cloneElement(Icon, {
              size,
              className: cn(ratingVariants[variant].emptyStar),
            })}
          </View>
        ),
      )}
    </View>
  );
};

interface PartialStarProps {
  fillPercentage: number;
  size: number;
  className?: string;
  Icon: React.ReactElement;
}
const PartialStar = ({ ...props }: PartialStarProps) => {
  const { fillPercentage, size, className, Icon } = props;

  return (
    <View className="relative inline-block">
      {React.cloneElement(Icon, {
        size,
        className: cn("fill-transparent", className),
      })}
      <View
        className="absolute top-0 overflow-hidden"
        style={{
          width: `${fillPercentage * 100}%`,
        }}
      >
        {React.cloneElement(Icon, {
          size,
          className: cn("fill-current", className),
        })}
      </View>
    </View>
  );
};

export { Ratings };
