import { useState, useCallback, useEffect } from "react";
import { Image, View } from "@/components/ui";
import type { CarouselApi } from "@/components/ui/carousel";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import { cn } from "@/lib/utils";
import { Typography } from "../typography";

export function ImageCarousel({
  images,
  height,
  autoPlay,
  initialIndex = 0,
  showArrows = true,
  pagingEnabled = true,
}: {
  images: string[];
  height?: number;
  autoPlay?: boolean;
  initialIndex?: number;
  showArrows?: boolean;
  pagingEnabled?: boolean;
}) {
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
  const plugins = autoPlay ? [Autoplay({ delay: 2000 })] : [];

  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) {
      return;
    }
    setCurrentIndex(api.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (carouselApi) {
      onSelect(carouselApi);
      carouselApi.on("select", () => onSelect(carouselApi));
      return () => {
        carouselApi.off("select", () => onSelect(carouselApi));
      };
    }
  }, [carouselApi, onSelect]);

  return (
    <View className="relative h-auto">
      <Carousel plugins={plugins} setApi={setCarouselApi}>
        {showArrows && (
          <View className="absolute top-[130px] z-10 flex w-full flex-row justify-between px-4">
            <CarouselPrevious className="static" />
            <CarouselNext className="static" />
          </View>
        )}
        <CarouselContent className="ml-0">
          {images.map((image, index) => (
            <CarouselItem key={index} className="w-full pl-0">
              <Image
                src={image}
                className={"w-full object-cover lg:rounded-2xl"}
                height={height}
                style={{ height }}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      {pagingEnabled && (
        <View className="absolute bottom-3 right-4 flex flex-row items-center rounded-full bg-stone-500/50 px-3 py-1 text-xs font-normal text-white">
          <Typography color="brand-primary">{`${currentIndex + 1}/${images.length}`}</Typography>
        </View>
      )}
      <View className="absolute bottom-1 flex w-full flex-row justify-center p-1 text-xs font-normal text-white">
        {images.map((_, index) => (
          <View
            key={index}
            className={cn(
              "mx-1 h-2 w-2 rounded-full",
              index === currentIndex ? "w-[20px] bg-blue-600" : "bg-gray-300",
            )}
          />
        ))}
      </View>
    </View>
  );
}
