import type {
  AddressByPostalCodeQuery,
  AddressByPostalCodeQueryVariables,
} from "@/__generated__/graphql";
import { AddressByPostalCodeDocument } from "@/__generated__/graphql";
import { createRequestFactory } from "@/lib/request-factory";

type Response = {
  address: string;
};

export const useAddressByPostalCodeStore = createRequestFactory<
  AddressByPostalCodeQuery,
  Response,
  AddressByPostalCodeQueryVariables
>({
  method: "query",
  graphqlDocument: AddressByPostalCodeDocument,
  fetchPolicy: "network-only",
  transformFunction: (res) => {
    if (res.addressByPostalCode) {
      return { address: res.addressByPostalCode.address, success: true };
    }
    throw new Error("Invalid postal code");
  },
});
