import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

type RefreshContextType = {
  refreshing: boolean;
  setRefreshing(value: boolean): void;
  onRefresh(): void;
};

const defaultContextValue = {
  refreshing: false,
  setRefreshing: () => {},
  onRefresh: () => {},
};

const ScrollRefreshContext =
  createContext<RefreshContextType>(defaultContextValue);

export const useScrollRefresh = (
  enable: boolean = true,
): RefreshContextType => {
  const context = useContext(ScrollRefreshContext);

  if (!enable) {
    return defaultContextValue;
  }

  return context;
};

export const ScrollRefreshProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = () => {
    setRefreshing(true);
  };

  return (
    <ScrollRefreshContext.Provider
      value={{ refreshing, onRefresh, setRefreshing }}
    >
      {children}
    </ScrollRefreshContext.Provider>
  );
};
