import { View, Image, Button } from "@/components/ui";
import { Basket } from "@/components/shared/icons";
import RecommendedTag from "@/assets/images/recommended-tag.png";
import { formatPrice } from "@/lib/helpers/number";
import DefaultImg from "@/assets/images/booking/default-img-1-1.png";
import { Typography } from "../typography";
import { useIntl } from "react-intl";
import { type ServiceName } from "@/types/service";
import { useRoute } from "@/components/shared/router";
import { bookingRoutes } from "@/components/hooks/use-booking-route";

export type RecommendedService = {
  imageSrc: string;
  title: string;
  price: number;
  recommended?: boolean;
  sessionBooked?: string;
  name?: ServiceName;
};

type Props = RecommendedService;

export function RecommendedCard({
  imageSrc,
  title,
  price,
  recommended,
  sessionBooked,
  name,
}: Props) {
  const intl = useIntl();
  const { push } = useRoute();
  const onClickCard = () => {
    if (name) {
      push(bookingRoutes[name][0]);
    }
  };
  return (
    <View className="relative flex flex-col overflow-hidden rounded-xl border border-secondary-border">
      {recommended && (
        <View className="absolute z-10">
          <Image src={RecommendedTag} width={88} height={18} />
        </View>
      )}
      <Image
        src={imageSrc || DefaultImg}
        alt={title}
        className="aspect-square h-[146px] w-full object-cover"
        contentFit="cover"
        style={{ height: undefined, width: "100%", aspectRatio: 1 }}
      />
      <View className="flex flex-col gap-1 p-2">
        <Typography variant="body-md">{title}</Typography>
        {price && (
          <View className="flex flex-row items-center gap-1">
            <Typography variant="body-md" color="foreground-intermediate">
              {intl.formatMessage({
                defaultMessage: "Start",
                id: "from",
              })}
            </Typography>
            <Typography variant="bold-md" color="brand-primary">
              {formatPrice(price, 0)}
            </Typography>
          </View>
        )}
        {sessionBooked && (
          <View className="flex flex-row items-center gap-1">
            <Basket className="size-3 color-brand-primary" size={12} />
            <Typography
              variant="label-md"
              className="native:leading-5 leading-4"
            >
              {sessionBooked}
              {intl.formatMessage({
                defaultMessage: "sessions booked",
                id: "lifetime-bookings",
              })}
            </Typography>
          </View>
        )}
        <View className="flex h-12 items-center justify-center">
          <Button
            variant="secondary"
            color="CTA2"
            className="w-full"
            size="sm"
            onClick={onClickCard}
          >
            Book Now
          </Button>
        </View>
      </View>
    </View>
  );
}
