import { create } from "zustand";

export type VisitListType =
  | "next7days"
  | "unscheduled"
  | "next30days"
  | "past30days";

type VisitListState = {
  listEmpty: Record<VisitListType, boolean>;
};

const initialList = {
  next7days: false,
  next30days: false,
  unscheduled: false,
  past30days: false,
};

export const useVisitListState = create<{
  data: VisitListState;
  setListEmpty(type: VisitListType, value: boolean): void;
  resetListEmpty(): void;
}>((set, get) => ({
  data: {
    listEmpty: initialList,
  },
  setListEmpty: (type: VisitListType, value: boolean) => {
    set({
      data: {
        ...get().data,
        listEmpty: {
          ...get().data.listEmpty,
          [type]: value,
        },
      },
    });
  },
  resetListEmpty: () => {
    set({
      data: {
        listEmpty: initialList,
      },
    });
  },
}));
