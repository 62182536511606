import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { emailRE, nameRE } from "@/constants/regex";

const bookingInfoSchema = z.object({
  name: z
    .string()
    .min(1, "Contact name is required")
    .refine((value) => nameRE.test(value), {
      message: "Please avoid using special characters",
    }),
  phoneNumber: z.string().min(1, "Phone number is required"),
  email: z.string().min(1, "Email is required").regex(emailRE, "Invalid email"),
  fullAddress: z.string().min(1, "Full address is required"),
  postalCode: z.string().min(1, "Postal code is required"),
  accessMode: z.string().min(1, "Please select access mode"),
  accessInstructions: z.string(),
  note: z.string(),
});

export type BookingInfoFormData = z.infer<typeof bookingInfoSchema>;

export interface BookingInfoFormReturn
  extends UseFormReturn<BookingInfoFormData> {
  defaultValues: BookingInfoFormData;
}

export const initialValues: BookingInfoFormData = {
  name: "",
  phoneNumber: "",
  email: "",
  fullAddress: "",
  postalCode: "",
  accessMode: "",
  accessInstructions: "",
  note: "",
};

export const useBookingInfoForm = (
  values?: BookingInfoFormData,
): BookingInfoFormReturn => ({
  defaultValues: initialValues,
  ...useForm<BookingInfoFormData>({
    mode: "onChange",
    defaultValues: values || initialValues,
    resolver: zodResolver(bookingInfoSchema),
  }),
});
