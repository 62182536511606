import type { InvoicePaymentStatusEnum } from "@/__generated__/graphql";
import { Card, CardContent, View } from "@/components/ui";
import { PaymentStatusBadge } from "./payment-status-badge";
import type { Invoice } from "@/types/invoice";
import { Divider } from "../../divider";
import { formatPrice } from "@/lib/helpers/number";
import { formatDate } from "@/lib/helpers/date";
import { Typography } from "../../typography";

export type InvoiceCardData = {
  id: string;
  amount: number;
  balance: number;
  issueDate: Date;
  dueDate: Date;
  clientPaymentIndicated: boolean;
  paymentStatus: InvoicePaymentStatusEnum;
};

export function mapToInvoiceCard(invoice: Invoice): InvoiceCardData {
  return {
    id: invoice.id,
    amount: invoice.totalAmount,
    balance: invoice.totalAmount - invoice.paidAmount,
    issueDate: new Date(invoice.issueDate ?? 0),
    dueDate: new Date(invoice.dueDate ?? 0),
    clientPaymentIndicated: Boolean(invoice.clientPaymentIndicated),
    paymentStatus: invoice.paymentStatus,
  };
}

export function InvoiceCard({
  id,
  amount,
  balance,
  issueDate,
  dueDate,
  clientPaymentIndicated,
  paymentStatus,
  onClick,
}: InvoiceCardData & { onClick(): void }) {
  return (
    <Card direction="row" onClick={onClick}>
      <CardContent className="flex-[2]">
        <View className="mb-2 flex flex-row items-center gap-2">
          <Typography variant="body-lg">Invoice #{id}</Typography>
          <PaymentStatusBadge
            balance={balance}
            amount={amount}
            clientPaymentIndicated={clientPaymentIndicated}
            dueDate={dueDate}
            status={paymentStatus}
          />
        </View>
        <View className="flex flex-row gap-2">
          <View>
            <Typography variant="body-sm" color="foreground-intermediate">
              Issue On
            </Typography>
            <Typography variant="body-md">
              {formatDate(issueDate, "MMM dd, yyyy")}
            </Typography>
          </View>
          <View>
            <Typography variant="body-sm" color="foreground-intermediate">
              Due On
            </Typography>
            <Typography variant="body-md">
              {formatDate(dueDate, "MMM dd, yyyy")}
            </Typography>
          </View>
        </View>
      </CardContent>
      <Divider vertical className="my-4" />
      <CardContent className="flex-1">
        <Typography
          variant="body-sm"
          color="foreground-intermediate"
          align="right"
        >
          Invoice Total
        </Typography>
        <Typography
          variant="label-lg"
          color="brand-primary"
          align="right"
          className="mb-1"
        >
          {formatPrice(amount)}
        </Typography>
        <Typography
          variant="body-sm"
          color="foreground-intermediate"
          align="right"
        >
          Balance
        </Typography>
        <Typography variant="body-lg" align="right">
          {formatPrice(balance)}
        </Typography>
      </CardContent>
    </Card>
  );
}
