import { View } from "@/components/ui";
import { BookingCountDown } from "../booking-count-down";
import { BookingInfoForm } from "../booking-info-form";
import type { Control, UseFormSetValue } from "react-hook-form";
import type { BookingInfoFormData } from "../useBookingInfoForm";
import type { Contact } from "@/types/users";

export const BookingInfoMain = ({
  control,
  setValue,
  contacts,
  onAddAnotherContact,
}: {
  control: Control<BookingInfoFormData>;
  setValue: UseFormSetValue<BookingInfoFormData>;
  contacts: Contact[];
  onAddAnotherContact: () => void;
}) => {
  return (
    <View className="flex flex-col gap-4">
      <View className="mx-4 mt-4 md:mt-2">
        <BookingCountDown />
      </View>
      <BookingInfoForm
        onAddAnotherContact={onAddAnotherContact}
        control={control}
        setValue={setValue}
        contacts={contacts}
      />
    </View>
  );
};
