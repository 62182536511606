import { ChangePhoneNumberModal } from "./change-phone-number-modal";
import { ChangeNumberOtpVerificationModal } from "./change-number-otp-verification-modal";
import { ChangePhoneNumberSuccessModal } from "./change-number-success-modal";
import { useProfileState } from "@/store/profile";
import { useAuthState } from "@/store/auth";
import { getPlatform } from "@/lib/utils";
import { useRoute } from "@/components/shared/router";
import { CancelChangePhoneNumberModal } from "./cancel-change-phone-number-modal";

export const ChangePhoneNumberModalContainer = () => {
  const { data, showModal } = useProfileState();
  const { setChangePhoneOtp } = useAuthState();
  const {
    changePhoneNumberModalOpen,
    changeNumberOtpVerificationModalOpen,
    cancelChangePhoneNumber,
    changeNumberSuccessModal,
  } = data;

  const { push } = useRoute();

  const handleClose = () => {
    showModal();
    setChangePhoneOtp(false);
  };

  const handleCloseSuccess = () => {
    handleClose();
    if (getPlatform() === "native") {
      push({ pageKey: "accountInfo" });
    }
  };

  return (
    <>
      <ChangePhoneNumberModal
        open={changePhoneNumberModalOpen}
        onClose={handleClose}
      />

      <ChangeNumberOtpVerificationModal
        open={changeNumberOtpVerificationModalOpen}
      />

      <CancelChangePhoneNumberModal open={cancelChangePhoneNumber} />

      <ChangePhoneNumberSuccessModal
        open={changeNumberSuccessModal}
        onClose={handleCloseSuccess}
      />
    </>
  );
};
