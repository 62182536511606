import { CS_NUMBER } from "@/constants";
import { handleWhatsappNumber } from "../platform";
import { getAdTrackingData } from "../monitoring/oci";
import { sendAnalyticData } from "../monitoring/analytics";

export const onChatSales = (
  includeTrackingUrl = false,
  message = "Hi! I would like to speak to sales about booking a new appointment through your web application."
) => {
  if (includeTrackingUrl) {
    message = embedTrackingUrl(message)
  }

  handleWhatsappNumber(CS_NUMBER, message);
  sendAnalyticData({
    name: 'wa_click',
    values: {}
  })
};

const embedTrackingUrl = (
  message: string
): string => {
  const adTrackingKey = getAdTrackingData()
  const LUCE_WEBSITE_URL = import.meta.env.VITE_LUCE_WEBSITE_URL ?? ''

  if (adTrackingKey && LUCE_WEBSITE_URL) {
    message += ` From your page ${LUCE_WEBSITE_URL}?origin=${adTrackingKey}`
  }

  return message
};
