import { View, Input, Button } from "@/components/ui";
import { InputFormControl } from "@/components/shared/input-form-control";
import { PrimaryAccountCheckbox } from "../primary-account-checkbox";
import { PhoneNumberInput } from "@/components/shared/phone-number-input";
import { useFieldArray, useFormState } from "react-hook-form";
import { defaultNationCode } from "@/constants";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { IconButton } from "@/components/ui/icon-button";
import type { UpdateContactProps } from ".";

export const UpdateContactForm = ({
  control,
  onSubmit,
  onDeleteContact,
  updateContactLoading,
  deleteContactLoading,
  trigger,
}: UpdateContactProps) => {
  const intl = useIntl();
  const { errors } = useFormState({ control });

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: "email",
  });

  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: "phoneNumber",
  });

  return (
    <View className="p-4 md:p-0">
      <InputFormControl
        name="firstName"
        label={intl.formatMessage({
          defaultMessage: "First Name",
          id: "first-name",
        })}
        className="mt-2"
        control={control}
        component={Input}
        componentProps={{
          placeholder: intl.formatMessage({
            defaultMessage: "Your First Name",
            id: "enter-first-name",
          }),
        }}
      />
      <InputFormControl
        name="lastName"
        label={intl.formatMessage({
          defaultMessage: "Last Name",
          id: "last-name",
        })}
        className="mt-2"
        control={control}
        component={Input}
        componentProps={{
          placeholder: intl.formatMessage({
            defaultMessage: "Your Last Name",
            id: "enter-last-name",
          }),
        }}
      />

      <View className="mt-2">
        <Typography variant={{ md: "label-lg", sm: "label-md" }}>
          {intl.formatMessage({
            defaultMessage: "Email",
            id: "email",
          })}
        </Typography>
        <View className="flex flex-col gap-1">
          {emailFields.map((field, index) => (
            <View
              key={field.id}
              className="mt-2 flex flex-row items-start gap-2"
            >
              <InputFormControl
                label=""
                className="flex-1 pb-0"
                name={`email.${index}.value`}
                control={control}
                component={Input}
                componentProps={{
                  placeholder: "example@mail.com",
                }}
              />
              {index === 0 ? (
                <IconButton
                  iconName="plus"
                  disabled={emailFields.length >= 3}
                  onClick={() => appendEmail({ value: "" })}
                />
              ) : (
                <IconButton
                  iconName="minus"
                  onClick={() => removeEmail(index)}
                />
              )}
            </View>
          ))}
        </View>
      </View>

      <View className="mb-3 mt-2">
        <Typography variant={{ md: "label-lg", sm: "label-md" }}>
          {intl.formatMessage({
            defaultMessage: "Phone Number",
            id: "phone-number",
          })}
        </Typography>
        <View className="flex flex-col gap-1">
          {phoneFields.map((field, index) => (
            <View
              key={field.id}
              className="mt-2 flex flex-row items-start gap-2 md:mt-2"
            >
              <PhoneNumberInput
                trigger={trigger}
                className="mt-1 flex-1"
                label=""
                control={control}
                errors={errors}
                phoneNumberName={`phoneNumber.${index}.value`}
                nationCodeName={`phoneNumber.${index}.nationCode`}
              />
              {index === 0 ? (
                <IconButton
                  className="mt-1"
                  iconName="plus"
                  disabled={emailFields.length >= 3}
                  onClick={() =>
                    appendPhone({ value: "", nationCode: defaultNationCode })
                  }
                />
              ) : (
                <IconButton
                  className="mt-1"
                  iconName="minus"
                  onClick={() => removePhone(index)}
                />
              )}
            </View>
          ))}
        </View>
      </View>

      <PrimaryAccountCheckbox control={control} />

      <View className="mt-2 flex flex-col justify-end gap-2 md:mt-1 md:flex-row md:items-end">
        <Button
          variant="secondary"
          color="CTA"
          loading={deleteContactLoading || updateContactLoading}
          onClick={onDeleteContact}
          iconName="trash"
          iconColor="danger"
          children={intl.formatMessage({
            defaultMessage: " Delete Contact",
            id: " delete-contact",
          })}
        />
        <Button
          variant="primary"
          color="CTA"
          loading={updateContactLoading}
          onClick={onSubmit}
          className="native:hidden"
          children={intl.formatMessage({
            defaultMessage: "Update",
            id: "update",
          })}
        />
      </View>
    </View>
  );
};
