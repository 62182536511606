import { otherServiceItems, popularServiceItems } from "../lib";
import { View } from "@/components/ui";
import { ServiceIcon } from "@/components/shared/service-icon";
import { ListHeading } from "@/components/shared/list-header";
import { SquaresFour, Sparkle } from "@/components/shared/icons";
import { useIntl } from "react-intl";
import type { Icon } from "@/components/shared/icons";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Typography } from "@/components/shared/typography";
import type { ServiceName } from "@/types/service";

function Section({
  title,
  icon: IconComponent,
  services,
}: {
  title: string;
  icon: Icon;
  services: {
    title: string;
    name: ServiceName;
    icon: JSX.Element;
  }[];
}) {
  return (
    <View className="border-b-[3px] border-background-intermediate">
      <View className="flex flex-row px-4 py-3">
        <IconComponent size={20} weight="fill" className="color-primary" />
        <Typography variant="label-md" className="ml-3">
          {title}
        </Typography>
      </View>
      <View className="flex flex-1 flex-row flex-wrap justify-start gap-y-2 py-2">
        {services.map((service) => (
          <View className="my-2 flex h-24 w-1/4 justify-center gap-1 px-4 py-2">
            <ServiceIcon
              icon={service.icon}
              title={service.title}
              name={service.name}
              key={service.title}
            />
          </View>
        ))}
      </View>
    </View>
  );
}

export function AllServicesModal({
  modalOpen,
  handleOpenChange,
}: {
  modalOpen: boolean;
  handleOpenChange(newState: boolean): void;
}) {
  const intl = useIntl();
  return (
    <BottomDrawerModal open={modalOpen} onOpenChange={handleOpenChange}>
      <View className="border-b border-background-intermediate px-4 py-3">
        <ListHeading title="All Services" icon={SquaresFour} />
      </View>
      <Section
        title="Popular Service"
        icon={Sparkle}
        services={popularServiceItems(intl)}
      />
      <Section
        title={intl.formatMessage({
          defaultMessage: "Other Services",
          id: "other-services",
        })}
        icon={SquaresFour}
        services={otherServiceItems(intl)}
      />
    </BottomDrawerModal>
  );
}
