import { useEffect, useMemo, useState } from "react";
import { Button, View } from "@/components/ui";
import { useCountdown } from "@/components/hooks/use-count-down";
import type { JwtInfo, UserInfo } from "@/types/users";
import { AuthModals, LoginType, SignUpStep } from "@/types/users";
import { useAuthState } from "@/store/auth";
import { useSignInWithPhoneStore } from "@/store/auth/signInWithPhone";
import { useClientConfirmPhoneNumberStore } from "@/store/auth/clientConfirmPhoneNumber";
import { useRoute } from "@/components/shared/router";
import { useClientStore } from "@/store/auth/client";
import { sendAnalyticData } from "@/lib/monitoring/analytics";
import { SignInProviderEnum } from "@/__generated__/graphql";
import { useClientVerifyPhoneNumberStore } from "@/store/auth/clientVerifyPhoneNumber";
import { useSendPinCodeToPhoneStore } from "@/store/auth/sendPinCodeToPhone";
import { showToast } from "@/components/ui/toast/show-toast";
import { OtpModal } from "../../otp-modal";

export function OtpVerificationModal() {
  const { replace } = useRoute();
  const [submitted, setSubmitted] = useState(false);

  const {
    data: { phone, pinCode, otpModalOpen, loginByModal, type, userInfo },
    setPinCode,
    showModal,
    setSignUpStep,
  } = useAuthState();

  const {
    loading: loginLoading,
    fetch: loginByPhone,
    error: loginError,
  } = useSignInWithPhoneStore();

  const {
    loading: confirmPhoneNumberLoading,
    fetch: confirmPhoneNumber,
    error: confirmPhoneNumberError,
  } = useClientConfirmPhoneNumberStore();

  const { fetch: resendSignUpCode, loading: resendSignUpCodeLoading } =
    useClientVerifyPhoneNumberStore();
  const { fetch: resendLoginCode, loading: resendLoginCodeLoading } =
    useSendPinCodeToPhoneStore();

  const { fetch: getUserInfo } = useClientStore();
  const { count, time, resetCount } = useCountdown(59);

  useEffect(() => {
    if (otpModalOpen) {
      resetCount();
      setPinCode("");
      setSubmitted(false);
    }
  }, [otpModalOpen]);

  const onClose = () => {
    setPinCode("");
    showModal();
  };

  const backToLogin = () => {
    if (loginByModal) {
      showModal(AuthModals.LOGIN);
    } else {
      showModal();
    }
  };

  const backToSignUp = () => {
    if (loginByModal) {
      showModal(AuthModals.SIGN_UP);
    } else {
      showModal();
    }
  };

  const back = () => {
    showModal();
  };

  const onLoginByPhone = () => {
    loginByPhone(
      {
        requestPayload: {
          input: {
            phone,
            code: pinCode,
          },
        },
      },
      { selfHandleError: true },
    ).then((res) => {
      if (res.data?.userInfo) {
        onLoginSucceed(res.data);
        sendAnalyticData({
          name: "login",
          values: {
            method: SignInProviderEnum.PhoneNumber,
          },
        });
      }
    });
  };

  const onSignUpByPhone = () => {
    confirmPhoneNumber(
      {
        requestPayload: {
          input: {
            phone,
            code: pinCode,
            onSignUp: true,
          },
        },
      },
      { selfHandleError: true },
    ).then((res) => {
      if (res.data?.success) {
        if (loginByModal) showModal(AuthModals.ADDRESS);
        else {
          showModal();
          setSignUpStep(SignUpStep.AddAddress);
        }
      }
    });
  };

  const onConfirmByPhone = () => {
    confirmPhoneNumber({
      requestPayload: {
        input: {
          phone,
          code: pinCode,
          onSignUp: true,
        },
      },
    }).then((res) => {
      if (res.data?.success) {
        showModal();
        getUserInfo({
          requestPayload: {
            id: userInfo.id,
          },
        });
        replace({ pageKey: "homepage" });
      }
    });
  };

  const onLoginSucceed = (_args: {
    userInfo: UserInfo; // unused?
    jwtInfo: JwtInfo; // unused?
  }) => {
    if (loginByModal) showModal(AuthModals.WELCOME);
    else {
      showModal();
      replace({ pageKey: "homepage" });
    }
  };

  const onResendPinCode = () => {
    if (type === LoginType.Login) {
      resendLoginPinCode();
    } else {
      resendSignUpPinCode();
    }
  };

  const resendLoginPinCode = () => {
    resendLoginCode({
      requestPayload: {
        input: {
          phone,
          enableWhatsappOtp: true,
        },
      },
    }).then((res) => {
      if (res.error) return;
      resetCount();
      showToast({
        title: "Success resend pin code",
        type: "success",
      });
    });
  };

  const resendSignUpPinCode = () => {
    resendSignUpCode({
      requestPayload: {
        input: {
          phone,
          onSignUp: true,
          enableWhatsappOtp: true,
        },
      },
    }).then((res) => {
      if (res.error) return;
      resetCount();
      showToast({
        title: "Success resend pin code",
        type: "success",
      });
    });
  };

  const { continueText, backText, onBack, onContinue, loading, error } =
    useMemo(() => {
      switch (type) {
        case LoginType.Login:
        case LoginType.EmailLogin:
          return {
            continueText: "Sign In",
            backText: "Change my phone number",
            onContinue: onLoginByPhone,
            onBack: backToLogin,
            loading: loginLoading,
            error: loginError,
          };
        case LoginType.SignUp:
          return {
            continueText: "Sign Up",
            backText: "Back",
            onContinue: onSignUpByPhone,
            onBack: backToSignUp,
            loading: confirmPhoneNumberLoading,
            error: confirmPhoneNumberError,
          };
        case LoginType.Confirm:
          return {
            continueText: "Confirm",
            backText: "Back",
            onContinue: onConfirmByPhone,
            onBack: back,
            loading: confirmPhoneNumberLoading,
            error: confirmPhoneNumberError,
          };
        default:
          return {
            continueText: "Continue",
            backText: "Back",
            onContinue: onConfirmByPhone,
            onBack: back,
            loading: confirmPhoneNumberLoading,
            error: confirmPhoneNumberError,
          };
      }
    }, [
      type,
      pinCode,
      loginByModal,
      loginLoading,
      loginError,
      confirmPhoneNumberLoading,
      confirmPhoneNumberError,
    ]);

  return (
    <OtpModal
      resetLoading={resendLoginCodeLoading || resendSignUpCodeLoading}
      open={otpModalOpen}
      onClose={onClose}
      pinCode={pinCode}
      setPinCode={setPinCode}
      onResendPinCode={onResendPinCode}
      phoneNumber={phone}
      time={time}
      count={count}
      errorMessage={submitted && error ? error : ""}
      buttonRender={
        <View className="flex flex-col gap-2">
          <Button
            variant="primary"
            color="CTA"
            className="mt-4"
            rounded="full"
            loading={loading}
            disabled={pinCode.length !== 6}
            onClick={() => {
              setSubmitted(true);
              onContinue();
            }}
            children={continueText}
          />
          <Button
            variant="secondary"
            color="CTA2"
            rounded="full"
            onClick={onBack}
            children={backText}
          />
        </View>
      }
    />
  );
}
