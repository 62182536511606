import { ChangeNumberForm } from "@/components/shared/change-number-form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  View,
} from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";

export const ChangePhoneNumberModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="flex h-full max-w-none flex-col p-6 md:h-auto md:w-[343px] md:max-w-lg">
        <DialogHeader>
          <View className="flex flex-col items-start gap-3 text-left">
            <View className="flex flex-row items-center gap-2">
              <IconButton
                variant="ghost"
                color="foreground"
                iconName="arrowLeft"
                className="md:hidden p-0"
                onClick={onClose}
              />
              <DialogTitle>Change Phone Number</DialogTitle>
            </View>
            <DialogDescription>
              Please enter your phone number. This number will be used for
              future login.
            </DialogDescription>
          </View>
        </DialogHeader>

        <ChangeNumberForm />
      </DialogContent>
    </Dialog>
  );
};
