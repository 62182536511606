import { Card, CardContent, View, Image } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { Wallet, WarningCircle } from "@/components/shared/icons";
import { formatPrice } from "@/lib/helpers/number";
import { InfoHelper } from "./info-helper";
import { ShareReferralBanner } from "@/containers/banners/share-referral";
import TransactionHistoryCard from "@/components/shared/transaction-history-card";
import EmptyWallet from "@/assets/images/empty-wallet.png";
import { useCreditAccountByIdStore } from "@/store/profile/creditAccountById";
import { useEffect } from "react";
import { useAuthState } from "@/store/auth";
import { generalCreditAccount } from "../utils";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { links } from "@/constants";

export const GeneralCreditAccountContainer = () => {
  const intl = useIntl();
  const { fetch, data } = useCreditAccountByIdStore();
  const {
    data: { userInfo },
  } = useAuthState();
  const userGeneralCreditAccount = generalCreditAccount(
    userInfo.creditAccounts,
  );
  useEffect(() => {
    if (userGeneralCreditAccount) {
      fetch({
        requestPayload: {
          id: userGeneralCreditAccount.id,
        },
      });
    }
  }, [userGeneralCreditAccount]);

  const creditAccount = data?.creditAccount;

  return (
    <View className="native:bg-white flex flex-col gap-6">
      <View className="p-4 md:p-0">
        <View className="flex flex-col gap-6">
          <Card border="none" radius="lg" shadow="sm">
            <CardContent>
              <View className="flex flex-row items-center justify-between">
                <View className="flex flex-col gap-1">
                  <Typography variant="body-xl" color="foreground-intermediate">
                    {intl.formatMessage({
                      defaultMessage: "Remaining Balance",
                      id: "account.remainingBalance",
                    })}
                  </Typography>
                  <View className="flex flex-row items-center gap-1">
                    <Wallet weight="fill" size={24} className="text-primary" />
                    <Typography variant="h5">
                      {formatPrice(creditAccount?.balance ?? 0)}
                    </Typography>
                  </View>
                </View>
                <InfoHelper />
              </View>
            </CardContent>
          </Card>

          <Card className="border-neutral-200 bg-background-intermediate">
            <CardContent className="px-4 py-3">
              <View className="flex flex-row items-start justify-between gap-1">
                <WarningCircle
                  weight="fill"
                  className="pt-1 text-primary"
                  size={20}
                />
                <Typography>
                  {intl.formatMessage({
                    defaultMessage:
                      "This credit can be used when paying invoices.",
                    id: "account.creditDescription",
                  })}
                  <Link
                    href={links.applyCreditHelpCentreLink}
                    className="text-primary"
                  >
                    {intl.formatMessage({
                      defaultMessage:
                        " Learn more on how to use your Account Credit.",
                      id: "account.creditGuide",
                    })}
                  </Link>
                </Typography>
              </View>
            </CardContent>
          </Card>

          <ShareReferralBanner />
        </View>
      </View>

      <View className="border-t border-secondary-border px-4 md:px-0">
        <View className="flex flex-row items-center justify-between px-2 py-4 md:px-4 md:py-3">
          <Typography className="text-lg font-medium">
            {intl.formatMessage({
              defaultMessage: "Transaction History",
              id: "transaction-history",
            })}
          </Typography>

          {/* as per discussion with UI/UX Designer for now the view all is static*/}
          <Typography className="text-base font-medium text-primary">
            {intl.formatMessage({
              defaultMessage: "View All",
              id: "view-all",
            })}
          </Typography>
        </View>

        {creditAccount ? (
          creditAccount.transactions.map((item) => (
            <TransactionHistoryCard key={item.id} transaction={item} icon />
          ))
        ) : (
          <View className="flex flex-1 flex-col items-center justify-center">
            <Image src={EmptyWallet} width={120} height={120} />
            <Typography className="text-sm text-foreground-intermediate">
              {intl.formatMessage({
                defaultMessage: "No transaction yet",
                id: "empty-transaction-message",
              })}
            </Typography>
          </View>
        )}
      </View>
    </View>
  );
};
