import {
  Avatar as AvatarUI,
  AvatarFallback,
  AvatarImage,
  Image,
} from "@/components/ui";
import { cn } from "@/lib/utils";
import DefaultWorker from "@/assets/images/worker.png";

export const WorkerAvatar = ({
  src = "",
  alt = "",
  className = "",
}: {
  src?: string;
  alt?: string;
  className?: string;
}) => {
  return (
    <AvatarUI alt={alt} className={cn("size-10 rounded-full", className)}>
      <AvatarImage src={src} />
      <AvatarFallback>
        <Image
          src={DefaultWorker}
          className="h-full w-full color-foreground-intermediate"
        />
      </AvatarFallback>
    </AvatarUI>
  );
};
