import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  View,
  Button,
} from "@/components/ui";
import { useProfileState } from "@/store/profile";
import { ChangePhoneNumberModalEnum } from "@/types/profile";
import { useAuthState } from "@/store/auth";
import { useUpdateAccountPhoneNumberStore } from "@/store/auth/updateAccountPhoneNumber";
import { ErrorMessage } from "@/components/shared/login-register/error-message";
import { getPlatform } from "@/lib/utils";
import { useRoute } from "@/components/shared/router";

export function CancelChangePhoneNumberModal({ open }: { open: boolean }) {
  const { push } = useRoute()
  const { showModal, data } = useProfileState();
  const {
    setChangePhoneOtp,
    data: { userInfo },
  } = useAuthState();

  const { fetch, loading, error } = useUpdateAccountPhoneNumberStore();

  const handleCancel = () => {
    showModal(ChangePhoneNumberModalEnum.CHANGE_PHONE_NUMBER_OTP_VERIFICATION);
  };

  const handleContinue = () => {
    fetch({
      requestPayload: {
        input: {
          clientId: userInfo.id,
          phoneNumber: data.phone,
        },
      },
    });
    showModal();
    setChangePhoneOtp(false);
    if (getPlatform() === "native") {
      push({ pageKey: "accountInfo" });
    }
  };

  return (
    <Dialog open={open}>
      <DialogContent className="w-[370px]">
        <DialogHeader className="flex flex-col gap-1">
          <DialogTitle>Cancel change phone number</DialogTitle>
          <DialogDescription>
            {`Are you sure you want to cancel? This action will discard your changes.`}
          </DialogDescription>
        </DialogHeader>
        <View className="flex flex-col items-stretch">
          <View className="flex flex-col gap-2">
            <Button
              variant="secondary"
              color="CTA2"
              loading={loading}
              disabled={loading}
              onClick={handleContinue}
              children="Yes, I want to cancel."
            />
            <Button
              variant="primary"
              loading={loading}
              disabled={loading}
              color="CTA"
              onClick={handleCancel}
              children="No, I want to change my phone number."
            />
          </View>
          {error && <ErrorMessage errorMessage={error} className="mt-4" />}
        </View>
      </DialogContent>
    </Dialog>
  );
}
